import { cloneDeep, findIndex } from "lodash";

export const initialValue = {
    devices: [],
    fetching: false,
    fetched: false,
    connected: false,
    connectedDevices: new Map(),
    pingedDevices: new Map(),
    currentDevice: undefined,
    devicesSearch: false,
    devicesSearchTimeout: undefined,
    devicesFound: [],
    startedAddressing: false,
    // piDelay: null,
    updateSend: new Map(),
    addressing: {
        address: 0,
        howManyAdressed: 0,
        toAddress: 0,
        protocol: "",
        history: []
    },
    search: {
        gateway: null,
        foundDevices: [],
        loading: false,
        loadingParams: {}
    },
    gateways: [],
    loadingGateways: false,
    alerts: {
        data: {},
        loading: {},
        errors: {},
        refreshTimes: {},
        timeouts: {}
    },
    alertsSearch: "",
    programs: {
        data: {},
        loading: false
    },
    agent: {
        data: {
            clients: [],
            test: []
        },
        loading: false,
        programs: []
    },
    backend: {
        programs: {
            dev: [],
            staging: [],
            prod: []
        },
        loading: false,
        jobs: {
            data: [],
            loading: false
        }
    },
    jobs: {
        jobs: [],
        loading: false,
        jobsExecutionLoading: {},
        jobsExecutionData: {}
    },
    scripts: {
        list: [],
        loading: false
    }
};

export default function devicesReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_DEVICES":
            return {
                ...state,
                fetching: false,
                fetched: true,
                devices: action.payload
            };
        case "DEVICES_CONNECTED": {
            let tmp = new Map(state.connectedDevices);
            for (let [key, value] of action.payload.entries()) {
                tmp.set(key, value);
            }
            return {
                ...state, connectedDevices: tmp
            };
        }
        case "PING_DEVICE": {
            let tmp = new Map(state.pingedDevices);
            for (let [key, value] of action.payload.entries()) {
                tmp.set(key, value);
            }
            return {
                ...state, pingedDevices: tmp
            };
        }
        case "SET_CURRENT_DEVICE":
            return {
                ...state, currentDevice: action.payload
            };
        case "START_DEVICES_SEARCH":
            return {
                ...state, devicesSearch: true, devicesSearchTimeout: action.payload, devicesFound: []
            };
        case "STOP_DEVICES_SEARCH":
            if (state.devicesSearchTimeout) clearTimeout(state.devicesSearchTimeout);
            return {
                ...state,
                devicesSearch: false,
                devicesSearchTimeout: undefined,
                devicesFound: action.payload ? action.payload : []
            };
        case "CHANGE_ADDRESSING": {
            return {
                ...state,
                startedAddressing: action.payload.state,
                addressing: {
                    address: action.payload.nextAddress || 0,
                    howManyAdressed: 0,
                    toAddress: action.payload.toAddress || 0,
                    protocol: action.payload.protocol || "",
                    history: []
                }
            };
        }
        case "STANDINGS_REFRESH_VALUES": {
            let tmp = cloneDeep(state.updateSend);
            tmp.set(action.payload.devID, action.payload);
            return {
                ...state, updateSend: tmp
            };
        }
        case "UPDATE_DEVICE_PENDING":
        case "ADD_DEVICE_TO_GATEWAY_PENDING":
        case "CREATE_DEVICE_PENDING": {
            return {
                ...state,
                fetching: true
            };
        }
        case "UPDATE_DEVICE_REJECTED":
        case "ADD_DEVICE_TO_GATEWAY_REJECTED":
        case "CREATE_DEVICE_REJECTED":
        case "CREATE_DEVICE_FULFILLED": {
            return {
                ...state,
                fetching: false
            };
        }
        case "CHANGE_ADDRESSING_STATE": {
            return {
                ...state,
                addressing: {
                    ...state.addressing,
                    ...action.payload,
                    history: [...state.addressing.history, state.addressing.address]
                }
            };
        }
        case "CHANGE_FARM":
            return {
                ...initialValue,
                gateways: state.gateways,
                alerts: state.alerts,
                loadingGateways: state.loadingGateways
            };
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "SEARCH_GW_CHANGE": {
            return { ...state, search: { ...state.search, gateway: action.payload, loading: false, foundDevices: [] } };
        }
        case "FOUND_ETH_DEVICE_DELTA": {
            if (state.search.gateway && !state.search.foundDevices.find(item => item.general.MAC === action.payload.general.MAC)) {
                return {
                    ...state,
                    search: {
                        ...state.search,
                        foundDevices: [...state.search.foundDevices, { ...action.payload, loaded: false }],
                        loading: false
                    }
                };
            }
            return state;
        }
        case "STARTED_SEARCHING": {
            return { ...state, search: { ...state.search, loading: true } };
        }
        case "GET_ETH_DEVICE_PARAMS_PENDING": {
            return {
                ...state,
                search: { ...state.search, loadingParams: { ...state.search.loadingParams, [action.meta.MAC]: true } }
            };
        }
        case "GET_ETH_DEVICE_PARAMS_REJECTED": {
            let foundDevices = state.search.foundDevices.slice(0);
            let index = findIndex(foundDevices, o => o.general.MAC === action.meta.MAC);
            if (index !== -1) {
                foundDevices[index] = { ...foundDevices[index], error: true };
                return {
                    ...state,
                    search: {
                        ...state.search,
                        loadingParams: { ...state.search.loadingParams, [action.meta.MAC]: false },
                        foundDevices
                    }
                };
            }
            return state;
        }
        case "GET_ETH_DEVICE_PARAMS_DELTA": {
            let foundDevices = state.search.foundDevices.slice(0);
            let index = findIndex(foundDevices, o => o.general.MAC === action.meta.MAC);
            if (index !== -1) {
                foundDevices[index] = { ...foundDevices[index], ...action.payload, loaded: true };
                return {
                    ...state,
                    search: {
                        ...state.search,
                        loadingParams: {
                            ...state.search.loadingParams,
                            [action.meta.MAC]: false
                        },
                        foundDevices
                    }
                };
            }
            return state;
        }
        case "CLEAR_SEARCH": {
            return { ...state, search: initialValue.search };
        }
        case "GET_GATEWAYS_PENDING":
            return { ...state, loadingGateways: state.gateways.length === 0 };
        case "GET_GATEWAYS_REJECTED":
            return { ...state, loadingGateways: false, gateways: [] };
        case "GET_GATEWAYS_FULFILLED":
            return { ...state, loadingGateways: false, gateways: action.payload };
        case "GET_GATEWAY_ALERTS": {
            let loading = { ...state.alerts.loading };
            loading[action.meta.ID] = true;
            let timeouts = { ...state.alerts.timeouts };
            timeouts[action.meta.ID] = action.meta.timeout;
            return { ...state, alerts: { ...state.alerts, loading, timeouts } };
        }
        case "GET_GATEWAY_ALERTS_FAILED": {
            let loading = { ...state.alerts.loading };
            loading[action.meta.ID] = false;
            let errors = { ...state.alerts.errors };
            errors[action.meta.ID] = true;
            let data = { ...state.alerts.data };
            delete data[action.meta.ID];
            let timeouts = { ...state.alerts.timeouts };
            clearTimeout(timeouts[action.meta.ID]);
            delete timeouts[action.meta.ID];
            return { ...state, alerts: { ...state.alerts, loading, errors, data, timeouts } };
        }
        case "GET_GATEWAY_ALERTS_STATE": {
            let loading = { ...state.alerts.loading };
            let data = { ...state.alerts.data };
            let refreshTimes = { ...state.alerts.refreshTimes };
            loading[action.meta.ID] = false;
            data[action.meta.ID] = action.payload;
            refreshTimes[action.meta.ID] = new Date();
            let errors = { ...state.alerts.errors };
            delete errors[action.meta.ID];
            let timeouts = { ...state.alerts.timeouts };
            clearTimeout(timeouts[action.meta.ID]);
            delete timeouts[action.meta.ID];
            return { ...state, alerts: { ...state.alerts, loading, data, refreshTimes, errors, timeouts } };
        }
        case "PREVIEW_ALERTS_FILTER_CHANGE": {
            return { ...state, alertsSearch: action.payload };
        }
        case "GET_DEVICE_PROGRAMS_PENDING": {
            return { ...state, programs: { ...state.programs, loading: action.meta.showLoading } };
        }
        case "GET_DEVICE_PROGRAMS_REJECTED": {
            return { ...state, programs: { ...state.programs, loading: false } };
        }
        case "GET_DEVICE_PROGRAMS_FULFILLED": {
            return { ...state, programs: { ...state.programs, loading: false, data: action.payload } };
        }
        case "GET_AGENT_LIST_PENDING": {
            return { ...state, agent: { ...state.agent, loading: true } };
        }
        case "GET_AGENT_LIST_REJECTED": {
            return { ...state, agent: { ...state.agent, loading: false } };
        }
        case "GET_AGENT_LIST_FULFILLED": {
            return { ...state, agent: { ...state.agent, loading: false, data: action.payload } };
        }
        case "GET_JOB_EXECUTION_STATUS_PENDING":
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    jobsExecutionLoading: { ...state.jobs.jobsExecutionLoading, [action.meta.jobId]: true }
                }
            };
        case "GET_JOB_EXECUTION_STATUS_REJECTED":
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    jobsExecutionLoading: { ...state.jobs.jobsExecutionLoading, [action.meta.jobId]: false }
                }
            };
        case "GET_JOB_EXECUTION_STATUS_FULFILLED":
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    jobsExecutionLoading: { ...state.jobs.jobsExecutionLoading, [action.meta.jobId]: false },
                    jobsExecutionData: { ...state.jobs.jobsExecutionData, [action.meta.jobId]: action.payload }
                }
            };
        case "GET_BACKEND_PROGRAMS_PENDING":
            return { ...state, backend: { ...state.backend, loading: true } };
        case "GET_BACKEND_PROGRAMS_REJECTED":
            return { ...state, backend: { ...state.backend, loading: false } };
        case "GET_BACKEND_PROGRAMS_FULFILLED":
            return { ...state, backend: { ...state.backend, loading: false, programs: action.payload } };
        case "GET_JOB_LIST_PENDING":
            return { ...state, jobs: { ...state.jobs, loading: true } };
        case "GET_JOB_LIST_REJECTED":
            return { ...state, jobs: { ...state.jobs, loading: false } };
        case "GET_JOB_LIST_FULFILLED":
            return { ...state, jobs: { ...state.jobs, jobs: action.payload, loading: false } };
        case "GET_AGENT_PROGRAM_LIST_PENDING":
            return { ...state, agent: { ...state.agent, loading: true } };
        case "GET_AGENT_PROGRAM_LIST_REJECTED":
            return { ...state, agent: { ...state.agent, loading: false } };
        case "GET_AGENT_PROGRAM_LIST_FULFILLED":
            return { ...state, agent: { ...state.agent, loading: false, programs: action.payload } };
        case "CLEAR_JOB_LIST":
            return { ...state, jobs: { ...state.jobs, jobs: [] } };
        case "GET_SCRIPT_LIST_PENDING":
            return { ...state, scripts: { ...state.scripts, loading: true } };
        case "GET_SCRIPT_LIST_REJECTED":
            return { ...state, scripts: { ...state.scripts, loading: false } };
        case "GET_SCRIPT_LIST_FULFILLED":
            return { ...state, scripts: { ...state.scripts, loading: false, list: action.payload } };
        case "OFFLINE_SYNC_DEVICES": {
            const devices = state.devices.slice();
            for (let device of action.payload) {
                const index = devices.findIndex(dev => dev.DevID === device.DevID);
                if (device.DtaDltTime) {
                    if (index !== -1) {
                        console.log("REMOVING DEVICE", device, index);
                        devices.splice(index, 1);
                    }
                } else {
                    if (index === -1) {
                        devices.push({ ...device, GatewayID: action.meta.GatewayID });
                    } else {
                        devices[index] = { ...device, GatewayID: action.meta.GatewayID };
                    }
                }
            }
            return { ...state, devices };
        }
        default:
            return state;
    }
}
