import { cloneDeep, get, isArray, isFinite, isNil, isString } from "lodash";
import memoizeOne from "memoize-one";
import { AnimalTypes } from "../constans/animalTypes";
import { Level } from "../constans/levelTypes";
import { SectorType } from "../constans/sectorTypes";
import i18n from "../i18n";
import { myID } from "../libs/generateID";
import SS0 from "../resources/images/standings-sorting/0.svg";
import SS1 from "../resources/images/standings-sorting/1.svg";
import SS2 from "../resources/images/standings-sorting/2.svg";
import SS3 from "../resources/images/standings-sorting/3.svg";
import SS4 from "../resources/images/standings-sorting/4.svg";
import SS5 from "../resources/images/standings-sorting/5.svg";
import SS6 from "../resources/images/standings-sorting/6.svg";
import SS7 from "../resources/images/standings-sorting/7.svg";
import { ValidationDefaultValues } from "../views/new-settings-view/validations/validation-values/ValidationDefaultValues";

/**
 *
 * @param name - Nazwa komory
 * @param individualFeeding - Indiwidualne karmienie
 * @param createStandings - Czy ma stworzyć stanowiska
 * @param chamberSize - Rozmiar komory
 * @param standingsInRow - Ilość stanowisk w rzędzie
 * @param standingsOrder - Rodzaj wyświetlania stanowiska 0-7
 * @param devices - Lista urządzeń w formacie [{DevID: "sdadada", Adr: 1}
 * @param standingsName - Nazwa początkowa stanowisk
 * @param standingsFrom - Liczba początkowa stanowisk
 * @param standingsAmount - Ilość stanowisk
 * @param standings - Lista stanowisk
 * @param id
 * @returns {{CName: *, CID: (*|string)}}
 */
export function generateChamber({
    name,
    individualFeeding,
    createStandings,
    chamberSize,
    standingsInRow,
    standingsOrder,
    devices,
    standingsName,
    standingsFrom,
    standingsAmount,
    standings,
    id
}) {
    const chamber = {
        CName: name,
        CID: id || myID()
    };
    //jesli jest to karmienie indywidualne
    if (individualFeeding) {
        chamber.IndividualFeeding = true;
        if (!(isFinite(standingsInRow) && standingsInRow > 0 && isFinite(standingsOrder) && standingsOrder >= 0 && standingsOrder <= 7)) {
            throw new Error(`ValidationError expected: standingsInRow number > 0, standingsOrder number <0...7> but got ${standingsInRow} ${standingsOrder}`);
        }
        chamber.StandsInRow = +standingsInRow;
        chamber.StandsOrder = +standingsOrder;
        chamber.Boxes = standings || [];
        if (devices) {
            if (devices.filter(dev => !dev.DevID).length) {
                throw new Error('ValidationError expected: devices [{DevID: string, Adr: number opt}]');
            }
            chamber.Devices = devices;
        } else {
            delete chamber.Devices;
        }
        if (createStandings) {
            chamber.Boxes = [...chamber.Boxes, ...generateStandings({
                name: standingsName,
                startFrom: +standingsFrom,
                amount: +standingsAmount
            })];
        }

    } else {
        if (typeof standingsAmount == 'number' && chamberSize > 0) {
            chamber.CSize = +chamberSize;
        } else {
            throw Error('ValidationError chamberSize (should be number & > 0');
        }
    }
    return chamber;
}


/**
 * Create standings with given paremeters
 * @param name
 * @param amount
 * @param startFrom
 * @returns {Array}
 */
export function generateStandings({ name, amount, startFrom }) {
    let boxes = [];
    if (isFinite(amount) && isFinite(startFrom) && amount > 0) {
        for (let i = +startFrom; i < +startFrom + +amount; i++) {
            let box = {
                BoxesName: `${name ? name : ''} ${i}`,
                BID: myID()
            };
            boxes.push(box);
        }
    } else {
        throw new Error(`ValidationError expected: amount - number > 0, startFrom number got ${amount} ${startFrom}`);
    }
    return boxes;
}

/**
 *
 * @param name
 * @param type
 * @param chambers
 * @returns {{SType: *, SName: *, SID: string, Chambers: Array}}
 */
export function generateSector({ name, type, chambers }) {
    if (name && isFinite(type)) {
        return {
            SID: myID(),
            SName: name,
            SType: type,
            Chambers: chambers || []
        };
    } else {
        throw new Error(`ValidationError expected name - string, type - number but got ${name} ${type}`);
    }

}

export function generateBuilding({ FarmID, name, sectors }) {
    const timestamp = +new Date();
    if (FarmID && name) {
        return {
            FarmID: FarmID,
            BgID: myID(),
            BName: name,
            Sectors: sectors || [],
            DtaCrtTime: timestamp,
            DtaModTime: timestamp
        };
    } else {
        throw new Error(`ValidationError expected name - string, FarmID - string but got ${name} ${FarmID}`);
    }

}


export function getLocationName(location = {}) {
    return location.CName || location.BName || location.SName || location.FarmName || location.BoxesName;
}

export function getLocationID(location = {}) {
    return location.CID || location.SID || location.BID || location.BgID || location.FarmID;
}

export function locationIsGroupChamber(location) {
    return location && [SectorType.BABY_ROOM, SectorType.PIGLET_HOUSE, SectorType.PORK_HOUSE].includes(location.sectorType || location.SType);
}

export function flatLocations(location, initialLocations = []) {
    // console.time("getFlatLocations");
    if (!location) {
        // console.timeEnd("getFlatLocations");
        return initialLocations;
    }
    let locations = initialLocations;
    if (getLocationID(location)) {
        locations.push(location);
    }
    let possibleChildKeys = ["Boxes", "Sectors", "Chambers", "Buildings"];
    possibleChildKeys.forEach(key => {
        if (location[key]) {
            location[key].forEach(child => {
                locations = flatLocations(child, locations);
            });
        }
    });
    console.timeEnd("getFlatLocations");
    return locations;
}

export function getFlatLocations(location) {
    return flatLocations(cloneDeep(location));
}

export function checkIfPlmntIDIncludesLocationID(plcmntID, locationID) {
    console.log("checkIfPlmntIDIncludesLocationID", arguments);
    if (isString(plcmntID)) {
        return plcmntID === locationID;
    } else if (isArray(plcmntID)) {
        return plcmntID.map(p => p.PlcmntID).includes(locationID);
    }
    return false;
}

/**
 * Zwraca ilość miejsc/stanowisk pogrupowane dla każdego rodzaju sektora
 * @param buildings - lista budynkow z stora (store.farms.buildings)
 * @return {{all: number}}
 */
export function getMaxAnimalCapacity(buildings = []) {
    const result = { all: 0 };
    for (let SType of Object.values(SectorType)) {
        result[SType] = 0;
    }
    buildings.forEach(building => {
        get(building, "Sectors", []).forEach(sector => {
            get(sector, "Chambers", []).forEach(chamber => {
                const SType = sector.SType;
                if (SType) {
                    if (chamber.IndividualFeeding) {
                        get(chamber, "Boxes", []).forEach(box => {
                            if (box) {
                                result[SType] += 1;
                            }
                        });
                    } else {
                        result[SType] += (chamber.CSize || 0);
                    }
                }
            });
        });
    });
    const total = Object.values(result).reduce((a, b) => a + b, 0);
    return {
        ...result,
        all: total
    };
}

export const getLocationNameByBuildingMapSelector = (map, id) => {
    const object = map.get(id);
    if (!object) return null;
    return object.name.join(" - ") || "?";
};


export const getPlcmntIDsByDevice = ({ device, index = null }) => {
    try {
        console.log(device, index, " E A E");
        return get(device, "PlcmntID", []).filter(item => {
            return !isNil(index) ? (item.Adr === index) : true;
        }).map(item => item.PlcmntID);
    } catch (e) {
        console.error(e);
        return [];
    }
};


export const convertBoxNameToNumber = (boxName) => {
    if (isFinite(boxName)) return boxName;
    try {
        const numStr = boxName.trim().split(" ").pop();
        if (numStr && isFinite(+numStr)) {
            return +numStr;
        }
    } catch (e) {
        console.error("convertBoxNameToNumber failed", e);
    }
    return null;
};


export const StandingOrderOptions = [
    {
        value: 0,
        svgPath: SS0,
        label: 'A1'
    },
    {
        value: 1,
        svgPath: SS1,
        label: 'A2'
    },
    {
        value: 2,
        svgPath: SS2,
        label: 'A3'
    },
    {
        value: 3,
        svgPath: SS3,
        label: 'A4'
    },
    {
        value: 4,
        svgPath: SS4,
        label: 'B1'
    },
    {
        value: 5,
        svgPath: SS5,
        label: 'B2'
    },
    {
        value: 6, svgPath: SS6, label: 'B3'
    },
    {
        value: 7,
        svgPath: SS7,
        label: 'B4'
    }
];

// nie pamietam dlaczego warchlaka moge wybrac na sektor loszek remontowych z location choosera ale pewnie mialo to jakis swoj powod wiec zostawiam
export function getAllowedSectorTypesByAnimalType(animalType) {
    switch (animalType) {
        case AnimalTypes.BOAR:
            return [SectorType.BOAR_HOUSE];
        case AnimalTypes.RENOVATION_SOW:
            return [SectorType.RENOVATION_SOWS, SectorType.MATING];
        case AnimalTypes.SOW:
            return [SectorType.SOWS, SectorType.DELIVERY, SectorType.RENOVATION_SOWS, SectorType.MATING];
        case AnimalTypes.PORKER:
            return [SectorType.RENOVATION_SOWS, SectorType.MATING, SectorType.PORK_HOUSE];
        case AnimalTypes.PIGLET:
            return [SectorType.RENOVATION_SOWS, SectorType.MATING, SectorType.BABY_ROOM, SectorType.PIGLET_HOUSE, SectorType.PORK_HOUSE];
        default:
            return [];
    }
}

// a tu juz typowo dla wprowadzenia na rfid
export function getAllowedSectorTypesByAnimalTypeForInsertion(animalType, validations) {
    const sectors = Object.values(SectorType);
    switch (animalType) {
        case AnimalTypes.BOAR: {
            if (get(validations, "boarOnlyOnBoarChamber", ValidationDefaultValues.boarOnlyOnBoarChamber)) {
                return [SectorType.BOAR_HOUSE];
            }
            return sectors;
        }
        case AnimalTypes.RENOVATION_SOW:
            return sectors; // https://redmine.wesstron.local/issues/9753
        case AnimalTypes.SOW:
            return [SectorType.SOWS, SectorType.DELIVERY, SectorType.MATING];
        case AnimalTypes.PORKER:
            return [SectorType.PORK_HOUSE];
        case AnimalTypes.PIGLET:
            return [SectorType.BABY_ROOM, SectorType.PIGLET_HOUSE];
        default:
            return [];
    }
}

export const getSectorTypes = (t = i18n.t) => _getSectorTypes(t);

const _getSectorTypes = memoizeOne((t) => {
    const maxIndex = Math.max(...Object.values(SectorType)) - 1;
    const sectorTypes = [];
    for (let i = 0; i <= maxIndex; i++) {
        sectorTypes[i] = t(`SType.${i}`);
    }
    return sectorTypes;
});

export function getAllowedSectorTypesByAnimalTypeForTransfer(animalType) {
    switch (animalType) {
        case AnimalTypes.BOAR:
        case AnimalTypes.RENOVATION_SOW:
        case AnimalTypes.SOW:
            return [...Object.values(SectorType)]; // kazdy typ sektora tylko dla loszkek, knurow, macior
        case AnimalTypes.PORKER:
            return [SectorType.PORK_HOUSE];
        case AnimalTypes.PIGLET:
            return [SectorType.BABY_ROOM, SectorType.PIGLET_HOUSE, SectorType.PORK_HOUSE, SectorType.RENOVATION_SOWS];
        default:
            return [];
    }
}

export const findParentByLevel = (currentId, level = Level.FARM, buildingList = []) => {
    const findBuilding = (parentId) => {
        if (!parentId) return null;
        const parent = buildingList.find(({ id }) => id === parentId);
        if (parent) {
            if (parent.level === level) return parent;
            return findBuilding(parent.parentId);
        }
        return null;
    };
    return findBuilding(currentId) || null;
};