import { useCallback } from "react";
import parametersDB from "../database/parametersDB";

export default function useQueryCaller() {

    return useCallback(async (query, params) => {
        try {
            console.log("useQueryCaller", query, params);
            return parametersDB.callQuery(query, params);
        } catch (e) {
            console.error(e);
        }
    }, []);
}