import React from "react";
import PropTypes from "prop-types"
import "./_checkbox.scss"
import {myID} from "../../../libs/generateID";

export default class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.id = myID();
    }


    onChange = e => {
        this.props.onChange(e?.target?.checked, this.props);
    };

    setAllowedValue = () => {
        const {readOnly, disabled, allowedValue, checked} = this.props;
        if ((readOnly || disabled) && allowedValue !== checked && [false, true].includes(allowedValue)) {
            this.onChange({target: {checked: !!allowedValue}});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {readOnly, disabled, allowedValue, checked} = this.props;
        if ((prevProps.readOnly !== readOnly) || (prevProps.disabled !== disabled) || (prevProps.allowedValue !== allowedValue) || (prevProps.checked !== checked)) {
            this.setAllowedValue();
        }
    }

    render() {
        const {
            label,
            id = this.id,
            disabled,
            checked,
            readOnly,
            required,
            divRef,
            margin,
            defaultChecked,
            isLoading,
            onClick,
            className
        } = this.props;
        let cName = "fetura-checkbox";
        if (!margin) cName += " margin-none";
        if (isLoading) cName += " is-loading";
        if (className) cName += ` ${className}`;
        return (
            <div className={cName} ref={divRef}>
                <input disabled={disabled} type="checkbox" id={id} onChange={this.onChange} checked={checked}
                       readOnly={readOnly} defaultChecked={defaultChecked}/>
                <label onClick={(disabled || readOnly) ? null : onClick} style={readOnly ? {pointerEvents: 'none'} : {}}
                       htmlFor={id}>
                    <span className={"checkbox"}/>
                    {!!label && '\u00A0'}
                    {label}
                    {!!required && <sup>*</sup>}
                </label>
            </div>
        );
    }

}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    divRef: PropTypes.object,
    margin: PropTypes.bool,
    allowedValue: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onClick: PropTypes.func
};

Checkbox.defaultProps = {
    onChange: (value) => {
    },
    // id: myID(),
    required: false,
    disabled: false,
    margin: true,
    defaultChecked: false
};
