import React from "react";
import {Field, getFormValues, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Checkbox from "../../basics/checkbox/Checkbox";
import { ReactLocalStorage } from "../../../utils/ReactLocalStorage";
import { changeMapSettings } from "../../../actions/editFarmMapActions";
import { getMapSettings } from "../../../selectors/mapSelector";
import { changeMapSettingsFailure, changeMapSettingsSuccess } from "../../../api/farmMap/farmMap";
export const ModalName = 'map-settings-modal';

function mapStateToProps(state, props) {
    return {
        formValues: getFormValues(ModalName)(state),
        initialData: getMapSettings(state)
    };
}
function submit(values, dispatch, props) {
    const {handleHide} = props;
    handleHide();
    try {
        ReactLocalStorage.setPrivate("mapSettings", values);
        dispatch(changeMapSettings(values));
        changeMapSettingsSuccess();
        handleHide();
    } catch (error) {
        changeMapSettingsFailure();
        console.error("table error:", values);
    }
}
class MapSettingsModal extends React.Component {
    constructor(props) {
        super(props);
        if(props.initialData){
            this.props.initialize(props.initialData)
        } else{
            this.props.initialize({
                amountOfAnimals: true,
                groups:true,
                climate: true,
                electric: true,
                water: true,
                diode: true,
                light: true,
            });
        }
        
    }
    renderCheckbox = ({input, label}) => (
        <Checkbox
            label={label}
            checked={input.value ? true : false}
            onChange={input.onChange}
        />
    );
    render() {
        const {show, handleHide, t, handleSubmit, submitting} = this.props;
        
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("mapSettings")}
                        onCloseClick={handleHide}
                    />
                    <ModalBody className={ModalName}>
                        <div className="checkboxes-wrapper">
                            <fieldset className="fieldset mb-3">
                                <legend>{t("map.settings.header")}</legend>
                                <Field
                                    name="amountOfAnimals"
                                    component={this.renderCheckbox}
                                    label={t("animalsCount")}
                                />
                                <Field
                                    name="groups"
                                    component={this.renderCheckbox}
                                    label={t("grid.groups")}
                                />
                                <Field
                                    name="climate"
                                    component={this.renderCheckbox}
                                    label={t("shadowDescription.CL_SK4._title")}
                                />
                                <Field
                                    name="electric"
                                    component={this.renderCheckbox}
                                    label={t("electricity")}
                                />
                                <Field
                                    name="water"
                                    component={this.renderCheckbox}
                                    label={t("water")}
                                />
                                <Field
                                    name="diode"
                                    component={this.renderCheckbox}
                                    label={t("diode")}
                                />
                                <Field
                                    name="light"
                                    component={this.renderCheckbox}
                                    label={t(
                                        "newSettings.buildings.farmMap.groups.lights"
                                    )}
                                />
                            </fieldset>
                        </div>
                    </ModalBody>
                    <ModalFooter
                        submitting={submitting}
                        hasConfirmButton={true}
                        confirmText={t("save")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
    })
)(MapSettingsModal)
