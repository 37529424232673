import { myID } from "../libs/generateID";

class ParametersDB {

    worker = new Worker(new URL("../workers/prepareAnimalParameters.worker.js", import.meta.url));
    messages = {};
    stackData = null;

    constructor() {
        this.worker.onmessage = this.onMessage;
    }

    onMessage = (event) => {
        console.log("ON MESSAGE", event);
        if (this.messages[event.data.id]) this.messages[event.data.id](event);
        delete this.messages[event.data.id];
    };

    sendMessageToWorker(type, data, onMessage) {
        const id = myID();
        this.messages[id] = onMessage;
        console.log("SENDING MESSAGE TO WORKER", type, data);
        this.worker.postMessage({ id, type, data });
    }

    callQuery(query, params) {
        return new Promise((resolve, reject) => {
            this.sendMessageToWorker("CALL_QUERY", { query, params }, (event) => {
                resolve(event.data.data.result);
            });
        });
    }

    calcParametersStacked(data, onMessage) {
        if (!this.stackData) {
            this.stackData = data;
            setTimeout(() => {
                this.sendMessageToWorker("");
            }, 15 * 1000);
        }
    }
}

const parametersDB = new ParametersDB();
export default parametersDB;