import {cloneDeep, get, isArray, isEqual, isString, memoize, merge, map, find} from "lodash";
import React from "react";
import config from "../conf/config";

/**
 * Funkcja służąca do szybszego klonowania obiektów. Nie używać jeśli chce się zachować klasy obiektu!
 * @param data
 * @returns {any}
 */
export function cloneFast(data) {
    if (data instanceof Object || Array.isArray(data)) {
        return JSON.parse(JSON.stringify(data));
    }
    return cloneDeep(data);
}

const round = (value) => +(value.toFixed(8));

export const getPrecision = (number) => {
    const string = `${number}`.trim().replace(",", ".");
    const value = string.split(".").filter(isString);
    return Math.pow(10, value.length === 2 ? value[1].length || 0 : 0);
};

/**
 * funkcja do bezpiecznego dodawania liczb zmiennoprzecinkowych
 * @param number1
 * @param number2
 * @return {number}
 */
export function safeAdd(number1, number2) {
    const precision = Math.max(getPrecision(number1), getPrecision(number2));
    return ((round(precision * number1) + round(number2 * precision)) / (precision));
}

/**
 * funkcja do bezpiecznego odejmowania liczb zmiennoprzecinkowych
 * @param number1
 * @param number2
 * @return {number}
 */
export function safeSubtract(number1, number2) {
    const precision = Math.max(getPrecision(number1), getPrecision(number2));
    return ((round(precision * number1) - round(number2 * precision)) / (precision));
}


/**
 * funkcja do bezpiecznego odejmowania liczb zmiennoprzecinkowych
 * @param number1
 * @param number2
 * @return {number}
 */
export function safeMultiply(number1, number2) {
    const precision = Math.max(getPrecision(number1), getPrecision(number2));
    return round((round(precision * number1) * round(number2 * precision)) / round(precision * precision));
}

/**
 * funkcja do bezpiecznego odejmowania liczb zmiennoprzecinkowych
 * @param number1
 * @param number2
 * @return {number}
 */
export function safeDivide(number1, number2) {
    const precision = Math.max(getPrecision(number1), getPrecision(number2));
    return round((round(precision * number1) / (number2)) / (precision));
}

/**
 * funkcja do bezpiecznego odejmowania liczb zmiennoprzecinkowych
 * @param number1
 * @param number2
 * @return {number}
 */
export function safeModulo(number1, number2) {
    const precision = Math.max(getPrecision(number1), getPrecision(number2));
    return ((round(precision * number1) % round(number2 * precision)) / (precision));
}


export function isStringifyEqual(value1, value2) {
    return JSON.stringify(value1) === JSON.stringify(value2);
}

export function getAverageValueFromArray(array) {
    return isArray(array) ? array.reduce((a, b) => a + b, 0) / array.length : null;
}

// tymczasowe ukrycie plików poza localhostem i developem
export function envIsLocalOrDevelop() {
    return process.env.REACT_APP_STAGE === "local" || process.env.REACT_APP_STAGE === "development";
}

export function getCountryFlag(lang, src = false, size = "24") {
    const countryCode = (lang === "zh" ? "cn" : lang === "en" ? "gb" : lang).toLowerCase();
    // todo - country flags padło
    // const source = `https://www.countryflags.io/${countryCode}/shiny/${size}.png`;
    const source = `https://flagcdn.com/24x18/${countryCode}.png`;
    if (src) return source;
    return <img alt={countryCode} src={source}/>;
}

export const isEqualLoose = (value1, value2) => {
    return value1 == value2;  // eslint-disable-line eqeqeq
};

export const getReferenced = memoize((object) => {
    return object;
}, (...args) => JSON.stringify(args));


export const getStageName = () => {
    return config.stageName;
};

/**
 * wrapper do zabezpieczenia prostych funkcji przed wywaleniem sie
 * @param f
 * @returns {(function(): (*|undefined))|*}
 */
export const catchify = (f) => {
    return function () {
        try {
            return f.apply(this, arguments);
        } catch (e) {
            console.warn("catched!");
            console.warn(e);
        }
    };
};

/**
 * evaluation of math expression
 * @param mathExpression
 * @return {null|*}
 */
export const mathEval = (mathExpression) => {
    if (/^([-+/*%]\d+(\.\d+)?)*/.test(mathExpression)) {
        // eslint-disable-next-line no-new-func
        return Function(`'use strict'; return (${mathExpression})`)();
    }
    console.warn("%s is not a match expression", mathExpression);
    return null;
};
/**
 * used to execute promises sequentially
 * @param fnList {array<function>} - array of promise factories
 * @return {Promise<[]>} Promise.allSettled lookalike
 */
export const executeSequentially = (fnList, nonBlocking = false) => {
    // used to store output from every promise
    let results = [];
    return fnList.reduce((current, nextFn) => {
        return current
            .then(() =>
                nextFn().then((r) => {
                    // task success
                    results.push({
                        status: "fulfilled",
                        value: r
                    });
                }))
            .catch((e) => {
                // task failed
                console.error("[executeSequentially]", e);
                results.push({
                    status: "rejected",
                    reason: e
                });
            });
    }, Promise.resolve([])).then(() => results);
};

export function waitUntil(conditionFunction, timeout = 100) {

    function poll(resolve) {
        if (conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), timeout);
    }

    return new Promise(poll);
}

export function getLanguageModule(language) {
    if (["en", "cimode"].includes(language)) return "en-gb";
    if (language === "zh") return "zh-cn";
    return language;
}

/**
 * use this if you want to compare list of unique elements (identificationPath should point to unique id) and you are interested only if something "important" changed (extraPaths)
 * when to use: "js expensive" selector which recalculates a lot due to bloat saved in some of the items you dont even use
 * did some benchmarks and with low number of extraPaths its 5% faster than _.isEqual - so it's not quite fast
 * @param identificationPath {string} path to unique id
 * @param extraPaths
 * @return {function(...[*]): boolean}
 */
export function makeIsEqualArrayByItemProperty(identificationPath, ...extraPaths) {
    return (...array) => {
        // if theres only 1 or 0 arrays return true
        if (array.length < 2) return true;
        // if one of the items is not an array or array length changed return with false
        if (array.some((a) => !isArray(a) || (a.length !== array[0].length))) return false;
        // we use dict like: "{key}": [{IndexOfKeyArray1}, {IndexOfKeyArray2}...{IndexOfKeyArrayN}]
        // so the look up will be faster
        const keyToIndex = new Map();
        const makeDefaultKeyToIndex = (key) => {
            // create key only if not currently exists, initialize with -1 (index not found)
            if (!keyToIndex.has(key)) {
                keyToIndex.set(key, new Array(array.length).fill(-1));
            }
        };
        // populate keyToIndex with real data
        for (let i = 0; i < array[0].length; i++) {
            for (let arrayIdx = 0; arrayIdx < array.length; arrayIdx++) {
                const key = `${get(array[arrayIdx][i], identificationPath)}`;
                makeDefaultKeyToIndex(key);
                keyToIndex.get(key)[arrayIdx] = i;
            }
        }
        // unique key number should be equal to array size
        if (keyToIndex.size !== array[0].length) return false;
        // check if any of the items changed
        for (let mapEntry of keyToIndex) {
            const indexes = mapEntry[1];
            // one of the item is not present in other arrays
            if (indexes.some((index) => index === -1)) return false;
            // check if items under given path changed or not
            const o1 = array[0][indexes[0]];
            for (let i = 1; i < indexes.length; i++) {
                const o2 = array[i][indexes[i]];
                if (extraPaths.some((path) => {
                    const item1 = get(o1, path);
                    const item2 = get(o2, path);
                    return !isEqual(item1, item2);
                })) {
                    return false;
                }

            }
        }
        return true;
    };
}

export const makeOptionsByMinMax = (min, max, step = 1) => {
    const options = [];
    let cur = min;
    while (cur <= max) {
        options.push(cur);
        cur += step;
    }
    return options;
};

export const getMapKeyByValue = (map, searchValue) => {
    for (let [key, value] of map.entries()) {
        if (isEqual(searchValue, value))
            return key;
    }
    return null;
};

export const createKeyToIndexDictionary = (array, key) => {
    const dict = {};
    for (let i = 0; i < array.length; i++) {
        dict[get(array[i], key)] = i;
    }
    return dict;
};

export const createKeyToValueDictionary = (array, dictionaryKey) => {
    const dict = {};
    for (let i = 0; i < array.length; i++) {
        dict[get(array[i], dictionaryKey)] = array[i];
    }
    return dict;
};

export const removeKeysByRegex = (obj, regex, clone = true) => {
    const copy = clone ? cloneDeep(obj) : obj;
    for (let key in copy) {
        if (!copy.hasOwnProperty(key)) continue;
        if (key.match(regex) !== null) {
            delete copy[key];
        }
    }
    return copy;
};

export const removeKeysStartingWithLodash = (obj, clone = false) => {
    return removeKeysByRegex(obj, new RegExp("^_", "g"), clone);
};

export function evalInScope(code, context) {
    try {
        // eslint-disable-next-line no-new-func
        return new Function(`with (this) { return (${code}); }`).call(context);
    } catch (e) {
        console.warn(e);
        return null;
    }
}

export const mergeByKey = (array1, array2, key) => {
    const cit = {[key]: null};
    return map(array1, (item) => {
        cit[key] = item[key];
        return merge(item, find(array2, cit));
    });
};
