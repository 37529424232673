import React, {useMemo} from "react";
import "./_alink.scss"
import PropTypes from "prop-types"
import {Link} from "react-router-dom";
import {useLink} from "../../../hooks/useLink";


const ALink = ({
                   children,
                   onClick,
                   href,
                   externalSite = false,
                   color = "success",
                   bolded = false,
                   uppercase = true,
                   disabled,
                   additionalClass,
               }) => {

    const className = useMemo(() => {
        let cName = "fetura-link";
        cName += ` ${color}`;
        if (bolded) cName += " fw-bold";
        if (uppercase) cName += " text-uppercase";
        if (disabled) cName += " disabled";
        if (additionalClass) cName += ` ${additionalClass}`;
        return cName;
    }, [color, bolded, uppercase, disabled, additionalClass]);

    const {url} = useLink(href);

    if (href && !externalSite) {
        return (
            <Link to={url} className={className}>{children}</Link>
        )
    }
    return (
        <a
            href={onClick ? null : url}
            className={className}
            onClick={onClick}
            {...externalSite && {rel: "noreferrer", target: "_blank"}}
        >
            {children}
        </a>
    );

}


ALink.propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error"]),
    bolded: PropTypes.bool,
    uppercase: PropTypes.bool,
    disabled: PropTypes.bool,
    externalSite: PropTypes.bool,
    linkInterpolation: PropTypes.bool // can pass :FarmID inside links which will get transformed to real farm id
};

export default ALink;