import NewIOT from "../NewIOT";
import {
    DispenserNRFCommandTypes,
    GatawayCommandTypes,
    MessageCommands
} from "../../constans/mqttMessages";
import store from "../../store/store";
import { getIOTAttributes } from "../../utils/IOTUtils";
import { removeRFIDsFromAnimalShadow } from "../../actions/shadowsActions";
import { isFunction } from "lodash";
import i18n from "../../i18n";
import { setFeedingForPig, setFeedingForPigLoading, setSyncingStatus } from "../../actions/feedingActions";
import {
    restoreClimateCurveFromGateway,
    restoreFeedingCurveFromGateway,
    restoreFeedingScheduleFromGateway
} from "../../actions/settingsAction";
import { makePromise } from "./TemplateFunctions";

export function setNutritionCurve(dev, curve) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_NUTRITION_CURVE, curve, null, resolve, reject);
        }
    });
}


export function getRFIDShadow(dev, RFID) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            return NewIOT.createAndSendMessageObjectToStateTopic(ClientID, GatewayID, LocalUserID, GatewayID, MessageCommands.GET_FEED_RFID_STATE, { RFID }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function setSchedule(dev, schedule) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_SCHEDULE, schedule, null, resolve, reject);
        }
    });

}

// export function setFeedEFCTime(dev, forage) {
//     const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
//     if (isValid && forage && forage.convertToBackend) {
//         return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_FEED_EFC_TIME, forage.convertToBackend(), {
//             loading: {
//                 title: i18n.t("configuration"),
//                 message: i18n.t("IOT.setEfficiencyTime", { name: Name }),
//                 status: 'loading',
//                 dismissible: false,
//                 dismissAfter: 0
//             },
//             success: {
//                 message: i18n.t("IOT.setEfficiencyTimeDone", { name: Name }),
//                 dismissible: true,
//                 dismissAfter: 3000,
//                 status: "success"
//             },
//             error: {
//                 message: i18n.t("IOT.setEfficiencyTimeFailed", { name: Name }),
//                 dismissible: true,
//                 dismissAfter: 3000,
//                 status: "error"
//             },
//             DevID: GatewayID
//         });
//     }

// }

export function getPig(dev, RFID, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_PIG, {
            type: "RFID",
            RFID: RFID
        }, null, onSuccess, onFailure);
    }
}

export function getDailyUsage(dev, locationID, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        let data = {
            PlcmntID: locationID
        };
        return NewIOT.createAndSendMessageObjectToStateTopic(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_DAILY_USAGE, data, null, onSuccess, onFailure);

    }
}


export function setChamberDevices(dev, chamberDevices = {}, onSuccess = undefined, onFailure = undefined) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_CHAMBER_DEVICES, chamberDevices, null, onSuccess, onFailure);
    }
}

export function addressWST(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.START_ADR_WST, data, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.startAddressing", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.startAddressingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.startAddressingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function addressNRF(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.START_ADDRESSING_NRF, data, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.startAddressing", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.startAddressingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.startAddressingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }

}

export function subaddressNRF(dev, start, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid, DevID } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, GatawayCommandTypes.ADDR_START_SUBADDRESSING_NRF, {
            state: start ? 1 : 0
        }, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: start ? i18n.t("IOT.startSubAddressing", { name: Name }) : i18n.t("IOT.stopSubAddressing", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: start ? i18n.t("IOT.startSubAddressingDone", { name: Name }) : i18n.t("IOT.stopSubAddressingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: start ? i18n.t("IOT.startSubAddressingFailed", { name: Name }) : i18n.t("IOT.stopSubAddressingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

export function stopAddressWST(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.STOP_ADR_WST, data, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.stopAddressing", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.stopAddressingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.stopAddressingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function stopAddressNRF(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.STOP_ADDRESSING_NRF, data, {
            loading: {
                title: i18n.t("IOT.addressing"),
                message: i18n.t("IOT.stopAddressing", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.stopAddressingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.stopAddressingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

export function getTime(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_GATEWAY_TIME, null, null, onSuccess, onFailure);
    }

}

export function openSSH(dev, port) {
    const { ClientID, LocalUserID, GatewayID, isValid, Name } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.OPEN_SSH, { port }, {
            loading: {
                title: i18n.t("IOT.openSSH"),
                message: i18n.t("IOT.openSSH", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.openSSHSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.openSSHFailure", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

export function ping(dev, onSuccess, onFailure, onSend) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.PING, null, null, onSuccess, onFailure, onSend);
    }
}


/**
 *
 * @param dev
 * @param rfids tablica stringów z numerami RFID do usunięcia
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function deletePig(dev, rfids, onSuccess = () => {
    store.dispatch(removeRFIDsFromAnimalShadow(rfids));
}, onFailure = () => {
}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    //console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.DELETE_PIG, { RFIDs: rfids }, {
            loading: {
                title: i18n.t("IOT.setDeletePig"),
                message: i18n.t("IOT.setDeletePigText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setDeletePigSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setDeletePigFailure", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }

}

export function synchronizeDevicesOnBackend(dev, DevIDs, showNotification) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_SYNC_DEVICES, { devices: DevIDs }, showNotification && {
            loading: {
                title: i18n.t("IOT.syncDevices"),
                message: i18n.t("IOT.syncDevicesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.syncDevicesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.syncDevicesFailure", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}


export const getNRFStatsAsync = makePromise(GatawayCommandTypes.GET_NRF_STATS,'loading device',['DevID'],{showNotification:false})

export function getNRFStats(dev, BridgeID, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_NRF_STATS, { DevID: BridgeID }, null, onSuccess, onFailure);
    }
}

export function setPlcmnts(dev, plcmnts) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_PLCMNTS, plcmnts, {
            loading: {
                title: i18n.t("IOT.setPlcmnts"),
                message: i18n.t("IOT.setPlcmntText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setPlcmntSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setPlcmntFailure", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

/**
 *
 * @param dev
 * @param type
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function getSchema(dev, type, onSend = () => {
}, onSuccess = () => {
}, onFailure = () => {
}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_SCHEMA, {
            type: type || "DI_NRF"
        }, {
            loading: {
                title: i18n.t("IOT.getSchema"),
                message: i18n.t("IOT.getSchemaText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.getSchemaSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.getSchemaFailure", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }
}

export function getQueuesLastSuccess(dev, onSuccess, onFailed) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_QUEUES_LAST_SUCCESS, null, null, onSuccess, onFailed);
    }
}

/**
 * Pobiera dane na rozne sposoby
 * 1. {RFID} - zwraca dane dla zwierzecia na podstawie RFID
 * 2. {AnmID} - zwraca dane dla zwierzecia na podstawie AnmID
 * 3. {PlcmntID, sortBy [consumption | RFID], acending [true | false], page, pageSize} - pobieranie zwierząt dla lokalizacji z mozliwoscia sortowania, i paginacji
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailed
 * @return {string}
 */
export function getPigsData(dev, data, onSuccess, onFailed) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_PIGS_DATA, data, null, onSuccess, onFailed);
    }
}

/**
 * Pobiera dane dla klimatu ascii
 * @param dev {Device}
 * @param start {number}
 * @param stop {number}
 * @param granularity {number}
 * @param onSuccess {callback}
 */
export function getAsciiClimateTestingData(dev, start, stop, granularity, onSuccess) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ASCII_CLIMATE_TESTING_DATA, {
            start,
            stop,
            granularity
        }, null, onSuccess);
    }
}

export function setScheduleOutput(dev, data, onSend = () => {
}, onSuccess = () => {
}, onFailure = () => {
}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SCHEDULE_OUTPUT, data, {
            loading: {
                title: i18n.t("IOT.setScheduleOutput"),
                message: i18n.t("IOT.setScheduleOutputText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setScheduleOutputSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setScheduleOutputError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }
}

export function setScheduleOutputMode(dev, data, onSend = () => {
}, onSuccess = () => {
}, onFailure = () => {
}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SCHEDULE_OUTPUT_MODE, data, {
            loading: {
                title: i18n.t("IOT.setScheduleOutputMode"),
                message: i18n.t("IOT.setScheduleOutputModeText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setScheduleOutputModeSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setScheduleOutputModeError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }
}

/**
 *
 * @param dev - gateway
 * @param DeviceIDs {array} {DevID: "asd" - zawsze, number: 1 - opcja}
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function clearAlarmsOnDevices(dev, { DeviceIDs } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CLEAR_PROBLEM, { DeviceIDs }, {
            loading: {
                title: i18n.t("IOT.clearDispenserNRFAlarm"),
                message: i18n.t("IOT.clearDispenserNRFAlarmText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.clearDispenserNRFAlarmSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.clearDispenserNRFAlarmError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

/**
 *
 * @param dev - gateway
 * @param devices: [
 {
                //NRF
                DevID: 'asd',	//string
                correctionChange: -10 // {-50, -45, ..., 45, 50}
            },
 {
                //WST
                DevID: 'qwe',	//string
                dispensers: [
                    {
                        number: 1,	//1-20
                        correctionChange: -15	// {-50, -45, ..., 45, 50}
                    },
                    ...1 - 20
                ]
            },
 ...∞
 ]
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function changeDispensersDoseCorrection(dev, { devices } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CHANGE_DISPENSERS_DOSE_CORRECTION, { devices }, {
            loading: {
                title: i18n.t("IOT.setChangeDispensersDoseCorrection"),
                message: i18n.t("IOT.setChangeDispensersDoseCorrectionText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setChangeDispensersDoseCorrectionSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setChangeDispensersDoseCorrectionError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

/**
 *
 * @param dev - gateway
 * @param devices: [
 {
        //NRF
        DevID: 'asd',	//string
        correction: -10 // {-50, -45, ..., 45, 50}
    },
 {
        //WST
        DevID: 'qwe',	//string
        dispensers: [
            {
                number: 1,	//1-20
                correction: -15	// {-50, -45, ..., 45, 50}
            },
            ...1 - 20
        ]
    },
 ...∞
 ]
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function setDispensersCondition(dev, { devices } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_DISPENSERS_DOSE_CORRECTION, { devices }, {
            loading: {
                title: i18n.t("condition"),
                message: i18n.t("IOT.setConditionText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setConditionSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setConditionError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

/**
 *
 * @param dev
 * @param devices devices: [
 {
        //NRF
        DevID: 'asd',	//string
        doses: 1, // {1 - 6}
        time: 456789987654 //opcjonalnie; timestamp
    },
 {
        //WST
        DevID: 'qwe',	//string
        dispensers: [
            {
                number: 1,	//1-20
                doses: 1, // {1 - 6}
                time: 456789987654 //opcjonalnie; timestamp
            },
            ...1 - 20
        ]
    },
 ...∞
 ]
 * @param onSend
 * @param onSuccess
 * @param onFailure
 * @return {string}
 */
export function skipDosesAfterTime(dev, { devices } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SKIP_DOSES_IN_TIME, { devices }, {
            loading: {
                title: i18n.t("IOT.skipDose"),
                message: i18n.t("IOT.setSkipDose", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setSkipDoseDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setSkipDoseFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure, onSend);
    }

}

export function getOnGoingAlarms(dev, { limit = 1000 } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ALL_ONGOING_ALARMS, {
            limit
        }, undefined, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) {
            onFailure();
        }
    }
}

export function getOnGoingAlarmsByCode(dev, { limit = 1000 } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ALL_ONGOING_ALARMS_BY_CODE, {
            limit
        }, undefined, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) {
            onFailure();
        }
    }
}

export function getAllAlarms(dev, LocalUserID, { limit = 0 } = {}, { onSend, onSuccess, onFailure } = {}) {
    let ClientID = dev.CliIDFaID_C.split("+")[0];
    return NewIOT.createAndSendMessageObject(ClientID, dev.DevID, LocalUserID, dev.DevID, GatawayCommandTypes.GET_ALL_ALARMS, {
        limit
    }, undefined, onSuccess, onFailure, onSend, dev.FarmID, true);
}

export function getGWAlertsUngrouped(dev, LocalUserID, { limit = 100, lastTime = undefined } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    let ClientID = dev.CliIDFaID_C.split("+")[0];
    return NewIOT.createAndSendMessageObject(ClientID, dev.DevID, LocalUserID, dev.DevID, GatawayCommandTypes.GET_GW_ALERTS_UNGROUPED, {
        limit,
        lastTime
    }, undefined, onSuccess, onFailure, onSend, dev.FarmID, true);
}

export function getAllAlarmsByCode(dev, { limit = 0 } = {}, {
    onSend,
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_ALL_ALARMS_BY_CODE, {
            limit
        }, undefined, onSuccess, onFailure, onSend);
    } else {
        if (isFunction(onFailure)) {
            onFailure();
        }
    }
}

export function setConfirmAlarm(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CONFIRM_ALARM, data, null, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

export function getUsageForPig(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_USAGE_FOR_PIG, data, null, onSuccess, onFailure);
    }
}

function onGetFeedingDataForPigSuccess(msg) {
    console.log("success", msg);
    store.dispatch(setFeedingForPig(msg.CAnsw));
    store.dispatch(setFeedingForPigLoading(msg.MsgId, msg.CAnsw, false));
}

function onGetFeedingDataForPigFailure(error, msg) {
    console.log("Fail", error, msg);
    store.dispatch(setFeedingForPigLoading(msg.MsgId, msg.CData, false));
}

export function getFeedingDataForPig(dev, data) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        let id = NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG, data, null, onGetFeedingDataForPigSuccess, onGetFeedingDataForPigFailure);
        store.dispatch(setFeedingForPigLoading(id, data, true));
        return id;
    }
}

export function getFeedingDataForPigSync(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        store.dispatch(setSyncingStatus(true));
        setTimeout(() => {
            store.dispatch(setSyncingStatus(false, true));
        }, 60 * 1000); // przez minute nie przyszlo wiec wylacz
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG_SYNC, null, null, onSuccess, onFailure);
    }
}

/**
 *
 * @param {Gateway} dev
 * @param {object} data
 * @param {array} data.animals tablica objektów {old: ..., new: ...} old i new maja postać {AnmID {string}, AnmNo1 {string|null}, RFID {string|null}, PlcmntID {string|null|array}}
 * @param {object} param2
 */
export function setAnimalChanges(dev, data, { onSuccess, onFailure } = {}) {
    console.log(data, "setAnimalChanges");
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_ANIMAL_CHANGES, data, null, onSuccess, onFailure);
    }
}

export function setNewInsertion(dev, data, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_NEW_INSERTION, data, {
            loading: {
                title: i18n.t("IOT.setNewInsertionTitle"),
                message: i18n.t("IOT.setNewInsertion", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setNewInsertionSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setNewInsertionFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }

}


export function setClimateCurve(dev, data) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CLIMATE_CURVE, data, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getDeviceLogs(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_DEVICE_LOGS, data, null, onSuccess, onFailure);
    }
}

export function searchETHDevices(dev, onSuccess) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SEARCH_ETH_DEVICES, {}, null, onSuccess);
    }
}

export function getParamETHDevice(dev, mac) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_PARAM_ETH_DEVICE, {
                MAC: mac
            }, null, resolve, reject);
        }
    });
}

export function setBasicParamETHDevice(dev, params, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_BASIC_PARAM_ON_ETH_DEVICE, params, null, onSuccess, onFailure);
    }
}

export function setPortParamOnETHDevice(dev, params, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_PORT_PARAM_ON_ETH_DEVICE, params, null, onSuccess, onFailure);
    }
}

export function setParamOnETHDevice(dev, params, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_PARAM_ON_ETH_DEVICE, params, null, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

export function verifyNRFSerialNumbers(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.VERIFY_NRF_SERIAL_NUMBERS, null, null, onSuccess, onFailure);
    }
}

export function saveNRFSerialNumbers(dev) {
    const { ClientID, LocalUserID, GatewayID, isValid, Name } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SAVE_NRF_SERIAL_NUMBERS, null, {
            loading: {
                title: i18n.t("IOT.saveNRFSerialNumbersTitle"),
                message: i18n.t("IOT.saveNRFSerialNumbers", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.saveNRFSerialNumbersSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.saveNRFSerialNumbersFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

export function restoreNRFSerialNumbers(dev) {
    const { ClientID, LocalUserID, GatewayID, isValid, Name } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.RESTORE_NRF_SERIAL_NUMBERS, null, {
            loading: {
                title: i18n.t("IOT.restoreNRFSerialNumbersTitle"),
                message: i18n.t("IOT.restoreNRFSerialNumbers", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.restoreNRFSerialNumbersSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.restoreNRFSerialNumbersFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

export function getGatewayAlerts(gateway, LocalUserID) {
    let ClientID = gateway.CliIDFaID_C.split("+")[0];
    return NewIOT.createAndSendMessageObject(ClientID, gateway.DevID, LocalUserID, gateway.DevID, GatawayCommandTypes.GET_GW_ALERT_COUNTER, null, null, null, null, null, gateway.FarmID, true);
}

export function setGWSendBinaryProgram(gateway, DevID, file) {
    const { ClientID, LocalUserID, GatewayID, isValid, Name } = getIOTAttributes(gateway);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_GW_SEND_BINARY_PROGRAM, {
            devId: DevID,
            file
        }, {
            loading: {
                title: i18n.t("IOT.setGWSendBinaryProgramTitle"),
                message: i18n.t("IOT.setGWSendBinaryProgram", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.setGWSendBinaryProgramSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.setGWSendBinaryProgramFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        });
    }
}

export function getUploadStatus(gateway) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_UPLOAD_STATUS, null, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function setStartUploadBinary(gateway, data) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid, Name } = getIOTAttributes(gateway);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_START_UPLOAD_BINARY, data, {
                loading: {
                    title: i18n.t("IOT.setStartUploadBinaryTitle"),
                    message: i18n.t("IOT.setStartUploadBinary", { name: Name }),
                    status: 'loading',
                    dismissible: false,
                    dismissAfter: 0
                },
                success: {
                    message: i18n.t("IOT.setStartUploadBinarySuccess", { name: Name }),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "success"
                },
                error: {
                    message: i18n.t("IOT.setStartUploadBinaryFailed", { name: Name }),
                    dismissible: true,
                    dismissAfter: 3000,
                    status: "error"
                },
                DevID: GatewayID
            }, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getProgramDetails(gateway, DevIDs) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_PROGRAM_DETAILS, { DevIDs }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getGatewayAlertsCategories(gateway, LocalUserID) {
    let ClientID = gateway.CliIDFaID_C.split("+")[0];
    return NewIOT.createAndSendMessageObject(ClientID, gateway.DevID, LocalUserID, gateway.DevID, GatawayCommandTypes.GET_GW_ALERT_COUNTERS_CATEGORIES, null, null, null, null, null, gateway.FarmID, true);
}

/**
 * Pobiera dane na rozne sposoby
 * 1. {RFID} - zwraca dane dla zwierzecia na podstawie RFID
 * 2. {AnmID} - zwraca dane dla zwierzecia na podstawie AnmID
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailed
 * @return {string}
 */
export function getRFIDPigHistory(dev, data, onSuccess, onFailed) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_RFID_PIG_HISTORY, data, null, onSuccess, onFailed);
    } else {
        if (isFunction(onFailed)) {
            onFailed();
        }
    }
}

const onGetGatewaySuccess = msg => {
    const gateway = msg.CAnsw;
    console.log("GWGWGWGW", gateway);
    if (gateway.Settings.Configuration?.[DispenserNRFCommandTypes.SET_NUTRITION_CURVE]) {
        for (let curve of gateway.Settings.Configuration[DispenserNRFCommandTypes.SET_NUTRITION_CURVE]) {
            if (curve) {
                store.dispatch(restoreFeedingCurveFromGateway(curve));
            }
        }
    }
    if (gateway.Settings.Configuration?.[DispenserNRFCommandTypes.SET_SCHEDULE]) {
        for (let schedule of gateway.Settings.Configuration[DispenserNRFCommandTypes.SET_SCHEDULE]) {
            if (schedule) {
                store.dispatch(restoreFeedingScheduleFromGateway(schedule));
            }
        }
    }
    if (gateway.Settings.Configuration?.[GatawayCommandTypes.SET_CLIMATE_CURVE]) {
        for (let curve of gateway.Settings.Configuration[GatawayCommandTypes.SET_CLIMATE_CURVE]) {
            if (curve) {
                store.dispatch(restoreClimateCurveFromGateway(curve));
            }
        }
    }
};

export function getGateway(dev) {
    const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_GATEWAY, null, null, onGetGatewaySuccess);
    }
}

export function setFeedersCalibration(gateway, data) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_FEEDERS_CALIBRATION, data, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getRpiIpAddresses(gateway) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_RPI_IP_ADDRESSES, null, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}


export function confirmSubaddressProcess(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CONFIRM_SUBADDRESS_PROCESS, data, {
            loading: {
                title: i18n.t("IOT.confirmSubaddressProcess"),
                message: i18n.t("IOT.confirmSubaddressProcessText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.confirmSubaddressProcessDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.confirmSubaddressProcessFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

export function setNutriProCurve(dev, curve) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_IPSUM_CURVE_V2, curve, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getIPSUMData(dev, PlcmntIDs) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_IPSUM_DATA, { PlcmntIDs }, null, resolve, reject, null, null, true);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getIPSUMDetails(dev, PlcmntID) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_IPSUM_DETAILS, { PlcmntID, newFormat: true }, null, resolve, reject, null, null, true);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getSettlements(dev) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_SETTLEMENTS, null, null, resolve, reject, null, null, true);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function confirmMBUSWaterSwap(gateway, DevID, isConfirmed, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, Name, isValid } = getIOTAttributes(gateway);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_CONFIRM_MBUS_WATER_SWAP, { DevID, isConfirmed }, {
            loading: {
                title: i18n.t("IOT.confirmMBUSWaterSwap"),
                message: i18n.t("IOT.confirmMBUSWaterSwapText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18n.t("IOT.confirmMBUSWaterSwapDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18n.t("IOT.confirmMBUSWaterSwapFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: GatewayID
        }, onSuccess, onFailure);
    }
    else {
        if (onFailure) onFailure(new Error("No MQTT Params"));
    }

}

export function setDeviceSettings(dev, data) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_SAVE_OFFLINE_SETTINGS, data, null, resolve, reject, null, null);
        } else {
            reject("unknown iot attributes");
        }
    });
}

export function getOfflineAggregatedData(gateway, DevID, prefix) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.GET_OFFLINE_AGGREGATED_DATA, { DevID: prefix ? `${prefix}#${DevID}` : DevID }, null, resolve, reject, null, null);
        } else {
            reject("unknown iot attributes");
        }
    });
}

export function getOfflineDevices(gateway) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, GatawayCommandTypes.SET_OFFLINE_FORCE_GLOBAL_SYNC, null, null, resolve, reject, null, null);
        } else {
            reject("unknown iot attributes");
        }
    });
}