import moment from "moment";
import { convertWeightToBaseUnit } from "../../../utils/UnitUtils";
import { getAnimalUnit } from "../../../utils/SettingsUtils";
import {
    editAnimalGroups,
    updateAnimalGroupsNotificationFailure,
    updateAnimalGroupsNotificationSuccess
} from "../../../api/animalGroups/editAnimalGroups";

export function submit(values, dispatch, props) {
    const { Weight = 0, GrNo1, HerdNumber, FirstInsertionTime, FirstBirthTime, SupplierID, _Comment } = values;
    const { group, initialValues } = props;
    const updateAnimals = (HerdNumber !== initialValues?.HerdNumber) || (FirstInsertionTime !== initialValues?.FirstInsertionTime) || (SupplierID !== initialValues?.SupplierID) || (FirstBirthTime !== initialValues?.FirstBirthTime);
    const row = {
        AnmGrp: group.AnmGrp,
        GrNo1,
        HerdNumber,
        SupplierID,
        updateAnimals,
        FirstInsertionTime: +moment(FirstInsertionTime),
        FirstBirthTime: +moment(FirstBirthTime),
        Weight: convertWeightToBaseUnit(+Weight, { fromUnit: getAnimalUnit() })
    };
    if (_Comment) {
        row.comment = _Comment;
    }
    return editAnimalGroups([row]).then((res) => {
        updateAnimalGroupsNotificationSuccess(res);
        props.handleHide();
    }).catch((err) => {
        updateAnimalGroupsNotificationFailure(err);
    });
}

export function validate(values, props) {
    const errors = {};
    const { t, animals, isService } = props;
    if (!values.GrNo1) errors.GrNo1 = t("required");
    if (!values.FirstInsertionTime) errors.FirstInsertionTime = t("required");
    else {
        if (!isService && moment(values.FirstInsertionTime).isAfter(new Date(), "days")) {
            errors.FirstInsertionTime = t("errors.doNotInsertEventInFuture");
        }
        if (values.FirstInsertionTime) {
            // wprowadzenie moze byc tylko pomiedzy urodzeniem a smiercia
            const firstBirth = animals.slice(0).filter((anm) => anm.DtaBrthTime).sort((o1, o2) => o1.DtaBrthTime - o2.DtaBrthTime)[0]?.DtaBrthTime;
            const firstDeath = animals.slice(0).filter((anm) => anm.DtaDthTime).sort((o1, o2) => o1.DtaDthTime - o2.DtaDthTime)[0]?.DtaDthTime;
            if (firstBirth && moment(firstBirth).isSameOrAfter(moment(values.FirstInsertionTime), "days")) {
                errors.FirstInsertionTime = `${t("errors.dateBefore")} ${moment(firstBirth).add(1, "day").format("L")}`;
            }
            if (firstDeath && moment(firstDeath).isSameOrBefore(moment(values.FirstInsertionTime), "days")) {
                errors.FirstInsertionTime = t("errors.dateAfter", { date: moment(firstDeath).subtract(1, "day").format("L") });
            }
        }
    }
    return errors;
}
