import moment from "moment";
import "moment-timezone";
import geoloc from "tz-lookup";
import store from "../store/store";
import {getFarm} from "../selectors/farmSelector";

export function getFarmZoneType(FarmID) {
    let farmTimezone = moment.tz.guess(true);
    if (FarmID) {
        try {
            let farm = getFarm(store.getState(), FarmID);
            if (Array.isArray(farm.FarmCord)) {
                farmTimezone = geoloc(farm.FarmCord[0], farm.FarmCord[1]);
            } else {
                let cords = farm.FarmCord.split(",");
                let x = 0;
                let y = 0;
                if (farm.FarmCord.includes("'")) {
                    x = +cords[0].split("'").join(".") || 0;
                    y = +cords[1].split("'").join(".") || 0;
                } else {
                    x = +cords[0] || 0;
                    y = +cords[1] || 0;
                }
                farmTimezone = geoloc(x, y);
            }
            return farmTimezone
        } catch (e) {
            console.error(e);
            farmTimezone = moment.tz.guess(true);
        }
    }
    return farmTimezone;
}
export function getWorkTime(worktime = null) {
        if(worktime)
        {
            let s = Math.floor((worktime / 1000) % 60);
            let m = Math.floor((worktime / (1000 * 60)) % 60);
            let h = Math.floor((worktime / (1000 * 60 * 60)) % 24);
            let d = Math.floor((worktime / (1000 * 60 * 60 * 24)))
            let seconds = (s < 10) ? "0" + s : s;
            let minutes = (m < 10) ? "0" + m : m;
            let hours = (h < 10) ? "0" + h : h;
            let days = (d < 10) ? "0" + d : d;
            return days + ":" + hours + ":" + minutes + ":" + seconds;
        }
        else return '-'
}
export const getFormattedDate = (ts) => moment(ts).format("DD.MM.YY");