import { flatten, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import animalsDB from "../database/animalsDB";
import groupsDB from "../database/groupsDB";
import { preEvents } from "../utils/AnimalDocumentsUtils";

export const initialValue = {
    selectedItem: null,
    events: [],
    fetchingEvent: false,
    cycleTable: []
};

/*
   Flow działania dokumentów lochy:
   1. INICIALIZACJA ZWIERZAT -> "INVENTORY_INITIALIZE"
   2. wybór zwierzeta -> "ANIMAL_DOCUMENTS_CHANGE_SELECTED_ITEM"
   3. zaladowanie eventów -> "LIST_EVENT_4_ANIMAL_FULFILLED"
   Opcionalne
   Gdy zwierzeta sie zmienia wolane jest INVENTORY_INITIALIZE i nowy obiekt swini sie znajduje w paylodzie to podmieniamy w dokumentach lochy
   Gdy dojdzie event to wolany jest LIST_EVENT_FULFILLED i wtedy dodawany jest do state.events oraz liczone jest cycleTable na nowo
 */

export default function animalDocumentsReducer(state = initialValue, action) {
    let R;
    switch (action.type) {
        case "GET_ANIMAL_MODIFICATION_TIME": {
            const stateObject = { ...state };
            const {payload: {anmIDs}} = action;

            if (!!state.selectedItem) {
                const { AnmID, AnmGrp, DtaDelTime } = state.selectedItem;
                const selectedID = AnmID || AnmGrp
                const selectedChanged = anmIDs && anmIDs.includes(selectedID)

                if (!anmIDs || selectedChanged) {
                    let newSelectedItem = null;
                    if (AnmID) {
                        newSelectedItem = animalsDB.getAnimalById(AnmID, {
                            joinEvents: true,
                            findDeleted: !!DtaDelTime,
                        });
                    }
                    if (state.selectedItem.AnmGrp) {
                        newSelectedItem = groupsDB.findGroupByID(AnmGrp);
                    }
                    stateObject["selectedItem"] = newSelectedItem;
                }
            }
            return stateObject;
        }
        case "ANIMAL_DOCUMENTS_CHANGE_SELECTED_ITEM": {
            const { payload: { item } } = action;
            const newSelectedItem = (item?.AnmGrp || item?.AnmID)
                ? item : null;
            return {
                ...initialValue,
                selectedItem: newSelectedItem
            }
        }
        //jesli zmieni sie lista zwierzat to jest wolane inventory initialize w widoku gdzie sa dokumenty - wiec updatujemy wybrana swinie
        case "INVENTORY_INITIALIZE": {
            const { animals, groups } = action.payload;
            let newSelectedItem;
            if (state.selectedItem?.AnmID) {
                newSelectedItem = animals?.find(a => a.AnmID === state.selectedItem.AnmID);
            }
            if (state.selectedItem?.AnmGrp) {
                newSelectedItem = groups.find(g => g.AnmGrp === state.selectedItem.AnmGrp);
            }
            if (newSelectedItem) {
                return {
                    ...state,
                    selectedItem: newSelectedItem
                }
            }
            return state;
        }
        case "LIST_EVENT_4_ANM_PENDING": //pobieranie eventow dla pojednyczych swin
        case "LIST_EVENT_4_ANIMAL_REJECTED":
        case "LIST_EVENT_4_ANIMAL_PENDING": {
            //jesli mamy wybrana swinie i pobieramy dla niej eventy to zmieniamy stora
            if (state.selectedItem?.AnmID) {
                if (get(action, "meta.AnmID") === state.selectedItem.AnmID) {
                    return {
                        ...state,
                        fetchingEvent: action.type.endsWith("_PENDING"),
                        events: action.type.endsWith("_PENDING") ? state.events : [],
                        cycleTable: action.type.endsWith("_PENDING") ? state.cycleTable : []
                    }
                }
            }
            return state;
        }
        case "LIST_EVENT_4_ANIMAL_FULFILLED":
            if (state.selectedItem?.AnmID) {
                if (get(action, "meta.AnmID") === state.selectedItem.AnmID) {
                    const animalDelTime = action?.meta?.animal?.DtaDelTime;
                    const events = !isEmpty(action.payload.items) ? action.payload.items.filter(ev => !animalDelTime ? ev.DtaDelTime === undefined : moment(ev.DtaDelTime).isBetween(moment(animalDelTime).subtract(1, "minute"), moment(animalDelTime), "minute", "[]")).sort((o1, o2) => o1.EvTime - o2.EvTime) : [];
                    R = preEvents(events, action.meta.animal, true, action.meta.cycleSettings);
                    return {
                        ...state,
                        fetchingEvent: false,
                        events,
                        cycleTable: R.cycleTable
                    };
                }
            }
            return state;

        // TODO - nie wiem za co ma odpowiadac fragment ten kodu po wejsciu na widok inny reducer juz to robi
        // case "LIST_EVENTS_FOR_ANIMALS_CYCLE_UPDATE":
        //     if (state.selectedItem instanceof Animal) {
        //         if (get(action, "meta.AnmID") === state.selectedItem.AnmID) {
        //             R = preEvents(action.meta.animal.events, action.meta.animal);
        //             return {
        //                 ...state,
        //                 cycleTable: R.cycleTable
        //             };
        //         }
        //     }
        //     return state;

        case "LIST_EVENTS_FOR_ANIMALS_REJECTED":
        case "LIST_EVENTS_FOR_ANIMALS_PENDING":
            if (state.selectedItem?.AnmGrp) {
                const key = "AnmGrp";
                if (get(action, `meta.${key}`) === state.selectedItem[key]) {
                    return {
                        ...state,
                        fetchingEvent: action.type.endsWith("_PENDING"),
                        cycleTable: action.type.endsWith("_PENDING") ? state.cycleTable : [],
                        events: action.type.endsWith("_PENDING") ? state.events : []
                    };
                }
            }
            return state;
        case "LIST_EVENTS_FOR_ANIMALS_FULFILLED":
            if (state.selectedItem?.AnmGrp) {
                const key = "AnmGrp";
                if (get(action, `meta.${key}`) === state.selectedItem[key]) {
                    return {
                        ...state,
                        fetchingEvent: false,
                        cycleTable: [],
                        events: flatten([...Object.values(action.payload)]).filter(ev => ev.DtaDelTime === undefined).sort((o1, o2) => o1.EvTime - o2.EvTime)
                    };
                }
            }
            return state;
        //jesli pobiora sie jakies eventy po wejsciu na widok
        case "LIST_EVENT_FULFILLED":
            if (state.selectedItem?.AnmID || state.selectedItem?.AnmGrp) {
                let newEvents = [...state.events];
                let changed = false;
                const deadTime = state.selectedItem.DtaDelTime;
                (action.payload.items || []).forEach(e => {
                    const eventAnmID = get(e, "AnmID");
                    if (eventAnmID) {
                        const animalIDs = state.selectedItem.AnmID ? [state.selectedItem.AnmID] : [...state.selectedItem[state.selectedItem.AnmGrp ? "AnmIDs" : "AnmList"], ...state.selectedItem.Rmvd]
                        if (animalIDs.includes(eventAnmID)) {
                            const existingEvent = newEvents.find(event => isEqual(event.EvID, e.EvID));
                            if (existingEvent && !!~newEvents.indexOf(existingEvent)) {
                                changed = true;
                                newEvents.splice(newEvents.indexOf(existingEvent), 1);
                            }
                            if ((!!deadTime && moment(e.DtaDelTime).isBetween(moment(deadTime).subtract(1, "minute"), moment(deadTime), "minute", "[]")) || !e.DtaDelTime) {
                                changed = true;
                                newEvents.push(e);
                            }
                        }
                    }
                });
                if (changed) {
                    newEvents = newEvents.sort((o1, o2) => o1.EvTime - o2.EvTime);
                    newEvents = newEvents.filter((ev) => !ev.DtaDelTime || (!!deadTime && moment(ev.DtaDelTime).isBetween(moment(deadTime).subtract(1, "minute"), moment(deadTime), "minute", "[]")))
                    return {
                        ...state,
                        events: newEvents,
                        cycleTable: state.selectedItem.AnmID ? preEvents(newEvents, state.selectedItem, true, action.meta.cycleSettings).cycleTable : []
                    };
                }
            }
            return state;

        case "ANIMAL_DOCUMENTS_RESET":
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
