import * as AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import {get, isEqual, isFunction, last, set} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {Col, Modal, Row} from "react-bootstrap";
import ReactDOM from "react-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {Field, initialize, isDirty, reduxForm} from "redux-form";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../../api/cycles/editSowCycle";
import {BoarTypes} from "../../../../constans/boarTypes";
import {EventTypes, USG_STATE} from "../../../../constans/eventTypes";
import animalsDB from "../../../../database/animalsDB";
import {getSelectedAnimalForDocuments} from "../../../../selectors/animalDocumentsSelectors";
import {getNoPregnancyReasons} from "../../../../selectors/dictionarySelectors";
import {getFarmID} from "../../../../selectors/farmSelector";
import {getValidationSettings} from "../../../../selectors/settingsSelector";
import {getAnimalOptions} from "../../../../utils/DataGridUtils";
import {isMobile} from "../../../../utils/MobileUtils";
import {getAnimalUnit} from "../../../../utils/SettingsUtils";
import {convertWeightToBaseUnit, convertWeightUnitTo, getUnit} from "../../../../utils/UnitUtils";
import Button from "../../../basics/button/Button";
import ReduxInput from "../../../basics/input/ReduxInput";
import ReduxSelect from "../../../basics/select/ReduxSelect";
import ModalBody from "../../../modals-new/ModalBody";
import ModalFooter from "../../../modals-new/ModalFooter";
import ModalHeader from "../../../modals-new/ModalHeader";
import {validateEvent} from "./EditEventPopupValidatorUtils";
import "./_edit-event-popup.scss";
import ConfirmModificationField from "../../../custom-fields/ConfirmModificationField";
import ReduxLabeledInput from "../../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../../basics/select/labeled-select/ReduxLabeledSelect";
import {upperFirstLetter} from "../../../../utils/TextUtils";

const FormName = "edit-event-popup-form";

function eventEqualZero(event){
    return (event.EvData?.PiCnt === 0 || event.EvData?.Weight === 0 || event.EvData?.Piglets === 0)
}

function submit(values, dispatch, props) {
    const {updateData: {event, path}, fieldName, animal: {AnmID, FarmID}, onHide} = props;
    let eventToUpdate = JSON.parse(JSON.stringify(event));
    delete eventToUpdate.$loki;
    let valueToSubmit = values[fieldName];
    if (["PiWeight", "Weight"].includes(fieldName)) {
        valueToSubmit = convertWeightToBaseUnit(+parseFloat(`${values[fieldName]}`.replace(",", ".")).toFixed(2), {
            fromUnit: getAnimalUnit()
        });
    }
    set(eventToUpdate, path, valueToSubmit);
    eventToUpdate.DtaModTime = +new Date();
    if(values?._Comment) {
        eventToUpdate._Comment = values._Comment;
    }
    //Lista event kodów do usunięcia gdy ktoś wpiszę wartość 0
    const eventsToDeleteWhenZeroList = [EventTypes.MOMMY, EventTypes.SEPARATION_TO_MOMMY, EventTypes.WEIGHTING, EventTypes.CASTRATION, EventTypes.SOW_CYCLES, EventTypes.SEPARATION, EventTypes.FALL_PIGLETS]
    let data = {AnmID, eventsToUpdate: [eventToUpdate], eventsToDelete: []}
    //Jeżeli ktoś wpisał wartość 0  to usuwa event
    if(eventsToDeleteWhenZeroList.includes(eventToUpdate.EvCode) && eventEqualZero(eventToUpdate)){
        data = {AnmID, eventsToUpdate: [], eventsToDelete: [eventToUpdate]}
    }
    return editSowCycle(data, {FarmID}).then((res) => {
        onHide();
        editSowCycleNotificationSuccess(res);
    }).catch((e) => {
        editSowCycleNotificationFailure(e);
    });
}

function validate(values, props) {
    const {fieldName, updateData: {path, event}, cycleTable, animal, validations} = props;
    const errors = {};
    let valueToValidate = values[fieldName];
    if (["PiWeight", "Weight"].includes(fieldName)) {
        valueToValidate = +parseFloat(`${values[fieldName]}`.replace(",", ".")).toFixed(2)
    }
    const isValid = validateEvent(path, event, valueToValidate, cycleTable, animal, validations);
    if (!isValid.result) errors[fieldName] = isValid.message;
    return errors;
}

function mapStateToProps(state, props) {
    const fieldName = last(get(props, "updateData.path", "").split("."));
    return {
        fieldName,
        dirty: isDirty(FormName)(state),
        animal: getSelectedAnimalForDocuments(state),
        cycleTable: state.animalDocuments.cycleTable,
        farm: getFarmID(state),
        noPregnancyReason: getNoPregnancyReasons(state),
        validations: getValidationSettings(state)
    }
}

const EventField = ({fieldName, field}) => (
    <Field
        name={fieldName}
        component={field.component}
        type={field.type}
        label={field.label}
        unit={field.unit}
        options={field.options}
        showIconOnErrorOnWarning={true}
        parse={value => value && isFunction(field.parse) ? field.parse(value) : value}
        format={value => value && isFunction(field.format) ? field.format(value) : value}
        clearButton={false}
        focusOnClosestInput={false}
    />
)

const EventPopup = ({t, handleSubmit, fieldName, field, submitting, dirty, onHide, invalid, animal, eventName}) => (
    <form onSubmit={handleSubmit}>
        <Row>
            <Col xs={12}>
                <fieldset className="fieldset mb-2">
                    <legend>{eventName}</legend>
                    <EventField field={field} fieldName={fieldName}/>
                </fieldset>
            </Col>
            <Col xs={12}>
                <ConfirmModificationField minimal name={animal?.AnmNo1}/>
            </Col>
            <Col xs={6}>
                <Button className={"w-100 m-0"} buttonStyle={"bordered"} type={"button"}
                        text={t("close")} onClick={onHide}
                        disabled={submitting}
                />
            </Col>
            <Col xs={6}>
                <Button className={"w-100 m-0"} buttonColor={"success"} isLoading={submitting}
                        text={t("save")} disabled={invalid || !dirty}
                />
            </Col>
        </Row>
    </form>
)

class EditEventPopup extends Component {

    constructor(props) {
        super(props);
        this.setInitialValues();
        this.child = React.createRef();
        this.modal = document.getElementsByClassName("view-container-modal")[0];
        this.mobile = isMobile();
    }

    componentDidMount() {
        if (!this.mobile) {
            if (this.child.current) {
                const {cellRect} = this.props;
                let left = cellRect.x + cellRect.width + 10;
                let childRect = this.child.current.getBoundingClientRect();
                let modalRect = this.modal.getBoundingClientRect();
                if (left + childRect.width > modalRect.right) {
                    left = cellRect.x - childRect.width - 10;
                    this.child.current.classList.add("right");
                } else {
                    this.child.current.classList.add("left");
                }

                let top = cellRect.y + (cellRect.height / 2) - (childRect.height / 2);
                let arrowTop = (childRect.height / 2) - 10;

                if (top < modalRect.top) {
                    top = modalRect.top + 10;
                    arrowTop = cellRect.y - modalRect.top + (cellRect.height / 2) - 10;
                } else if (top + childRect.height > modalRect.bottom) {
                    top = modalRect.bottom - childRect.height - 10;
                    arrowTop = cellRect.y - modalRect.bottom + childRect.height + (cellRect.height / 2) - 10;
                }

                this.child.current.style.left = `${left}px`;
                this.child.current.style.top = `${top}px`;
                this.child.current.style.setProperty("--arrow-top", `${arrowTop}px`)
            }
            document.addEventListener("mousedown", this.handleClickOutside);
            if (this.modal) this.modal.addEventListener("scroll", this.onScroll);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        if (this.modal) this.modal.removeEventListener("scroll", this.onScroll);
    }

    handleClickOutside = (event) => {
        event.stopPropagation();
        if (this.child?.current && !this.child.current.contains(event.target) && !event?.target?.className?.includes("select-item")) {
            this.props.onHide();
        }
        return false;
    };

    onScroll = () => {
        if (this.child.current && this.modal) this.props.onHide();
    };

    setInitialValues() {
        const {dispatch, updateData: {path, event}, fieldName} = this.props;
        let initialValue = get(event, path);
        if (["PiWeight", "Weight"].includes(fieldName)) {
            initialValue = convertWeightUnitTo(+get(event, path, 0), {
                unit: getAnimalUnit(),
                showUnit: false,
                fixed: 2,
                rawValue: true
            })
        }
        dispatch(initialize(FormName, {[fieldName]: initialValue}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.updateData, this.props.updateData)) {
            this.setInitialValues();
        }
    }

    getBoars() {
        const {farm} = this.props;
        let boars = animalsDB.getAllAnimals(farm, AnimalTypes.BOAR, true, false).filter((animal) => animal.BoarType !== BoarTypes.FARM);
        return getAnimalOptions(boars).map((o) => ({
            name: o.name,
            value: get(o, "value.AnmID", null)
        }));
    };

    getUsgStates() {
        const {t} = this.props;
        return [
            {name: t("events.usgEvent.negative"), value: USG_STATE.NEGATIVE},
            {name: t("events.usgEvent.positive"), value: USG_STATE.POSITIVE},
            {name: t("events.usgEvent.toRepeat"), value: USG_STATE.REPEAT},
            {name: t("events.usgEvent.toRepeatNegative"), value: USG_STATE.REPEAT_NEGATIVE},
            {name: t("events.usgEvent.toRepeatPositive"), value: USG_STATE.REPEAT_POSITIVE},
        ];
    };

    getNoPregnancyReasons() {
        const {noPregnancyReason} = this.props;
        return get(noPregnancyReason, `WData`, []).map((item) => ({name: item.Value, value: item.ID}));
    };

    getField(field, EvCode, label) {
        const fieldObject = {type: "number", component: label ? ReduxLabeledInput : ReduxInput};
        if (label) fieldObject.label = upperFirstLetter(label);
        const _DateFormat = "YYYY-MM-DD";
        const getSelectDetails = () => {
            if (field === "BoarID") fieldObject.options = this.getBoars();
            else if (field === "Pregnant") fieldObject.options = this.getUsgStates();
            else if (field === "Reason") fieldObject.options = this.getNoPregnancyReasons();
            return [];
        };
        switch (field) {
            case "EvTime":
                fieldObject.type = "date";
                fieldObject.parse = (value) => [EventTypes.INSEMINATION, EventTypes.SEPARATION].includes(EvCode) ? +moment.utc(moment(value).format(_DateFormat)) : moment(value).toDate().getTime();
                fieldObject.format = (timestamp) => [EventTypes.INSEMINATION, EventTypes.SEPARATION].includes(EvCode) ? moment.utc(timestamp).format(_DateFormat) : moment(timestamp).format(_DateFormat);
                break;
            case "PiWeight":
            case "Weight":
                fieldObject.type = "text";
                fieldObject.unit = getUnit("weight", getAnimalUnit());
                break;
            case "BoarID":
            case "Pregnant":
            case "Reason":
                fieldObject.component = label ? ReduxLabeledSelect : ReduxSelect;
                getSelectDetails();
                break;
            default:
                fieldObject.type = "number";
                fieldObject.parse = (value) => +value;
                break;
        }
        return fieldObject;
    };

    createPopup() {
        const {fieldName, updateData, onHide, handleSubmit, submitting, t, dirty, animal, invalid} = this.props;
        const label = updateData?.label ?? null;
        const evCode = get(updateData, "event.EvCode");
        const field = this.getField(fieldName, evCode, label);
        const eventName = t(`eventTypes.${evCode}`);
        if (this.mobile) {
            return (
                <Modal className="event-popup-mobile-modal" onHide={onHide} show={true} size={"xs"}>
                    <form onSubmit={handleSubmit}>
                        <ModalHeader title={eventName ? `${t("editEvent")} - ${eventName}` : t("editEvent")}
                                     onCloseClick={onHide}/>
                        <ModalBody>
                            <EventField {...this.props} field={field}/>
                            <ConfirmModificationField name={animal?.AnmNo1}/>
                        </ModalBody>
                        <ModalFooter submitting={submitting} hasConfirmButton={true} confirmText={t("save")}
                                     onCloseClick={onHide} formName={FormName} hasButtonDisabled={invalid || !dirty}/>
                    </form>
                </Modal>
            )
        }
        return (
            <div ref={this.child} className={`event-popup`}>
                <EventPopup {...this.props} eventName={eventName} field={field}/>
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(this.createPopup(), document.getElementById("root") || document.createElement("div"));
    }

}

EditEventPopup.propTypes = {
    updateData: PropTypes.object.isRequired
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    reduxForm({
        form: FormName,
        onSubmit: submit,
        validate
    })
)(EditEventPopup)
