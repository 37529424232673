import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import { isNil, lowerFirst, memoize, uniq } from "lodash";
import moment from "moment";
import { USG_STATE } from "../constans/eventTypes";
import { GraftingProgramApplicationTypes } from "../constans/graftingProgramApplicationTypes";
import i18n from "../i18n";
import store from "../store/store";
import { preEvents } from "./AnimalDocumentsUtils";
import {
    getFirstTechnologyGroupStart,
    getTechnologyGroupWeeks,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy,
    getTimeOnMatingRoom,
    getTimeOnSowsRoom
} from "./SettingsUtils";
import { createKeyToValueDictionary } from "./Utils";

export const REMOVE_TECHNOLOGY_GROUP_REASONS = {
    INSEMINATION_OTHER_WEEK: 0,
    NEGATIVE_USG: 1,
    NO_PREGNANCY: 2,
    SEPARATION: 3,
    FALL: 4,
    SELL: 5,
    NEXT_CYCLE: 6,
    NO_PARTURITION_EVENT: 7,
    IS_MOMMY: 8, // mozliwe ze bedzie 9
};

export const ANIMALS_IN_GROUP = `SELECT ARRAY(DISTINCT AnmID) as AnmIDs FROM (SELECT AnmID, AnmNo1, LAST(CASE WHEN key = 'technologyGroupRemoval' THEN [value] END) as removeReason FROM ParamsTable WHERE group_nr = ? GROUP BY AnmID ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC) WHERE removeReason IS NULL OR removeReason = ${REMOVE_TECHNOLOGY_GROUP_REASONS.SEPARATION}`;
const GROUPS_FOR_ANIMALS = "SELECT LAST(group_nr) as group_nr FROM ParamsTable WHERE actCycle = true AND AnmID IN @(?) GROUP BY AnmID";
export const TECHNOLOGY_GROUP_GRID = `
WITH
groupInseminationsCte AS (SELECT * FROM ParamsTable WHERE key = 'boar' AND group_nr IN @(?) ORDER BY dataEv),
removedCte AS (SELECT group_nr, AnmID, [value] FROM ParamsTable WHERE key = 'technologyGroupRemoval' AND group_nr IN @(?) AND [value] <> ${REMOVE_TECHNOLOGY_GROUP_REASONS.SEPARATION} ORDER BY dataEv),
animalInseminationCte AS (SELECT AnmID, FIRST(cycle) as cycle, FIRST(dataEv) as dataEv, FIRST(group_nr) as group_nr FROM groupInseminationsCte GROUP BY group_nr, AnmID),
dataCte AS (SELECT animalInseminationCte.*, removedCte.[value] IS NOT NULL as isRemoved FROM animalInseminationCte LEFT JOIN removedCte ON CONCAT(animalInseminationCte.AnmID, animalInseminationCte.group_nr) = CONCAT(removedCte.AnmID, removedCte.group_nr))
SELECT 
    DATEADD(week, DATE_DIFF_UTC('week', ?, FIRST(dataEv)), ?) as week,
	FIRST(group_nr) as group_nr, 
	ARRAY_NOT_NULL(DISTINCT CASE WHEN cycle = CONST('FIRST_CYCLE_INDEX') THEN AnmID END) as firstCycleSows, 
	ARRAY_NOT_NULL(DISTINCT CASE WHEN cycle > CONST('FIRST_CYCLE_INDEX') THEN AnmID END) as sows,
	ARRAY_NOT_NULL(DISTINCT CASE WHEN cycle = CONST('FIRST_CYCLE_INDEX') AND isRemoved = true THEN AnmID END) as removedFirstCycleSows, 
	ARRAY_NOT_NULL(DISTINCT CASE WHEN cycle > CONST('FIRST_CYCLE_INDEX') AND isRemoved = true THEN AnmID END) as removedSows
FROM dataCte GROUP BY DATEADD(week, DATE_DIFF_UTC('week', ?, dataEv), ?)
`;
export const TECHNOLOGY_GROUP_ANIMAL_GRID = `
SELECT 
	AnmID,
	FIRST_NOT_NULL(CASE WHEN key = 'boar' THEN dataEv END) as inseminationDate,
	FIRST(cycle) as cycle,
	ARRAY_NOT_NULL(CASE WHEN key = 'usgResult' THEN {date: dataEv, result: [value]} END) as usg,
	FIRST_NOT_NULL(CASE WHEN key = 'expectedFarrowing' THEN [value] END) as expectedFarrowing,
	FIRST_NOT_NULL(CASE WHEN key = 'shiftFarrowDay' THEN {date: dataEv, diff: [value]} END) as farrowing,
	FIRST_NOT_NULL(CASE WHEN key = 'weanedPiglet' THEN {date: dataEv, amount: [value]} END) as separation,
    FIRST_NOT_NULL(CASE WHEN key = 'technologyGroupRemoval' THEN {reason: [value]} END) as removed,
    FIRST_NOT_NULL(CASE WHEN key = 'isMommy' THEN [value] END) as isMommy,
    ARRAY_NOT_NULL(CASE WHEN key = 'vaccination' THEN {date: dataEv, medicine: [value]} END ) as vaccinations,
    ARRAY_NOT_NULL(CASE WHEN key = 'pigletVaccination' THEN {date: dataEv, medicine: [value]} END ) as pigletVaccinations
FROM (SELECT * FROM ParamsTable WHERE group_nr = ? ORDER BY dataEv) WHERE AnmID IS NOT NULL GROUP BY AnmID -- AnmID IS NOT NULL musi być, ponieważ pigletRoomSeparation nie ma AnmID i psuje rekordy
`;

export const TECHNOLOGY_GROUPS_ANIMALS = `
SELECT 
  group_nr, 
  ARRAY(DISTINCT AnmID) as animals 
FROM 
  (
    SELECT 
      AnmID, 
      group_nr, 
      FIRST_NOT_NULL(
        CASE WHEN key = 'technologyGroupRemoval' THEN [value] END
      ) as removeReason 
    FROM 
      ParamsTable 
    WHERE 
      (
        key = 'boar' 
        OR key = 'technologyGroupRemoval'
      )
      AND group_nr IN @(?)
    GROUP BY 
      AnmID, 
      group_nr
  ) 
WHERE 
  removeReason IS NULL 
  OR removeReason = ${REMOVE_TECHNOLOGY_GROUP_REASONS.SEPARATION} 
GROUP BY 
  group_nr
`;

export const TECHNOLOGY_GROUP_TASKS = {
    MATING: "mating",
    USG: "USG",
    TRANSFER_SOWS: "transferToSows",
    TRANSFER_DELIVERY: "transferToDelivery",
    PARTURITION: "parturition",
    SEPARATION: "separation",
    GRAFTING: "grafting"
};

export function getCreateDay() {
    try {
        let state = store.getState();
        return state.technologyGroup.createDay[0].createDay;
    } catch (e) {
        return 0;
    }

}

export function isRemovedFromTechnologyGroup(insemination, events, animal, cycleTable = null, {
    technologyGroupWeeks,
    technologyGroupStart
} = {}) {
    const inseminationDate = moment.utc(insemination.EvTime);
    const { end } = getTechnologyGroupByTime(+inseminationDate, technologyGroupStart, technologyGroupWeeks);
    const max = +end;
    // const { max } = getTechnologyGroupByWeek(inseminationDate.isoWeek(), inseminationDate.year(), {
    //     technologyGroupWeeks,
    //     technologyGroupStart
    // });
    // let otherAnimalEvents = events.filter(item => item.AnmID === insemination.AnmID && !item.DtaDelTime && moment.utc(item.EvTime).isBetween(insemination.EvTime, new Date(), "[]"));
    let otherAnimalEvents = events.filter(item => item.AnmID === insemination.AnmID && !item.DtaDelTime);
    let cycles = cycleTable ? { cycleTable: cycleTable.slice() } : preEvents(otherAnimalEvents, animal);
    let cycle = null;
    for (let c of cycles.cycleTable) {
        if (c[EventTypes.INSEMINATION].find(item => item.EvID === insemination.EvID)) {
            cycle = c;
            break;
        }
    }
    if (cycle[EventTypes.IS_MOMMY].length > 0) {
        return {
            reason: REMOVE_TECHNOLOGY_GROUP_REASONS.IS_MOMMY,
            event: cycle[EventTypes.IS_MOMMY][0]
        };
    }
    // sprawdzanie czy bylo odsadzenie prosiat, poniewaz odsadzenie zostawia w grupie
    let separation = cycle[EventTypes.SEPARATION][0];
    if (separation) return null;
    // sprawdzanie czy miala negatywne badanie usg po inseminacji
    let usg = cycle[EventTypes.USG].find(item => item.EvData.Pregnant === USG_STATE.NEGATIVE);
    if (usg) return {
        reason: REMOVE_TECHNOLOGY_GROUP_REASONS.NEGATIVE_USG,
        event: usg
    };
    // sprawdzanie czy byl zlogoszony brak ciazy
    let noPregnancy = cycle[EventTypes.NO_PREGNANCY][0];
    if (noPregnancy) return {
        reason: REMOVE_TECHNOLOGY_GROUP_REASONS.NO_PREGNANCY,
        event: noPregnancy
    };
    // sprawdzanie czy byla inseminacja w kolejny tygodniu
    otherAnimalEvents = otherAnimalEvents.filter(item => moment.utc(item.EvTime).isBetween(insemination.EvTime, new Date(), "[]"));
    let otherInsemination = otherAnimalEvents.find(item => item.EvCode === EventTypes.INSEMINATION && moment.utc(item.EvTime).isAfter(max, "day") && !cycle[EventTypes.INSEMINATION].find(cycleInsemination => item.EvID === cycleInsemination.EvID));
    if (otherInsemination) return {
        reason: REMOVE_TECHNOLOGY_GROUP_REASONS.INSEMINATION_OTHER_WEEK,
        event: otherInsemination
    };
    // sprawdzanie czy zwierze upadlo
    let fall = otherAnimalEvents.find(item => item.EvCode === EventTypes.FALL);
    if (fall) return {
        reason: REMOVE_TECHNOLOGY_GROUP_REASONS.FALL,
        event: fall
    };
    //sprawdzanie czy zwierze zostalo sprzedane
    let sell = otherAnimalEvents.find(item => item.EvCode === EventTypes.SELL);
    if (sell) return {
        reason: REMOVE_TECHNOLOGY_GROUP_REASONS.SELL,
        event: sell
    };
    if (cycles.cycleTable.find(item => item.cycle > cycle.cycle)) {
        return {
            reason: REMOVE_TECHNOLOGY_GROUP_REASONS.NEXT_CYCLE
        };
    }
    return null;
}

export function isCurrentTechnologyGroup(max) {
    return moment.utc().diff(max, "weeks") < 20;
}

export function getTasksForGroup(startOfGroup, endOfGroup, graftingProgram) {
    let timeOnMating = Math.ceil(getTimeOnMatingRoom() / 7);
    let timeOnSows = Math.ceil(getTimeOnSowsRoom() / 7);
    let timeFromInseminationToPregnancy = Math.floor(getTimeFromInseminationToPregnancy() / 7);
    let timeFromInseminationToParturition = Math.floor(getTimeFromInseminationToPartuition() / 7);
    let groupIndex = moment.utc().startOf("day").diff(startOfGroup, "weeks");
    let tasks = [];
    if (groupIndex >= timeFromInseminationToParturition) tasks.push({
        task: TECHNOLOGY_GROUP_TASKS.PARTURITION,
        start: +moment(startOfGroup).add(timeFromInseminationToParturition, "weeks"),
        end: +moment(endOfGroup).add(timeFromInseminationToParturition, "weeks")
    });
    if (groupIndex >= timeFromInseminationToPregnancy) tasks.push({
        task: TECHNOLOGY_GROUP_TASKS.USG,
        start: +moment(startOfGroup).add(timeFromInseminationToPregnancy, "weeks"),
        end: +moment(endOfGroup).add(timeFromInseminationToPregnancy, "weeks")
    });
    if (groupIndex >= 0) tasks.push({
        task: TECHNOLOGY_GROUP_TASKS.MATING,
        start: startOfGroup,
        end: endOfGroup
    });
    if (groupIndex >= timeOnMating + timeOnSows - 1) tasks.push({
        task: TECHNOLOGY_GROUP_TASKS.TRANSFER_DELIVERY,
        start: +moment(startOfGroup).add(timeOnMating + timeOnSows - 1, "weeks"),
        end: +moment(endOfGroup).add(timeOnMating + timeOnSows - 1, "weeks")
    });
    if (groupIndex >= timeOnMating - 1 && !tasks.includes(TECHNOLOGY_GROUP_TASKS.TRANSFER_DELIVERY)) tasks.push({
        task: TECHNOLOGY_GROUP_TASKS.TRANSFER_SOWS,
        start: +moment(startOfGroup).add(timeOnMating - 1, "weeks"),
        end: +moment(endOfGroup).add(timeOnMating - 1, "weeks")
    });
    if (groupIndex >= 20) tasks.push({ task: TECHNOLOGY_GROUP_TASKS.SEPARATION });
    if (graftingProgram) {
        for (let row of graftingProgram.WData.MedicineList) {
            let age = null;
            if (row.Application === GraftingProgramApplicationTypes.INSEMINATION) {
                age = Math.floor(row.Age / 7);
            }
            if (row.Application === GraftingProgramApplicationTypes.PARTURITION) {
                age = Math.floor(row.Age / 7 + timeFromInseminationToParturition);
            }
            if (row.Application === GraftingProgramApplicationTypes.SEPARATION) {
                age = Math.floor(row.Age / 7 + 20);
            }
            if (age !== null && groupIndex >= age) {
                tasks.push({
                    task: row.Medicine,
                    start: moment(startOfGroup).add(age, "weeks"),
                    end: moment(endOfGroup).add(age, "weeks")
                });
            }
        }
    }
    return tasks;
}

export function getTaskTranslation(task, medicines) {
    switch (task) {
        case TECHNOLOGY_GROUP_TASKS.PARTURITION:
            return i18n.t("apiNotifications.createParturitionTitle");
        case TECHNOLOGY_GROUP_TASKS.USG:
            return i18n.t("eventTypes.U");
        case TECHNOLOGY_GROUP_TASKS.MATING:
            return i18n.t("animalDocuments.insemination");
        case TECHNOLOGY_GROUP_TASKS.SEPARATION:
            return i18n.t("logsView.shortNames.1018");
        case TECHNOLOGY_GROUP_TASKS.TRANSFER_SOWS:
            return i18n.t("transferToSows");
        case TECHNOLOGY_GROUP_TASKS.TRANSFER_DELIVERY:
            return i18n.t("transferToDelivery");
        case TECHNOLOGY_GROUP_TASKS.GRAFTING:
            return i18n.t("eventTypes.G");
        default:
            let medicine = medicines.find(item => item.WordID === task);
            if (medicine) {
                return medicine.WData.Name;
            }
    }
}

/**
 * zwraca sformatowaną nazwę dla zakresu dat grupy technologicznej
 * @param start {Moment}
 * @param end {Moment}
 * @param forceShowYear {boolean}
 * @return {string}
 */
export const formatTechnoGroupName = (start, end, forceShowYear = false) => {
    const dayDiff = Math.abs(start.diff(end, "days"));
    const isOneWeekGroup = dayDiff <= 7;
    const week1 = start.isoWeek();
    // dodawany jeden dzien, aby przekrecic tydzien w przypadku startu w poniedzialek
    const endShortenBy1Week = end.clone().add(1, "day").subtract(1, "week");
    const week2 = endShortenBy1Week.isoWeek();
    const year1 = start.isoWeekYear();
    const year2 = endShortenBy1Week.isoWeekYear();
    const showYear = year1 !== year2 || forceShowYear;
    const showWeek = week1 !== week2;
    if (showYear) {
        return !isOneWeekGroup && showWeek ? `${week1}-${week2} (${year2})` : `${week1} (${year1})`;
    }
    return !isOneWeekGroup && showWeek ? `${week1}-${week2}` : `${week1}`;
};

/**
 * generuje unikalne id dla grupy technologicznej; używane żeby pokazać animacje na gridzie na wynikach
 * wcześniej był brany tydzień danej grupy, ale o nie jest unikalny dla niektórych przypadków
 * (jeden rok może mieć dwa 52. tygodnie; pierwsze dni nowego roku mają taka przypadłości)
 * @param start {Moment}
 * @param end {Moment}
 * @return {`${*}-${*}.${*}-${*}`}
 */
export const getTechnologyGroupDurationId = (start, end) => {
    return `${start.isoWeek()}-${start.isoWeekYear()}.${end.isoWeek()}-${end.isoWeekYear()}`;
};

const helpers = {
    /**
     * zwraca dzień startu grupy technologicznej dla podanej daty
     * @param time
     * @param technoGroupStartTime
     * @param technoGroupDuration
     * @return {moment.Moment}
     */
    getTechnoGroupStartByTime: (time, technoGroupStartTime, technoGroupDuration) => {
        const day = moment.utc(time).startOf("day");
        const diffBetweenGroupStartAndStartOfYear = moment.utc(technoGroupStartTime).diff(day, "week");
        // musimy zapamiętać znak działania przed wyrównaniem tygodnia
        const sign = diffBetweenGroupStartAndStartOfYear < 0 ? -1 : 1;
        // wyrównaj do pełnych tygodn rożnice tygodniu pomiędzy startem grupy a wybranym rokiem
        const diff = Math.ceil(Math.abs(diffBetweenGroupStartAndStartOfYear) / technoGroupDuration) * sign * technoGroupDuration;
        // pierwsza grupa technologiczna dla podanego roku
        const startTime = moment.utc(technoGroupStartTime).subtract(diff, "week");
        // sprawdź, czy nie przelecieliśmy za bardzo i napraw
        if (+startTime > time) startTime.subtract(technoGroupDuration, "week");
        return startTime;
    },
    /**
     * tworzy obiekt grupy technologicznej tzn. zakres, nazwę oraz "id"
     * @param startTime {moment.Moment}
     * @param weeks {number}
     * @return {{start: *, name: string, end: *, id: `${*}-${*}.${*}-${*}`}}
     */
    createTechnoGroup: (startTime, weeks) => {
        const start = startTime.clone();
        const end = startTime.clone().add(weeks, "week").subtract(1, "day").endOf("day");
        return {
            start,
            end,
            name: formatTechnoGroupName(start, end),
            id: getTechnologyGroupDurationId(start, end)
        };
    }
};
/**
 * zwraca tablice obiektów z zakresami trwania grup technologicznych w podanym roku, dla podanych ustawień grup technologicznych
 * @param technoGroupStartTime {number} timestamp od którego liczymy grupy technologiczne
 * @param technoGroupDuration {number} liczba mówi ile mają trwać pojedyncze grupy technologiczne
 * @param year {number} rok dla którego mamy zwrócić listę grup technologicznych
 * @return {[{start: {Moment}, end: {Moment}}]}
 */
export const getTechnologyGroupWeeksByYear = memoize((technoGroupStartTime, technoGroupDuration, year) => {
    const weeks = [];
    const startOfYear = moment.utc(year, "YYYY").startOf("year");
    const currentTechnoGroup = helpers.getTechnoGroupStartByTime(+startOfYear, technoGroupStartTime, technoGroupDuration);
    if (currentTechnoGroup.year() < year) currentTechnoGroup.add(technoGroupDuration, "week");
    while (currentTechnoGroup.year() === year) {
        const start = currentTechnoGroup.clone();
        weeks.push(helpers.createTechnoGroup(start, technoGroupDuration));
        currentTechnoGroup.add(technoGroupDuration, "week");
    }
    return weeks;
}, (...args) => args.join("+"));

/**
 * returns technology group by insemination time (or just time)
 * @param time {number}
 * @param technoGroupStartTime {number|null}
 * @param technoGroupDuration {number|null}
 * @return {{start: *, name: string, end: *, id: `${*}-${*}.${*}-${*}`}}
 */
export const getTechnologyGroupByTime = (time, technoGroupStartTime, technoGroupDuration) => {
    if (isNil(technoGroupDuration)) technoGroupDuration = getTechnologyGroupWeeks();
    if (isNil(technoGroupStartTime)) technoGroupStartTime = getFirstTechnologyGroupStart();
    const day = moment.utc(time).startOf("day");
    const start = helpers.getTechnoGroupStartByTime(+day, technoGroupStartTime, technoGroupDuration);
    return helpers.createTechnoGroup(start, technoGroupDuration);
};

export function getTechnologyGroupName(inseminationTime, { technologyGroupWeeks, technologyGroupStart }) {
    const technoGroup = getTechnologyGroupByTime(inseminationTime, technologyGroupStart, technologyGroupWeeks);
    if (!technoGroup) return "?";
    return technoGroup.name;
    // let technologyGroupStartDay = moment.utc(technologyGroupStart).isoWeekday();
    // if (technologyGroupWeeks === 1) {
    //     let inseminationWeekDay = moment.utc(inseminationTime).isoWeekday();
    //     if (
    //         technologyGroupStart !== 1 &&
    //         inseminationWeekDay < technologyGroupStartDay
    //     )
    //         return moment.utc(inseminationTime).subtract(1, "week").week();
    //     return moment.utc(inseminationTime).week();
    // }
    // let diff = moment.utc(inseminationTime).diff(technologyGroupStart, "weeks");
    // let groupsDiff = Math.floor(diff / technologyGroupWeeks);
    // let groupTime = moment
    //     .utc(technologyGroupStart)
    //     .add(groupsDiff * technologyGroupWeeks, "weeks");
    // let week = groupTime.week();
    // return `${week} - ${week + technologyGroupWeeks - 1}`;
}

export async function getTechnologyGroupAnimals(startTime, technologyGroupWeeks, technologuGroupStart, queryCaller) {
    try {
        const technoGroup = getTechnologyGroupByTime(startTime, technologuGroupStart, technologyGroupWeeks);
        const groupName = formatTechnoGroupName(technoGroup.start, technoGroup.end, true);
        const res = await queryCaller(ANIMALS_IN_GROUP, [groupName]);
        if (res.length === 0 || !res[0].AnmIDs) return [];
        return res[0].AnmIDs;
    } catch (e) {
        console.error(e);
        return [];
    }
}

export async function getTechnologyGroupsForAnimals(AnmIDs, queryCaller) {
    const res = await queryCaller(GROUPS_FOR_ANIMALS, [AnmIDs]);
    return uniq(res.map(item => item.group_nr));
}

export async function getTechnologyGroupsObjectsForAnimals(AnmIDs, queryCaller, { technologyGroupStart, technologyGroupWeeks }) {
    console.log(AnmIDs);
    const groups = await getTechnologyGroupsForAnimals(AnmIDs, queryCaller);
    const groupYears = {};
    const regex = new RegExp("\\d{4}");
    let values = [];
    for (let group of groups) {
        if (!group) continue;
        const year = group.match(regex)[0];
        if (!groupYears[year]) {
            let tmp = {};
            const groupsInYear = getTechnologyGroupWeeksByYear(technologyGroupStart, technologyGroupWeeks, +year);
            for (let row of groupsInYear) {
                const name = formatTechnoGroupName(row.start, row.end, true);
                tmp[name] = row;
            }
            groupYears[year] = tmp;
        }
        values.push({ name: group, value: groupYears[year][group] });
    }
    return values;
}

export function getLastTechnologyGroups(technoGroupStartTime, technoGroupDuration, numberOfTechnologyGroups) {
    const weeks = getTechnologyGroupWeeksByYear(technoGroupStartTime, technoGroupDuration, moment.utc().year()).filter(item => item.start.isBefore(moment.utc()));
    if (weeks.length < numberOfTechnologyGroups) {
        const prevYearWeeks = getTechnologyGroupWeeksByYear(technoGroupStartTime, technoGroupDuration, moment.utc().year() - 1);
        weeks.unshift(...prevYearWeeks.slice(prevYearWeeks.length - numberOfTechnologyGroups + weeks.length));
    }
    return weeks;
}

export function getTechnologyGroupGrid(technoGroupStartTime, technoGroupDuration) {
    let tmp = [];
    const day = moment.utc(technoGroupStartTime).day();
    const start = moment.utc().day(day);
    if (start.isAfter(moment.utc(), "day")) {
        start.subtract(1, "week");
    }
    for (let i = 0; i < 21; i++) {
        const week = start.clone().subtract(i, "weeks");
        const group = getTechnologyGroupByTime(+week, technoGroupStartTime, technoGroupDuration);
        tmp.push({ week: week.isoWeek(), year: week.year(), group, start: +week.startOf("day"), end: +week.clone().add(6, "days").endOf("day") });
    }
    return tmp;
}

export async function getTechnologyGroupGridData(weeks, technoGroupStartTime, queryCaller) {
    try {
        const groups = weeks.map(row => formatTechnoGroupName(row.group.start, row.group.end, true));
        const res = await queryCaller(TECHNOLOGY_GROUP_GRID, [groups, groups, technoGroupStartTime, technoGroupStartTime, technoGroupStartTime, technoGroupStartTime]);
        let tmp = {};
        for (let row of res) {
            tmp[+row.week] = row;
        }
        return tmp;
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function getTechnologyGroupAnimalData(group, queryCaller) {
    const res = queryCaller(TECHNOLOGY_GROUP_ANIMAL_GRID, [group]);
    return res;
}

export async function getTechnologyGroupsAnimals(groups, queryCaller) {
    const res = await queryCaller(TECHNOLOGY_GROUPS_ANIMALS, [groups]);
    return createKeyToValueDictionary(res, "group_nr");
}

export const TECHNOLOGY_GROUP_TASK_NAMES = {
    INSEMINATION: 'Insemination',
    USG: 'USG',
    PARTURITION: 'Parturition',
    SEPARATION: 'Separation',
    VACCINATION: 'Vaccination',
    PIGLETS_VACCINATION: 'PigletsVaccination',
    TRANSFER: 'Transfer',
    PIGLETS_CASTRATION: 'PigletsCastration',
    PIGLETS_TEETH: 'PigletsTeeth',
    PIGLETS_TAILS: 'PigletsTails',
    PIGLETS_WEIGHTING: 'PigletsWeighting',
    MANUAL: 'Manual',
};
export function technologyGroupTaskTranslation(name) {
    switch (name) {
        case TECHNOLOGY_GROUP_TASK_NAMES.PIGLETS_WEIGHTING:
            return i18n.t("pigletsWeighting");
        case TECHNOLOGY_GROUP_TASK_NAMES.USG:
            return i18n.t("usg");
        default:
            return i18n.t(lowerFirst(name));
    }
}
