import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import { first, get, isArray, isNil, isString, memoize, pick } from "lodash";
import memoizeOne from "memoize-one";
import { createSelector } from 'reselect';
import { AnimalTypes } from "../constans/animalTypes";
import { BoarTypes } from "../constans/boarTypes";
import { getPigBalance } from "../utils/EventUtils";
import { checkIfUserIsService } from "../utils/NewRolesUtils";
import { enhancedComparer } from "../utils/TextUtils";
import { formatLocationName } from "../utils/global-formatters/formatLocationName";
import { animalFormatterSelector, getAllAnimals, getTattooedGilts } from "./animalSelector";
import { getBuildings, makeGetBuildingsMap } from "./buildingsSelector";
import { getRaces } from "./dictionarySelectors";
import { getAllEventsForFarm } from "./eventsSelectors";
import utilSelectors from "./utilSelectors";
import { getAllGroups } from "./groupSelector";

export const InventorySortType = {
    BY_ANIMAL_NUMBER_ASC: 'SORT_BY_ANIMAL_NUMBER_ASC',
    BY_ANIMAL_NUMBER_DESC: 'SORT_BY_ANIMAL_NUMBER_DESC',
    BY_ANIMAL_TYPE_ASC: 'SORT_BY_ANIMAL_TYPE_ASC',
    BY_ANIMAL_TYPE_DESC: 'SORT_BY_ANIMAL_TYPE_DESC',
    BY_LOCATION_ASC: 'SORT_BY_LOCATION_ASC',
    BY_LOCATION_DESC: 'SORT_BY_LOCATION_DESC',
    BY_AGE_ASC: 'SORT_BY_AGE_ASC',
    BY_AGE_DESC: 'SORT_BY_AGE_DESC',
    BY_HERD_NUMBER_ASC: 'SORT_BY_HERD_NUMBER_ASC',
    BY_HERD_NUMBER_DESC: 'SORT_BY_HERD_NUMBER_DESC',
    BY_RACE_ASC: "SORT_BY_RACE_ASC",
    BY_RACE_DESC: "SORT_BY_RACE_DESC",
    BY_CODE_RACE_ASC: "SORT_BY_CODE_RACE_ASC",
    BY_CODE_RACE_DESC: "SORT_BY_CODE_RACE_DESC",
    BY_TATTOO_NUMBER_ASC: "SORT_BY_TATTOO_NUMBER_ASC",
    BY_TATTOO_NUMBER_DESC: "SORT_BY_TATTOO_NUMBER_DESC",
};

const getSort = (state) =>
    state.inventory.inventorySort;

const getLocationFilter = (state) =>
    state.inventory.locationFilter;

const getLocationFilterIsFarm = (state) =>
    state.inventory.locationIsFarm;

const getStringFilter = (state, props) =>
    state.inventory.inventoryFilter;

const getAnimalKindFilter = (state) =>
    state.inventory.inventoryAnimalKind;

export const getAnimalKinds = (state, props) =>
    state.inventory.animalTypeMap;

export const getShowDead = (state, props) =>
    state.inventory.showDead;

export const getShowDeleted = (state, props) =>
    state.inventory.showDeleted;

export const getShowEmpty = (state, props) =>
    state.inventory.showEmpty;

export const getShowAge = (state, props) =>
    state.inventory.showAge;

export const getFormatAge = (state) =>
    state.inventory.formatAge;

export const getShowHerdNumber = (state, props) =>
    state.inventory.showHerdNumber;

export const getShowRace = (state) =>
    state.inventory.showRace;

export const getShowCodeRace = (state) =>
    state.inventory.showCodeRace;

export const getShowTattoo = (state) =>
    state.inventory.showTattoo;

export const getRaceFilter = (state) =>
    state.inventory.raceFilter;

const getFarms = (state) =>
    state.farms.farms;

export const getInventoryColumnsObject = (state) => {
    return state.inventory.inventoryColumns;
};

export const getInventoryColumns = (state) => state.inventory.inventoryColumns;

const getBuildingsDict = createSelector(
    [getBuildings, getFarms],
    (buildings = [], farms = []) => {
        const buildingsDict = {};
        farms.forEach(farm => {
            buildingsDict[farm.FarmID] = [farm.FarmID];
        });
        buildings.forEach(building => {
            let tmpBuilding = [building.BgID];
            get(building, "Sectors", []).forEach(sector => {
                tmpBuilding = [...new Set([...tmpBuilding, sector.SID])];
                let tmpSector = [sector.SID];
                get(sector, "Chambers", []).forEach(chamber => {
                    tmpBuilding = [...new Set([...tmpBuilding, chamber.CID])];
                    tmpSector = [...new Set([...tmpSector, chamber.CID])];
                    let tmpChamber = [chamber.CID];
                    get(chamber, "Boxes", []).forEach(box => {
                        tmpBuilding = [...new Set([...tmpBuilding, box.BID])];
                        tmpSector = [...new Set([...tmpSector, box.BID])];
                        tmpChamber = [...new Set([...tmpChamber, box.BID])];
                        buildingsDict[box.BID] = [box.BID];
                    });
                    buildingsDict[chamber.CID] = tmpChamber;
                });
                buildingsDict[sector.SID] = tmpSector;

            });
            buildingsDict[building.BgID] = tmpBuilding;
            buildingsDict[building.FarmID] = [...new Set([building.FarmID, ...tmpBuilding])];
        });
        return buildingsDict;

    }
);

export const getPigletNumber = createSelector([getAllEventsForFarm], (allEventsForFarm) => {
    const result = {};
    for (const event of allEventsForFarm) {
        if (![
            EventTypes.PARTURITION, EventTypes.FALL_PIGLETS, EventTypes.SEPARATION_TO_MOMMY,
            EventTypes.SEPARATION, EventTypes.MOMMY, EventTypes.INSEMINATION, EventTypes.PARTURITION_STATE,
            EventTypes.SEPARATION_TO_MOMMY_GILTS, EventTypes.TATTOO].includes(event.EvCode)) continue;
        if (!result[event.AnmID]) result[event.AnmID] = [];
        result[event.AnmID].push(event);
    }
    for (const anmID in result) {
        result[anmID] = getPigBalance(result[anmID], true);
    }
    return result;
});

const getBuildingsMap = makeGetBuildingsMap();

export const getLocationFormatter = createSelector([utilSelectors.getDevices, getBuildingsMap], (devicesList, buildingsMap) => {
    return memoize((locationId) => {
        return formatLocationName(locationId, { nameDeep: 3, buildingsMap, devicesList });
    }, (location) => isArray(location) ? location.join(",") : isString(location) ? location : "");
});

const getInventoryDataMemoized = memoizeOne((groups, animals, races, animalTypeMap, animalNameFormatter, formatLocation, pigletNumbers, tattooedGilts) => {
    let inventory = [];
    let error = {};
    for (let animal of animals) {
        const { Value: RaceName, Code: RaceCode } = races?.WData?.find((race) => race.ID === animal.Race) ?? {};
        const tmp = {
            animal: animal,
            name: animalNameFormatter(animal),
            id: animal.AnmID,
            animalCount: Math.max(isFinite(+get(animal, "AnmCnt", 0)) ? +get(animal, "AnmCnt", 0) : 0, 0),
            typeName: animalTypeMap.get(animal.AnimalKind) || "-",
            location: isArray(animal.PlcmntID) ? animal.PlcmntID.map(o => o.PlcmntID) : [animal.DelPlcmntID || animal.PlcmntID],
            _isDead: !!animal.DtaDthTime || !!animal.DtaDelTime,
            herdNumber: animal.HerdNumber || "-",
            birthDate: animal.DtaBrthTime || 0,
            raceName: RaceName,
            raceCode: RaceCode,
            tattooNumber: animal.AnmNo2 || "-",
            piglets: pigletNumbers[animal.AnmID],
            gilts: (tattooedGilts[animal.AnmID] || []).map((item) => item.AnmNo1)
        };
        if (animal.AnimalKind === AnimalTypes.BOAR) {
            tmp.boarType = animal.BoarType || BoarTypes.STATIONARY;
        }
        const searchArray = ["name", "animal.RFID", "typeName", "animal.AnmNo2", "raceName", "raceCode"];
        if (tmp.gilts.length) searchArray.push("gilts");
        tmp.search = JSON.stringify(pick(tmp, searchArray));
        tmp.locationName = formatLocation(tmp.location);
        inventory.push(tmp);
    }
    groups.forEach((group) => {
        const isDeleted = !isNil(group.DtaDltTime);
        const animalsInGroup = [];
        const removedTypesFromGroup = [];
        const removedAnimals = [];
        [...new Set([...group.AnmIDs, ...group.Rmvd])].forEach((anmId) => {
            let animalIdx = inventory.findIndex((o) => !o.isGroup && o.id === anmId);
            const animal = inventory[animalIdx];
            if (animalIdx !== -1) {
                if (!get(group, "Rmvd", []).includes(anmId)) {
                    if (isDeleted) {
                        console.error(

                            "[AnimalInsideDeletedGroup] animal %s %s found in deleted group %s",

                            inventory[animalIdx].animal.AnmNo1,

                            inventory[animalIdx].animal.AnmID,

                            group.AnmGrp

                        );
                        error["AnimalInsideDeletedGroup"] = true;
                    } else {
                        animalsInGroup.push(inventory[animalIdx]);
                        if (animal.animal.AnimalKind === AnimalTypes.RENOVATION_SOW && animal.animalCount === 1) { //Jeżeli jest loszką w grupię dodajemy ją jako pojedyńcza i zapisujemy jej grupę
                            animal.group = group;
                        } else {
                            inventory.splice(animalIdx, 1);
                        }
                    }
                } else {
                    removedAnimals.push(inventory[animalIdx]);
                    let animalKind = inventory[animalIdx]?.animal?.AnimalKind;
                    if (!isNil(animalKind)) removedTypesFromGroup.push(inventory[animalIdx]?.animal?.AnimalKind);
                    if ([AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(animal.animal.AnimalKind)) inventory.splice(animalIdx, 1); //Jeżeli nie jest loszką to nie pokazujemy usunietych zwierząt jako osobne
                }

            }
        });
        // w momencie kiedy klikniemy na tuczniki chcemy wyswietlic grupy w ktorych rowniez jakies kiedys byly #7857, #9283
        // if (group.AnmIDs.length === 0) {
        //     group.Rmvd.forEach((anmId) => {
        //         let animalIdx = inventory.findIndex((o) => !o.isGroup && o.id === anmId);
        //         if (animalIdx >= 0 && !get(group, "AnmIDs", []).includes(anmId)) {
        //             let animalKind = inventory[animalIdx]?.animal?.AnimalKind;
        //             if (!isNil(animalKind)) removedTypesFromGroup.push(inventory[animalIdx]?.animal?.AnimalKind);
        //         }
        //     });
        // }
        const getDataFromAnimals = (_animalsInGroup = [], _removedTypesFromGroup = []) => {
            const data = {
                typesInGroup: [],
                birthDate: 0,
                herdNumber: "-",
                animalCount: {},
                removedAnimalCount: {}
            };
            for (let index = 0; index < _animalsInGroup.length; index++) {
                let item = _animalsInGroup[index];
                if (!isNil(item.animal.AnimalKind) && !data.typesInGroup.includes(item.animal.AnimalKind)) {
                    data.typesInGroup.push(item.animal.AnimalKind);
                }
                if (item.birthDate && (index === 0 || (item.birthDate < data.birthDate))) {
                    data.birthDate = item.birthDate;
                }
                if (item.herdNumber !== "-" && data.herdNumber === "-") {
                    data.herdNumber = item.herdNumber;
                }
                if (!item._isDead) {
                    data.animalCount[item.typeName] = (data.animalCount[item.typeName] || 0) + (item.animalCount || 0);
                }
            }
            for (const removedType of _removedTypesFromGroup) {
                const typeExists = data.typesInGroup.includes(removedType);
                if (typeExists) continue;
                data.removedAnimalCount[animalTypeMap.get(removedType)] = 0;
            }
            return data;
        };
        const { typesInGroup, birthDate, herdNumber, animalCount, removedAnimalCount } = getDataFromAnimals(animalsInGroup, removedTypesFromGroup);
        const tmp = {
            group: group,
            isGroup: true,
            isDeleted,
            isEmpty: !animalsInGroup.length,
            typesInGroup,
            name: group.GrNo1 || "-",
            id: group.AnmGrp,
            typeName: animalTypeMap.get("group"),
            animals: animalsInGroup,
            __StartTime: group.StartTime,
            birthDate,
            herdNumber,
            animalCount,
            removedAnimalCount,
            removedTypesFromGroup,
            removedAnimals
        };
        tmp.locationName = formatLocation(tmp.location);
        inventory.push(tmp);
        // exclude removed animals from list if was inside a group
        // [...group.Rmvd, ...group.AnmIDs].forEach((anmId) => {
        //     let animalIdx = inventory.findIndex(o => !o.isGroup && o.id === anmId);
        //     if (animalIdx !== -1) {
        //         if (inventory[animalIdx]._isDead) {
        //             inventory.splice(animalIdx, 1);
        //         }
        //     }
        // });
    });
    // for (let group of groups) {

    // }
    return {
        inventory: inventory.filter(({ isGroup, animal, _isDead }) => {
            if (isGroup) return true;
            if ([AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(animal.AnimalKind)) {
                // jesli zwierze jest zywe i nie ma grupy przypisanej to cos jest nie tak
                if (!_isDead) {
                    console.error("[AnimalWithoutGroupError] animal %s %s has no group", animal.AnmNo1, animal.AnmID);
                    error["AnimalWithoutGroupError"] = true;
                }
                return animal.RFID || animal.Tagged;
            }
            return true;
        }),
        error,
        size: animals.length
    };
});

const getInventoryData = createSelector(
    [getAllGroups, getAllAnimals, getRaces, getAnimalKinds, animalFormatterSelector, getLocationFormatter, getPigletNumber, getTattooedGilts],
    getInventoryDataMemoized
);

export const getInventoryError = createSelector([getInventoryData], ({ error }) => {
    const isService = checkIfUserIsService();
    const errors = Object.keys(error);
    return isService ? errors : errors.length ? ["GeneralInventoryError"] : [];
});

const getInventorySortedMemoize = memoizeOne((inventoryData, sortBy, stringFilter, animalKindFilter, locationFilter, showDead, showDeleted, showEmpty, animalTypeMap, buildingsDict, formatLocation, isFarm, raceFilter) => {
    let _inventory = inventoryData.inventory;
    const inventory = [];
    const hasPigletsFilter = animalKindFilter === "pigletsBalance";
    let sortFunction = () => 0;
    const getTypeAsNumber = (item) => item.isGroup ? 1 : 0;
    const sortByType = (_o1, _o2, ascending = true) => {
        const o1 = ascending ? _o1 : _o2;
        const o2 = ascending ? _o2 : _o1;
        const diff = getTypeAsNumber(o1) - getTypeAsNumber(o2);
        if (diff) return diff;
        return 0;
    };
    const sortByBirthDate = (_o1, _o2, ascending = true) => {
        if (ascending) return _o1.birthDate - _o2.birthDate;
        return _o2.birthDate - _o1.birthDate;
    };
    switch (sortBy) {
        case InventorySortType.BY_ANIMAL_NUMBER_DESC:
        case InventorySortType.BY_ANIMAL_NUMBER_ASC: {
            const isAscending = sortBy.endsWith("_ASC");
            sortFunction = (o1, o2) => sortByType(o1, o2, isAscending) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            });
            break;
        }
        case InventorySortType.BY_ANIMAL_TYPE_DESC:
        case InventorySortType.BY_ANIMAL_TYPE_ASC: {
            sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "typeName"), get(o2, "typeName"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            }) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: true
            });
            break;
        }
        case InventorySortType.BY_LOCATION_DESC:
        case InventorySortType.BY_LOCATION_ASC: {
            sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "locationName"), get(o2, "locationName"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            }) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: true
            });
            break;
        }
        case InventorySortType.BY_AGE_DESC:
        case InventorySortType.BY_AGE_ASC: {
            sortFunction = (o1, o2) => sortByType(o1, o2) || sortByBirthDate(o1, o2, sortBy.endsWith("_ASC")) ||
                enhancedComparer(get(o1, "name"), get(o2, "name"), {
                    numeric: true,
                    ascending: true
                });
            break;
        }
        case InventorySortType.BY_HERD_NUMBER_ASC:
        case InventorySortType.BY_HERD_NUMBER_DESC: {
            sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "herdNumber"), get(o2, "herdNumber"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            }) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: true
            });
            break;
        }
        case InventorySortType.BY_RACE_ASC:
        case InventorySortType.BY_RACE_DESC:
            sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "raceName"), get(o2, "raceName"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            }) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: true
            });
            break;
        case InventorySortType.BY_CODE_RACE_ASC:
        case InventorySortType.BY_CODE_RACE_DESC: {
            sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "raceCode"), get(o2, "raceCode"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            }) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: true
            });
            break;
        }
        case InventorySortType.BY_TATTOO_NUMBER_ASC:
        case InventorySortType.BY_TATTOO_NUMBER_DESC: {
            sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "tattooNumber"), get(o2, "tattooNumber"), {
                numeric: true,
                ascending: sortBy.endsWith("_ASC")
            }) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                numeric: true,
                ascending: true
            });
            break;
        }
        default:
            break;
    }
    let showingGroups = false;
    let newStringFilter = stringFilter.trim().toLowerCase();
    if (stringFilter.includes(animalTypeMap.get('group'))) {
        showingGroups = true;
        newStringFilter = newStringFilter.replace(animalTypeMap.get('group'), "");
    }
    const performFilter = (item, isGroup = false) => {
        let show = true;
        if (newStringFilter && item.isGroup ? true : !showingGroups) {
            show = isGroup || item.search.toLowerCase().includes(newStringFilter);
        }

        if (locationFilter && !item.location.filter(loc => (isFarm ? first(buildingsDict[locationFilter]) : buildingsDict[locationFilter] || [locationFilter]).includes(loc)).length) {
            show = false;
        }

        if (raceFilter && item.animal?.Race !== raceFilter) {
            show = false;
        }

        if (!isNil(animalKindFilter)) {
            if (item.hasOwnProperty("animal")) {
                if (!hasPigletsFilter && item.animal.AnimalKind !== animalKindFilter) {
                    show = false;
                }
                if (hasPigletsFilter && show) {
                    show = item.piglets > 0;
                }

            } else if (item.hasOwnProperty("group") && ![...item.typesInGroup, ...item.removedTypesFromGroup].includes(animalKindFilter)) {
                show = false;
            }
        }
        return show ? item : null;
    };
    const shouldPushAnimalToInventory = (item, isGroup = false) => {
        if (isNil(item.animal.DtaDthTime) && isNil(item.animal.DtaDelTime)) return true;
        if (isGroup) {
            if (showEmpty && (item.animal.DtaDthTime || item.animal.DtaDelTime)) return true;
        }
        if (showDead && !!item.animal.DtaDthTime) return true;
        if (showDeleted && !!item.animal.DtaDelTime) return true;
        return false;
    };
    for (let o of _inventory) {
        let tmp = { ...o };
        if (o.isGroup) {
            let location = [];
            const search = [];
            const animals = [];
            o.animals.forEach((animal) => {

                if (performFilter(animal, true)) {
                    const shouldPush = shouldPushAnimalToInventory(animal, true);
                    if (shouldPush) {
                        animals.push(animal);
                        if (!animal.animal.AnimalKind === AnimalTypes.RENOVATION_SOW)
                            search.push(animal.search);
                        location = [
                            ...new Set([...location, ...animal.location]),
                        ];
                    }
                }
            });

            tmp.search = { name: tmp.name, typeName: tmp.typeName };
            if (!showingGroups) tmp.search.search = search;
            tmp.search = JSON.stringify(tmp.search);

            tmp.animals = animals.sort(sortFunction);
            tmp.location = location;
            // console.log(tmp.location);
            tmp.locationName = formatLocation(tmp.location);
            tmp = performFilter(tmp);
            const isAnimalCountObject = typeof tmp?.animalCount === 'object' ? Object.keys(tmp?.animalCount).length === 0 : !tmp?.animalCount; //Check if animalCount is empty object
            if ((!showingGroups || (o.isGroup && showingGroups)) && tmp) {
                if ((!showEmpty && o.animals.filter((a) => !a.animal.hasOwnProperty("DtaDelTime")).length && !isAnimalCountObject) || showEmpty) {
                    inventory.push(tmp);
                }
            }
        } else if (!showingGroups) {
            const shouldPush = shouldPushAnimalToInventory(tmp);
            if (shouldPush) {
                tmp = performFilter(tmp);
                if (tmp) inventory.push(tmp);
            }
        }
    }
    return { inventory: inventory.sort(sortFunction).slice(0), size: inventoryData.size };
});

export const makeGetInventorySorted = () => {
    return createSelector(
        [getInventoryData, getSort, getStringFilter, getAnimalKindFilter, getLocationFilter, getShowDead, getShowDeleted, getShowEmpty, getAnimalKinds, getBuildingsDict, getLocationFormatter, getLocationFilterIsFarm, getRaceFilter],
        getInventorySortedMemoize
    );
};

export const getTotalAnimals = createSelector(
    [getAllAnimals, getPigletNumber], (animals, pigletNumbers) => {
        const results = {};
        Object.values(AnimalTypes).forEach(value => {
            results[value] = {
                selected: 0,
                total: 0
            };
        });
        results["pigletsBalance"] = {
            selected: 0,
            total: 0
        };
        const addAnimalCount = (animal, key) => {
            const animalCount = Math.max(isFinite(+get(animal, "AnmCnt", 0)) ? +get(animal, "AnmCnt", 0) : 0, 0);
            results[animal.AnimalKind] && (results[animal.AnimalKind][key] += animalCount);
            if (animal.AnimalKind === AnimalTypes.SOW) {
                const sowObject = pigletNumbers[animal.AnmID];
                if (sowObject !== undefined) results["pigletsBalance"][key] += sowObject;
            }
        };
        animals.forEach(animal => {
            if (!animal.DtaDthTime && !animal.DtaDelTime && animal.BoarType !== BoarTypes.SPERM) {
                addAnimalCount(animal, "total");
            }
        });
        return results;
    }
);
