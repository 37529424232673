import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Modal} from "react-bootstrap";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {submit, validate} from "./ConfirmResurrectAnimalSubmit";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import InfoBox from "../../basics/info-box/InfoBox";
import {withRouter} from "react-router-dom";
import ReduxLabeledSelect from '../../basics/select/labeled-select/ReduxLabeledSelect';
import { getAllChambers, getBuildingsMap } from '../../../selectors/buildingsSelector';
import { locationIsGroupChamber } from '../../../utils/BuildingUtils';
import AnimalTypes from '@wesstron/utils/Api/constants/animalTypes';
import { getAllGroups } from '../../../selectors/groupSelector';
import { getActiveAnimals} from '../../../selectors/animalSelector';

export const ModalName = "confirm-resurrect-animal";

const selector = formValueSelector(ModalName);

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        selectedItem: state.animalDocuments.selectedItem,
        plcmntID: selector(state, "plcmntID"),
        buildings: getAllChambers(state),
        groups: getAllGroups(state),
        buildingsMap: getBuildingsMap(state),
        animals: getActiveAnimals(state)
    };
}

class ConfirmResurrectAnimal extends Component {
    constructor(props) {
        super(props);
        props.initialize({plcmntID: ""});
    }
    locationHasBoxes(location){
        return !!location.Boxes
    }
    isInitializeValue(location){
        const {selectedItem} = this.props;
        return selectedItem.DelPlcmntID === location.CID
    }
    getAnimalGroup(animal){
        const {groups} = this.props;
        return groups.find((item) => !![...item.AnmIDs,...item.Rmvd].find((AnmID) => AnmID === animal.AnmID))
    }
    isLocationEmpty(location, groupID){ //zwracamy true jeżeli lokalizacja jest pusta lub posiada tą samą grupę
        const {animals} = this.props;
        return !(animals.filter((item) => {
            if(item.PlcmntID === location.id){
                const group = this.getAnimalGroup(item)
                return group && group.AnmGrp !== groupID
            }
            return false
        }) || []).length
    }
    getLocationOptions() {
        const {buildings, selectedItem, buildingsMap} = this.props;
        const tmp = [];
        //Zwierzę indywidalne możemy wybrać stanowiska indywidualny
        //Zwierzę w grupię możemy wybrać tylko stanowiska dla grup które są bez innych grup
        const group = this.getAnimalGroup(selectedItem)
        if(group){
            buildings.forEach((item) => {
                const location = buildingsMap.get(item.CID)
                if ((locationIsGroupChamber(location) && this.isLocationEmpty(location, group.AnmGrp))) {
                    tmp.push({
                        value: this.isInitializeValue(item) ? "" : item.CID,
                        name: item.CName,
                    });
                }
            }); 
        } else {
            buildings.forEach((item) => {
                const location = buildingsMap.get(item.CID)
                if ((!locationIsGroupChamber(location) && !this.locationHasBoxes(item)) || this.isInitializeValue(item)) {
                    tmp.push({
                        value: this.isInitializeValue(item) ? "" : item.CID,
                        name: item.CName,
                    });
                }
            });
        }
        
        return tmp;
    }
    showLocationSelect(){
        const {selectedItem} = this.props;
        return [AnimalTypes.RENOVATION_SOW,AnimalTypes.BOAR,AnimalTypes.SOW].includes(selectedItem.AnimalKind)
    }
    render() {
        const {handleHide, show, t, animal, submitting, selectedItem, invalid} =
            this.props;
        const isGroup = selectedItem.hasOwnProperty("GrNo1");
        const showNewLocationSelect = this.showLocationSelect()
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("confirmResurrectAnimal")}
                    onCloseClick={handleHide}
                />
                <ModalBody>
                    <InfoBox>
                        {!isGroup &&
                            t("toResurrectAnimal", {AnmNo1: animal.AnmNo1})}
                        {isGroup &&
                            t("toRessurectAnimalToGroup", {
                                GrNo1: selectedItem.GrNo1,
                            })}
                    </InfoBox>
                    <Field
                        name={"animalNumber"}
                        component={ReduxLabeledInput}
                        type={"text"}
                        label={t("animalNumber")}
                    />
                    <Field
                        label={t("enterReasonOfYourDecision")}
                        component={ReduxLabeledInput}
                        type={"textarea"}
                        id={"comment"}
                        required
                        name={"comment"}
                    />
                    {showNewLocationSelect && (
                        <Field
                            label={t("resurrectLocation")}
                            component={ReduxLabeledSelect}
                            options={this.getLocationOptions()}
                            id={"plcmntID"}
                            required
                            name={"plcmntID"}
                        />
                    )}
                </ModalBody>
                <ModalFooter
                    hasConfirmButton
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                    submitting={submitting}
                    hasButtonDisabled={invalid}
                />
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(ConfirmResurrectAnimal);
