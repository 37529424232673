import React from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import CustomBrowserRouter from "./components/router/CustomBrowserRouter";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import ThemeProvider from "./components/theme-provider/ThemeProvider";
import App from "./containers/App";
import "./i18n";
import * as serviceWorker from "./libs/registerServiceWorker";
import store from "./store/store";
import { isCypress } from "./utils/CypressUtils";
import { initializeFirebase } from "./utils/FirebaseUtils";
import FallbackComponent from "./views/fallback-error-view/FallbackErrorView";
import "./whydidyourender";
import { bugsnagClient } from "./utils/bugsnagUtils/BugsnagUtils";

if (isCypress()) {
    window.store = store;
}

const ErrorBoundary = bugsnagClient
    .getPlugin("react")
    .createErrorBoundary(React);

const DEBUG = process.env.REACT_APP_STAGE !== 'production';
if (!DEBUG) {
    if (!window.console) window.console = {};
    const methods = ["log", "debug", "warn", "info", "time", "timeEnd"];
    for (const method of methods) {
        // keep org method
        console[`__${method}`] = console[method];
        console[method] = () => { };
    }
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <CookiesProvider>
        <CustomBrowserRouter>
            <Provider store={store}>
                <ThemeProvider>
                    <ErrorBoundary FallbackComponent={FallbackComponent}>
                        <ScrollToTop>
                            <App />
                        </ScrollToTop>
                    </ErrorBoundary>
                </ThemeProvider>
            </Provider>
        </CustomBrowserRouter>
    </CookiesProvider>
);
serviceWorker.register();
initializeFirebase();
