import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {getFarmID} from "../selectors/farmSelector";
import {getClientID, getUser} from "../selectors/userSelector";
import {interpolateLink} from "../utils/URLUtils";
import {useHistory} from "react-router-dom";

export const useLink = (href) => {
    const FarmID = useSelector(getFarmID);
    const ClientID = useSelector(getClientID);
    const {LocalUserID} = useSelector(getUser);

    const history = useHistory()

    const interpolationParams = useMemo(() => {
        return {
            FarmID,
            ClientID,
            LocalUserID
        }
    }, [FarmID, ClientID, LocalUserID])

    const url = useMemo(() => {
        if (href) {
            return interpolateLink(href, interpolationParams);
        }
        return href;
    }, [href, interpolationParams]);

    const open = useCallback(() => {
        history.push({
            pathname: url
        })
    }, [url, history]);

    return {url, open}
}