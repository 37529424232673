import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import memoizeOne from "memoize-one";
import {
    editAnimalGroups,
    updateAnimalGroupsNotificationFailure,
    updateAnimalGroupsNotificationSuccess
} from "../../../api/animalGroups/editAnimalGroups";
import { cloneDeep, groupBy } from "lodash";

export function submit(values, dispatch, props) {
    const { insertions, _Comment } = values;
    const { mergedInsertions: { insertionsIDs = [] } = {}, group: { AnmGrp } = {} } = props;
    const insertionsToChange = {};
    Object.entries(insertions).forEach(([key, value]) => {
        if (key.startsWith("mergedInsertions")) {
            const insertionDetails = key.split("_");
            insertionsToChange[`${insertionDetails[1]}_${insertionDetails[2]}`] = value;
        }
    });
    const row = {
        AnmGrp,
        insertionsIDs,
        insertionsToChange,
        updateAnimals: false
    };
    if (_Comment) {
        row.comment = _Comment;
    }
    return editAnimalGroups([row]).then((res) => {
        updateAnimalGroupsNotificationSuccess(res);
        props.handleHide();
    }).catch((err) => {
        updateAnimalGroupsNotificationFailure(err);
    });
}

const groupDetailData = memoizeOne((detailedData) => {
    let tmp = [];
    const groups = groupBy(detailedData, item => item.date);
    for (let date in groups) {
        const groupedByCode = groupBy(groups[date], "summary.code");
        if (groupedByCode[EventTypes.INSERTION]) {
            const groupedByAnimal = groupBy(groupedByCode[EventTypes.INSERTION], item => item[EventTypes.INSERTION].anmID);
            for (let key in groupedByAnimal) {
                console.log(date, key);
                tmp.push(groupedByAnimal[key].reduce((prev, curr) => {
                    if (!prev) return curr;
                    return {
                        ...curr,
                        [EventTypes.INSERTION]: {
                            ...curr[EventTypes.INSERTION],
                            amount: prev[EventTypes.INSERTION].amount + curr[EventTypes.INSERTION].amount
                        },
                    };
                }));
            }
        }
        for (let code in groupedByCode) {
            if (code !== EventTypes.INSERTION) {
                tmp.push(...groupedByCode[code]);
            }
        }
    }
    return cloneDeep(tmp);
});

const getPositionOfInsertions = memoizeOne((detailedData) => {
    const tmp = {};
    for (let i = 0; i < detailedData.length; i++) {
        const row = detailedData[i];
        if (!row.date) continue;
        if (row.summary.code === EventTypes.INSERTION) {
            const key = `mergedInsertions_${row.date}_${row[row.summary.code].anmID}`;
            tmp[key] = i;
        }
    }
    return tmp;
});

const getLocationOfAnimal = memoizeOne((detailedData, AnmID) => {
    for (let row of detailedData) {
        const locationKeys = Object.keys(row).filter((key) => key.startsWith("location_"));
        for (let key of locationKeys) {
            const locationRow = row[key];
            if (locationRow.animals.some(item => item.id === AnmID)) return key.split("_")[1];
        }
    }
});

export function validate(values, props) {
    const { t, locationDays: { detailedData = [] } = {} } = props;
    const { insertions = {} } = values;
    const errors = {
        insertions: {}
    };
    // modyfikuje obiektu w srodku aby sprawdzic walidacje
    const groupedDetailedData = groupDetailData(detailedData);
    const mergedInsertionKeys = Object.keys(insertions).filter((key) => key.startsWith("mergedInsertions"));
    const positionOfInsertions = getPositionOfInsertions(groupedDetailedData);
    for (let key of mergedInsertionKeys) {
        const currentValue = insertions[key];
        if (currentValue <= 0) {
            errors.insertions[key] = t("errors.noLessOrEq0");
        }
        const position = positionOfInsertions[key];
        if (position !== undefined) {
            const AnmID = key.split("_")[2];
            const location = getLocationOfAnimal(detailedData, AnmID); // tu specjalnie wrzucone, aby nie szukalo czesto
            const row = groupedDetailedData[position];
            const diff = currentValue - row[EventTypes.INSERTION].amount;
            row[EventTypes.INSERTION].amount = currentValue;
            row.summary.amount = row.summary.amount + diff;
            if (row.hasOwnProperty(`location_${location}`)) {
                row[`location_${location}`].amount = row[`location_${location}`].amount + diff;
            }
            if (diff !== 0) {
                for (let i = position + 1; i < groupedDetailedData.length; i++) {
                    const tmp = groupedDetailedData[i];
                    tmp.summary.amount = tmp.summary.amount + diff;
                    if (tmp.hasOwnProperty(`location_${location}`)) {
                        tmp[`location_${location}`].amount = tmp[`location_${location}`].amount + diff;
                    }
                }
            }
        } else {
            console.warn("Stało się coś, czego nie oczekiwałem", key, detailedData, positionOfInsertions);
        }
    }
    console.log(groupedDetailedData);
    const hasHistoryIssue = groupedDetailedData.some(row => {
        if (row.summary.amount <= 0) return true;
        if (row[EventTypes.INSERTION].amount !== null && row[EventTypes.INSERTION].amount <= 0) return true;
        const locationKeys = Object.keys(row).filter((key) => key.startsWith("location_"));
        for (let key of locationKeys) {
            if (row[key].amount < 0) return true;
        }
        return false;
    });
    if (hasHistoryIssue) {
        errors._error = t("sumOfInsertionError");
    }
    return errors;
}