import React, { Component } from "react";
import InfoBox from "../../components/basics/info-box/InfoBox";
import "./_fallback-error.scss";
import Card from "../../components/basics/card/Card";
import LabeledInput from "../../components/basics/input/labeled-input/LabeledInput";
import { Col, Row } from "react-bootstrap";
import Button from "../../components/basics/button/Button";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import i18n from "../../i18n";
import { bugsnagClient } from "../../utils/bugsnagUtils/BugsnagUtils";

export class FallbackErrorView extends Component {

    constructor(props) {
        super(props);
        i18n.setDefaultNamespace("translation");
        this.state = {
            message: ''
        };
    }

    forceRedirectToHomePage = () => {
        try {
            this.props.history.push("/");
            setTimeout(() => window.location.reload(), 250);
        } catch (e) {
        }
    };

    reportBugSubmit = () => {
        console.log(this.props, this.state.message);

        bugsnagClient.notify(new Error("Bug submit form"), (event) => {
            event.severity = "warn";
            event.addMetadata("feedback", {
                userMessage: this.state.message,
                message: this.props.error ? this.props.error.message : '',
                stack: this.props.error ? this.props.error.stack : '',
                info: this.props.info
            });
        }, () => {
            this.setState({
                reportSent: true,
                message: ''
            });
        });
    };

    render() {
        const { reportSent, message } = this.state;
        return (
            <div className="fallback-error col-12">
                <Row bsPrefix={"row h-100 justify-content-center align-items-center w-100 mx-auto"}>
                    <Col lg={6}>
                        <Card>
                            <h4 className="align-items-center">
                                <i className="fas fa-fw fa-exclamation-triangle" />{' '}{i18n.t("fallbackErrorView.appCrashed")}
                            </h4>
                            <p>
                                {i18n.t("fallbackErrorView.appCrashedLong")}{' '}
                                <Button type={"button"} className={"redirect-button"}
                                    onClick={this.forceRedirectToHomePage}>
                                    {i18n.t("clickHere")}
                                </Button>
                                {' '}
                                {i18n.t("fallbackErrorView.appCrashedLong2")}
                            </p>
                            {
                                reportSent &&
                                <InfoBox boxColor="info">
                                    {i18n.t("fallbackErrorView.reportSent")}
                                </InfoBox>
                            }
                            {
                                !reportSent &&
                                <>
                                    <LabeledInput value={message} onChange={value => this.setState({ message: value })}
                                        type={"textarea"} label={i18n.t("fallbackErrorView.lastActions")} />
                                    <Button type={"button"} buttonStyle={message ? "" : "text"} disabled={!message}
                                        className="float-end" buttonColor={"primary"}
                                        onClick={this.reportBugSubmit}>{i18n.t("send")}</Button>
                                </>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default compose(
    withRouter,
)(FallbackErrorView);
