import { isArray, isFinite, isMap, isNil, isObject, isString, get } from "lodash";
import { makeGetBuildingsMap } from "../../selectors/buildingsSelector";
import store from "../../store/store";
import i18next from "../../i18n";
import { Level } from "../../constans/levelTypes";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import memoizeOne from "memoize-one";
import {isFiniteNumber} from "../MathUtils";
import {isVirtualPlacement, removeVirtualPlacement} from "../LocationUtils";
import { getShortInterfaceWithAddress } from "../DevicesUtils";

const getBuildingsMap = makeGetBuildingsMap();


const _makeDeviceDictionary = memoizeOne(deviceList => {
    const deviceDict = {};
    deviceList.forEach(d => {
        if (d.DevType === DevType.DISPENSER_NRF && isArray(d.PlcmntID)) {
            d.PlcmntID.forEach(({ PlcmntID }) => {
                if (!deviceDict[PlcmntID]) deviceDict[PlcmntID] = [];
                deviceDict[PlcmntID].push(d)
            })
        }
    })
    return deviceDict;
})

function getLocationID(locationId) {
    if (isVirtualPlacement(locationId)) {
        const id = removeVirtualPlacement(locationId);
        return {id, prefix: i18next.t("lastKnownLocation")};
    }
    return {id: locationId, prefix: null};
}

const params = {showDeleted: true};
/**
 * funkcja do formattowania obiektów
 * @param locationId {array|string}
 * @param buildingsMap - getBuildingsMap ze stora lepiej przekazwac jak ktos uzywa w komponencie zeby odswiezalo widok
 * @param devicesList
 * @param maxItems
 * @param nameDeep
 * @param nameDeepStandings
 * @param nameDeepChambers
 * @param nameDeepSectors
 * @param nameDeepBuildings
 * @param withDispenserAddress - dodaje informacje o dozowniku w danym miejscu
 * @param standingNameType {"default"|"short"|"long"} - czy zmieniać nazwe stanowiska (default - nic nie zmieniac, short - ustawiac jako liczba, long - dodawac w nazwie Stanowisko)
 * @param notFoundText
 * @param missingLocationText
 * @return {string}
 */
export const formatLocationName = (locationId, {
    buildingsMap,
    devicesList,
    maxItems = 3,
    nameDeep = null, // globalny name deep nadpisuje nameDeepStandings
    nameDeepStandings = null,
    nameDeepChambers = null,
    nameDeepSectors = null,
    nameDeepBuildings = null,
    withDispenserAddress = false,
    standingNameType = "default",
    notFoundText,
    missingLocationText = i18next.t('missingLocation')
} = {}) => {
    if (!isMap(buildingsMap)) buildingsMap = getBuildingsMap(store.getState(), params);
    if (!isArray(devicesList)) devicesList = get(store.getState(), "farmDevices.devices", []);
    if (!isString(notFoundText)) notFoundText = i18next.t("unknownOrDeleted");
    if (!isString(missingLocationText)) missingLocationText = "";
    let prefixes = [];
    const deviceDict = withDispenserAddress ? _makeDeviceDictionary(devicesList) : {};
    const locationIdList = [];
    const result = [];
    if (isString(locationId)) {
        const {id, prefix} = getLocationID(locationId);
        locationIdList.push(id);
        if (prefix) {
            prefixes.push(prefix);
        }
    } else if (isArray(locationId)) {
        locationId.forEach(id => {
            if (isString(id)) {
                const {id: _id, prefix} = getLocationID(id);
                locationIdList.push(_id);
                if (prefix) {
                    prefixes.push(prefix);
                }
            } else if (isObject(id)) {
                const {id: _id, prefix} = getLocationID(id.PlcmntID);
                locationIdList.push(_id);
                if (prefix) {
                    prefixes.push(prefix);
                }
            }
        })
    } else if (isObject(locationId)) {
        const {id, prefix} = getLocationID(locationId.PlcmntID);
        locationIdList.push(id);
        if (prefix) {
            prefixes.push(prefix);
        }
    }
    locationIdList.forEach(id => {
        const o = buildingsMap.get(id);
        if (o) {
            let _deep = isNil(nameDeep) ? 1 : nameDeep;
            switch (o.level) {
                case Level.SECTOR:
                    if (isFinite(nameDeepSectors)) {
                        _deep = nameDeepSectors;
                    }
                    break;
                case Level.FARM:
                    _deep = 1;
                    return missingLocationText;
                case Level.BUILDING:
                    if (isFinite(nameDeepBuildings)) {
                        _deep = nameDeepBuildings;
                    }
                    break;
                case Level.BOX:
                    // domyslnie nazwa komory i nazwa stanowiska
                    _deep = isNil(nameDeep) ? 2 : nameDeep;
                    if (isFinite(nameDeepStandings)) {
                        _deep = nameDeepStandings;
                    }
                    break;
                case Level.CHAMBER:
                    if (isFinite(nameDeepChambers)) {
                        _deep = nameDeepChambers;
                    }
                    break;
                default:
                    break;
            }

            // we dont want to mutate the names if we need to shorten or add prefix to standing names
            const nameArray = standingNameType !== "default" ? [...o.name] : o.name;
            const start = Math.max(_deep ? nameArray.length - _deep : 0, 0);
            const stop = _deep ? start + _deep : undefined;
            if (nameArray.length === 4) {
                switch (standingNameType) {
                    case "short": {
                        // we shorten the standing name i.e 'Standing 4 23' {string} becomes '23' {string} and '23' {number} becomes '23' {string}
                        nameArray[3] = `${nameArray[3]}`.match(/\d+$/)[0];
                        break;
                    }
                    case "long": {
                        nameArray[3] = isFiniteNumber(nameArray[3]) ? `${i18next.t("standing")} ${nameArray[3]}` : nameArray[3];
                        break;
                    }
                    default:
                        break;
                }
            }
            let locationName = `${nameArray.slice(start, stop).join(" - ")}${o.isDeleted ? ` (${i18next.t("deleted")})` : ""}`
            if (withDispenserAddress && [Level.CHAMBER, Level.BOX].includes(o.level)) {
                const names = (deviceDict[o.id] || []).map(d => getShortInterfaceWithAddress(d)).join(", ");
                if (names) {
                    locationName += ` (${names})`;
                }
            }
            result.push(locationName)
        } else {
            if (notFoundText) {
                result.push(notFoundText);
                prefixes.length = 0;
            }
        }
    })
    if (result.length === 0) return missingLocationText;
    return prefixes.join(", ") + `${prefixes.length > 0 ? ": " : ""}` + result.slice(0, maxItems).join(", ") + (result.length > maxItems ? "..." : "");
}

