import i18n from "../i18n";
import { catchify } from "./Utils";


export const changeLanguage = async (language) => {
    // change i18n language
    await i18n.changeLanguage(language);
    // update site html tag to use correct language
    catchify(() => {
        document.documentElement.setAttribute("lang", language || "en");
    })();
}

