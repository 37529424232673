import {LoadedDataTypes} from "../../../../constans/devices";
import {get, has, isEqual, isFunction, isNil, set, isArray} from "lodash";
import {IntervalTimes} from "../../../../constans/intervals";
import memoizeOne from "memoize-one";
import {isFiniteNumber} from "../../../../utils/MathUtils";
import {makeIsEqualArrayByItemProperty} from "../../../../utils/Utils";

const DEBUG = false;


export const getValue = (_key, _data, _checkIfItemIsOutdatedFn) => {
    const r = {
        value: [],
        isOutdated: false,
        hasData: false,
        timestamp: null
    }
    if (isNil(_key)) return {...r, value: undefined};
    const keyAsArray = isArray(_key) ? _key : [_key];
    for (let i = 0; i < keyAsArray.length; ++i) {
        r.value[i] = undefined;
        const key = keyAsArray[i];
        if (has(_data, key)) {
            r.value[i] = get(_data, key);
            r.hasData = true;
            if (has(_data, `${key}_ts`) && r.timestamp === null) {
                r.timestamp = get(_data, `${key}_ts`);
            }
            if (!r.isOutdated && isFunction(_checkIfItemIsOutdatedFn)) {
                r.isOutdated = !_checkIfItemIsOutdatedFn(_data, key);
            }
        }
    }
    return isArray(_key) ? r : {
        ...r,
        value: r.value[0]
    }
}

export const getDataForPreview = ({shadow, device, model}) => {
    DEBUG && console.log(shadow, device, model);
    const dataShadow = (item) => {
        let value;
        if (isArray(item.shadowKey) || isArray(item.deviceKey)) {
            const maxLength = Math.max(...[item?.deviceKey?.length, item?.shadowKey?.length].map(isFiniteNumber), 0);
            value = new Array(maxLength).fill(undefined);
        }
        let dataType = LoadedDataTypes.NO_DATA;
        if (isNil(item.shadowKey) && isNil(item.deviceKey)) {
            dataType = LoadedDataTypes.SHADOW;
        }
        let outdated = false;
        let syncError = !isNil(shadow) && !isNil(item.shadowKey) && !isNil(item.deviceKey) ? !isEqual(getValue(item.shadowKey, shadow).value, getValue(item.deviceKey, device).value) : false;
        let notSupported = isFunction(item.notSupported) ? item.notSupported({device, shadow}) : false;
        if (notSupported === false) {
            if (!isNil(item.shadowKey)) {
                const result = getValue(item.shadowKey, shadow, getShadowState);
                value = result.value;
                if (result.hasData) {
                    dataType = LoadedDataTypes.SHADOW;
                    outdated = result.isOutdated;
                    // coś co długi robił nie wiem dokładnie o co w tym chodzi ale powinno działać tak samo jak wcześniej
                    if (item.isTime && item.metaKey) {
                        value += get(shadow, `metadata.${item.metaKey}`);
                        if (isNaN(value)) value = undefined;
                    }
                }
            }
            if (dataType !== LoadedDataTypes.SHADOW) {
                if (!isNil(item.deviceKey)) {
                    const result = getValue(item.deviceKey, device);
                    value = result.value;
                    if (result.hasData) {
                        dataType = LoadedDataTypes.DYNAMO;
                    }
                }
            }
        }

        return {
            value,
            dataType,
            isSyncError: syncError,
            isShadowOutdated: outdated,
            isSupported: !notSupported,
            isTime: item.isTime
        };
    }

    const data = {};
    model.forEach((d) => {
            if (d.visual === true) return;
            // if (d.button) return;
            set(data, d.key, dataShadow(d));
        }
    )
    return data;
}

export const getMetaTime = (shadow, shadowKey) => {
    if (!shadow || !shadowKey) return null;
    try {
        const newKey = shadowKey.replaceAll("]", "").replaceAll("[", ".");
        const key = newKey.split(".")[0];
        const timestamp = get(shadow, `metadata.${key}`);
        return isNil(timestamp) ? null : timestamp;
    } catch (err) {
        return null;
    }
}

export const getShadowState = (shadow, shadowKey) => {
    const timestamp = getMetaTime(shadow, shadowKey);
    return isNil(timestamp) ? false : (+new Date() - timestamp) <= IntervalTimes.DEVICE_OUTDATED_DATA

}

const isPartiallyEqual = makeIsEqualArrayByItemProperty("DevID", "DtaModTime");

const emptyArray = [];

export const getSelectedDevices = memoizeOne((allDevices, ids = emptyArray) => {
    console.log("getSelectedDevices")
    const devices = [];
    ids.forEach(id => {
        const tmp = allDevices.find(d => d.DevID === id);
        if (tmp) {
            devices.push(tmp);
        }
    })
    return devices;
}, (newInputs, lastInputs) => {
    if (!isPartiallyEqual(newInputs[0], lastInputs[0])) return false;
    return isEqual(lastInputs[1], newInputs[1]);

});
