import React, {PureComponent} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ViewContainerModal from "../../basics/view-containter-modal/ViewContainerModal";
import Button from "../../basics/button/Button";
import LocationContent from "./drawer-content/LocationContent";
import AnimalContent from "./drawer-content/AnimalContent";
import {getActiveAnimals} from "../../../selectors/animalSelector";
import {getBuildingsMap} from "../../../selectors/buildingsSelector";
import memoizeOne from "memoize-one";
import SiloContent from "./drawer-content/SiloContent";
import GroupContent from "./drawer-content/GroupContent";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import Card from "../../basics/card/Card";
import {CustomEntityTypes, getDeviceEntityType, textIdToObject} from "../../../utils/FarmMapUtils";
import SiloRadarContent from "./drawer-content/SiloRadarContent";
import LightContent from "./drawer-content/LightContent";
import ClimateContent from "./drawer-content/ClimateContent";
import CageContent from "./drawer-content/CageContent";
import IpsumContent from "./drawer-content/IpsumContent";
import NutriProV2Content from "./drawer-content/NutriProV2Content";
import NutriProContent from "./drawer-content/NutriProContent";
import ElectricContent from "./drawer-content/ElectricContent";
import WaterContent from "./drawer-content/WaterContent";
import VehicleWeightContent from "./drawer-content/VehicleWeightContent";
import InventoryContent from './drawer-content/InventoryContent';

class LocationDrawer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mobile && this.props.animateType === "longclick" && ((prevProps.selectedId !== this.props.selectedId) || (prevProps.animateType !== this.props.animateType))) {
            if (!this.state.expanded) {
                this.setState({
                    expanded: true
                })
            }
        }
    }

    toggleExpand = () => {
        this.setState(state => ({
            expanded: !state.expanded
        }))
    }


    getLocation = memoizeOne((locations, id) => locations.get(id));

    getAnimal = memoizeOne((animals, id) => animals.find(({AnmID}) => AnmID === id))

    getDevice = memoizeOne((devices, id) => devices.find(({DevID}) => DevID === id))

    renderContent() {
        const {type, animals, buildings, selectedId, devices, mapType} = this.props;
        const {expanded} = this.state;
        if (!selectedId && mapType ==='chambers'){
            return <InventoryContent toggleExpand={this.toggleExpand} expanded={expanded} />
        }
        if (!selectedId) return null;
        switch (type) {
            case "location": {
                const location = this.getLocation(buildings, selectedId);
                const show = !!location;
                return show ? <LocationContent {...this.props} key={selectedId} location={location} expanded={expanded}
                                               toggleExpand={this.toggleExpand}/> : null;
            }

            case "animal": {
                const animal = this.getAnimal(animals, selectedId);
                const location = this.getLocation(buildings, animal?.PlcmntID);
                const show = !!animal && !!location;
                return show ?
                    <AnimalContent {...this.props} key={selectedId} animal={animal} location={location}
                                   expanded={expanded}
                                   toggleExpand={this.toggleExpand}/> : null;
            }

            case "device": {
                const {id: DevID, index: Index} = textIdToObject(selectedId);
                const device = this.getDevice(devices, DevID) || null;
                const show = !!device;
                if (!show) return null;
                const entityType = getDeviceEntityType(device);
                switch (entityType) {
                    case CustomEntityTypes.ELECTRICITY: {
                        return (
                            <ElectricContent {...this.props} key={selectedId} device={device}
                                             expanded={expanded}
                                             toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.WATER: {
                        return (
                            <WaterContent {...this.props} key={selectedId} device={device}
                                          expanded={expanded}
                                          toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.CAGE_2WAY: {
                        return (
                            <CageContent {...this.props} key={selectedId} device={device}
                                         expanded={expanded}
                                         toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.CLIMATE: {
                        return (
                            <ClimateContent {...this.props} key={selectedId} device={device}
                                            expanded={expanded}
                                            toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.SILO: {
                        switch (device.DevType) {
                            case DevType.SILO_SENSOR:
                            case DevType.SCALE: {
                                return <SiloContent {...this.props} key={selectedId} device={device} index={Index}
                                                    expanded={expanded}
                                                    toggleExpand={this.toggleExpand}/>
                            }
                            case DevType.SILO_RADAR: {
                                return (
                                    <SiloRadarContent {...this.props} key={selectedId} device={device}
                                                      expanded={expanded}
                                                      toggleExpand={this.toggleExpand}/>
                                )
                            }
                            default:
                                return null;
                        }

                    }
                    case CustomEntityTypes.GROUP: {
                        const location = this.getLocation(buildings, device?.PlcmntID[0]?.PlcmntID);
                        return <GroupContent {...this.props} key={selectedId} location={location}
                                             device={device}
                                             expanded={expanded}
                                             toggleExpand={this.toggleExpand}/>
                    }
                    case CustomEntityTypes.NUTRI_PRO: {
                        return (
                            <NutriProContent {...this.props} key={selectedId} device={device}
                                             expanded={expanded}
                                             toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.NUTRI_PRO_V2: {
                        return (
                            <NutriProV2Content {...this.props} key={selectedId} device={device}
                                               expanded={expanded}
                                               toggleExpand={this.toggleExpand}/>
                        )

                    }
                    case CustomEntityTypes.VEHICLE_WEIGHT: {
                        return (
                            <VehicleWeightContent {...this.props} key={selectedId} device={device}
                                                  expanded={expanded}
                                                  toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.IPSUM: {
                        return (
                            <IpsumContent {...this.props} key={selectedId} device={device}
                                          expanded={expanded}
                                          toggleExpand={this.toggleExpand}/>
                        )
                    }
                    case CustomEntityTypes.LIGHT: {
                        return <LightContent {...this.props} key={selectedId} device={device} index={Index}
                                             expanded={expanded}
                                             toggleExpand={this.toggleExpand}/>
                    }
                    default:
                        return null;
                }
            }
            default:
                return null;
        }
    }

    render() {
        const {expanded} = this.state;
        const {mobile, t} = this.props;
        const content = this.renderContent();
        if (!content) return null;
        if (mobile) {
            return (
                <>
                    <div className={"map-drawer-mobile"}>
                        <Button buttonColor={"menu-color"}
                                onClick={this.toggleExpand}>{t("modals.feedingSowInfoModal.details")}</Button>
                    </div>
                    <ViewContainerModal name={t("modals.feedingSowInfoModal.details")} show={expanded}
                                        onHide={this.toggleExpand}>
                        <Card>
                            {content}
                        </Card>
                    </ViewContainerModal>
                </>
            )
        }
        return (
            content
        )

    }
}

export default compose(
    withTranslation(),
    connect(state => ({
        buildings: getBuildingsMap(state),
        animals: getActiveAnimals(state),
        devices: state.farmDevices.devices
    }))
)(LocationDrawer)
