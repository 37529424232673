import {get, isFinite} from "lodash";
import {ReactLocalStorage} from "./ReactLocalStorage";
import {catchify} from "./Utils";

export const ScrollLock = {
    NON_BLOCKING: 0,
    MENU: 1,
    LEFT_BAR: 2,
    MODAL: 3,
    DRAWER: 4,
    RIGHT_DRAWER: 5,
    HELPER: 6,
    FILES: 7,
    HANDWRITING: 8,
    DATA_MONITOR: 9,
    CHART_FULL_SCREEN: 10
};

export function scrollToTop(props = {}) {
    document.body.scrollIntoView(({block: 'start', behavior: 'smooth', ...props}));
}

export function scrollVirtualizedFixedHeight(_listContainer, _indexToScroll = -1) {
    try {
        if (_indexToScroll === -1) {
            return;
        }
        console.log(_listContainer)
        const rect = _listContainer.getBoundingClientRect();
        const rectItem = _listContainer.getElementsByClassName("fetura-list-item")[0].getBoundingClientRect();
        const offsetScroll = window.scrollY;
        const offsetInContainer = _indexToScroll * (rectItem.height);
        const scrollY = rect.top + offsetInContainer + offsetScroll;
        const scrollMax = window.document.body.offsetHeight - window.outerHeight;
        console.table({scrollMax, offsetInContainer, scrollY, offsetScroll, rect, rectItem});
        window.scrollTo({
            top: Math.min(scrollY - getRemToPx(10), scrollMax),
            left: window.innerWidth / 2
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function getStyleValue(style) {
    let re = new RegExp(/\((.*?)\)/);
    return get(style.match(re), "[1]", null);
}

export function isOverflown({clientWidth, scrollWidth} = {}) {
    return scrollWidth > clientWidth;
}

export function getOverflowRatio({clientWidth, scrollWidth} = {}) {
    return clientWidth / scrollWidth;
}

const lockClassName = (number) => number && isFinite(number) ? `lock-scroll-${number}` : "lock-scroll";

export function bodyLockScroll(uniqueNumber = 0) {
    document.body.classList.add(lockClassName(uniqueNumber));
}

export function bodyUnlockScroll(uniqueNumber = 0) {
    document.body.classList.remove(lockClassName(uniqueNumber));
}

export function getRemToPx(rem = 1) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const DO_TASK_MIN_INTERVAL = 100;

/**
 * this is utility tool for dom modification using elements which are not yet rendered on screen
 * i.e: check blinkElement for usage
 * @param task {function} - function that will be repeatedly invoked unless a return value is equal to true or no there is no returned value
 * @param maxTimeout {number} - max time for task to be invoked
 */
export const createVirtualizedTask = (task, {maxTimeout = 7.5 * 1000} = {}) => {
    const maxExecutionTime = Date.now() + maxTimeout;
    // maxExecutionTime should be enough but due to massive usage of stub of Date class in tests there will be another parallel check of number of task invocation
    const maxRetry = Math.round(maxTimeout / DO_TASK_MIN_INTERVAL);
    let retryNumber = 0;
    const doTask = () => {
        let status = false;
        let executionTime = Date.now();
        const isReadyToExecute = (executionTime <= maxExecutionTime) && (retryNumber <= maxRetry);
        if (retryNumber) {
            console.warn("[createVirtualizedTask] retry %s of %s", retryNumber, maxRetry);
        }
        if (isReadyToExecute) {
            try {
                const funStatus = task();
                if (funStatus === true || funStatus === undefined) {
                    console.log("[createVirtualizedTask] task success by returned value '%s'", funStatus);
                    status = true;
                } else {
                    console.warn("[createVirtualizedTask] task failed");
                }
            } catch (e) {
                console.warn("[createVirtualizedTask] task unexpected error]");
            }
            if (status !== true) {
                retryNumber++;
                onElementPainted(doTask, {delay: DO_TASK_MIN_INTERVAL});
            }
        } else {
            console.warn("[createVirtualizedTask] failed due to exceeded maxTimeout or maxRetry");
        }
    }
    doTask();
}

export function blinkElement(elementId) {
    createVirtualizedTask(() => {
        try {
            const element = document.getElementById(elementId);
            element.classList.add("notice-me");
            element.classList.add("noticable");
            onElementPainted(catchify(() => {
                element.classList.remove("notice-me")
            }), {delay: 2000})
        } catch (err) {
            // let virtualized task know that the job is not done
            return false;
        }
    });
}

export function getHeaderTabsHeight() {
    let height;
    try {
        height = document.getElementById("app-header").getElementsByClassName("rmc-tabs-tab-bar")[0].getBoundingClientRect().height || 0;
    } catch (e) {
        height = 0;
    }
    if (height === 0) {
        try {
            height = document.getElementById("app-header").getElementsByClassName("tabs")[0].getBoundingClientRect().height || 0;
        } catch (e) {
            height = 0;
        }
    }
    return height;

}

/**
 * Nie wiem czemu ale jak usune na developie lokiego i reloadne strone to nigdy sie nie zaladuje stronki az nie wywolam tego
 */
export function unregisterServiceWorkers() {
    try {
        console.log("Force unregister service worker")
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        if (registration.scope.includes("fetura")) {
                            registration.unregister().then(o => console.log(registration.scope, " out!"));
                        }
                    }
                });
        }
    } catch (e) {
        console.error(e)
    }

}

export function onElementPainted(callback, {delay} = {}) {
    setTimeout(() => {
        try {
            requestAnimationFrame(callback)
        } catch (e) {
            console.error(e);
        }
    }, delay)
}

/**
 * pobranie parenta ze scrollem
 * @param node
 * @return {null|*|HTMLElement}
 */
export function getScrollParent(node) {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
    if (!node) {
        return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }
    return getScrollParent(node.parentNode) || document.body;
}

export function isElementInViewport(node) {
    const rect = node.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isElementPartiallyInViewport(node) {
    const rect = node.getBoundingClientRect();
    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight);
}


export function isDarkMode() {
    const name = ReactLocalStorage.getPrivate("theme", "normal");
    if (name === "auto" && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
    }
    return name === "dark";
}

export function isElementFixed(node, {includeSticky = false} = {}) {
    let element = node;
    const positionTypes = ["fixed"];
    if (includeSticky) positionTypes.push("sticky");
    try {
        while (typeof element === "object" && element.nodeName.toLowerCase() !== "body") {
            if (positionTypes.includes(window.getComputedStyle(element).getPropertyValue("position").toLowerCase())) return true;
            element = element.parentElement;
        }
    } catch (err) {
        return false;
    }

    return false;
}

export function emitScrollEvent() {
    window.dispatchEvent(new CustomEvent('scroll'));
}


export function getStickyOffset() {
    let _offset = 0;
    catchify(() => {
        const viewContainer = document.getElementsByClassName("view-container")[0];
        if (!viewContainer) return;
        // instead of scanning whole dom tree search for specific class names/tags
        // nie jest dobrze. co prawda nie jest też źle. można powiedzieć, że jest średnio
        const stickyList = {
            ClassName: ["settings-view-header", "sticky-item"],
            TagName: ["header"]
        };
        // could be a performance issue due to many sticky positioned elements
        for (let [type, names] of Object.entries(stickyList)) {
            for (let name of names) {
                const candidates = [...viewContainer[`getElementsBy${type}`](name)];
                if (candidates.length) {
                    _offset = candidates[0].getBoundingClientRect().height;
                    return;
                }
            }
        }
    })();
    return _offset;
}

export function scrollToID(id, {yOffset = "auto", behavior = "smooth"} = {}) {
    createVirtualizedTask(() => {
        return scrollToElement(document.getElementById(id), {behavior, yOffset});
    });
}

export function scrollToElement(element, {yOffset = "auto", behavior = "smooth"} = {}) {
    try {
        if (!element) return false;
        // scroll to the top of the element
        // adjust scroll with sticky positioned elements
        const offset = yOffset === "auto" ? getStickyOffset() : yOffset || 0;
        window.scrollTo({
            top: element.getBoundingClientRect().top + window.pageYOffset - offset,
            behavior
        });
        return true;
    } catch (e) {
        console.warn(e);
        return false;
    }
}

export function setNativeValue(element, value) {
    const {set: valueSetter} = Object.getOwnPropertyDescriptor(element, 'value') || {}
    const prototype = Object.getPrototypeOf(element)
    const {set: prototypeValueSetter} = Object.getOwnPropertyDescriptor(prototype, 'value') || {}

    if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value)
    } else if (valueSetter) {
        valueSetter.call(element, value)
    } else {
        throw new Error('The given element does not have a value setter')
    }
}

export function clearCanvas(canvas) {
    canvas.width = 0;
    canvas.height = 0;
}

export const MAX_CANVAS_SIZE_SAFARI = 16777216;

export function isOverlapping(rect1, rect2) {
    return !(rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom)
}

export function disableSubmitOnEnterHandler(e) {
    if (e.key === "Enter") e.preventDefault();
}