export const ns = ["login", "translation"];

/**
 * Opcje i18n, wyeksportowane, aby zaciągnąć je w workerze od paramsów
 * Wyniesione do innego pliku, bo 
 */
export const i18nOptions = {
    compatibilityJSON: "v3", // ToDo przekonwertować tłumaczenia do v4 albo wyzej
    mustacheConfig: {
        tags: ["{{", "}}"],
        disable: false,
    },
    // nsSeparator: false,
    objectNotation: true,
    fallbackLng: false,
    debug: false, // !["production", "test"].includes(process.env.REACT_APP_STAGE),
    ns,
    interpolation: {
        format: function (value, format) {
            if (format === "uppercase") return value.toUpperCase();
            if (format === "lowercase") return value.toLowerCase();
            if (format === "bold") return value.bold();
            return value;
        },
        escapeValue: false, // not needed for react as it escapes by default
        skipOnVariables: false, // in order to behave as v19
    },
};