import { createSelector } from "reselect";
import { getDevices } from "./deviceSelector";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import { Interfaces } from "../constans/devices";
import { getProgramDetails, getShadowLoadings, getShadows, getUploadData, getUploadLoading } from "./shadowSelectors";
import { groupBy } from "lodash";
import moment from "moment";

export const getDebugGateway = state => state.devicesDebug.gateway;

export const getDebugDispensersForGateway = createSelector(getDebugGateway, getDevices, (gateway, devices) => {
    if (!gateway) return [];
    return devices.filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI && item.GatewayID === gateway.DevID);
});

export const getBootControlTableData = createSelector(getDebugDispensersForGateway, getShadows, (dispensers, shadows) => {
    return dispensers.map(dispenser => {
        let program = "-";
        let shadow = shadows.get(dispenser.DevID);
        if (shadow && shadow.bootloader) {
            program = shadow.bootloader.name || "-";
        }
        return { device: dispenser, program };
    });
});

export const getKeepBootStatus = createSelector(getDebugGateway, getShadows, (gateway, shadows) => {
    if (!gateway) return false;
    let shadow = shadows.get(gateway.DevID);
    if (!shadow) return false;
    return shadow.keepNRFInBootStatus || false;
});

export const nrfDataDebugSelector = createSelector(getDevices, getShadows, getShadowLoadings, (devices, shadows, shadowLoading) => {
    let bridges = devices.filter(item => item.DevType === DevType.BRIDGE);
    let data = {
        bridges: [],
        nrfToGetData: [],
        loading: false
    };
    for (let bridge of bridges) {
        let dispensersForBridge = devices.filter(item => item.DevType === DevType.DISPENSER_NRF && item.ParentID === bridge.DevID);
        let nrfData = [];
        for (let dispenser of dispensersForBridge) {
            let obj = { dispenser };
            let loadingNRF = shadowLoading[dispenser.DevID];
            if (loadingNRF) data.loading = true;
            let shadow = shadows.get(dispenser.DevID);
            if (shadow) {
                obj.startTime = shadow?.config?.timeStart;
                let lastResponseTime = Math.max(...Object.values(shadow.metadata));
                obj.noConnection = moment().diff(lastResponseTime, "minute") > 15;
            }
            nrfData.push(obj);
            data.nrfToGetData.push(dispenser);
        }
        let tmp = [];
        let groupedByInterface = groupBy(nrfData, o => o.dispenser.Interface);
        for (let inter in groupedByInterface) {
            let groupedByMulti = groupBy(groupedByInterface[inter], o => o.dispenser.Address & 0xFF00);
            for (let multiAddress in groupedByMulti) {
                let multi = devices.find(item => item.ParentID === bridge.DevID && item.Interface === +inter && item.Address === +multiAddress);
                if (!multi) continue;
                data.nrfToGetData.push(multi);
                let dispensersForMulti = groupedByMulti[multiAddress].sort((a, b) => a.dispenser.Address - b.dispenser.Address);
                let multiShadow = shadows.get(multi.DevID);
                tmp.push({ address: +multiAddress, interface: +inter, fullWidth: true, multi });
                let subaddressesOnDatabase = [];
                for (let row of dispensersForMulti) {
                    let subaddress = row.dispenser.Address & 0xFF;
                    subaddressesOnDatabase.push(subaddress);
                    let canStatus = multiShadow ? multiShadow?.statusCAN?.status?.[subaddress - 1] || 0 : null;
                    let canTime = multiShadow ? multiShadow?.statusCAN?.time?.[subaddress - 1] || -1 : null;
                    tmp.push({ ...row, can: { status: canStatus, time: canTime } });
                }
                for (let i = 0; i < (multiShadow?.statusCAN?.status || []).length; i++) {
                    let status = multiShadow.statusCAN.status[i];
                    if (status === 1 && !subaddressesOnDatabase.includes(i + 1)) {
                        tmp.push({
                            dispenser: { Name: "?", Address: +multiAddress + i + 1, Interface: +inter },
                            can: { status, time: multiShadow.statusCAN.time[i] },
                            noDevice: true
                        });
                    }
                }
            }
        }
        data.bridges.push({ bridge, nrf: tmp });
        // let nrfs = [];
        // let nrfsForBridge = devices.filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI && item.ParentID === bridge.DevID);
        // for (let nrf of nrfsForBridge) {
        //     let shadow = shadows.get(nrf.DevID);
        //     let lastResponseTime = 0;
        //     if (shadow) {
        //         for (let key in shadow.metadata) {
        //             if (shadow.metadata[key] > lastResponseTime) {
        //                 lastResponseTime = shadow.metadata[key];
        //             }
        //         }
        //     }
        //     nrfs.push({
        //         device: nrf,
        //         flags: get(shadow, "flags", []),
        //         counters: get(shadow, "counters", []),
        //         versions: get(shadow, "versions", []),
        //         startTime: get(shadow, "config.timeStart", 0),
        //         curvesCRC: get(shadow, "curveCRC", []),
        //         schedulesCRC: get(shadow, "scheduleCRC", []),
        //         efficiency: get(shadow, "efficiencies", []).map(item => {
        //             let status = get(shadow, "efficiencyStatuses", []).find(s => s.nr === item.nr) || {};
        //             return {
        //                 ...item,
        //                 ...status
        //             }
        //         }),
        //         statusCAN: get(shadow, "statusCAN", {status: [], time: []}),
        //         noConnection: shadow ? moment().diff(lastResponseTime, "minute") > 15 : false,
        //         nrfsForMulti: devices.filter(item => {
        //             if (item.DevType !== DevType.DISPENSER_NRF || item.ParentID !== bridge.DevID || item.Interface !== nrf.Interface) return false;
        //             let multiAddress = item.Address & 0xFF00;
        //             return multiAddress === nrf.Address;
        //         })
        //     })
        // }
        // data.push({
        //     bridge,
        //     nrfs
        // });
    }
    return data;
});

export const nrfProgramsDebugSelector = createSelector(getDevices, getShadows, (devices, shadows) => {
    let confs = devices.filter(item => item.DevType === DevType.BRIDGE_CONF);
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    let data = [];
    for (let conf of confs) {
        let shadow = shadows.get(conf.DevID);
        let programData = {
            current: {
                state: shadow?.boot_info.act_info.state_prg,
                version: shadow?.boot_info.act_info.ver_prg,
                size: shadow?.boot_info.act_info.prg_size,
                name: shadow?.boot_info.act_info.name_prg,
            },
            memory: {
                state: shadow?.boot_info.prg_info.state_prg,
                version: shadow?.boot_info.prg_info.ver_prg,
                size: shadow?.boot_info.prg_info.prg_size,
                name: shadow?.boot_info.prg_info.name_prg,
            },
            nrf: {
                state: shadow?.boot_info_nrf.prg_info.state_prg,
                version: shadow?.boot_info_nrf.prg_info.ver_prg,
                size: shadow?.boot_info_nrf.prg_info.prg_size,
                name: shadow?.boot_info_nrf.prg_info.name_prg,
            },
            f103: {
                state: shadow?.boot_info_f103?.prg_info.state_prg,
                version: shadow?.boot_info_f103?.prg_info.ver_prg,
                size: shadow?.boot_info_f103?.prg_info.prg_size,
                name: shadow?.boot_info_f103?.prg_info.name_prg,
            }
        };
        let doserProgStatus = {
            left: {
                packets: shadow?.send_dozo_prog_status[0].packetsCounter,
                max: shadow?.send_dozo_prog_status[0].packetsToSend,
                uploading: !!shadow?.send_dozo_prog_status[0].state
            },
            center: {
                packets: shadow?.send_dozo_prog_status[1].packetsCounter,
                max: shadow?.send_dozo_prog_status[1].packetsToSend,
                uploading: !!shadow?.send_dozo_prog_status[1].state
            },
            right: {
                packets: shadow?.send_dozo_prog_status[2].packetsCounter,
                max: shadow?.send_dozo_prog_status[2].packetsToSend,
                uploading: !!shadow?.send_dozo_prog_status[2].state
            }
        };
        let multiData = [];
        let multis = devices.filter(item => item.DevType === DevType.DISPENSER_NRF_MULTI && item.ParentID === conf.ParentID);
        for (let multi of multis) {
            let multiShadow = shadows.get(multi.DevID);
            multiData.push({
                multi,
                flag: multiShadow?.flags[1],
                counters: multiShadow?.counters,
                version: multiShadow?.versions[1],
                name: multiShadow?.bootloader.name
            });
        }
        let gateway = devices.find(item => item.DevID === conf.GatewayID);
        let gatewayShadow = shadows.get(gateway?.DevID);
        data.push({
            conf,
            programData,
            doserProgStatus,
            multis: multiData,
            programs: {
                memory: gatewayShadow?.actualBinaries?.SLIP_BR || [],
                f103: gatewayShadow?.actualBinaries?.NRF_F103 || [],
                nrf: gatewayShadow?.actualBinaries?.NRF_DI || []
            }
        });
    }
    return { data, gateways };
});

export const climateOnOffRegister = createSelector(getDevices, getShadows, getShadowLoadings, (devices, shadows) => {
    let climates = devices.filter(item => item.DevType === DevType.CLIMATE_SK3).sort((a, b) => a.Address - b.Address);
    let data = [];
    for (let climate of climates) {
        let shadow = shadows.get(climate.DevID);
        if (shadow) {
            const { powerOffRegister, powerOnRegister } = shadow;
            for (let powerOffTime of powerOffRegister) {
                data.push({
                    name: climate.Name,
                    type: "off",
                    climate,
                    ...powerOffTime
                });
            }
            for (let powerOnTime of powerOnRegister) {
                data.push({
                    name: climate.Name,
                    type: "on",
                    climate,
                    ...powerOnTime
                });
            }
        }
    }
    data.sort((a, b) => a.time - b.time);
    return data;
});
const getGatewayFromProps = (state, props) => props.gateway;

const getGatewayPrograms = createSelector(getGatewayFromProps, getShadows, (gateway, shadows) => {
    let programs = {
        bridge: [],
        multi: [],
        dispenser: []
    };
    let shadow = shadows.get(gateway.DevID);
    if (shadow) {
        programs.bridge.push(...shadow.actualBinaries?.SLIP_BR || []);
        programs.multi.push(...shadow.actualBinaries?.NRF_DI || []);
        programs.dispenser.push(...shadow.actualBinaries?.NRF_F103 || []);
    }
    return programs;
});

const getBridgePrograms = createSelector(getGatewayPrograms, programs => {
    return programs.bridge;
});

export const makeGetBridgePrograms = () => getBridgePrograms;

const getMultiPrograms = createSelector(getGatewayPrograms, programs => {
    return programs.multi;
});

export const makeGetMultiPrograms = () => getMultiPrograms;

const getDispenserPrograms = createSelector(getGatewayPrograms, programs => {
    return programs.dispenser;
});

export const makeGetDispenserPrograms = () => getDispenserPrograms;

export const getBridgesBySelectedPrograms = createSelector(getDevices, (devices) => {
    return devices.filter(item => item.DevType === DevType.BRIDGE_CONF).map(conf => ({
        conf,
        bridge: devices.find(item => item.DevID === conf.ParentID)
    }));
});

export const getBridgesBySelectedProgramsForNRF = createSelector(getDevices, (devices) => {
    return devices.filter(item => item.DevType === DevType.BRIDGE_CONF).map(conf => ({
        conf,
        bridge: devices.find(item => item.DevID === conf.ParentID),
        hasLeft: devices.filter(item => item.DevType === DevType.DISPENSER_NRF && item.Interface === Interfaces.NRF_0 && item.ParentID === conf.ParentID).length > 0,
        hasCenter: devices.filter(item => item.DevType === DevType.DISPENSER_NRF && item.Interface === Interfaces.NRF_2 && item.ParentID === conf.ParentID).length > 0,
        hasRight: devices.filter(item => item.DevType === DevType.DISPENSER_NRF && item.Interface === Interfaces.NRF_1 && item.ParentID === conf.ParentID).length > 0
    }));
});

export const getLoadingUploadStatus = createSelector(getDevices, getUploadLoading, (devices, loading) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        if (loading[gateway.DevID]) return true;
    }
    return false;
});

export const getProgramsStatus = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    let statuses = new Set();
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data) {
            statuses.add(data.actualStatus);
        }
    }
    // zasada, ze ktorykolwiek ze statusow jest taki, to powinno zwrocic
    if (statuses.has("NO_CONNECTION")) return "NO_CONNECTION";
    if (statuses.has("IDLE") && statuses.size === 1) return "IDLE";
    if (statuses.has("TASK_ERROR") && statuses.size === 1) return "TASK_ERROR";
    if (statuses.has("TASK_CANNOT_FIND_CPU_TO_UPDATE") && statuses.size === 1) return "TASK_CANNOT_FIND_CPU_TO_UPDATE";

    if (statuses.has("DOWNLOADING_BINARY")) return "DOWNLOADING_BINARY";
    if (statuses.has("CLEARING_PROGRAM_FROM_BRIDGE")) return "CLEARING_PROGRAM_FROM_BRIDGE";
    if (statuses.has("CHECKING_PROGRAM_ON_BRIDGE")) return "CHECKING_PROGRAM_ON_BRIDGE";
    if (statuses.has("SAVING_BRIDGE_MEMORY")) return "SAVING_BRIDGE_MEMORY";
    if (statuses.has("WAITING")) return "WAITING";
    if (statuses.has("READING_BRIDGE_VERSION")) return "READING_BRIDGE_VERSION";
    if (statuses.has("RESTARTING_BRIDGE")) return "RESTARTING_BRIDGE";

    if (statuses.has("SAVING_MULTI_MEMORY")) return "SAVING_MULTI_MEMORY";
    if (statuses.has("RESTARTING_MULTI")) return "RESTARTING_MULTI";
    if (statuses.has("COPYING_PROGRAM_MULTI")) return "COPYING_PROGRAM_MULTI";
    if (statuses.has("CHECKING_MULTI")) return "CHECKING_MULTI";

    if (statuses.has("SAVING_DISPENSER_MEMORY")) return "SAVING_DISPENSER_MEMORY";
    if (statuses.has("CHECKING_DISPENSER")) return "CHECKING_DISPENSER";

    if (statuses.has("CLEARING_PROGRAM_FROM_UNIVERSAL")) return "CLEARING_PROGRAM_FROM_UNIVERSAL";
    if (statuses.has("CHECKING_PROGRAM_ON_UNIVERSAL")) return "CHECKING_PROGRAM_ON_UNIVERSAL";
    if (statuses.has("SAVING_UNIVERSAL_MEMORY")) return "SAVING_UNIVERSAL_MEMORY";
    if (statuses.has("READING_UNIVERSAL_VERSION")) return "READING_UNIVERSAL_VERSION";
    if (statuses.has("UNIVERSAL_COPYING")) return "UNIVERSAL_COPYING";

    if (statuses.has("TASK_SUCCESS")) return "TASK_SUCCESS";
    return "ERROR";
});

export const getProgramsPercent = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    let progress = [];
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data && data.progress !== undefined && data.progress !== null) {
            progress.push(data.progress);
        }
    }
    if (progress.length === 1) return progress[0];
    if (progress.length > 0) {
        let sum = 0;
        for (let p of progress) {
            sum += p;
        }
        return sum / progress.length;
    }
    return 0;
});

export const getProgramsDevType = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data?.type) {
            return data.type;
        }
    }
    return null;
});

export const makeGetProgramsDevType = () => getProgramsDevType;

export const getProgrammedDevices = createSelector(getDevices, getUploadData, (devices, uploadData) => {
    const devs = devices.filter(item => {
        const gatewayStatus = uploadData[item.GatewayID];
        return gatewayStatus && gatewayStatus.DevIDs.includes(item.DevID);
    });
    const grouped = groupBy(devs, "GatewayID");
    const data = [];
    for (let GwID in grouped) {
        data.push({ GwID, DevIDs: grouped[GwID].map(dev => dev.DevID) });
    }
    return data;
});

export const getProgramsBridgeInfo = createSelector(getDevices, getProgramDetails, getUploadData, (devices, details, uploadData) => {
    let data = [];
    let bridges = devices.filter(item => {
        if (item.DevType !== DevType.BRIDGE) return false;
        const gatewayStatus = uploadData[item.GatewayID];
        return gatewayStatus && gatewayStatus.DevIDs.includes(item.DevID);
    });
    bridges.sort((a, b) => a.Address - b.Address);
    const grouped = groupBy(bridges, "GatewayID");
    for (let GwID in grouped) {
        data.push({
            GwID,
            gateway: devices.find(item => item.DevID === GwID),
            bridges: grouped[GwID].map(bridge => {
                let bridgeDetails = details[bridge.GatewayID]?.[bridge.DevID];
                return {
                    bridge,
                    current: bridgeDetails?.actualVer,
                    memory: bridgeDetails?.memoryVer
                };
            })
        });
    }
    return data;
});

export const getProgramsMultiInfo = createSelector(getDevices, getProgramDetails, getUploadData, getProgramsBridgeInfo, (devices, details, uploadData, bridgeInfo) => {
    let multis = devices.filter(item => {
        if (item.DevType !== DevType.DISPENSER_NRF_MULTI) return false;
        const gatewayStatus = uploadData[item.GatewayID];
        return gatewayStatus && gatewayStatus.DevIDs.includes(item.DevID);
    });
    multis.sort((a, b) => {
        if (a.Interface === b.Interface) return a.Address - b.Address;
        return a.Interface - b.Interface;
    });
    for (let gwRow of bridgeInfo) {
        for (let bridgeRow of gwRow.bridges) {
            const { bridge } = bridgeRow;
            let bridgeDetails = details[bridge.GatewayID]?.[bridge.DevID];
            bridgeRow.program = bridgeDetails?.nrfVer;
            bridgeRow.multis = multis.filter(item => item.ParentID === bridge.DevID).map(multi => {
                let multiDetails = details[multi.GatewayID]?.[multi.DevID];
                return {
                    multi,
                    current: multiDetails?.actualVer,
                    memory: multiDetails?.memoryVer
                };
            });
        }
    }
    return bridgeInfo;
});

export const getProgramsDispenserInfo = createSelector(getDevices, getProgramDetails, getUploadData, getProgramsMultiInfo, (devices, details, uploadData, multiInfo) => {
    let dispensers = devices.filter(item => {
        if (item.DevType !== DevType.DISPENSER_NRF) return false;
        const gatewayStatus = uploadData[item.GatewayID];
        return gatewayStatus && gatewayStatus.DevIDs.includes(item.DevID);
    });
    dispensers.sort((a, b) => {
        if (a.Interface === b.Interface) return a.Address - b.Address;
        return a.Interface - b.Interface;
    });
    for (let gwRow of multiInfo) {
        for (let bridgeRow of gwRow.bridges) {
            const { bridge } = bridgeRow;
            let bridgeDetails = details[bridge.GatewayID]?.[bridge.DevID];
            bridgeRow.program = bridgeDetails?.f103Ver;
            for (let multiRow of bridgeRow.multis) {
                const { multi } = multiRow;
                let multiDetails = details[multi.GatewayID]?.[multi.DevID];
                multiRow.program = multiDetails?.f103ProgVer;
                multiRow.dispensers = dispensers.filter(item => item.ParentID === bridge.DevID && item.Interface === multi.Interface && (item.Address & 0xFF00) === multi.Address).map(dispenser => {
                    let dispenserDetails = details[dispenser.GatewayID]?.[dispenser.DevID];
                    return {
                        dispenser,
                        current: dispenserDetails?.actualVer
                    };
                });
            }
        }
    }
    return multiInfo;
});

export const getProgramsRetry = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data) {
            return data.currentAttempt;
        }
    }
    return 0;
});

export const getProgramsMaxRetries = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data) {
            return data.maxAttempts;
        }
    }
    return 3;
});

export const getProgramHardware = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data) {
            return { max: data.numberOfHardwares, current: data.currentHardware };
        }
    }
    return null;
});

export const getProgramHardwareName = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data) {
            return data.hardwareName;
        }
    }
    return null;
});

export const makeGetSoftwareVersion = () => createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data) {
            return data.softVer;
        }
    }
    return null;
});

function getBridgeProgress(status, percent, type, retry, maxRetries) {
    let maxPercentForDownload = 3;
    let maxPercentForClearingAndChecking = 3;
    let maxPercentForRestartBridge = 5;
    let maxPercentForReadingBridgeVersion = 3;
    let maxPercentForWaiting = 3;
    let leftPercentForSavingMemory = (100 - (maxPercentForClearingAndChecking * 2) - maxPercentForRestartBridge - maxPercentForDownload) / maxRetries;
    let percentForClearingAndChecking = maxPercentForClearingAndChecking / maxRetries;
    if (status === "DOWNLOADING_BINARY") {
        return maxPercentForDownload;
    }
    // CLEARING_PROGRAM_FROM_BRIDGE i CHECKING_PROGRAM_ON_BRIDGE to procesy polaczone ze soba
    if (status === "CLEARING_PROGRAM_FROM_BRIDGE") {
        return maxPercentForDownload + (retry * percentForClearingAndChecking);
    }
    if (status === "CHECKING_PROGRAM_ON_BRIDGE") {
        return (retry * percentForClearingAndChecking) + (percentForClearingAndChecking * maxRetries) + maxPercentForDownload;
    }
    if (status === "SAVING_BRIDGE_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (leftPercentForSavingMemory * retry);
        let currentPercentForSave = percent * ((leftPercentForSavingMemory - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingBridgeVersion / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "WAITING") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (leftPercentForSavingMemory * retry);
        let shouldStartOn = leftPercentForSavingMemory - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingBridgeVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "READING_BRIDGE_VERSION") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (leftPercentForSavingMemory * retry);
        let shouldStartOn = leftPercentForSavingMemory - (maxPercentForReadingBridgeVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "RESTARTING_BRIDGE") {
        let percentForRestart = maxPercentForRestartBridge / maxRetries;
        return 100 - maxPercentForRestartBridge + (retry * percentForRestart);
    }
}

function getMultiProgress(status, percent, type, retry, maxRetries, hardware) {
    let maxPercentForDownload = 3;
    let maxPercentForClearingAndChecking = 3;
    let maxPercentForReadingBridgeVersion = 3;
    let maxPercentForWaiting = 3;
    let percentForCheckingMulti = 3;
    let percentForRestartingMulti = 3;
    let percentForCopyingMulti = 3;
    let percentForClearingAndChecking = maxPercentForClearingAndChecking / maxRetries;
    let leftForAllHardware = 100 - maxPercentForDownload;
    let percentForOneHardware = leftForAllHardware / hardware.max;
    let hardwareAdd = hardware.current * percentForOneHardware;
    let leftPercentForSavingMemory = (percentForOneHardware - (maxPercentForClearingAndChecking * 2) - maxPercentForDownload) / maxRetries / 3;
    let bridgeSaving = leftPercentForSavingMemory;
    let multiSaving = leftPercentForSavingMemory * 2;
    if (status === "DOWNLOADING_BINARY") {
        return maxPercentForDownload;
    }
    // CLEARING_PROGRAM_FROM_BRIDGE i CHECKING_PROGRAM_ON_BRIDGE to procesy polaczone ze soba
    if (status === "CLEARING_PROGRAM_FROM_BRIDGE") {
        return (retry * percentForClearingAndChecking) + maxPercentForDownload + hardwareAdd;
    }
    if (status === "CHECKING_PROGRAM_ON_BRIDGE") {
        return (retry * percentForClearingAndChecking) + maxPercentForClearingAndChecking + maxPercentForDownload + hardwareAdd;
    }
    if (status === "SAVING_BRIDGE_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * retry) + hardwareAdd;
        let currentPercentForSave = percent * ((bridgeSaving - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingBridgeVersion / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "WAITING") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * retry) + hardwareAdd;
        let shouldStartOn = bridgeSaving - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingBridgeVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "READING_BRIDGE_VERSION") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * retry) + hardwareAdd;
        let shouldStartOn = bridgeSaving - (maxPercentForReadingBridgeVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "SAVING_MULTI_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * retry) + hardwareAdd;
        let currentPercentForSave = percent * ((multiSaving - (percentForRestartingMulti / maxRetries) - (percentForCopyingMulti / maxRetries) - (percentForCheckingMulti / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "RESTARTING_MULTI") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * retry) + hardwareAdd;
        let currentPercentForSave = multiSaving - (percentForRestartingMulti / maxRetries) - (percentForCopyingMulti / maxRetries) - (percentForCheckingMulti / maxRetries);
        return startFrom + currentPercentForSave;
    }
    if (status === "COPYING_PROGRAM_MULTI") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * retry) + hardwareAdd;
        let currentPercentForSave = multiSaving - (percentForCopyingMulti / maxRetries) - (percentForCheckingMulti / maxRetries);
        return startFrom + currentPercentForSave;
    }
    if (status === "CHECKING_MULTI") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * retry) + hardwareAdd;
        let currentPercentForSave = multiSaving - (percentForCheckingMulti / maxRetries);
        return startFrom + currentPercentForSave;
    }
}

function getDispenserProgress(status, percent, type, retry, maxRetries, hardware) {
    let maxPercentForDownload = 3;
    let maxPercentForClearingAndChecking = 3;
    let maxPercentForReadingBridgeVersion = 3;
    let maxPercentForWaiting = 3;
    let percentForCheckingMulti = 3;
    let maxPercentForCheckingDispenser = 3;
    let leftForAllHardware = 100 - maxPercentForDownload;
    let percentForOneHardware = leftForAllHardware / hardware.max;
    let hardwareAdd = hardware.current * percentForOneHardware;
    let leftPercentForSavingMemory = (percentForOneHardware - (maxPercentForClearingAndChecking * 2) - maxPercentForDownload) / maxRetries / 6;
    // let leftPercentForSavingMemory = (100 - (maxPercentForClearingAndChecking * 2) - maxPercentForDownload) / maxRetries / 6 / hardware.max;
    let bridgeSaving = leftPercentForSavingMemory;
    let multiSaving = leftPercentForSavingMemory * 3;
    let dispenserSaving = leftPercentForSavingMemory * 2;
    let percentForClearingAndChecking = maxPercentForClearingAndChecking / maxRetries;
    if (status === "DOWNLOADING_BINARY") {
        return maxPercentForDownload;
    }
    // CLEARING_PROGRAM_FROM_BRIDGE i CHECKING_PROGRAM_ON_BRIDGE to procesy polaczone ze soba
    if (status === "CLEARING_PROGRAM_FROM_BRIDGE") {
        return (retry * percentForClearingAndChecking) + maxPercentForDownload + hardwareAdd;
    }
    if (status === "CHECKING_PROGRAM_ON_BRIDGE") {
        return (retry * percentForClearingAndChecking) + (percentForClearingAndChecking * maxRetries) + maxPercentForDownload + hardwareAdd;
    }
    if (status === "SAVING_BRIDGE_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * retry) + hardwareAdd;
        let currentPercentForSave = percent * ((bridgeSaving - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingBridgeVersion / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "WAITING") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * retry) + hardwareAdd;
        let shouldStartOn = bridgeSaving - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingBridgeVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "READING_BRIDGE_VERSION") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * retry) + hardwareAdd;
        let shouldStartOn = bridgeSaving - (maxPercentForReadingBridgeVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "SAVING_MULTI_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * retry) + hardwareAdd;
        let currentPercentForSave = percent * ((multiSaving - (percentForCheckingMulti / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "CHECKING_MULTI") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * retry) + hardwareAdd;
        let currentPercentForSave = multiSaving - (percentForCheckingMulti / maxRetries);
        return startFrom + currentPercentForSave;
    }
    if (status === "SAVING_DISPENSER_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * maxRetries) + (dispenserSaving * retry) + hardwareAdd;
        let currentPercentForSave = percent * ((dispenserSaving - (maxPercentForCheckingDispenser / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "CHECKING_DISPENSER") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (bridgeSaving * maxRetries) + (multiSaving * maxRetries) + (dispenserSaving * retry) + hardwareAdd;
        let currentPercentForSave = dispenserSaving - (maxPercentForCheckingDispenser / maxRetries);
        return startFrom + currentPercentForSave;
    }
}

function getUniversalProgress(status, percent, type, retry, maxRetries, hardware) {
    let maxPercentForDownload = 3;
    let maxPercentForClearingAndChecking = 3;
    let maxPercentForRestartUniversal = 5;
    let maxPercentForReadingUniversalVersion = 3;
    let maxPercentForWaiting = 3;
    let leftPercentForSavingMemory = (100 - (maxPercentForClearingAndChecking * 2) - maxPercentForRestartUniversal - maxPercentForDownload) / maxRetries;
    let percentForClearingAndChecking = maxPercentForClearingAndChecking / maxRetries;
    if (status === "DOWNLOADING_BINARY") {
        return maxPercentForDownload;
    }
    if (status === "CLEARING_PROGRAM_FROM_UNIVERSAL") {
        return maxPercentForDownload + (retry * percentForClearingAndChecking);
    }
    if (status === "CHECKING_PROGRAM_ON_UNIVERSAL") {
        return (retry * percentForClearingAndChecking) + (percentForClearingAndChecking * maxRetries) + maxPercentForDownload;
    }
    if (status === "SAVING_UNIVERSAL_MEMORY") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (leftPercentForSavingMemory * retry);
        let currentPercentForSave = percent * ((leftPercentForSavingMemory - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingUniversalVersion / maxRetries)) / 100);
        return startFrom + currentPercentForSave;
    }
    if (status === "WAITING") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (leftPercentForSavingMemory * retry);
        let shouldStartOn = leftPercentForSavingMemory - (maxPercentForWaiting / maxRetries) - (maxPercentForReadingUniversalVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "READING_UNIVERSAL_VERSION") {
        let startFrom = maxPercentForDownload + (maxPercentForClearingAndChecking * 2) + (leftPercentForSavingMemory * retry);
        let shouldStartOn = leftPercentForSavingMemory - (maxPercentForReadingUniversalVersion / maxRetries);
        return startFrom + shouldStartOn;
    }
    if (status === "UNIVERSAL_COPYING") {
        let percentForRestart = maxPercentForRestartUniversal / maxRetries;
        return 100 - maxPercentForRestartUniversal + (retry * percentForRestart);
    }
    return 0;
}

export const getProgressForProgramming = createSelector(getProgramsStatus, getProgramsPercent, getProgramsDevType, getProgramsRetry, getProgramsMaxRetries, getProgramHardware, (status, percent, type, retry, maxRetries, hardware) => {
    if (status === "NO_CONNECTION" || status === "IDLE") return null;
    if (status === "TASK_ERROR" || status === "TASK_SUCCESS" || status === "TASK_CANNOT_FIND_CPU_TO_UPDATE") return {
        percent: 100,
        status
    };
    if (type === DevType.BRIDGE) return {
        percent: getBridgeProgress(status, percent, type, retry, maxRetries, hardware),
        status
    };
    if (type === DevType.DISPENSER_NRF_MULTI) return {
        percent: getMultiProgress(status, percent, type, retry, maxRetries, hardware),
        status
    };
    if (type === DevType.DISPENSER_NRF) return {
        percent: getDispenserProgress(status, percent, type, retry, maxRetries, hardware),
        status
    };
    if (type === DevType.UNIVERSAL) return {
        percent: getUniversalProgress(status, percent, type, retry, maxRetries, hardware),
        status
    };
    return { percent: 0, status };
});

export const getResultForProgramming = createSelector(getUploadData, getDevices, getProgramsDevType, (upload, devices, type) => {
    if (type !== DevType.DISPENSER_NRF) return null;
    let table = [];
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data?.dozoStatus) {
            for (let DevID in data.dozoStatus) {
                let device = devices.find(item => item.DevID === DevID);
                table.push({
                    name: device.Name,
                    address: device.Address,
                    interf: device.Interface,
                    status: data.dozoStatus[DevID]
                });
            }
        }
    }
    if (table.length === 0) return null;
    table.sort((a, b) => a.interf - b.interf !== 0 ? a.interf - b.interf : a.address - b.address);
    return table;
});

export const getProgramsHardwareList = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    let hardwares = new Set();
    let numberOfHardwares = 0;
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data?.hardwares) {
            for (let hardware of data.hardwares) {
                hardwares.add(hardware);
            }
            if (numberOfHardwares === 0) numberOfHardwares = hardwares.size;
        }
    }
    if (numberOfHardwares !== hardwares.size) return null;
    return [...hardwares.values()];
});

export const getProgramChecklist = createSelector(getUploadData, getDevices, (upload, devices) => {
    let gateways = devices.filter(item => item.DevType === DevType.GATEWAY);
    let checkStatus = [];
    let steps = [];
    for (let gateway of gateways) {
        let data = upload[gateway.DevID];
        if (data?.checkList && data.currentStep !== undefined && data.currentStep !== null) {
            checkStatus.push(data.checkList);
            steps.push(data.currentStep);
        }
    }
    steps = steps.filter(item => item !== undefined);
    let shortest = checkStatus[0];
    for (let i = 1; i < checkStatus.length; i++) {
        if (shortest.length > checkStatus[i].length) shortest = checkStatus[i];
    }
    return {
        checklist: shortest,
        step: Math.min(...steps)
    };
});

export const getAvailableDevicesToUpdate = createSelector(getDevices, (devices) => {
    const types = { dispenser: false, multi: false, bridge: false };
    for (let device of devices) {
        if (device.DevType === DevType.DISPENSER_NRF) types.dispenser = true;
        if (device.DevType === DevType.DISPENSER_NRF_MULTI) types.multi = true;
        if (device.DevType === DevType.BRIDGE) types.bridge = true;
        if (device.DevType === DevType.UNIVERSAL) types.universal = true;
    }
    return types;
});