import React from "react";
import ReactDOM from "react-dom";
import "./_button-group.scss";
import {CSSTransition} from "react-transition-group";


function ButtonGroupBody({style, children, vertical, fixed, className}) {

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {outlined: false});
        }
        return child;
    });

    return (
        <div style={style}
             className={`fetura-button-group ${vertical ? "vertical" : ""} ${fixed ? "fixed" : ""} ${className}`}>
            {childrenWithProps}
        </div>
    )
}

function ButtonGroup({renderInPortal = true, style, children, vertical = false, fixed = false, className = "", show}) {

    const body = (
        <ButtonGroupBody style={style} className={className} vertical={vertical} fixed={fixed}>
            {children}
        </ButtonGroupBody>
    );

    if (fixed && renderInPortal) {
        if (show === true || show === false) {
            return ReactDOM.createPortal((
                <CSSTransition
                    in={show}
                    timeout={300}
                    classNames="show"
                    mountOnEnter
                    unmountOnExit>
                    {body}
                </CSSTransition>
            ), document.getElementById("root") || document.createElement("div"));
        }
        return ReactDOM.createPortal(body, document.getElementById("root") || document.createElement("div"))

    }
    return body;
}

export default ButtonGroup;