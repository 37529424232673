import React from "react";
import PropTypes from "prop-types";
import "./_info-box.scss";

export default class InfoBox extends React.Component {

    render() {
        const {boxColor, fixed, onClick, children, className: classNameProps, outlined} = this.props;
        let className = "fetura-info-box";
        (boxColor) && (className += ` ${boxColor}`);
        (fixed) && (className += " fixed");
        (onClick) && (className += " pointer");
        (classNameProps) && (className += ` ${classNameProps}`);
        (outlined) && (className += " outlined");
        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        );
    }

}

InfoBox.propTypes = {
    boxColor: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""]),
    fixed: PropTypes.bool,
    outlined: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
};

InfoBox.defaultProps = {
    boxColor: "info",
    fixed: false,
    outlined: true
};
