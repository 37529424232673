import {
    cloneDeep,
    debounce,
    findIndex,
    get,
    isArray,
    isEqual,
    isFinite,
    isFunction,
    isNil,
    isNumber,
    xor
} from "lodash";
import memoizeOne from "memoize-one";
import PropTypes from "prop-types";
import React from "react";
import { Fade } from "react-bootstrap";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    Brush,
    CartesianGrid,
    ComposedChart,
    Customized,
    ErrorBar,
    Legend,
    Line,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { compose } from "redux";
import {
    makeGetChartReferenceLineFontSize,
    makeGetChartStrokeWidth,
    makeGetReferenceLineTextPosition,
    makeGetScatterShape
} from "../../../selectors/settingsSelector";
import { tickPrecisionFormatter } from "../../../utils/ChartsUtils";
import { getColorByName } from "../../../utils/ColorUtils";
import { ScrollLock, bodyLockScroll, bodyUnlockScroll } from "../../../utils/DOMUtils";
import { isFiniteNumber } from "../../../utils/MathUtils";
import { isMobile } from "../../../utils/MobileUtils";
import { cloneFast } from "../../../utils/Utils";
import NotFound from "../../NotFound";
import Button from "../button/Button";
import Checkbox from "../checkbox/Checkbox";
import Input from "../input/Input";
import BasicTooltip from "../tooltip/Tooltip";
import CustomLegend from "./CustomLegend";
import Label from "./Label";
import SaveAsExcell from "./SaveAsExcell";
import "./_chart.scss";

function makeMapStateToProps() {
    const getChartStrokeWidth = makeGetChartStrokeWidth();
    const getChartReferenceLineFontSize = makeGetChartReferenceLineFontSize();
    const getChartReferenceLineTextPosition = makeGetReferenceLineTextPosition();
    const getScatterShape = makeGetScatterShape();
    return state => ({
        settingsStrokeWidth: getChartStrokeWidth(state),
        settingsReferenceLineSize: getChartReferenceLineFontSize(state),
        referenceLinePosition: getChartReferenceLineTextPosition(state),
        scatterShape: getScatterShape(state)
    });
}

export class Chart extends React.Component {

    constructor(props, context) {
        super(props, context);
        let data = this.getData(this.props) || [];
        const { brush } = this.props;
        const { min, max } = brush ? this.calculateMinAndMaxForBrush(data) : { min: 0, max: Math.max(data.length - 1, 0) };
        let hide = [];
        for (let [index, d] of props.dataDef.entries()) {
            if (d.defaultOff) hide.push(index);
        }
        this.state = {
            mobile: isMobile(),
            hide,
            data,
            min,
            max,
            blockBrush: false,
            brushWidth: null,
            domain: props.Yaxis.domain || this.getDefaultDomain(data, false),
            changedDomain: false,
            fullscreen: false,
            renderedContainer: false
        };
        this.selected = [];
        this.container = React.createRef();
        this.checkbox = React.createRef();
        this.selectionRef = React.createRef();
        this.rechartsRef = React.createRef();
        this.chartContainer = React.createRef();
        this.raf = null;
        this.mobileDefaults = {
            hide: {
                hide: true
            },
            mirror: {
                mirror: true,
                label: null,
                tickLine: false,
                tickSize: 0
            }
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!isEqual(this.props, nextProps)) return true;
        return !isEqual(this.state, nextState);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data } = this.state;
        if (data.length !== prevState.data.length) {
            // gdy mamy dlugosc 0 to tracimy refa i trzeba na nowo podpiac observer
            if (!data.length || !prevState.data.length) {
                this.initializeObserver();
                this.setState({ renderedContainer: data.length ? true : false });
            } else {
                this.calculateBrushWidth();
            }
        }
    }

    createObserver() {
        try {
            const observer = new ResizeObserver(this.calculateBrushWidth);
            if (this.container.current) {
                observer.observe(this.container.current);
            }
            return observer;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    destroyObserver() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    componentDidMount() {
        this.initializeObserver();
        if (this.props.data.length > 0) {
            this.setState({ renderedContainer: true });
        }
    }

    initializeObserver(forceCalculate = true) {
        this.destroyObserver();
        this.observer = this.createObserver();
        if (forceCalculate) {
            this.calculateBrushWidth();
        }
    }

    componentWillUnmount() {
        this.destroyObserver();
        if (this.state.fullscreen) {
            bodyUnlockScroll(ScrollLock.CHART_FULL_SCREEN);
        }
    }

    getBrushWidth() {
        if (this.container.current) {
            let width = this.container.current.clientWidth - 70;
            if (this.props.brush.blockBrush && this.checkbox.current) {
                width -= this.checkbox.current.clientWidth + 10;
            }
            return width;
        }
        return null;
    }

    calculateBrushWidth = debounce(() => {
        const { brush } = this.props;
        if (brush) {
            this.setState({
                brushWidth: this.getBrushWidth()
            });
        }
    }, 125);

    onLegendItemClicked = (item) => {
        const { hasShadows, dataDef, onLegendClick } = this.props;
        const { payload: { index, dataKey } } = item;
        let hide = xor(this.state.hide, [index]);
        if (hasShadows) {
            let key = dataKey.trim();
            if (!dataKey.includes("Shadow")) {
                key += "Shadow";
            } else {
                key = key.split("Shadow")[0];
            }
            let shadowIndex = findIndex(dataDef, o => o.dataKey === key);
            if (shadowIndex >= 0) {
                hide = xor(hide, [shadowIndex]);
            }
        }
        onLegendClick(item);
        this.setState({
            hide
        });
    };

    getData = (props) => {
        const { data, dataDef } = props;
        let newData = cloneDeep(data);
        dataDef.forEach(def => {
            if (isFunction(def.valueConverter)) {
                newData = newData.map(o => ({
                    ...o,
                    [def.dataKey]: def.valueConverter(get(o, def.dataKey))
                }));
            }
        });
        return newData;
    };

    isHidden = (index) => {
        return this.state.hide.includes(index);
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const { data, brush, dataDef } = this.props;
        const { blockBrush, changedDomain } = this.state;
        if (!isEqual(data, nextProps.data)) {
            let data = this.getData(nextProps);
            let obj = {
                data
            };
            if (brush && !blockBrush) {
                obj = {
                    ...obj,
                    ...(brush ? this.calculateMinAndMaxForBrush(data, nextProps) : {
                        min: 0,
                        max: Math.max(data.length - 1, 0)
                    })
                };
            }
            obj.domain = this.getDefaultDomain(data, changedDomain);
            this.setState(obj);
        }
        if (nextProps.refreshHideOnNewChartDef && !isEqual(dataDef, nextProps.dataDef)) {
            console.log(dataDef, nextProps.dataDef, "chanded");
            let hide = [];
            for (let [index, data] of nextProps.dataDef.entries()) {
                if (data.defaultOff) hide.push(index);
            }
            this.setState({
                hide
            });
        }
    }

    getChartType() {
        const { type } = this.props;
        switch (type) {
            case "Area":
                return AreaChart;
            case "Bar":
                return BarChart;
            case "Scatter":
                return ScatterChart;
            case "Composed":
                return ComposedChart;
            default:
                return AreaChart;
        }
    }

    getDataType(dataType) {
        const { type } = this.props;
        switch (type) {
            case "Area":
                return Area;
            case "Bar":
                return Bar;
            case "ErrorBar":
                return ErrorBar;
            case "Customized":
                return Customized;
            case "Scatter":
                return Scatter;
            case "Composed":
                switch (dataType) {
                    case "Bar":
                        return Bar;
                    case "ErrorBar":
                        return ErrorBar;
                    case "Area":
                        return Area;
                    case "Line":
                        return Line;
                    case "Scatter":
                        return Scatter;
                    case "Customized":
                        return Customized;
                    default:
                        return Area;
                }
            default:
                return Area;
        }
    }

    calculateMinAndMaxForBrush(data = [], props = this.props) {
        const { brush: { brushKey, type = "showAll", args = {} }, Xaxis: { dataKey } = {} } = props;
        const params = {
            ...args,
            ...!isNumber(args.percent) && { percent: 60 },
            ...!isNumber(args.point) && { point: Math.round(data.length / 2) },
            relativePercent: !!args.relativePercent,
            ...!["avg", "min", "max"].includes(args.middlePoint) && { middlePoint: "avg" },
            ...![1, 2, 3, 4].includes(args.dataWithValueInRow) && { dataWithValueInRow: 3 }
        };
        const getDataByIndex = (_index) => {
            return get(data, `[${_index}].${brushKey}`, null);
        };
        const getIndexRange = (_index, shiftPoint = data.length) => {
            const shift = Math.round((shiftPoint * (params.percent / 2)) / 100);
            return [Math.max(0, _index - shift), Math.min(data.length - 1, _index + shift)];
        };
        if (!data.length) return { min: 0, max: 0 };
        switch (type) {
            case "showOnDifferent": {
                let min = 0, max = data.length - 1;
                for (let i = 0; i <= (data.length - params.dataWithValueInRow); i++) {
                    for (let j = 0; j < params.dataWithValueInRow; j++) {
                        if (!getDataByIndex(i + j)) break;
                        if (j === (params.dataWithValueInRow - 1)) {
                            min = i;
                        }
                    }
                }
                for (let i = data.length - 1; i >= params.dataWithValueInRow; i--) {
                    for (let j = 0; j < params.dataWithValueInRow; j++) {
                        if (!getDataByIndex(i + j)) break;
                        if (j === (params.dataWithValueInRow - 1)) {
                            max = i;
                        }
                    }
                }
                return { min: Math.min(max, min), max: Math.max(min, max) };
            }
            case "showAroundPoint": {
                const isXAxisNumeric = data.every(d => isFiniteNumber(get(d, dataKey)));
                if (isXAxisNumeric) {
                    if (params.relativePercent) {
                        const getClosestValue = (_goal) => {
                            let index = Math.floor(data.length / 2), diff = null;
                            for (let i = 0; i < data.length; i++) {
                                const value = get(data[i], dataKey, null);
                                if (isFiniteNumber(value)) {
                                    const currentDiff = Math.abs(value - _goal);
                                    if (diff === null || currentDiff < diff) {
                                        diff = currentDiff;
                                        index = i;
                                        if (diff === 0) break; // direct hit
                                    }
                                }
                            }
                            return index;
                        };
                        const diff = (params.percent * params.point) / 200;
                        const [min, max] = [getClosestValue(params.point - diff), getClosestValue(params.point + diff)];
                        return { min: Math.min(max, min), max: Math.max(min, max) };

                    } else {
                        const index = data.findIndex(d => get(d, dataKey) === params.point);
                        let [min, max] = getIndexRange(index);
                        return { min: Math.min(max, min), max: Math.max(min, max) };
                    }
                } else {
                    const [min, max] = getIndexRange(params.point);
                    return { min: Math.min(max, min), max: Math.max(min, max) };
                }

            }
            case "showByPercentage": {
                // zmienne lokalne oraz funkcje potrzebne dla tego switcha
                let theIndex;
                const getClosestIndex = (_goal) => {
                    let middleIndex = Math.floor(data.length / 2), diff = null;
                    for (let i = 0; i < data.length; i++) {
                        const value = getDataByIndex(i);
                        if (isFiniteNumber(value)) {
                            const currentDiff = Math.abs(value - _goal);
                            if (diff === null || currentDiff < diff) {
                                diff = currentDiff;
                                middleIndex = i;
                                if (diff === 0) break; // direct hit
                            }
                        }
                    }
                    return middleIndex;
                };
                //
                switch (params.middlePoint) {
                    case "avg": {
                        let counter = 0, sum = 0;
                        for (let i = 0; i < data.length; i++) {
                            const value = getDataByIndex(i);
                            if (isFiniteNumber(value)) {
                                counter++;
                                sum += value;
                            }
                        }
                        const average = sum / counter;
                        theIndex = getClosestIndex(average);
                        break;
                    }
                    case "min":
                    case "max": {
                        let currentValue = 0;
                        for (let i = 0; i < data.length; i++) {
                            const value = getDataByIndex(i);
                            if (isFiniteNumber(value) && Math[params.middlePoint](value, currentValue) === value) {
                                currentValue = value;
                            }
                        }
                        theIndex = getClosestIndex(currentValue);
                        break;
                    }
                    default: {
                        theIndex = 0;
                        break;
                    }
                }
                const [min, max] = getIndexRange(theIndex);
                return { min: Math.min(max, min), max: Math.max(min, max) };
            }
            case "showAll":
            default: {
                return { min: 0, max: data.length - 1 };
            }
        }
    }

    onCheckboxChange = value => {
        this.setState({
            blockBrush: value
        });
    };

    getMaxData(data) {
        const { dataDef } = this.props;
        let maxValue = 0;
        for (let d of data) {
            for (let def of dataDef) {
                const value = isArray(d[def.dataKey]) ? Math.max(...d[def.dataKey]) : d[def.dataKey];
                if (value > maxValue) {
                    maxValue = value;
                }
            }
        }
        return maxValue;
    }

    getDefaultDomain = memoizeOne((data, changedDomain, isSecond = false) => {
        if (changedDomain) return this.state.domain;
        const { dataDef, showDomainInput } = this.props;
        if (showDomainInput && !isSecond) {
            let maxValue = 0;
            let valuesBelow0 = false;
            for (let d of data) {
                for (let def of dataDef) {
                    const value = isArray(d[def.dataKey]) ? Math.max(...d[def.dataKey]) : d[def.dataKey];
                    if (value > maxValue) {
                        maxValue = value;
                    }
                    if (value < 0) {
                        valuesBelow0 = true;
                    }
                }
            }
            console.log(maxValue);
            maxValue = Math.ceil(maxValue);
            return [valuesBelow0 ? "dataMin - 1" : 0, maxValue + 1];
        }
        // lepsze liczenie domain dla duzych liczb (~5% od wysokosci grida)
        const step = (dataSize, sign = 1) => {
            return !isNumber(dataSize) || !isFinite(dataSize) ? dataSize : dataSize + (sign * Math.max(1, Math.abs(dataSize * .05)));
        };
        for (let d of data) {
            for (let def of dataDef) {
                if (d[def.dataKey] < 0) {
                    return [dataMin => step(dataMin, -1), dataMax => step(dataMax)];
                }
            }
        }
        return [0, dataMax => step(dataMax)];
    });

    onBrushChange = value => {
        this.setState({
            min: value.startIndex,
            max: value.endIndex
        });
    };

    onDomainChange = value => {
        let domain = this.state.domain.slice(0);
        domain[1] = value ? +value : "dataMax + 1";
        this.setState({
            domain,
            changedDomain: true
        });
    };

    shouldHide = (line) => {
        if (!line.yAxisId) return false;
        const { hide } = this.state;
        const { dataDef } = this.props;
        const dataCount = dataDef.filter(o => o.yAxisId === line.yAxisId).length;
        const hideCount = hide.map(number => dataDef[number]).filter(o => !!o && line.yAxisId === o.yAxisId).length;
        return dataCount === hideCount;
    };
    labelFormatter = (value) => {
        const { showTooltipLabel, tooltipLabelFormatter } = this.props;
        if (showTooltipLabel) return isFunction(tooltipLabelFormatter) ? tooltipLabelFormatter(value) : value;
        return "";
    };
    onSelectStart = (obj) => {
        this.selected = [obj].filter((o) => !!o);
        this.timestamp = Date.now();
    };

    onSelectMove = (obj) => {
        if (this.raf) return;
        if (this.selected.length === 1) {
            console.log("move");
            if (this.selectionRef.current) {
                this.setSelectionRect(Math.min(obj.chartX, this.selected[0].chartX), this.rechartsRef.current.state.offset.top, Math.abs(obj.chartX - this.selected[0].chartX), this.rechartsRef.current.state.offset.height);
                this.raf = requestAnimationFrame(() => {
                    this.raf = null;
                });
            }
        }
    };

    setSelectionRect = (x = 0, y = 0, width = 0, height = 0) => {
        if (this.selectionRef.current) {
            this.selectionRef.current.setAttribute("x", x);
            this.selectionRef.current.setAttribute("y", y);
            this.selectionRef.current.setAttribute("width", width);
            this.selectionRef.current.setAttribute("height", height);
        }
    };

    onSelectEnd = (obj) => {
        if (!obj) return;
        const { onSelectionChange } = this.props;
        const diff = Date.now() - this.timestamp;
        if (diff > 25) {
            if (this.selected.length && (obj.chartX !== this.selected[0].chartX)) {
                this.selected[1] = obj;
                this.selected.sort((o1, o2) => o1.chartX - o2.chartX);
                const cloned = cloneFast(this.selected);
                if (isFunction(onSelectionChange)) {
                    onSelectionChange(cloned);
                }
            }
        }
        this.setSelectionRect();
        this.selected = [];

    };

    onFullscreenClick = () => {
        const { fullscreen } = this.state;
        if (fullscreen) {
            bodyUnlockScroll(ScrollLock.CHART_FULL_SCREEN);
        } else {
            bodyLockScroll(ScrollLock.CHART_FULL_SCREEN);
        }
        this.setState({
            fullscreen: !fullscreen
        });
    };

    render() {
        const {
            hasShadows,
            saveAsExcell,
            className,
            dataDef,
            width,
            height,
            Yaxis,
            thirdYaxis,
            Xaxis,
            showLegend,
            showTooltip,
            tooltipFormatter,
            secondYaxis,
            type,
            brush,
            referenceLines,
            referenceAreas,
            t,
            showDomainInput,
            onChartClick,
            tooltipContent,
            isAnimationActive,
            children,
            onSelectionChange,
            cartesianGrid,
            addDefaultDate,
            onMouseMove,
            syncId,
            syncMethod,
            noDataFoundType,
            mobileCustomization,
            enableFullscreen,
            overloadFontSizeRatio,
            settingsStrokeWidth,
            settingsReferenceLineSize,
            referenceLinePosition,
            overloadReferenceLinePosition,
            scatterShape
        } = this.props;
        const {
            data,
            min,
            max,
            blockBrush,
            brushWidth,
            domain,
            changedDomain,
            mobile,
            fullscreen,
            renderedContainer
        } = this.state;
        const selectionProps = isFunction(onSelectionChange) && !mobile ? {
            onMouseDown: this.onSelectStart,
            onMouseUp: this.onSelectEnd,
            onMouseMove: this.onSelectMove
        } : {};
        return (
            <div style={{ width: width, height: height }} ref={this.container}>
                {
                    !data.length &&
                    <NotFound type={noDataFoundType || "noDataFound"} />
                }
                {
                    !!data.length && renderedContainer && ReactDOM.createPortal(
                        <div className={`fetura-chart${fullscreen ? " fullscreen" : ""}`} ref={this.chartContainer}>
                            <ResponsiveContainer width={"100%"} height={"100%"}
                                className={`fetura-chart-container ${className} ${children ? "has-children" : ""}`}>
                                {
                                    React.createElement(this.getChartType(),
                                        {
                                            ref: this.rechartsRef,
                                            data,
                                            onClick: onChartClick,
                                            onTouchMove: onMouseMove,
                                            ...selectionProps,
                                            syncId,
                                            syncMethod
                                        }, [
                                        <XAxis
                                            dataKey={Xaxis.dataKey}
                                            tickFormatter={Xaxis.formatter || tickPrecisionFormatter}
                                            label={{
                                                value: Xaxis.name,
                                                position: Xaxis.position || "bottom",
                                                offset: Xaxis.offset || 5
                                            }}
                                            ticks={Xaxis.ticks}
                                            domain={Xaxis.domain}
                                            type={Xaxis.type}
                                            hide={Xaxis.hide}
                                            key={1}
                                        />,
                                        <YAxis domain={Yaxis.domain || domain}
                                            tickFormatter={Yaxis.formatter || tickPrecisionFormatter}
                                            label={{
                                                value: Yaxis.name,
                                                angle: -90,
                                                position: Yaxis.position || "insideLeft",
                                                offset: Yaxis.offset || 5,
                                                style: { textAnchor: "middle" }
                                            }}
                                            yAxisId={Yaxis.yAxisId || "left"} allowDataOverflow={true}
                                            ticks={Yaxis.ticks}
                                            type={Yaxis.type}
                                            width={Yaxis.width}
                                            key={2}
                                            {...mobile && { ...this.mobileDefaults[mobileCustomization] }}
                                        />,
                                        secondYaxis &&
                                        <YAxis hide={secondYaxis.hide}
                                            domain={secondYaxis.domain || this.getDefaultDomain(data, changedDomain, true)}
                                            tickFormatter={secondYaxis.formatter || tickPrecisionFormatter}
                                            label={{
                                                value: secondYaxis.name,
                                                angle: 90,
                                                position: secondYaxis.position || "insideRight",
                                                offset: secondYaxis.offset || 5,
                                                style: { textAnchor: "middle" }
                                            }}
                                            orientation="right"
                                            yAxisId={secondYaxis.yAxisId || "right"}
                                            ticks={secondYaxis.ticks}
                                            type={secondYaxis.type}
                                            tick={secondYaxis.tick}
                                            width={secondYaxis.width}
                                            key={3}
                                            {...mobile && { ...this.mobileDefaults[mobileCustomization] }}
                                        />,
                                        thirdYaxis &&
                                        <YAxis hide={true}
                                            domain={thirdYaxis.domain || this.getDefaultDomain(data, changedDomain, true)}
                                            tickFormatter={thirdYaxis.formatter || tickPrecisionFormatter}
                                            label={{
                                                value: thirdYaxis.name,
                                                angle: 90,
                                                position: thirdYaxis.position || "insideRight",
                                                offset: thirdYaxis.offset || 5,
                                                style: { textAnchor: "middle" }
                                            }}
                                            orientation="right"
                                            yAxisId={thirdYaxis.yAxisId || "right"}
                                            ticks={thirdYaxis.ticks}
                                            width={thirdYaxis.width}
                                            type={thirdYaxis.type}
                                            key={4}
                                        />,
                                        showLegend &&
                                        <Legend content={CustomLegend} verticalAlign="top"
                                            key={5}
                                            height={36} mobile={mobile} onClick={this.onLegendItemClicked} />,
                                        showTooltip &&
                                        <Tooltip isAnimationActive={false} formatter={tooltipFormatter}
                                            labelFormatter={this.labelFormatter} offset={90}
                                            key={6} cursor={{ strokeWidth: 4 }}
                                            content={tooltipContent} />,
                                        ...dataDef.map((def, index) =>
                                            React.createElement(this.getDataType(def.chartType),
                                                {
                                                    key: `def_${index}`,
                                                    isAnimationActive,
                                                    index: index,
                                                    type: def.type || "monotone",
                                                    name: def.name || "",
                                                    dot: isNil(def.dot) ? false : def.dot,
                                                    fill: isFunction(def.color) ? def.color : getColorByName(this.isHidden(index) ? "secondary" : def.color),
                                                    stroke: isFunction(def.color) ? def.color : getColorByName(this.isHidden(index) ? "secondary" : def.color),
                                                    strokeDasharray: hasShadows && `${def.dataKey}`.endsWith("Shadow") ? "5 5" : def.strokeDasharray,
                                                    strokeWidth: isNil(def.strokeWidth) ? settingsStrokeWidth : def.strokeWidth,
                                                    fillOpacity: def.opacity || +(type === "Bar") || +(def.chartType === "Bar"),
                                                    dataKey: this.isHidden(index) ? `${def.dataKey} ` : def.dataKey,
                                                    hide: this.isHidden(index),
                                                    connectNulls: def.connectNulls !== false,
                                                    strokeOpacity: isNil(def.strokeOpacity) ? 1 : def.strokeOpacity,
                                                    unit: def.unit,
                                                    yAxisId: def.yAxisId || "left",
                                                    minPointSize: def.minPointSize,
                                                    stackId: def.stack,
                                                    maxBarSize: 20,
                                                    component: def.component,
                                                    formatter: def.formatter,
                                                    shape: def.shape || scatterShape
                                                },
                                                def.custom ? def.custom(this.state) : null
                                            )
                                        ),
                                        referenceLines.map((line, idx) =>
                                            this.shouldHide(line) || (!isNil(line.hideIndex) && this.isHidden(line.hideIndex)) ? null :
                                                <ReferenceLine key={`ref_${idx}`} x={line.x} y={line.y}
                                                    yAxisId={line.yAxisId || "left"}
                                                    strokeOpacity={line.opacity}
                                                    strokeDasharray={line.strokeDasharray}
                                                    ifOverflow={line.isOverflow || "discard"}
                                                    label={(props) => <Label {...props}
                                                        fontSizeRatio={overloadFontSizeRatio || settingsReferenceLineSize}
                                                        color={getColorByName(line.color)}
                                                        index={idx} label={line.name}
                                                        textPosition={overloadReferenceLinePosition || referenceLinePosition}
                                                        container={this.chartContainer} />}
                                                    stroke={getColorByName(line.color)} isFront />
                                        ),
                                        referenceAreas.map((area, idx) =>
                                            this.shouldHide(area) || (!isNil(area.hideIndex) && this.isHidden(area.hideIndex)) ? null :
                                                <ReferenceArea key={`refarea_${idx}`} x1={area.x1} x2={area.x2}
                                                    y1={area.y1}
                                                    label={area.label}
                                                    yAxisId={area.yAxisId || "left"}
                                                    fill={isNil(area.color) ? undefined : getColorByName(area.color)}
                                                    fillOpacity={isNil(area.opacity) ? 0.1 : area.opacity}
                                                    y2={area.y2}
                                                    stroke={isNil(area.color) ? "none" : getColorByName(area.color)}
                                                    strokeOpacity={0} />),
                                        isFunction(onSelectionChange) ?
                                            <rect className={"rechart-selection"} ref={this.selectionRef}
                                                fill={getColorByName("blue")} fillOpacity={0.2}
                                                strokeDasharray={"5 5"}
                                                strokeWidth={2}
                                                key={7}
                                                stroke={getColorByName("blue")} /> : null,
                                        brush &&
                                        <Brush startIndex={min}
                                            endIndex={max}
                                            stroke={getColorByName("green")}
                                            dataKey={Xaxis.dataKey}
                                            width={brushWidth}
                                            onChange={this.onBrushChange}
                                            tickFormatter={brush.tickFormatter}
                                            key={8}
                                        />,
                                        cartesianGrid.map((grid, key) => (
                                            <CartesianGrid key={`grid-${key}`} horizontal={!!grid.horizontal}
                                                vertical={!!grid.vertical}
                                                verticalPoints={grid.verticalPoints}
                                                horizontalPoints={grid.horizontalPoints} />))

                                    ]
                                    )
                                }
                            </ResponsiveContainer>
                            {children}
                            {
                                !!saveAsExcell &&
                                <SaveAsExcell addDefaultDate={addDefaultDate} Xaxis={Xaxis} data={data} dataDef={dataDef}
                                    fileName={saveAsExcell} />
                            }
                            {
                                enableFullscreen &&
                                <Button icon={<i className={`fa-solid ${fullscreen ? "fa-close" : "fa-maximize"}`} />}
                                    type="button" buttonStyle="text" onClick={this.onFullscreenClick}
                                    className="fetura-chart-maximize" />
                            }
                            {
                                brush && brush.blockBrush &&
                                <Checkbox label={t("basics.chart.block")} divRef={this.checkbox} checked={blockBrush}
                                    onChange={this.onCheckboxChange} />
                            }
                            {
                                !mobile && showDomainInput &&
                                <div className="fetura-chart-domain-input">
                                    <Input type={"number"} value={domain[1]} onChange={this.onDomainChange} />
                                    <Fade in={this.getMaxData(data) > domain[1]} unmountOnExit mountOnEnter>
                                        <div>
                                            <BasicTooltip tooltipContent={t("basics.chart.notShowingAll")} type={"warning"}>
                                                <i className="fas fa-exclamation-circle" />
                                            </BasicTooltip>
                                        </div>
                                    </Fade>
                                </div>
                            }
                        </div>,
                        fullscreen ? document.getElementById("root") : this.container.current
                    )
                }
            </div>
        );
    }

}

const colorPropCheck = (props, propName, componentName) => {
    let value = props[propName] || "";
    let arrayOfColors = ["green", "orange", "red", "pink", "blue"];
    if (!arrayOfColors.includes(value) && !value.startsWith("#")) {
        return new Error(`Invalid prop "${propName}" supplied to "${componentName}". Should have value one of ["green", "orange", "red", "pink", "blue"] or starts with #, current value ${value}`);
    }
};

Chart.propTypes = {
    dataDef: PropTypes.arrayOf(PropTypes.shape({
        // color: PropTypes.oneOf(["green", "orange", "red", "pink", "blue"]).isRequired,
        color: colorPropCheck,
        dataKey: PropTypes.string.isRequired,
        name: PropTypes.string,
        type: PropTypes.string,
        valueConverter: PropTypes.func,
        unit: PropTypes.string,
        defaultOff: PropTypes.bool,
        chartType: PropTypes.oneOf(["Area", "Bar", "Scatter", "Line", "ErrorBar", "Customized"]), //tylko dla composed chartow
        stack: PropTypes.string
    })).isRequired,
    Xaxis: PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        formatter: PropTypes.func,
        ticks: PropTypes.array,
        domain: PropTypes.array,
        type: PropTypes.string,
        hide: PropTypes.bool
    }).isRequired,
    Yaxis: PropTypes.shape({
        name: PropTypes.string,
        formatter: PropTypes.func,
        domain: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        yAxisId: PropTypes.string,
        ticks: PropTypes.array,
        width: PropTypes.number,
        type: PropTypes.string
    }).isRequired,
    type: PropTypes.oneOf(["Area", "Bar", "Composed", "Scatter", "ErrorBar", "Customized"]),
    className: PropTypes.string,
    syncId: PropTypes.string,
    syncMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    width: PropTypes.string,
    height: PropTypes.string,
    data: PropTypes.array.isRequired,
    showLegend: PropTypes.bool,
    showTooltip: PropTypes.bool,
    tooltipFormatter: PropTypes.func,
    saveAsExcell: PropTypes.string,
    secondYaxis: PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatter: PropTypes.func,
        domain: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        yAxisId: PropTypes.string,
        ticks: PropTypes.array,
        tick: PropTypes.bool
    }),
    thirdYaxis: PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatter: PropTypes.func,
        domain: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        yAxisId: PropTypes.string,
        ticks: PropTypes.array,
    }),
    brush: PropTypes.shape({
        brushKey: PropTypes.string.isRequired,
        blockBrush: PropTypes.bool,
        tickFormatter: PropTypes.func,
        type: PropTypes.oneOf(["showAll", "showOnDifferent", "showByPercentage", "showAroundPoint"]),
        args: PropTypes.shape({ // argumenty zalezne od type
            percent: PropTypes.number, // [showAroundPoint, showByPercentage] przyblizenia danych
            point: PropTypes.number, // [showAroundPoint] punkt przyblizenia
            relativePercent: PropTypes.bool, // [showAroundPoint] czy przyblizenie jest stale czy zmienne od wartosci punktu
            middlePoint: PropTypes.oneOf(["avg", "min", "max"]), // [showByPercentage] na czym ma sie skupic przyblizenie
            dataWithValueInRow: PropTypes.number, // [showOnDifferent] ile wartosci musi byc pod rzad aby wyswietlac wykres
        })
    }),
    referenceLines: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: colorPropCheck,
        name: PropTypes.string.isRequired,
        yAxisId: PropTypes.string,
        position: PropTypes.string
    })),
    referenceAreas: PropTypes.arrayOf(PropTypes.shape({
        x1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        x2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: colorPropCheck,
        yAxisId: PropTypes.string
    })),
    hasShadows: PropTypes.bool,
    showDomainInput: PropTypes.bool, // czy ma pokazac inputa na osi Y
    tooltipLabelFormatter: PropTypes.func,
    onLegendClick: PropTypes.func,
    onChartClick: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onMouseMove: PropTypes.func,
    refreshHideOnNewChartDef: PropTypes.bool,
    tooltipContent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    showTooltipLabel: PropTypes.bool,
    isAnimationActive: PropTypes.bool,
    brushShowWholeDataset: PropTypes.bool,
    addDefaultDate: PropTypes.bool,
    noDataFoundType: PropTypes.string,
    cartesianGrid: PropTypes.arrayOf(PropTypes.shape({
        horizontal: PropTypes.bool,
        vertical: PropTypes.bool,
        horizontalPoints: PropTypes.array,
        verticalPoints: PropTypes.array
    })),
    mobileCustomization: PropTypes.oneOf(["hide", "reverse", "none"]),
    enableFullscreen: PropTypes.bool
};

Chart.defaultProps = {
    className: "",
    refreshHideOnNewChartDef: false,
    showTooltipLabel: true,
    data: [],
    dataDef: [],
    type: "Area",
    width: "100%",
    height: "100%",
    showLegend: true,
    showTooltip: true,
    saveAsExcell: "",
    referenceLines: [],
    referenceAreas: [],
    isAnimationActive: true,
    onLegendClick: () => {
    },
    brushShowWholeDataset: false,
    cartesianGrid: [],
    addDefaultDate: false,
    mobileCustomization: "mirror",
    enableFullscreen: true
};

export default compose(
    withTranslation(),
    connect(makeMapStateToProps)
)(Chart);