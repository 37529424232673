import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import Backends from "i18next-chained-backend";
import config from "./conf/config";
import LanguageDetector from "i18next-browser-languagedetector";
import { i18nOptions } from "./utils/I18nUtils";

const Backend = new HttpBackend();

const constructBackends = () => {
    return {
        backends: [Backend],
        backendOptions: [
///////////////////////////
/////////////
/////////////////////////////////////////////////////////
//////////////
/////////////////////
            {
                loadPath: `${config.apiGateway.URL}translations/locale?language={{lng}}&namespace={{ns}}`, // xhr load path for my own fallback
            },
//////////////////////
        ],
    };
};

console.log(initReactI18next);

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backends)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // lng: 'cimode', // overrides detected
        backend: constructBackends(),
        ...i18nOptions,
    });
// console.log("translationsdetect", process.env.NODE_ENV);
if (process.env.NODE_ENV === "test") {
    i18n.changeLanguage("pl");
} else {
    i18n.use(LanguageDetector);
}
// console.log("i18n", process.env)

export default i18n;
