import { Form, Formik, useField } from 'formik';
import { invert } from 'lodash';
import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setMapSQL } from '../../../../actions/animalParametersActions';
import { mapSQL } from '../../../../constans/sqlSelects';
import useQueryCaller from '../../../../hooks/useQueryCaller';
import { getAnimalParametersLoading, getMapSQL } from '../../../../selectors/animalParametersSelector';
import { getAnimalCount } from '../../../../selectors/farmMapSelector';
import Button from '../../../basics/button/Button';
import Input from '../../../basics/input/Input';
import SQLInput from "../../../basics/input/sql-input/SQLInput";
import Select from "../../../basics/select/Select";
import "./_filter-animal-control.scss";

function FilterAnimalControlSelect({ name, options }) {

    const { t } = useTranslation();

    const [input, , handlers] = useField(name);

    const onChange = useCallback(value => {
        handlers.setValue(value);
    }, [handlers]);

    return (
        <Select value={input.value} onChange={onChange} options={options} placeholder={t("chooseValue")} />
    );
}

function FilterAnimalControlInput({ name, placeholder }) {
    const [input, meta] = useField(name);

    return (
        <Input {...input} {...meta} onChangeWithEvent placeholder={placeholder} />
    );
}

const FilterAnimalControlSQL = forwardRef(({ name }, ref) => {

    const [input, , handlers] = useField(name);

    const onChange = useCallback(value => {
        handlers.setValue(value);
    }, [handlers]);

    return (
        <SQLInput onChange={onChange} value={input.value} ref={ref} placeholder="SQL" />
    );
});

function FilterAnimalControlLegend({ items }) {
    return (
        <div className="filter-animal-control-legend">
            {
                items.map((row, index) => (
                    <div key={index}>
                        <div className={`filter-animal-control-legend-color ${row.color}`} />
                        <span>- {row.text}</span>
                    </div>
                ))
            }
        </div>
    );
}

function FilterAnimalControlRepetitionLegend() {

    const { t } = useTranslation();

    const items = useMemo(() => [
        { text: t("repetitions", { count: 2 }), color: "warning" },
        { text: t("moreThanRepetitions", { count: 2 }), color: "error" },
    ], [t]);

    return <FilterAnimalControlLegend items={items} />;
};

function FilterAnimalControlParturitionLegend() {

    const { t } = useTranslation();

    const items = useMemo(() => [
        { text: t("parturitioned"), color: "info" },
        { text: t("beforeParturition"), color: "success" },
        { text: t("afterExpectedParturitionDate"), color: "error" },
    ], [t]);

    return <FilterAnimalControlLegend items={items} />;
}


export default function FilterAnimalControl() {

    const { t } = useTranslation();

    const animalCount = useSelector(getAnimalCount);

    const dispatch = useDispatch();

    const queryCaller = useQueryCaller();

    const sql = useSelector(getMapSQL);
    const isLoading = useSelector(getAnimalParametersLoading);

    const sqlInputRef = useRef();

    const onSubmit = useCallback(async (values) => {
        const invertedSQL = invert(mapSQL);
        const sql = values.sql === "custom" ? values.customValue : values.sql;
        const params = values.sql === "custom" ? [] : values[`${invertedSQL[values.sql]}_Value`] ? [+values[`${invertedSQL[values.sql]}_Value`]] : [];
        const result = await queryCaller(sql, params);
        dispatch(setMapSQL(sql, result, ...params));
        if (values.sql === "custom") {
            sqlInputRef.current.addSavedValue(values.customValue);
        }
    }, [dispatch, queryCaller]);

    const options = useMemo(() => {
        return [
            { name: t("idleSows"), value: mapSQL.SOW_IDLE_DAYS },
            { name: t("sowsAfterExpectedParturition"), value: mapSQL.SOW_AFTER_EXPECTED_FARROWING },
            { name: t("sowsBeforeParturition"), value: mapSQL.SOW_BEFORE_EXPECTED_FARROWING },
            { name: t("sowsWithRepetitions"), value: mapSQL.SOW_REPETITIONS },
            { name: t("sowsBeforeAfterAndDelayedParturition"), value: mapSQL.SOW_BEFORE_DELAYED_AFTER_PARTURITION },
            { name: t("transferDelay"), value: mapSQL.SOW_EXPECTED_TRANSFER },
            { name: t("sowsWithoutPigletOnBirthRoom"), value: mapSQL.SOW_WITHOUT_PIGLETS },
            { name: t("sowsWithBrokenCycle"), value: mapSQL.SOW_WRONG_CYCLE },
            { name: t("sowsWithPiglets"), value: mapSQL.SOW_WITH_PIGLETS },
            { name: t("userRolePicker.customAccess"), value: "custom" },
        ];
    }, [t]);

    return (
        <Formik onSubmit={onSubmit} initialValues={{ customValue: sql, sql: mapSQL.SOW_IDLE_DAYS }}>
            {
                props => (
                    <Form>
                        <Row className="filter-animal-control">
                            <Col lg={"auto"} xs={12} className="filter-animal-control-input">
                                <FilterAnimalControlSelect options={options} name="sql" />
                            </Col>
                            <Col lg={"auto"} xs={12} className="filter-animal-control-input">
                                {
                                    props.values.sql === mapSQL.SOW_IDLE_DAYS &&
                                    <FilterAnimalControlInput name={`SOW_IDLE_DAYS_Value`} placeholder={t("grid.idleDays")} />
                                }
                                {
                                    props.values.sql === mapSQL.SOW_AFTER_EXPECTED_FARROWING &&
                                    <FilterAnimalControlInput name={`SOW_AFTER_EXPECTED_FARROWING_Value`} placeholder={t("daysAfterExpectedParturition")} />
                                }
                                {
                                    props.values.sql === mapSQL.SOW_BEFORE_EXPECTED_FARROWING &&
                                    <FilterAnimalControlInput name={`SOW_BEFORE_EXPECTED_FARROWING_Value`} placeholder={t("daysBeforeParturition")} />
                                }
                                {
                                    props.values.sql === "custom" &&
                                    <FilterAnimalControlSQL ref={sqlInputRef} name="customValue" />
                                }
                                {
                                    props.values.sql === mapSQL.SOW_REPETITIONS &&
                                    <FilterAnimalControlRepetitionLegend />
                                }
                                {
                                    props.values.sql === mapSQL.SOW_BEFORE_DELAYED_AFTER_PARTURITION &&
                                    <FilterAnimalControlParturitionLegend />
                                }
                            </Col>
                            <Col lg={"auto"} xs={12} className="filter-animal-control-submit justify-content-center justify-content-lg-end text-end">
                                <Button buttonColor="success" disabled={isLoading} className="me-3" isLoading={props.isSubmitting}>{t("show")}</Button>
                                <i className="fa-solid fa-pig me-1" />
                                <span>{animalCount > 1000 ? "+999" : animalCount}</span>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
}
