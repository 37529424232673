import {isNil, isNumber, isString, startCase, toLower, get} from "lodash";

/**
 * Funkcja do wrzucania parametrów do stringów, wyszukuje ${numer_argumentu} i zamiania na argumenty[numer_argumentu}
 * Przydatne do tlumaczen np. stringBuildter("Mam {1} lat i na imie {2}", 12, "Paweł") zwroci nam Mam 12 lat i na imie Pawel
 * @param string
 * @returns string
 */
export function stringBuilder(string = "") {
    let result = `${string}`;
    for (let i = 1; i < arguments.length; i++) {
        let itemToBeReplaced = new RegExp(`\\{${i}\\}`, "g");
        result = result.replace(itemToBeReplaced, arguments[i]);
    }
    return result;
}

/**
 * Funkcja kopiująca przekazany tekst do schowka
 * @param text
 */
export function copyToClipboard(text) {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "dummy_id");
    document.getElementById("dummy_id").value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

/**
 * Funkcja sprawdzająca, czy podany tekst jest adresem email
 * @param string
 * @returns boolean
 */

export function validateEmail(string = "") {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(string);
}

export const indexToChar = (index = 0, result = "") => {
    const minCharCode = 65;
    const maxCharCode = 90;
    const len = maxCharCode - minCharCode + 1;
    const charIdx = index % len;
    const divider = Math.floor(index / len);
    result += String.fromCharCode(minCharCode + charIdx);
    if (divider) {
        return indexToChar(divider, result);
    }
    return result;
};

/**
 * Funkcja do sorotowania - localeCompare filho de puta pendecho!11
 * @param o1 {string|null|undefined|number}
 * @param o2 {string|null|undefined|number}
 * @param numeric - trakrować jako string czy jako numer
 * @param ascending
 * @param nonValuesAtEnd - czy nulle undefiny i inne smiecie spadaja na koniec
 */
export const enhancedComparer = (
    o1,
    o2,
    {numeric = false, ascending = true, nonValuesAtEnd = true} = {}
) => {
    if (isNil(o1) && isNil(o2)) return 0;
    if (isNil(o1)) return 1;
    if (isNil(o2)) return -1;
    if (isNil(o1) !== isNil(o2))
        return (nonValuesAtEnd ? isNil(o1) : isNil(o2)) ? 1 : -1;
    let val1 = `${ascending ? o1 : o2 || ""}`.trim();
    let val2 = `${ascending ? o2 : o1 || ""}`.trim();
    if (val1.startsWith("-") && val2.startsWith("-")) {
        val1 = val1.slice(1);
        val2 = val2.slice(1);
        return -val1.localeCompare(val2, undefined, {
            sensitivity: "base",
            numeric,
        });
    }
    return val1.localeCompare(val2, undefined, {
        sensitivity: "base",
        numeric,
    });
};

export const makeEnhancedComparer = (key, options) => {
    return (o1, o2) => enhancedComparer(key ? get(o1, key) : o1, key ? get(o2, key) : o2, options);
}

export const toPrettyHex = (number) => {
    return isNumber(number) ? number.toString(16).toUpperCase() : "0x?";
};

export const upperCase = (str = "") => startCase(toLower(str));

export const getTextAfterSign = (text, sign) => {
    const textArr = text.split(sign);
    if (textArr.length >= 2) return textArr[1];
    return text;
};

export const getTextBeforeSign = (text, sign) => {
    const textArr = text.split(sign);
    return textArr[0];
};

export function levenshteinDistance(str1 = "", str2 = "") {
    const track = Array(str2.length + 1)
        .fill(null)
        .map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator // substitution
            );
        }
    }
    return track[str2.length][str1.length];
}

export function dotify(longText = "", {maxLength = 9, dots = 3} = {}) {
    if (isString(longText)) {
        const diff = longText.length - maxLength;
        if (diff > 0) {
            const charLen = maxLength - dots;
            const [before, after] = [
                Math.round(charLen / 2),
                charLen - Math.round(charLen / 2),
            ];
            return `${longText.substr(0, before)}${new Array(dots)
                .fill(".")
                .join("")}${longText.substr(-after)}`;
        }
    }
    return longText;
}

export const upperFirstLetter = (text) => {
    return (
        text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1)
    );
};

export const lowerFirstLetter = (text) => {
    return text.charAt(0).toLowerCase() + text.slice(1);
};

export const removeSpaces = (text = "") => {
    return `${text}`.replace(/\s/g, "");
};

export const includesText = (text, filter) => {
    const normalizeText = (text) => removeSpaces(text).toLowerCase();
    return normalizeText(text).includes(normalizeText(filter));
};
export const textReturn = (text) => {
    return text ? text : "";
};

export const reverseString = (text) => text.split("").slice(0).reverse().join("");

const normalizeCase = {
    snakeCase: (s) => s.split("_").map((o) => o.toLowerCase())
};

const convertCase = {
    camelCase: (nArray) => nArray.map((o, i) => i ? upperFirstLetter(o) : o).join(""),
    pascalCase: (nArray) => nArray.map(upperFirstLetter).join(""),
};

const changeCase = (normalizer, converter, s) => converter(normalizer(s));

/**
 * Konwertuje ciąg znaków zapisany w formacie "snake_case" na "camelCase".
 *
 * @param {string} snakeCaseString - Ciąg znaków w formacie "snake_case" do konwersji.
 * @returns {string} - Ciąg znaków w formacie "camelCase".
 */
export const snakeCaseToCamelCase = (snakeCaseString) => changeCase(normalizeCase.snakeCase, convertCase.camelCase, snakeCaseString);

export const replaceLast = (source, delimiter, replacement) => {
    if (!source.includes(delimiter)) return source;
    let occurrences = source.split(delimiter);
    let lastOccurrence = occurrences.pop();
    return occurrences.join(delimiter) + replacement + lastOccurrence;
};

export const template = (template, values = {}) => {
    const regex = new RegExp(":[a-zA-Z0-9]+", "g");
    let result = template;
    [...template.matchAll(regex)].forEach(([key]) => {
        result = result.replaceAll(key, values[key.slice(1)] ?? key);
    });
    return result;
}

export const splitByIndex = (text, index) => {
    const part1 = text.split("");
    const part2 = part1.splice(index);
    return [part1.join(""), part2.join("")];
}