import React from "react";
import LabeledInput from "./LabeledInput";
import Proptypes from "prop-types";
import {debounce} from "lodash";

export default class ReduxLabeledInput extends React.PureComponent {

    onChange = (value) => {
        this.handleCallback(value, "onChange");
    };

    onBlur = (value) => {
        this.handleCallback(value, "onBlur");
    };

    handleCallback = (value, callbackType) => {
        const {debounce} = this.props;
        if (debounce) {
            this._debouncedCallback(value, callbackType);
        } else {
            this._callback(value, callbackType);
        }
    }

    _debouncedCallback = debounce((newValue, callbackType) => {
        this._callback(newValue, callbackType);
    }, this.props.debounce);

    _callback = (newValue, callbackType) => {
        const {input} = this.props;
        input[callbackType](newValue);
    }

    render() {
        const {
            label,
            type,
            disabled,
            id,
            input: {value},
            meta: {touched, error, warning},
            unit,
            required,
            optionalProps,
            className,
            min,
            autofocus,
            placeholder,
            max,
            showIconOnErrorOnWarning,
            dataList,
            autocomplete,
            infoBoxContent
        } = this.props;
        return (
            <LabeledInput infoBoxContent={infoBoxContent} dataList={dataList} className={className} required={required}
                          disabled={disabled} label={label} type={type}
                          onChange={this.onChange} id={id} value={value}
                          autocomplete={autocomplete}
                          error={touched && error ? error : ""} unit={unit} optionalProps={optionalProps}
                          placeholder={placeholder} warning={touched && warning ? warning : ""}
                          min={min} autofocus={autofocus} max={max} showIconOnErrorOnWarning={showIconOnErrorOnWarning}
                          onBlur={this.onBlur}/>
        )
    }

}

ReduxLabeledInput.propTypes = {
    label: Proptypes.string.isRequired,
    type: Proptypes.string.isRequired,
    unit: Proptypes.string,
    id: Proptypes.string,
    optionalProps: Proptypes.object,
    infoBoxContent: Proptypes.string,
    debounce: Proptypes.number,
};

ReduxLabeledInput.defaultProps = {
    optionalProps: {},
    infoBoxContent: "",
    debounce: 0
};
