import { isArray, isFinite } from "lodash";
import moment from "moment";
import { Milliseconds } from "../constans/milliseconds";
import i18next from "i18next";
import { EventTypes } from "../constans/eventTypes";

export const TIME_FORMAT = {
    DATE: "L",
    TIME: "HH:mm",
    DATE_TIME: "DD.MM.YY HH:mm",
    DATE_TIME_WITH_SECONDS: "DD.MM.YY HH:mm:ss",
    TIME_DATE: "HH:mm DD.MM.YY",
    DATE_FILENAME: "DD-MM-YYYY"
};

export function checkIfTimeRangeOverlaps(start1, end1, start2, end2, { isSameTimeOk = true } = {}) {
    if (!isFinite(start1) || !isFinite(end1) || !isFinite(start2) || !isFinite(end2)) {
        throw new Error("checkIfTimeRangeOverlap ArgumentException expected timestamps");
    }
    return isSameTimeOk ? Math.max(start1, start2) < Math.min(end1, end2) : Math.max(start1, start2) <= Math.min(end1, end2);
}

/**
 * Funkcja konwertuje zadane godziny i minuty na timestamp'a z zerową datą
 * @param timeString (HH:mm)
 * @returns {number|null}
 */
export function convertHoursAndMinutesToTimestamp(timeString) {
    if (!timeString) return null;
    const timeArr = timeString.split(":");
    if (!timeArr[0] || !timeArr[1]) return null;
    return +moment(0).hours(+timeArr[0]).minutes(+timeArr[1]);
}

/**
 * Funkcja sprawdza czy dany dzień jest pomiędzy innymi dniami, jesli ten sam, obsłużono parametr inclusivity
 * @param startTimestamp
 * @param endTimestamp
 * @param timestamp
 * @param inclusivity
 * @returns {*}
 */
export function isDayInRange(startTimestamp, endTimestamp, timestamp, { inclusivity = "[]" } = {}) {
    const start = moment(startTimestamp).startOf("day");
    const end = moment(endTimestamp).startOf("day");
    const time = moment(timestamp).startOf("day");

    if (!["()", "[]", "(]", "[)"].includes(inclusivity)) {
        throw new Error("Inclusivity parameter must be one of (), [], (], [)");
    }
    return time.isBetween(start, end, undefined, inclusivity);
}

/**
 * returns a readable timestamp for a human being
 * @param timestamp {number}
 * @param omitEmptyValues {boolean} omits empty values i.e: `5 hours 0 minutes 32 seconds` will become `5 hours 32 seconds` if `omitEmptyValues` is set
 * @param omitKeys {array<string>} omits specific keys, i.e: you want to show a long period of time and do not care about minutes/seconds just pass ["second", "minute"]
 * @return {string}
 */
export function timeFormatter(timestamp, { omitEmptyValues = true, omitKeys } = {}) {
    const
        days = Math.floor(timestamp / Milliseconds.DAY),
        hours = Math.floor(timestamp / Milliseconds.HOUR) % 24,
        seconds = Math.floor(timestamp / Milliseconds.SECOND) % 60,
        minutes = Math.floor(timestamp / Milliseconds.MINUTE) % 60,
        milliseconds = Math.floor(timestamp) % 1000;
    const time = [days, hours, minutes, seconds];
    const keys = ["day", "hour", "minute", "second"];
    if (isArray(omitKeys)) {
        for (const key of omitKeys) {
            const index = keys.indexOf(key);
            if (index !== -1) {
                keys.splice(index, 1);
                time.splice(index, 1);
            }
        }
    }
    let addKey = false;
    const result = [];
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let value = time[i];
        const isLastKey = i === (keys.length - 1);
        // start appending with a first non-zero value or with the last key
        if (value !== 0 || isLastKey) addKey = true;
        // omit non values if already has something as a result
        if (omitEmptyValues && result.length && value === 0) addKey = false;
        // add milliseconds if applicable
        if (key === "second" && (milliseconds !== 0)) {
            addKey = true;
            value = parseFloat(`${value}.${(milliseconds + "").padStart(3, "0")}`);
        }
        if (!addKey) continue;
        result.push({
            key: `timeUtils.${key}Count`,
            params: { count: value }
        });
    }
    return result.map(({ key, params }) => i18next.t(key, params)).join(" ");
}

/**
 * Funkcja zwraca ilość minut od początku dnia
 * @param timestamp {number}
 * @returns {number}
 */
export function getMinutesOfDay(timestamp) {
    return moment(timestamp).hours() * 60 + moment(timestamp).minutes();
}


export function localTimeFormatter(timestamp) {
    return moment(timestamp).format(TIME_FORMAT.TIME);
}

export function localDateTimeFormatter(timestamp) {
    return moment(timestamp).format(TIME_FORMAT.DATE_TIME);
}

export function localTimeDateFormatter(timestamp) {
    return moment(timestamp).format(TIME_FORMAT.TIME_DATE);
}

export function localDateFormatter(timestamp) {
    return moment(timestamp).format(TIME_FORMAT.DATE);
}

export function utcDateTimeFormatter(timestamp) {
    return moment.utc(timestamp).format(TIME_FORMAT.DATE_TIME);
}

export function utcDateFormatter(timestamp) {
    return moment.utc(timestamp).format(TIME_FORMAT.DATE);
}

export function localMomentToUTC(_momentObj, { format = moment.HTML5_FMT.DATE } = {}) {
    const mom = _momentObj instanceof moment ? _momentObj : moment(_momentObj);
    return moment.utc(mom.format(format), format);
}

export function utcMomentToLocal(_momentObj, { format = moment.HTML5_FMT.DATE } = {}) {
    const mom = _momentObj instanceof moment ? _momentObj : moment.utc(_momentObj);
    return moment(mom.format(format), format);
}

export function getWeekDayName(day) {
    switch (day) {
        case 0:
            return i18next.t("newSettings.notifications.general.monday");
        case 1:
            return i18next.t("newSettings.notifications.general.tuesday");
        case 2:
            return i18next.t("newSettings.notifications.general.wednesday");
        case 3:
            return i18next.t("newSettings.notifications.general.thursday");
        case 4:
            return i18next.t("newSettings.notifications.general.friday");
        case 5:
            return i18next.t("newSettings.notifications.general.saturday");
        case 6:
            return i18next.t("newSettings.notifications.general.sunday");
        default:
            return "";
    }
}

export const convertMsToHourAndMinutesString = (milliseconds) => {
    let hours = Math.floor(milliseconds / Milliseconds.HOUR);
    let minutes = Math.floor(milliseconds % Milliseconds.HOUR / (1000 * 60));
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};

export const transformEventDate = (timestamp, eventType) => {
    const dateAsMoment = moment(timestamp);
    const currentDay = moment();
    switch (eventType) {
        case EventTypes.SELL:
        case EventTypes.FALL:
            return dateAsMoment.isSame(currentDay, "day") ?
                +moment(`${dateAsMoment.format("L")} ${currentDay.format("HH:mm")}`, "L HH:mm")
                :
                +dateAsMoment.clone().endOf("day");
        default:
            return +dateAsMoment;
    }
};
