
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";


export function changeMapSettingsSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.map.settings.title"),
        message: i18n.t("apiNotifications.map.settings.changeSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function changeMapSettingsFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.map.settings.title"),
        message: i18n.t("apiNotifications.map.settings.changeFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
