import moment from "moment";
import {get} from "lodash";
import animalsDB from "../database/animalsDB";
import {EventTypes} from "../constans/eventTypes";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import memoizeOne from "memoize-one";

export const getGroupBasicInfoBoxDataDB = (group, _animals = []) => {
    let animalsSize = null;
    let herdNumber = "";
    if (group) {
        const {AnmIDs} = group;
        const animals = [];
        if (animals.length === 0) {
            for (let anmID of AnmIDs) {
                animals.push(
                    animalsDB.getAnimalById(anmID, {joinEvents: false})
                );
            }
        } else {
            animals.push(..._animals);
        }
        animalsSize = animals.reduce((a, b) => a + get(b, "AnmCnt", 0), 0);
        herdNumber = animals.find((anm) => anm?.HerdNumber)?.HerdNumber || "";
    }
    return {
        animalsSize,
        herdNumber,
    };
};

export const getFirstInsertion = (insertions) => {
    //get insertion date when animals are removed
    let dates = Object.keys(insertions).map((key) =>
        parseInt(key.split("_")[1])
    );
    if (!dates.length) return null;
    const min = Math.min(...dates);
    const max = Math.max(...dates);
    if (min !== max) return [min, max];
    return [min];
};

export const getGroupBasicInfoBoxData = ({
                                             group,
                                             animals = [],
                                             clients = [],
                                             locationDays: {detailedData = []} = {},
                                             insertions = {}
                                         } = {}) => {
    const formatTime = (ts) => moment(ts).format("DD.MM.YY");
    const firstAbsInsertion = memoizeOne(getFirstInsertion);
    let animalSize = null, insertedCounter = null, soldCounter = null, fallCounter = null, insertionCounter = null,
        toGroupTransferCounter = null, fromGroupTransferCounter = null, removedCounter = null, hasIndividualAnimal = false,
        insertionRange = [], birthRange = [], sellRange = [], deathRange = [], suppliers = [];
    const supplierFormatter = (supplier) => {
        const found = clients.find(({ID}) => ID === supplier)?.Value;
        return found ? found : '-'
    };
    if (group) {
        animalSize = 0;
        insertedCounter = 0;
        soldCounter = 0;
        fallCounter = 0;
        insertionCounter = 0;
        toGroupTransferCounter = 0;
        fromGroupTransferCounter = 0;
        removedCounter = 0;
        const {AnmIDs} = group;
        const isRemoved = (animal) => animal?.DtaDelTime
        const inGroup = ({AnmID}) => AnmIDs.includes(AnmID);
        for (let animal of animals) {
            const cnt = get(animal, "AnmCnt", 0);
            animalSize += (inGroup(animal) && !isRemoved(animal)) ? cnt : 0;
            if ((animal.AnmCnt === 1 && (![AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(animal.AnimalKind) || animal.RFID || animal.Tagged)) && !hasIndividualAnimal) {
                hasIndividualAnimal = true;
            }
            if (animal.SupplierID && !suppliers.includes(animal.SupplierID)) {
                suppliers.push(animal.SupplierID);
            }
            // bierzemy zywe i martwe - omijamy usuniete

            if (animal.DtaBrthTime) {
                birthRange.push(animal.DtaBrthTime);
            }
            if (!animal.DtaDelTime) {
                if (animal.DtaInTime) {
                    insertionRange.push(animal.DtaInTime);
                }
            }
            if (animal.DtaDthTime) {
                if (animal.DthRsn === 1) {
                    sellRange.push(animal.DtaDthTime);
                } else {
                    deathRange.push(animal.DtaDthTime);
                }
            }
        }
        for (const row of detailedData) {
            for (const key in row) {
                if (!row[key]?.anmID || row[key]?.amount === null) continue;
                const amount = Math.abs(row[key]?.amount ?? 0);
                const type = row[key]?.type ?? "";
                const removedFromGroup = row[key]?.removedReason;
                switch (key) {
                    case EventTypes.INSERTION:
                        insertedCounter += amount;
                        insertionCounter += amount;
                        break;
                    case EventTypes.TRANSFER:
                        if (type === "error") {
                            if(removedFromGroup){
                                removedCounter += amount;
                            } else{
                                fromGroupTransferCounter += amount;
                            }
                            
                        } else if (type === "success") {
                            toGroupTransferCounter += amount;
                            insertedCounter += amount;
                        }
                        break;
                    case EventTypes.FALL:
                        fallCounter += amount;
                        deathRange.push(row.date);
                        break;
                    case EventTypes.SELL:
                        soldCounter += amount;
                        sellRange.push(row.date);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const timeFormatter = (range) => {
        if (!range) return '-'
        let newRange = range.slice(0);
        newRange.sort((o1, o2) => o1 - o2);
        newRange = [...new Set(newRange.map(formatTime))];
        if (newRange.length > 2) {
            newRange = [newRange[0], newRange[newRange.length - 1]];
        }
        return newRange.join("-") || "-";
    };

    const getFirstInsertionTime = () => {
        const insertionsTmp = insertionRange.slice(0);
        insertionsTmp.sort((o1, o2) => o1 - o2);
        return insertionsTmp[0] || firstAbsInsertion(insertions)?.[0] || +new Date();
    };

    const getFirstBirthTime = () => {
        let births = birthRange.slice(0);
        births.sort((o1, o2) => o1 - o2);
        return births[0] || +new Date();
    };
    return {
        name: group ? group.GrNo1 : "-",
        animalsSize: animalSize !== null ? animalSize : "-",
        soldCounter: soldCounter !== null ? soldCounter : "-",
        fallCounter: fallCounter !== null ? fallCounter : "-",
        insertedCounter: insertedCounter !== null ? insertedCounter : "-",
        insertionCounter: insertionCounter !== null ? insertionCounter : "-",
        toGroupTransferCounter: toGroupTransferCounter !== null ? toGroupTransferCounter : "-",
        fromGroupTransferCounter: fromGroupTransferCounter !== null ? fromGroupTransferCounter : "-",
        removedCounter : removedCounter !== null ? removedCounter : "-",
        insertion: timeFormatter(insertionRange.length ? insertionRange : firstAbsInsertion(insertions)),
        sell: timeFormatter(sellRange),
        birth: timeFormatter(birthRange),
        death: timeFormatter(deathRange),
        supplier: suppliers.length ? suppliers.map(supplierFormatter).join(", ") : "-",
        supplierID: suppliers.length ? suppliers[0] : "",
        weight: get(group, "Weight", 0),
        herdNumber: animals.find((anm) => anm.HerdNumber)?.HerdNumber || "",
        firstInsertionTime: getFirstInsertionTime(),
        firstBirthTime: getFirstBirthTime(),
        hasIndividualAnimal,
    };
};
