import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../components/modals-new/confirm-modal/ConfirmModal";
import moment from "moment";
import {removeSale, removeSaleNotificationFailure, removeSaleNotificationSuccess} from "../sale/removeSale";

export function resurrectAnimal({AnmID, AnmCnt = 1, EvID = undefined, AnmGrp = undefined, Comment, NewPlacementID = null}, {
    FarmID,
    ClientID,
    LocalUserID
} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.resurrectAnimal({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            AnmID,
            AnmCnt,
            EvID,
            AnmGrp,
            Comment,
            NewPlacementID
        }
    })
}

export function resurrectAnimalNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("logView.tableName.animals"),
        message: i18n.t("apiNotifications.resurrectAnimalSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function resurrectAnimalNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("logView.tableName.animals"),
        message: i18n.t("apiNotifications.resurrectAnimalFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function removeAnimalsFromSaleNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.removeAnimalsFromSale"),
        message: i18n.t("apiNotifications.removeAnimalsFromSaleSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function removeAnimalsFromSaleNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.removeAnimalsFromSale"),
        message: i18n.t("apiNotifications.removeAnimalsFromSaleFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function showRemoveSaleInfo(res, dispatch) {
    if (!res.isEmptySale) return;
    dispatch(show(ConfirmModalName, {
        title: i18n.t("translation.apiNotifications.removeSale"),
        text: i18n.t("sellLastAnimalText", {date: moment(res.date).format("L")}),
        confirmText: i18n.t("yes"),
        closeText: i18n.t("no"),
        onConfirmed: (props) => {
            return removeSale({SaleID: res.id}).then(res => {
                removeSaleNotificationSuccess(res);
                props.handleHide();
            }).catch(e => {
                removeSaleNotificationFailure(e);
            })
        }
    }))
}
