import { setGlobalLoading } from "../actions/viewActions";
import { history } from "../components/router/CustomBrowserRouter";
import buildingsDB from "../database/buildingsDB";
import devicesDB from "../database/devicesDB";
import lokiDB from "../database/lokiDB";
import settingsDB from "../database/settingsDB";
import store from "../store/store";
import { onFarmClick } from "./FarmUtils";
import { checkIfUserIsService } from "./NewRolesUtils";

export function checkUserData(user, sub, farm, offlineData) {
    store.dispatch(setGlobalLoading(true, "Sprawdzanie danych"));
    return new Promise((resolve, reject) => {
        const { ipcRenderer } = window.require("electron");

        async function listener(event, data) {
            console.log("Received LOCAL_DATA", event, data);
            const { file } = data;
            if (!file) {
                history.replace("/load", offlineData);
            } else {
                await loadFileToLoki(user, sub, file, farm);
                onFarmClick(farm, history);
            }
            ipcRenderer.removeListener("LOCAL_DATA", listener);
            store.dispatch(setGlobalLoading(false));
            resolve();
        }
        ipcRenderer.on("LOCAL_DATA", listener);
        ipcRenderer.send("LOCAL_DATA", { user: user.Login });
    });
}

// export function checkUserData(user, sub, farm, offlineData) {
//     store.dispatch(setGlobalLoading(true, "Sprawdzanie danych"));
//     const { ipcRenderer } = window.require("electron");

//     async function listener(event, data) {
//         console.log("Received LOCAL_DATA", event, data);
//         const { file } = data;
//         if (!file) {
//             history.replace("/load", offlineData);
//         } else {
//             await loadFileToLoki(user, sub, file, farm);
//             onFarmClick(farm, history);
//         }
//         ipcRenderer.removeListener("LOCAL_DATA", listener);
//         store.dispatch(setGlobalLoading(false));
//     }

//     ipcRenderer.on("LOCAL_DATA", listener);
//     ipcRenderer.send("LOCAL_DATA", { user: user.Login });
// }

export function saveFileInfo(user, file) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("SAVE_FILE", { user, file });
}

export async function loadFileToLoki(user, sub, file, farm) {
    // console.log("Deleting old database");
    // await lokiDB.deleteDatabase();
    const isService = checkIfUserIsService();
    let key = isService ? `${sub}_${user.ClientID}` : sub;
    console.log("Creating new database");
    await lokiDB.loadDatabase(key);
    const { buildings, devices, settings } = JSON.parse(JSON.stringify(file));
    console.log("Inserting buildings");
    buildingsDB.insertIntoBuildings(buildings);
    console.log("Inserting devices");
    devicesDB.insertIntoDevices(devices);
    console.log("Inserting settings");
    settingsDB.insertIntoSettings(settings);
    saveFileInfo(user.Login, file);
    onFarmClick(farm, history);
}

export function saveDevices(devices, user) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("SAVE_DEVICES", { devices, user });
}