import { cloneDeep, get } from "lodash";
import Paths from "../api/paths";
import eventsDB from "../database/eventsDB";
import { invokeApig } from "../libs/awsLib";
import store from "../store/store";
import { getValidationSettings } from "../utils/SettingsUtils";
import { prepareAnimalParametersForceUpdate } from "./animalParametersActions";

export function getAllEvents(FarmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_EVENTS",
            payload: eventsDB.getAllEventsForFarm(FarmID)
        });
    };
}

export function prepareEventsToUpdate(events) {
    let _events = cloneDeep(events);
    if (Array.isArray(_events)) {
        for (let event of _events) {
            delete event.$loki;
        }
    } else {
        delete _events.$loki;
    }
    return _events;
}

export function getUpdateCycleList(animal) {
    return function (dispatch) {
        dispatch({
            type: "LIST_EVENTS_FOR_ANIMALS_CYCLE_UPDATE",
            meta: { AnmID: animal.AnmID, animal: animal }
        });
    };
}

export function getEventsForAnimal(animal, LastEvaluatedKey = undefined) {
    return function (dispatch, getState) {
        if (animal) {
            const cycleSettings = get(getState(), "settings.general.SetData.Settings.Cycle", {});
            cycleSettings.validations = getValidationSettings();
            let querryParams = { FarmID: animal.FarmID, AnmID: animal.AnmID, partial: true };
            if (LastEvaluatedKey) {
                querryParams.DtaModTime = 0;
                querryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
                querryParams.ESKEvID = LastEvaluatedKey.EvID;
            }
            dispatch({
                type: "LIST_EVENT_4_ANM", payload: invokeApig({
                    ...Paths.listEvent({ farmID: animal.FarmID }),
                    queryParams: querryParams
                }), meta: { AnmID: animal.AnmID, animal: animal }
            }).then(async res => {
                if (res.value.LastEvaluatedKey) {
                    dispatch(getEventsForAnimal(animal, res.value.LastEvaluatedKey));
                }
                dispatch({
                    type: "LIST_EVENT_4_ANIMAL_FULFILLED",
                    payload: res.value,
                    meta: { AnmID: animal.AnmID, animal: animal, cycleSettings }
                });
            }).catch(error => {
                console.error(error);
                dispatch({
                    type: "LIST_EVENT_4_ANIMAL_FULFILLED",
                    payload: {
                        items: eventsDB.getAllEvents4Animal(animal.AnmID)
                    },
                    meta: { AnmID: animal.AnmID, animal: animal, cycleSettings }
                });
            });
            dispatch({
                type: "LIST_EVENT_4_ANIMAL_LOADING",
                payload: animal.AnmID
            });

        } else {
            dispatch({
                type: "SET_ALL_ANIMALS_EMPTY"
            });
        }
    };
}

/**
 *
 * @param animalIDs
 * @param FarmID
 * @param meta doatkowa meta AnmGrp - przy pobieraniu z grup prosze cos takiego zamiescic
 * @return {function(...[*]=)}
 */
export function getEventsForAnimals(animalIDs, { FarmID = store.getState().location.farm, meta = {} } = {}) {
    return function (dispatch) {
        console.log(FarmID, animalIDs);
        dispatch({
            type: "LIST_EVENTS_FOR_ANIMALS",
            payload: invokeApig({
                ...Paths.listEventsForAnimals(),
                body: {
                    AnmIDs: animalIDs
                },
                queryParams: {
                    FarmID
                }
            }),
            meta: { ...meta, AnmIDs: animalIDs }
        }).catch(e => {
            const data = {};
            for (let AnmID of animalIDs) {
                data[AnmID] = eventsDB.getAllEvents4Animal(AnmID);
            }
            dispatch({
                type: "LIST_EVENTS_FOR_ANIMALS_FULFILLED",
                payload: data,
                meta: { ...meta, AnmIDs: animalIDs }
            });
        });
        dispatch({
            type: "LIST_EVENT_4_ANIMALS_LOADING",
            payload: animalIDs
        });
    };
}

export function getEvents(FarmID, LastEvaluatedKey = undefined, forceFetch = false, lastAnimalChanges = []) {
    return function (dispatch, getState) {
        let querryParams = { FarmID: FarmID, DtaModTime: eventsDB.getModificationTime(FarmID).DtaModTime, partial: true };
        const cycleSettings = get(getState(), "settings.general.SetData.Settings.Cycle", {});
        if (LastEvaluatedKey) {
            querryParams.DtaModTime = 0;
            querryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
            querryParams.ESKEvID = LastEvaluatedKey.EvID;
        }
        dispatch({
            type: "LIST_EVENT",
            payload: invokeApig({
                ...Paths.listEvent({ farmID: FarmID }),
                queryParams: querryParams,
                forceFetch
            }),
            meta: { cycleSettings }
        }).then(async res => {
            if (res.value.items && res.value.items.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value.items);
                eventsDB.insertIntoEvents(tmp);
                dispatch(getAllEvents(FarmID));
                const tmpAnimals = new Set(res.value.items.map(item => item.AnmID));
                lastAnimalChanges.push(...tmpAnimals);
            }
            if (res.value.LastEvaluatedKey) {
                // musimy wyslac za kazdym razem forceFetch bo nie wiemy w jakiej partii zostanie zwrocony nowy obiekt
                dispatch(getEvents(FarmID, res.value.LastEvaluatedKey, forceFetch, lastAnimalChanges));
            } else {
                // przygotuj tylko gdy skończymy pobierać wszystkie eventy
                dispatch(prepareAnimalParametersForceUpdate({ animalChanges: lastAnimalChanges }));
            }
            dispatch(getEventsModificationTime(FarmID));

        }).catch((e) => {
            console.error(e);
            dispatch(getAllEvents(FarmID));
            dispatch(getEventsModificationTime(FarmID));
        });
    };
}

export function getEventsModificationTime(farmId) {
    return function (dispatch) {
        dispatch({
            type: "GET_EVENTS_MODIFICATION_TIME",
            payload: eventsDB.getModificationTime(farmId)
        });
    };
}

export function changeEventChooserFilter(animalType) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_EVENT_CHOOSER_FILTER",
            payload: animalType
        });
    };
}
