import {useCallback, useEffect} from 'react';

const ignoreClasses = ["select-item", "menu-color", "text-area-hints-menu-item"];

export default function useOnClickOutside(ref, handler, {eventType = "mousedown"} = {}) {

    const listener = useCallback(e => {
        if (!ref.current || ref.current.contains(e.target) || ignoreClasses.find(classN => e.target.classList.contains(classN))) return;
        handler(e);
    }, [ref, handler])

    useEffect(() => {
        console.log("[useOnClickOutside] Adding listener");
        document.addEventListener(eventType, listener);

        return () => {
            console.log("[useOnClickOutside] Removing listener");
            document.removeEventListener(eventType, listener);
        }
    }, [listener, eventType])
};
