import {createSelector} from "reselect";
import groupsDB from "../database/groupsDB";
import utilSelectors from "./utilSelectors"


export const getManageSubgroups = utilSelectors.getManageSubgroups;

const sortByNewest = (items) => {
    items.sort((o1, o2) => o2.DtaModTime - o1.DtaModTime);
    return items;
}
/**
 * returns list of active groups
 */
export const getActiveGroups = createSelector([utilSelectors.getFarmID, utilSelectors.getGroupDtaModTime], (farmId) => {
    return sortByNewest(groupsDB.getAllGroups(farmId, {showDeleted: false}));
});

/**
 * returns list of all groups (active and deleted)
 */
export const getAllGroups = createSelector([utilSelectors.getFarmID, utilSelectors.getGroupDtaModTime], (farmId) => {
    return sortByNewest(groupsDB.getAllGroups(farmId, {showDeleted: true}));
});

/**
 * returns map of all groups with animal id key
 */
export const getGroupMapWithAnimalID = createSelector([utilSelectors.getFarmID], (farmId) => {
    const groups = groupsDB.getAllGroups(farmId, {showDeleted: false})
    const groupMap = new Map()
    groups.forEach((group) => {
        group.AnmIDs.forEach((AnmID) => {
            groupMap.set(AnmID,group)
        })
    })
    return groupMap;
});