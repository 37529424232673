import {get, isArray, isEqual, isFunction, isNil, set} from "lodash";
import {convertPressureUnitTo, convertVoltageUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import {LoadedDataTypes} from "../../../../../constans/devices";
import {getValue} from "../../../devices-settings/utils/DeviceSettingUtils";
import {
    temperatureFormatter as _temperatureFormatter
} from "../../../../../utils/global-formatters/temperatureFormatter";

const DEBUG = true;

export const initializeForm = ({initialize, shadow, device, model}) => {
    const result = {loadedDataFrom: LoadedDataTypes.SHADOW, isSet: false, SetTime: +new Date()}
    const timestamps = [];
    DEBUG && console.log(shadow, device, model);
    const dataShadow = (item) => {
        if (item.visual) return;
        if (item.button) return;
        DEBUG && console.log("evaluating '%s': shadowKey=%s, deviceKey=%s", item.key, item.shadowKey, item.deviceKey);
        let value;
        const defaultValue = isNil(item.defaultValue) ? item.min : item.defaultValue;
        const shadowResult = getValue(item.shadowKey, shadow);
        if (shadowResult.hasData) {
            value = shadowResult.value;
            DEBUG && console.log("resolved value by shadowKey: %s", shadowResult.value);
            if (item.isTime && item.metaKey) {
                value += get(shadow, `metadata.${item.metaKey}`) - +new Date();
                if (isNaN(value)) value = undefined;
            }
        } else {
            const deviceResult = getValue(item.deviceKey, device);
            if (deviceResult.hasData) {
                DEBUG && console.log("resolved value by deviceKey: %s", deviceResult.value);
                if (deviceResult.timestamp !== null) {
                    timestamps.push(timestamps);
                }
                if (result.loadedDataFrom === LoadedDataTypes.SHADOW) {
                    result.loadedDataFrom = LoadedDataTypes.DYNAMO;
                    //try to get date and isSet
                    try {
                        const keyArr = (isArray(item.deviceKey) ? item.deviceKey[0] : item.deviceKey).split(".");
                        const index = keyArr.indexOf("Configuration");
                        if (index !== -1) {
                            const key = keyArr.slice(0, index + 2).join(".");
                            result.isSet = get(device, `${key}.isSet`, result.isSet);
                            result.SetTime = get(device, `${key}.SetTime`, result.SetTime);
                            DEBUG && console.log("found parent setting: %s set=%s time=%s", key, result.isSet, result.SetTime);
                        }
                    } catch (err) {
                        //unluko
                    }
                }
                value = deviceResult.value;
            } else {
                result.loadedDataFrom = LoadedDataTypes.NO_DATA;
                value = defaultValue;
                DEBUG && console.log("could not resolve the value, setting=%s", value);
            }
        }
        if (isFunction(item.onAfterMerge)) {
            value = item.onAfterMerge(value, result);
        }
        //jesli nie znajdzie opcji to wybierz pierwsza z brzegu
        if (isArray(item.options)) {
            if (!item.options.find(o => isEqual(value, o.value))) {
                value = item.options[0].value;
            }
        }
        // jesli mamy boola jako undefined to zmieniamy na false
        if (isNil(value) && item.boolean) {
            value = false;
        }
        return value;
    }

    const data = {};
    model.forEach(d => {
            if (data.visual === true) return;
            set(data, d.key, dataShadow(d));
        }
    )
    // jeśli mamy lokalne timestamp-y _ts dla kluczy to możemy nadpisać 'isSet', jeśli dane klucze nie były ustawiane z danym timestamp-em
    if (timestamps.length && result.isSet === false) {
        if (timestamps.every(ts => ts < result.SetTime)) {
            result.isSet = true;
        }
    }
    initialize(data);
    return result;
}

export const temperatureFormatter = (value) => _temperatureFormatter(value);

export const secondsFormatter = (value) => `${((value || 0) / 1000).toFixed(1)}s`

export const minutesFormatter = (value) => `${((value || 0) / 60000).toFixed(1)}min`

export const percentageFormatter = (value) => `${value}%`;

export const pressureFormatter = (value) => convertPressureUnitTo(value, {showUnit: true, fixed: 2})

export const voltageFormatter = (value) => convertVoltageUnitTo(value, {
    showUnit: true,
    fixed: 1,
    unit: UnitTypes.MEDIUM
});
