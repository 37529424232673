import moment from "moment";
import {editAnimal, editAnimalNotificationFailure, editAnimalNotificationSuccess} from "../../../api/animal/EditAnimal";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import animalsDB from "../../../database/animalsDB";
import buildingsDB from "../../../database/buildingsDB";
import devicesDB from "../../../database/devicesDB";
import { addZerosToRfid, prepareAnimalObjectToSave } from "../../../utils/AnimalsUtils";
import { checkIfUserIsService } from "../../../utils/NewRolesUtils";
import { removeKeysStartingWithLodash } from "../../../utils/Utils";

function prepareAnimalObject(animal, props) {
    const animalObject = JSON.parse(JSON.stringify(animal));
    // TODO - when integrating with topigs, only the BoarID and SowID are saved so it's a temporary solution for the client to edit this information
    const animalGenetics = {
        MotherNumber: animal.MotherNumber,
        FatherNumber: animal.FatherNumber,
        MotherTattoo: animal.MotherTattoo,
        FatherTattoo: animal.FatherTattoo,
        MotherRace: animal.MotherRace,
        FatherRace: animal.FatherRace,
        OldSowID: props?.geneticsInformation?.MotherAnmID,
        OldBoarID: props?.geneticsInformation?.FatherAnmID
    };
    if (!animalObject.SupplierID) {
        animalObject.SupplierID = undefined;
    }
    delete animalObject.MotherNumber;
    delete animalObject.FatherNumber;
    delete animalObject.MotherTattoo;
    delete animalObject.FatherTattoo;
    delete animalObject.MotherRace;
    delete animalObject.FatherRace;
    delete animalObject.$loki;
    removeKeysStartingWithLodash(animalObject, false);
    // naprawia czyszczenie Genetyki przez edycję ogólną (GENERAL), które miało miejsce
    if (props.type === "GENETICS") {
        animalObject.Genetics = animalGenetics;
    } else {
        // > wyślij obiekt Genetics z front-a bez danych wewnętrznych
        // > spread-uj to na API
        // > profit-u brak
        delete animal.Genetics;
    }
    animalObject.RFID = animal.RFID ? addZerosToRfid(animal.RFID) : undefined;
    return animalObject;
}

export function editAnimalSubmit(values, props) {
    return editAnimal([{
        animal: prepareAnimalObject(values, props),
        devices: devicesDB.getDevicesInPlcmntID(values.PlcmntID, { showDevicesInChildren: false }).filter((item) => item.DevType !== DevType.SMALL_CAGE && item.GatewayID).map((device) => ({
            DevID: device.DevID, GwID: device.GatewayID
        })),
        comment: values._Comment ?? null
    }], { FarmID: values.FarmID }).then(res => {
        props.handleHide();
        editAnimalNotificationSuccess(res);
    }).catch((e) => {
        editAnimalNotificationFailure(e);
    });
}


export function submit(values, dispatch, props) {
    let newAnimal = prepareAnimalObjectToSave(values);
    delete newAnimal.isDead;
    if (!values.isDead) {
        delete newAnimal.DtaDthTime;
    }
    return editAnimalSubmit(newAnimal, props);
}

export function validate(values, props) {
    const errors = {};
    const { t, animal, giltsWithRFID } = props;
    if (!values.AnmNo1) {
        errors.AnmNo1 = t("required");
    } else {
        if (values.AnmNo1 !== animal.AnmNo1 && animalsDB.checkIfAnimalExistOnFarm(values.AnmNo1, values.FarmID, values.AnmID)) {
            errors.AnmNo1 = t("errors.exist");
        }
    }

    if (!values.DtaBrthTime) {
        errors.DtaBrthTime = t("required");
    }

    if (!values.DtaInTime) {
        errors.DtaInTime = t("required");
    } else {
        if (!checkIfUserIsService() && moment(values.DtaBrthTime).isAfter(new Date(), "days")) {
            errors.DtaBrthTime = t("errors.doNotInsertEventInFuture");
        }
        if (values.DtaInTime) {
            const date = moment(values.DtaInTime).subtract(1, "day").format("L");
            if (moment(values.DtaBrthTime).isSameOrAfter(moment(values.DtaInTime), "days")) {
                errors.DtaBrthTime = t("errors.dateAfter", { date });
            }
            if (values.DtaDthTime && moment(values.DtaInTime).isSameOrAfter(moment(values.DtaDthTime), "days")) {
                errors.DtaInTime = t("errors.dateAfter", { date });
            }
        }
    }

    if (values.RFID) {
        const rfid = addZerosToRfid(values.RFID);
        const animalWithRFID = animalsDB.getAnimalByRfid(rfid, values.FarmID);
        if (!animalWithRFID || animalWithRFID.AnmID === animal.AnmID || !animalWithRFID.AnmNo1.includes(animalWithRFID.RFID.slice(-4))) {
            if ((animalWithRFID && animalWithRFID.AnmNo1 !== animal.AnmNo1) || !!buildingsDB.getLocationByRFID(rfid, values.FarmID)) {
                errors.RFID = t("errors.duplicate");
            } else if (+values.AnmCnt > 1) {
                errors.RFID = t("errors.anmCntTooBigForRFID");
            }
        }
        if (giltsWithRFID[rfid]) {
            errors.RFID = t("errors.duplicate");
        }
    }

    if (!values.AnmCnt) {
        errors.AnmCnt = t("required");
    }

    if (+values.Weight < 0) {
        errors.Weight = t("errors.greaterOrEqualZero");
    }
    return errors;
}

export function warn(values, props) {
    const { t, animal } = props;
    const warnings = {};
    if (values.RFID) {
        const rfid = addZerosToRfid(values.RFID);
        let animalsWithRFID = animalsDB.getAnimalByRfid(rfid, values.FarmID);
        if (animalsWithRFID && animal.AnmID !== animalsWithRFID.AnmID && animalsWithRFID.AnmNo1.includes(animalsWithRFID.RFID.slice(-4))) {
            warnings.RFID = t("errors.duplicatedRFIDRemove", { AnmNo1: animalsWithRFID.AnmNo1 });
        }
    }
    return warnings;
}
