import moment from "moment";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";
import { getPigBalanceForSowFromState } from "../../../utils/EventUtils";
import animalsDB from "../../../database/animalsDB";

export function submit(values, dispatch, props) {
    values.DtaModTime = +new Date();
    const eventsToUpdate = [values];
    return editSowCycle({ AnmID: values.AnmID, eventsToUpdate: eventsToUpdate, eventsToDelete: [] }, { FarmID: values.FarmID }).then((res) => {
        props.handleHide();
        editSowCycleNotificationSuccess(res);
    }).catch((e) => {
        editSowCycleNotificationFailure(e);
    });
}

export function validate(values, props) {
    const errors = {
        EvData: {}
    };
    const { t } = props;

    if (!values.EvTime) {
        errors.EvTime = t("required");
    }

    if (!values.EvData?.StartTime) {
        errors.EvData.StartTime = t("required");
    } else if (values.EvData.StartTime > values.EvData.EndTime) {
        errors.EvData.StartTime = t("errors.dateAfter", { date: moment(values.EvData.EndTime).format("L") });
    }

    if (!values.EvData?.EndTime) {
        errors.EvData.EndTime = t("required");
    }

    if (!values.EvData?.Dose) {
        errors.EvData.Dose = t("required");
    } else if (values.EvData?.Dose <= 0) {
        errors.EvData.Dose = t("errors.lessVal", { count: 1 });
    }

    if (!values.EvData?.PiCnt) {
        errors.EvData.PiCnt = t("required");
    } else {
        let animal = animalsDB.getAnimalById(values.AnmID, { joinEvents: false });
        let pigBalance = getPigBalanceForSowFromState(animal, values.EvData?.StartTime || values.EvTime);
        if (pigBalance < values.EvData.PiCnt) {
            errors.EvData.PiCnt = t("errors.pigletsInDate", {
                date: moment(values.EvData?.StartTime || values.EvTime).format("L"),
                amount: pigBalance
            });
        }
    }

    return errors;
}
