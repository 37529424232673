import {
    AlarmControlPanelCommandTypes,
    BridgeCommandTypes,
    ClimateCommandTypes,
    ClimateDriverCommandTypes,
    DispenserDriverCommandTypes,
    DispenserNRFCommandTypes,
    GatawayCommandTypes,
    MessageCommands,
    SeparationCageCommandTypes,
    UniversalBoardCommandTypes
} from "../../constans/mqttMessages";

const specialMessages = {
    [DispenserDriverCommandTypes.RESET_CONSUMPTION]: true,
    [SeparationCageCommandTypes.DO_TARE]: true,
    [SeparationCageCommandTypes.RESET_DAILY_VISITS]: true,
    [SeparationCageCommandTypes.CLEAR_INSERTION_DATA]: true,
    [SeparationCageCommandTypes.RESET]: true,
    [DispenserDriverCommandTypes.START_MOTORS]: true,
    [DispenserDriverCommandTypes.STOP_MOTORS]: true,
    [MessageCommands.GET_FULL_DEV_STATE]: true,
    [MessageCommands.GET_DEVICES]: true,
    [DispenserNRFCommandTypes.GET_LOGS]: true,
    [DispenserNRFCommandTypes.DELETE_LOGS]: true,
    [DispenserNRFCommandTypes.GET_PIG]: true,
    [DispenserNRFCommandTypes.ADD_PIG]: true,
    [DispenserNRFCommandTypes.GET_WORK_TYPE]: true,
    [DispenserNRFCommandTypes.GET_HISTORY_STANDARD]: true,
    [MessageCommands.PING]: true,
    [DispenserNRFCommandTypes.GET_DAILY_USAGE]: true,
    [MessageCommands.GET_AGGREGATED_DATA]: true,
    [GatawayCommandTypes.START_ADDRESSING]: true,
    [GatawayCommandTypes.GET_GATEWAY_TIME]: true,
    [MessageCommands.UPDATE_SINGLE_DEVICE]: true,
    [MessageCommands.REMOVE_DEVICES]: true,
    [MessageCommands.GET_NRF_FEEDERS]: true,
    [SeparationCageCommandTypes.GET_SENSORS_AND_VALVES]: true,
    [ClimateDriverCommandTypes.GET_CONFIGURATION]: true,
    [GatawayCommandTypes.OPEN_SSH]: true,
    [GatawayCommandTypes.GET_NRF_STATS]: true,
    [GatawayCommandTypes.START_ADR_WST]: true,
    [GatawayCommandTypes.START_ADDRESSING_NRF]: true,
    [GatawayCommandTypes.STOP_ADR_WST]: true,
    [GatawayCommandTypes.STOP_ADDRESSING_NRF]: true,
    [GatawayCommandTypes.GET_SCHEMA]: true,
    [MessageCommands.AUTH_USER]: true,
    [GatawayCommandTypes.ADDR_START_SUBADDRESSING_NRF]: true,
    [BridgeCommandTypes.B_INFO]: true,
    [BridgeCommandTypes.B_BOOT_INFO]: true,
    [BridgeCommandTypes.B_BOOT_INFO_NRF]: true,
    [BridgeCommandTypes.B_NRF_STAT]: true,
    [BridgeCommandTypes.B_485_TOUT_R]: true,
    [BridgeCommandTypes.B_GET_SCAN_ADDR]: true,
    [GatawayCommandTypes.GET_QUEUES_LAST_SUCCESS]: true,
    [GatawayCommandTypes.GET_PIGS_DATA]: true,
    [GatawayCommandTypes.GET_ASCII_CLIMATE_TESTING_DATA]: true,
    [MessageCommands.GET_FEED_RFID_STATE]: true,
    [GatawayCommandTypes.GET_ALL_ALARMS]: true,
    [GatawayCommandTypes.GET_ALL_ONGOING_ALARMS]: true,
    [GatawayCommandTypes.GET_USAGE_FOR_PIG]: true,
    [MessageCommands.REFRESH_TOKEN]: true,
    [GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG]: true,
    [GatawayCommandTypes.PING]: true,
    [DispenserNRFCommandTypes.SET_ADDITIONAL_WATER]: true,
    [DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION]: true,
    [GatawayCommandTypes.GET_ALL_ONGOING_ALARMS_BY_CODE]: true,
    [GatawayCommandTypes.GET_ALL_ALARMS_BY_CODE]: true,
    [GatawayCommandTypes.GET_DEVICE_LOGS]: true,
    [GatawayCommandTypes.SEARCH_ETH_DEVICES]: true,
    [GatawayCommandTypes.GET_PARAM_ETH_DEVICE]: true,
    [GatawayCommandTypes.VERIFY_NRF_SERIAL_NUMBERS]: true,
    [GatawayCommandTypes.RESTORE_NRF_SERIAL_NUMBERS]: true,
    [GatawayCommandTypes.SAVE_NRF_SERIAL_NUMBERS]: true,
    [DispenserNRFCommandTypes.GET_SERIAL_NUMBER]: true,
    [GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG_SYNC]: true,
    [GatawayCommandTypes.GET_GW_ALERTS_UNGROUPED]: true,
    [ClimateCommandTypes.GET_CLOCK_MENU]: true,
    [GatawayCommandTypes.GET_UPLOAD_STATUS]: true,
    [GatawayCommandTypes.GET_PROGRAM_DETAILS]: true,
    [GatawayCommandTypes.GET_DATA_BY_LOCATION]: true,
    [GatawayCommandTypes.GET_DATA_BY_LOCATION_DELTA]: true,
    [GatawayCommandTypes.GET_RFID_PIG_HISTORY]: true,
    [GatawayCommandTypes.GET_GATEWAY]: true,
    [DispenserNRFCommandTypes.GET_IPSUM_PASSES]: true,
    [DispenserNRFCommandTypes.GET_IPSUM_DETAILS_PASSES]: true,
    [GatawayCommandTypes.GET_RPI_IP_ADDRESSES]: true,
    [DispenserNRFCommandTypes.GET_IPSUM_RFIDS]: true,
    [DispenserNRFCommandTypes.GET_IPSUM_RFID_INFO]: true,
    [UniversalBoardCommandTypes.U_READ_RAM]: true,
    [GatawayCommandTypes.GET_IPSUM_DATA]: true,
    [GatawayCommandTypes.GET_IPSUM_DETAILS]: true,
    [GatawayCommandTypes.GET_SETTLEMENTS]: true,
    [ClimateCommandTypes.GET_AMBIENT_CONDITIONS]: true,
    [AlarmControlPanelCommandTypes.GET_HMIS_CONFIGURATION]: true,
    [GatawayCommandTypes.GET_OFFLINE_AGGREGATED_DATA]: true,
}

export default (command) => specialMessages[command] ?? false;