import { invokeApig } from '../libs/awsLib';
import devicesDB from "../database/devicesDB";
import { cutUselessPrefixes } from "../utils/DevicesUtils";
import Paths from "../api/paths";
import {
    getGatewayAlerts as getGatewayAlertsIOT,
    getGatewayAlertsCategories as getGatewayAlertsCategoriesIOT,
    getParamETHDevice,
    searchETHDevices,
    synchronizeDevicesOnBackend
} from "../IOT/device-functions/GatewayFunctions";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import * as TableNames from "@wesstron/utils/Api/constants/tableNames";
import i18next from "i18next";
import { notify } from "reapop";
import { getAlerts, getSearchGateway } from "../selectors/deviceSelector";
import { getDevicePrograms as getDeviceProgramsAPI } from "../api/devices/getDevicePrograms";
import { getAgentList as getAgentListAPI } from "../api/devices/getAgentList";
import { getJobListForType as getJobListForTypeAPI } from "../api/devices/getJobListForType";
import { getJobExecutionStatus as getJobExecutionStatusAPI } from "../api/devices/getJobExecutionStatus";
import { getBackendPrograms as getBackendProgramsAPI } from "../api/devices/getBackendPrograms";
import { getAgentProgramList as getAgentProgramListAPI } from "../api/devices/getAgentProgramList";
import { getScriptList as getScriptListAPI } from "../api/devices/getScriptList";
import { saveDevices } from '../utils/ElectronUtils';

export function fetchDevices(farmID, forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "FETCH_DEVICES",
            payload: invokeApig({
                ...Paths.listDevices({ farmID: farmID }),
                queryParams: {
                    DtaModTime: "" + devicesDB.getModificationTime(farmID).DtaModTime,
                    table: "Devices"
                },
                forceFetch
            })
        }).then((res) => {
            //console.log("CONNECTION",res);
            let l = {};
            Object.assign(l, res);
            //console.log("res", l);
            if (res.value.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value);
                devicesDB.insertIntoDevices(tmp);
            }
            dispatch(getDevices(farmID));
        }).catch(error => {
            dispatch(getDevices(farmID));
        });
    };
}

export function createDeviceDynamoDB(values, FarmID, onSuccess = null) {
    let devices = cutUselessPrefixes(values);
    return function (dispatch) {
        dispatch({
            type: "CREATE_DEVICE", payload: invokeApig({
                ...Paths.createDevices({ farmID: FarmID }),
                body: devices,
                queryParams: { table: TableNames.DEVICES }
            })
        }).then(() => {
            const not = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.added"),
                status: 'success',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(notify(not));
            if (onSuccess) onSuccess();
            let devices = Array.isArray(values) ? values : [values];
            let map = new Map();
            for (let device of devices) {
                let dev = device;
                if (device.DevType === DevType.BROADCAST || device.DevType === DevType.DI_TIME || device.DevType === DevType.BRIDGE_CONF || device.DevType === DevType.BRIDGE_WORK) {
                    dev = devices.find(item => item.DevType === DevType.BRIDGE) || devicesDB.getDeviceByID(device.ParentID);
                }
                let gw = devicesDB.getGateway(dev);
                let array = map.get(gw.DevID) || [];
                array.push(device);
                map.set(gw.DevID, array);
            }
            for (let [gatewayID, values] of map.entries()) {
                synchronizeDevicesOnBackend(gatewayID, values.map(device => device.DevID));
            }
        }).catch((e) => {
            console.error(e);
            const not = {
                title: i18next.t("error"),
                message: e.message,
                status: 'error',
                dismissible: true,
                dismissAfter: 3000
            };
            dispatch(notify(not));
        });
        dispatch({ type: "CREATE_DEVICE", payload: devices });
    };
}

export function updateDeviceDynamoDB(values, FarmID, clientID, localUserID, notifications, onSuccess, showSynchronizePopup = true) {
    let devices = cutUselessPrefixes(values);
    return function (dispatch) {
        dispatch({
            type: "UPDATE_DEVICE", payload: invokeApig({
                ...Paths.updateDevices({ farmID: FarmID, clientID: clientID, localUserID: localUserID }),
                method: "PUT",
                body: devices,
                queryParams: { table: "Devices" }
            })
        }).then(() => {
            if (notifications && notifications.success) notifications.success();
            if (onSuccess) onSuccess();
            let devices = Array.isArray(values) ? values : [values];
            let map = new Map();
            for (let device of devices) {
                try {
                    let gw = devicesDB.getGateway(device);
                    let array = map.get(gw.DevID) || [];
                    array.push(device);
                    map.set(gw.DevID, array);
                } catch (e) {
                    console.error(e);
                }
            }
            for (let [gatewayID, values] of map.entries()) {
                synchronizeDevicesOnBackend(gatewayID, values.map(device => device.DevID), showSynchronizePopup);
            }
        }).catch(() => {
            if (notifications && notifications.error) notifications.error();
        });
        dispatch({ type: "UPDATE_DEVICE", payload: devices });
    };
}

export function getDevices(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_DEVICES",
            payload: devicesDB.getDevices(farmID)
        });
    };
}

export function changeAddressingState(state) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_ADDRESSING_STATE",
            payload: state
        });
    };
}

export function updateDeviceParam(values, FarmID, clientID, localUserID, notifications, onSuccess, showSynchronizePopup = true) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_DEVICE_PARAMS", payload: invokeApig({
                ...Paths.deviceParam({ farmID: FarmID, clientID: clientID, localUserID: localUserID }),
                body: { params: values }
            })
        }).then(() => {
            let gateways = new Map();
            for (let value of values) {
                let device = devicesDB.getDeviceByID(value.DevID);
                let gw = gateways.get(device.GatewayID) || new Set();
                gw.add(device.DevID);
                gateways.set(device.GatewayID, gw);
            }
            for (let [gatewayID, set] of gateways.entries()) {
                synchronizeDevicesOnBackend(gatewayID, [...set.values()], showSynchronizePopup);
            }
            if (notifications && notifications.success) notifications.success();
            if (onSuccess) onSuccess();
        }).catch(() => {
            if (notifications && notifications.error) notifications.error();
        });
    };
}

export function changeSearchGW(gateway) {
    return function (dispatch) {
        searchETHDevices(gateway, () => {
            dispatch({
                type: "STARTED_SEARCHING"
            });
        });
        dispatch({
            type: "SEARCH_GW_CHANGE",
            payload: gateway
        });
    };
}

export function foundETHDevice(msg) {
    return function (dispatch) {
        dispatch({
            type: "FOUND_ETH_DEVICE_DELTA",
            payload: msg
        });
    };
}

export function getETHDeviceParams(mac) {
    return function (dispatch, getState) {
        let state = getState();
        let gateway = getSearchGateway(state);
        dispatch({
            type: "GET_ETH_DEVICE_PARAMS",
            payload: getParamETHDevice(gateway, mac),
            meta: { MAC: mac }
        });
    };
}

export function gotETHDeviceParams(message) {
    return function (dispatch) {
        dispatch({
            type: "GET_ETH_DEVICE_PARAMS_DELTA",
            payload: message,
            meta: {
                MAC: message.general.MAC
            }
        });
    };
}

export function clearSearch() {
    return function (dispatch) {
        dispatch({
            type: "CLEAR_SEARCH"
        });
    };
}

export function getGatewayAlerts(gateway, LocalUserID) {
    return function (dispatch, getState) {
        let timeout = setTimeout(() => {
            let state = getState();
            let alerts = getAlerts(state);
            if (alerts.loading[gateway.DevID]) {
                dispatch({
                    type: "GET_GATEWAY_ALERTS_FAILED",
                    meta: {
                        ID: gateway.DevID
                    }
                });
            }
        }, 10 * 1000);
        dispatch({
            type: "GET_GATEWAY_ALERTS",
            payload: getGatewayAlertsIOT(gateway, LocalUserID),
            meta: {
                ID: gateway.DevID,
                timeout
            }
        });
    };
}

export function getGatewayAlertsCategories(gateway, LocalUserID) {
    return function (dispatch, getState) {
        let timeout = setTimeout(() => {
            let state = getState();
            let alerts = getAlerts(state);
            if (alerts.loading[gateway.DevID]) {
                dispatch({
                    type: "GET_GATEWAY_ALERTS_FAILED",
                    meta: {
                        ID: gateway.DevID
                    }
                });
            }
        }, 10 * 1000);
        dispatch({
            type: "GET_GATEWAY_ALERTS",
            payload: getGatewayAlertsCategoriesIOT(gateway, LocalUserID),
            meta: {
                ID: gateway.DevID,
                timeout
            }
        });
    };
}

export function getGatewaysAlerts(gateways) {
    return function (dispatch, getState) {
        let state = getState();
        const { user: { user: { LocalUserID } }, farmDevices: { alerts: { timeouts } } } = state;
        for (let key in timeouts) {
            clearTimeout(timeouts[key]);
        }
        for (let gateway of gateways) {
            dispatch(getGatewayAlertsCategories(gateway, LocalUserID));
        }
    };
}

export function getGateways(FarmIDs) {
    return function (dispatch) {
        dispatch({
            type: "GET_GATEWAYS",
            payload: invokeApig({
                ...Paths.getGateways(),
                queryParams: { FarmIDs }
            })
        });
    };
}

export function getGatewayAlertsState(msg) {
    return function (dispatch) {
        dispatch({
            type: "GET_GATEWAY_ALERTS_STATE",
            payload: msg.CAnsw,
            meta: {
                ID: msg.DeviceId
            }
        });
    };
}

export function previewAlertsFilterChange(search) {
    return function (dispatch) {
        dispatch({
            type: "PREVIEW_ALERTS_FILTER_CHANGE",
            payload: search
        });
    };
}

export function getDevicePrograms(showLoading = true) {
    return function (dispatch) {
        dispatch({
            type: "GET_DEVICE_PROGRAMS",
            payload: getDeviceProgramsAPI(),
            meta: {
                showLoading
            }
        }).catch(e => {
            console.error(e);
        });
    };
}

export function getAgentList() {
    return function (dispatch) {
        dispatch({
            type: "GET_AGENT_LIST",
            payload: getAgentListAPI(),
        });
    };
}

export function getJobList(type) {
    return function (dispatch) {
        dispatch({
            type: "GET_JOB_LIST",
            payload: getJobListForTypeAPI(type),
        });
    };
}

export function clearJobList() {
    return function (dispatch) {
        dispatch({
            type: "CLEAR_JOB_LIST"
        });
    };
}

export function getJobExecutionStatus(jobId) {
    return function (dispatch) {
        dispatch({
            type: "GET_JOB_EXECUTION_STATUS",
            payload: getJobExecutionStatusAPI(jobId),
            meta: { jobId }
        });
    };
}

export function getBackendPrograms() {
    return function (dispatch) {
        dispatch({
            type: "GET_BACKEND_PROGRAMS",
            payload: getBackendProgramsAPI()
        });
    };
}

export function getAgentProgramList() {
    return function (dispatch) {
        dispatch({
            type: "GET_AGENT_PROGRAM_LIST",
            payload: getAgentProgramListAPI()
        });
    };
}

export function getScriptList() {
    return function (dispatch) {
        dispatch({
            type: "GET_SCRIPT_LIST",
            payload: getScriptListAPI()
        });
    };
}

export function offlineSyncDevice(devices, GatewayID) {
    return function (dispatch, getState) {
        dispatch({
            type: "OFFLINE_SYNC_DEVICES",
            payload: devices,
            meta: { GatewayID }
        });
        const state = getState();
        saveDevices(devices, state.user.user.Login);
    };
}