import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resultTargetList } from '../../../constans/resultSQLSelects';
import { UnitTypes } from '../../../constans/unitTypes';
import useQueryCaller from '../../../hooks/useQueryCaller';
import { getForageCostForDevice, getForageUsageForDevice, getGroupGaugesAggDataLoading, getWaterCostForDevice, getWaterUsageForDevice } from '../../../selectors/aggregatedDataSelector';
import { getAnimalMedicineCost, getSelectedAnimalForDocuments } from '../../../selectors/animalDocumentsSelectors';
import { getAnimalParametersFirstFetching } from '../../../selectors/animalParametersSelector';
import { makeGetBuildingsMap } from '../../../selectors/buildingsSelector';
import { makeGetCurrency } from '../../../selectors/economySelectors';
import { getAllForages, getExpectedWaterUsageForGroup, getResultsTarget } from '../../../selectors/settingsSelector';
import { getPercent } from '../../../utils/GaugeUtils';
import { getGroupAnimalTypes } from '../../../utils/GroupUtils';
import { getRowQuery, getTargetRange } from '../../../utils/ResultsV2Utils';
import { getAnimalUnit, getFCRRange } from '../../../utils/SettingsUtils';
import { convertVolumeUnitTo, convertWeightUnitTo } from '../../../utils/UnitUtils';
import { createKeyToValueDictionary } from '../../../utils/Utils';
import Gauge from '../../basics/gauge/Gauge';
import LoadingComponent from '../../loading/LoadingComponent';
import CollapsableContainer from '../containers/CollapsableContainer';
import FatteningProgress from '../fattening-progress/FatteningProgress';
import { applyOffset } from '../ideal-charts/charts/utils';
import useDeviceLocations from '../ideal-charts/useDeviceLocations';

function GaugesContainer({ hasMoreAnimalTypes, children, name }) {

    if (!hasMoreAnimalTypes) return (
        <Col lg={4} md={6}>
            {children}
        </Col>
    );
    return (
        <Col xs={12}>
            <fieldset className="fieldset mb-3">
                <legend>{name}</legend>
                {children}
            </fieldset>
        </Col>
    );
}

function GaugeItem({ label, min, max, value, valueFormatter, reverse, sideToSide }) {

    const percent = useMemo(() => {
        return getPercent(value, min, max);
    }, [value, min, max]);

    return (
        <Gauge
            label={label}
            min={min}
            max={max}
            value={value}
            percent={percent}
            valueFormatter={valueFormatter}
            reverse={reverse}
            sideToSide={sideToSide}
            showLegend />
    );
}

function MortalityGauges({ group, groupTypes, queryCaller, hasMoreAnimalTypes, targets, targetDictionary }) {

    const { t } = useTranslation();

    const generalTarget = useMemo(() => {
        return getTargetRange("generalMortality", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const weanerTarget = useMemo(() => {
        return getTargetRange("pigletMortality", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const porkersTarget = useMemo(() => {
        return getTargetRange("pigletMortality", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const giltTarget = useMemo(() => {
        return getTargetRange("pigletMortality", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const mortalities = useMemo(() => {
        const valueKeys = [{ key: group.AnmGrp }];
        const generalMortality = getRowQuery("porker", "generalMortality", valueKeys);
        const tmp = { weaners: null, gilts: null, finisher: null, general: queryCaller(generalMortality)?.[0]?.mortality };
        if (groupTypes.hasWeaners) {
            let query = getRowQuery("porker", "pigletMortality", valueKeys);
            tmp.weaners = queryCaller(query)?.[0]?.mortality;
        }
        if (groupTypes.hasGilts) {
            let query = getRowQuery("porker", "giltMortality", valueKeys);
            tmp.gilts = queryCaller(query)?.[0]?.mortality;
        }
        if (groupTypes.hasFinisher) {
            let query = getRowQuery("porker", "porkerMortality", valueKeys);
            tmp.finisher = queryCaller(query)?.[0]?.mortality;
        }
        return tmp;
    }, [groupTypes, group]); // eslint-disable-line react-hooks/exhaustive-deps

    const colSizes = useMemo(() => {
        const elements = groupTypes.hasWeaners + groupTypes.hasGilts + groupTypes.hasFinisher;
        if (elements === 1) {
            return { lg: 12, md: 12 };
        }
        let items = elements + 1;
        return { lg: 12 / items, md: items > 2 ? 6 : 12 };
    }, [groupTypes]);

    const valueFormatter = useCallback(value => value.toFixed(2) + "%", []);

    return (
        <GaugesContainer hasMoreAnimalTypes={hasMoreAnimalTypes} name={t("mortality")}>
            <Row>
                <Col {...colSizes}>
                    <GaugeItem label={t("generalMortality")} min={generalTarget[0]} max={generalTarget[1]} value={mortalities.general} valueFormatter={valueFormatter} reverse />
                </Col>
                {
                    groupTypes.hasWeaners && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("animalTypes.2")} min={weanerTarget[0]} max={weanerTarget[1]} value={mortalities.weaners} valueFormatter={valueFormatter} reverse />
                    </Col>
                }
                {
                    groupTypes.hasFinisher && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("animalTypes.3")} min={porkersTarget[0]} max={porkersTarget[1]} value={mortalities.finisher} valueFormatter={valueFormatter} reverse />
                    </Col>
                }
                {
                    groupTypes.hasGilts && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("gilt")} min={giltTarget[0]} max={giltTarget[1]} value={mortalities.gilts} valueFormatter={valueFormatter} reverse />
                    </Col>
                }
            </Row>
        </GaugesContainer>
    );
}

const SellGauges = forwardRef(({ group, hasMoreAnimalTypes, queryCaller, groupTypes, targets, targetDictionary }, ref) => {

    const { t } = useTranslation();

    const generalTarget = useMemo(() => {
        return getTargetRange("generalSellWeight", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const weanerTarget = useMemo(() => {
        return getTargetRange("pigletSellWeight", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const porkersTarget = useMemo(() => {
        return getTargetRange("porkerSellWeight", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const giltTarget = useMemo(() => {
        return getTargetRange("giltSellWeight", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const weights = useMemo(() => {
        const valueKeys = [{ key: group.AnmGrp }];
        const generalSell = getRowQuery("porker", "generalSellWeight", valueKeys);
        const tmp = { weaners: null, gilts: null, finisher: null, general: queryCaller(generalSell)?.[0]?.weight };
        if (groupTypes.hasWeaners) {
            let query = getRowQuery("porker", "pigletSellWeight", valueKeys);
            tmp.weaners = queryCaller(query)?.[0]?.weight;
        }
        if (groupTypes.hasGilts) {
            let query = getRowQuery("porker", "giltSellWeight", valueKeys);
            tmp.gilts = queryCaller(query)?.[0]?.weight;
        }
        if (groupTypes.hasFinisher) {
            let query = getRowQuery("porker", "porkerSellWeight", valueKeys);
            tmp.finisher = queryCaller(query)?.[0]?.weight;
        }
        return tmp;
    }, [groupTypes, group]); // eslint-disable-line react-hooks/exhaustive-deps

    const colSizes = useMemo(() => {
        const elements = groupTypes.hasWeaners + groupTypes.hasGilts + groupTypes.hasFinisher;
        if (elements === 1) {
            return { lg: 12, md: 12 };
        }
        let items = elements + 1;
        return { lg: 12 / items, md: items > 2 ? 6 : 12 };
    }, [groupTypes]);

    const valueFormatter = useCallback(value => {
        return convertWeightUnitTo(value, {
            unit: getAnimalUnit(),
            showUnit: true,
            fixed: 1
        });
    }, []);

    useImperativeHandle(ref, () => ({
        weights
    }));

    return (
        <GaugesContainer hasMoreAnimalTypes={hasMoreAnimalTypes} name={t("averageSellWeight")}>
            <Row>
                <Col {...colSizes}>
                    <GaugeItem label={t("generalAverageSellWeight")} min={generalTarget[0]} max={generalTarget[1]} value={weights.general} valueFormatter={valueFormatter} />
                </Col>
                {
                    groupTypes.hasWeaners && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("animalTypes.2")} min={weanerTarget[0]} max={weanerTarget[1]} value={weights.weaners} valueFormatter={valueFormatter} />
                    </Col>
                }
                {
                    groupTypes.hasFinisher && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("animalTypes.3")} min={porkersTarget[0]} max={porkersTarget[1]} value={weights.finisher} valueFormatter={valueFormatter} />
                    </Col>
                }
                {
                    groupTypes.hasGilts && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("gilt")} min={giltTarget[0]} max={giltTarget[1]} value={weights.gilts} valueFormatter={valueFormatter} />
                    </Col>
                }
            </Row>
        </GaugesContainer>
    );
});

function GainGauges({ group, groupTypes, queryCaller, hasMoreAnimalTypes, targets, targetDictionary }) {

    const { t } = useTranslation();

    const weanerTarget = useMemo(() => {
        return getTargetRange("pigletGain", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const porkersTarget = useMemo(() => {
        return getTargetRange("porkerGain", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const gains = useMemo(() => {
        const tmp = { weaners: null, finisher: null };
        const valueKeys = [{ key: group.AnmGrp }];
        if (groupTypes.hasWeaners) {
            let query = getRowQuery("porker", "pigletGain", valueKeys);
            tmp.weaners = queryCaller(query)?.[0]?.gain;
        }
        if (groupTypes.hasFinisher) {
            let query = getRowQuery("porker", "porkerGain", valueKeys);
            tmp.finisher = queryCaller(query)?.[0]?.gain;
        }
        return tmp;
    }, [group, groupTypes]); // eslint-disable-line react-hooks/exhaustive-deps

    const colSizes = useMemo(() => {
        const elements = groupTypes.hasWeaners + groupTypes.hasGilts + groupTypes.hasFinisher;
        if (elements === 1) {
            return { lg: 12, md: 12 };
        }
        let items = elements + 1;
        return { lg: 12 / items, md: items > 2 ? 6 : 12 };
    }, [groupTypes]);

    const valueFormatter = useCallback(value => {
        return convertWeightUnitTo(value, {
            unit: getAnimalUnit(),
            showUnit: true,
            fixed: 1
        });
    }, []);

    if (!groupTypes.hasWeaners && !groupTypes.hasFinisher) return null;
    return (
        <GaugesContainer hasMoreAnimalTypes={hasMoreAnimalTypes} name={t("gain")}>
            <Row>
                {
                    groupTypes.hasWeaners &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("weanerGain")} min={weanerTarget[0]} max={weanerTarget[1]} value={gains.weaners} valueFormatter={valueFormatter} />
                    </Col>
                }
                {
                    groupTypes.hasFinisher &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("finisherGain")} min={porkersTarget[0]} max={porkersTarget[1]} value={gains.finisher} valueFormatter={valueFormatter} />
                    </Col>
                }
            </Row>
        </GaugesContainer>
    );
}

function SellWeightGauges({ group, hasMoreAnimalTypes, queryCaller, groupTypes, targets, targetDictionary }) {

    const { t } = useTranslation();

    const generalTarget = useMemo(() => {
        return getTargetRange("generalSellWeightTotal", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const weanerTarget = useMemo(() => {
        return getTargetRange("pigletSellWeightTotal", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const porkersTarget = useMemo(() => {
        return getTargetRange("porkerSellWeightTotal", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const giltTarget = useMemo(() => {
        return getTargetRange("giltSellWeightTotal", targets, targetDictionary);
    }, [targetDictionary, targets]);

    const weights = useMemo(() => {
        const valueKeys = [{ key: group.AnmGrp }];
        const generalSellWeight = getRowQuery("porker", "generalSellWeightTotal", valueKeys);
        const tmp = { weaners: null, gilts: null, finisher: null, general: queryCaller(generalSellWeight)?.[0]?.weight };
        if (groupTypes.hasWeaners) {
            let query = getRowQuery("porker", "pigletSellWeightTotal", valueKeys);
            tmp.weaners = queryCaller(query)?.[0]?.weight;
        }
        if (groupTypes.hasGilts) {
            let query = getRowQuery("porker", "giltSellWeightTotal", valueKeys);
            tmp.gilts = queryCaller(query)?.[0]?.weight;
        }
        if (groupTypes.hasFinisher) {
            let query = getRowQuery("porker", "porkerSellWeightTotal", valueKeys);
            tmp.finisher = queryCaller(query)?.[0]?.weight;
        }
        return tmp;
    }, [groupTypes, group]); // eslint-disable-line react-hooks/exhaustive-deps

    const colSizes = useMemo(() => {
        const elements = groupTypes.hasWeaners + groupTypes.hasGilts + groupTypes.hasFinisher;
        if (elements === 1) {
            return { lg: 12, md: 12 };
        }
        let items = elements + 1;
        return { lg: 12 / items, md: items > 2 ? 6 : 12 };
    }, [groupTypes]);

    const valueFormatter = useCallback(value => {
        if (value < 1000000) {
            return convertWeightUnitTo(value, {
                unit: UnitTypes.MEDIUM,
                showUnit: true,
                fixed: 1
            });
        }
        return convertWeightUnitTo(value, {
            unit: UnitTypes.BIG,
            showUnit: true,
            fixed: 1
        });
    }, []);

    return (
        <GaugesContainer hasMoreAnimalTypes={hasMoreAnimalTypes} name={t("sellWeight")}>
            <Row>
                <Col {...colSizes}>
                    <GaugeItem label={t("generalSellWeight")} min={generalTarget[0]} max={generalTarget[1]} value={weights.general} valueFormatter={valueFormatter} />
                </Col>
                {
                    groupTypes.hasWeaners && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("animalTypes.2")} min={weanerTarget[0]} max={weanerTarget[1]} value={weights.weaners} valueFormatter={valueFormatter} />
                    </Col>
                }
                {
                    groupTypes.hasFinisher && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("animalTypes.3")} min={porkersTarget[0]} max={porkersTarget[1]} value={weights.finisher} valueFormatter={valueFormatter} />
                    </Col>
                }
                {
                    groupTypes.hasGilts && hasMoreAnimalTypes &&
                    <Col {...colSizes}>
                        <GaugeItem label={t("gilt")} min={giltTarget[0]} max={giltTarget[1]} value={weights.gilts} valueFormatter={valueFormatter} />
                    </Col>
                }
            </Row>
        </GaugesContainer>
    );
}

function ForageGauges({ group, hasMoreAnimalTypes, groupInfo, forageLocations, sellGauges, inserted }) {

    const { t } = useTranslation();

    const fcrRange = useRef(getFCRRange());

    const getBuildingsMap = useMemo(makeGetBuildingsMap, []);

    const buildingsMap = useSelector(getBuildingsMap);

    const forageUsage = useSelector(state => getForageUsageForDevice(state, groupInfo.start, groupInfo.end, forageLocations, groupInfo));
    const forages = useSelector(getAllForages);

    const fcr = useMemo(() => {
        if (!sellGauges || !forageUsage || !inserted) return null;
        return (forageUsage.sum / inserted) / (sellGauges.weights.finisher - group.Weight);
    }, [forageUsage, sellGauges, inserted, group]);

    const fUsage = useMemo(() => {
        if (!forageUsage) return null;
        const tmp = [];
        const foragesUsage = Object.values(forageUsage.forages);
        for (let row of foragesUsage) {
            if (row.forageID) {
                console.log(row);
                const forageName = forages.find(item => item.SetID === row.forageID)?.SetData?.Name || "unknown";
                const min = applyOffset(row.expected, -row.offset);
                const max = applyOffset(row.expected, row.offset);
                const minAll = applyOffset(row.expectedAll, -row.offset);
                const maxAll = applyOffset(row.expectedAll, row.offset);
                console.log(forageName, min, max, minAll, maxAll);
                tmp.push(
                    {
                        label: forageName === "unknown" ? t("forageDispensedUnknown") : t("forageDispensedName", { name: forageName }),
                        value: row.usage,
                        min: minAll,
                        max: maxAll,
                        id: row.forageID
                    },
                    {
                        label: forageName === "unknown" ? t("forageDispensedForPieceUnknown") : t("forageDispensedForPieceName", { name: forageName }),
                        value: row.forPiece,
                        min,
                        max,
                        id: row.forageID + "Piece"
                    }
                );
            }
        }
        return tmp;
    }, [forageUsage, forages, t]);

    const fPlcmntUsage = useMemo(() => {
        if (!forageUsage) return null;
        const usageForSType = {};
        let tmp = [];
        for (let PlcmntID in forageUsage.plcmnt) {
            const location = buildingsMap.get(PlcmntID);
            if (!usageForSType[location.SType]) usageForSType[location.SType] = { all: 0, forPiece: 0, expected: { min: 0, max: 0 }, expectedAll: { min: 0, max: 0 } };
            usageForSType[location.SType].all += forageUsage.plcmnt[PlcmntID].usage;
            usageForSType[location.SType].forPiece += forageUsage.plcmnt[PlcmntID].forPiece;
            usageForSType[location.SType].expected.min += applyOffset(forageUsage.plcmnt[PlcmntID].expected, -forageUsage.plcmnt[PlcmntID].offset);
            usageForSType[location.SType].expected.max += applyOffset(forageUsage.plcmnt[PlcmntID].expected, forageUsage.plcmnt[PlcmntID].offset);
            usageForSType[location.SType].expectedAll.min += applyOffset(forageUsage.plcmnt[PlcmntID].expectedAll, -forageUsage.plcmnt[PlcmntID].offset);
            usageForSType[location.SType].expectedAll.max += applyOffset(forageUsage.plcmnt[PlcmntID].expectedAll, forageUsage.plcmnt[PlcmntID].offset);
        }
        for (let SType in usageForSType) {
            const usage = usageForSType[SType];
            tmp.push(
                {
                    label: `${t("forageDispensed")} - ${t(`SType.${SType - 1}`)}`,
                    value: usage.all,
                    min: usage.expectedAll.min,
                    max: usage.expectedAll.max,
                },
                {
                    label: `${t("forageDispensedForPiece")} - ${t(`SType.${SType - 1}`)}`,
                    value: usage.forPiece,
                    min: usage.expected.min,
                    max: usage.expected.max,
                }
            );
        }
        return tmp;
    }, [forageUsage, buildingsMap, t]);

    const valueFormatter = useCallback(value => {
        const unit = value > 10000000 ? UnitTypes.BIG : UnitTypes.MEDIUM;
        return convertWeightUnitTo(value, {
            unit: unit,
            showUnit: true,
            fixed: 1
        });
    }, []);

    if (!inserted) return null;
    return (
        <>
            {
                fcr !== null && !isNaN(fcr) &&
                <GaugesContainer hasMoreAnimalTypes name={t("fcr")}>
                    <Row>
                        <Col xl={12}>
                            <GaugeItem label={t("fcr")} min={fcrRange.current[0]} max={fcrRange.current[1]} value={fcr} />
                        </Col>
                    </Row>
                </GaugesContainer>
            }
            {
                fUsage && fUsage.length > 0 &&
                <GaugesContainer hasMoreAnimalTypes name={t("IOT.forageConsumption")}>
                    <Row className="justify-content-center">
                        {
                            fUsage.map(row => (
                                <Col key={row.id} lg={3} md={6}>
                                    <GaugeItem label={row.label} min={row.min} max={row.max} value={row.value} valueFormatter={valueFormatter} sideToSide />
                                </Col>
                            ))
                        }
                    </Row>
                </GaugesContainer>
            }
            {
                fPlcmntUsage && fPlcmntUsage.length > 0 &&
                <GaugesContainer hasMoreAnimalTypes name={t("forageUsageForSector")}>
                    <Row className="justify-content-center">
                        {
                            fPlcmntUsage.map(row => (
                                <Col key={row.id} lg={3} md={6}>
                                    <GaugeItem label={row.label} min={row.min} max={row.max} value={row.value} valueFormatter={valueFormatter} sideToSide />
                                </Col>
                            ))
                        }
                    </Row>
                </GaugesContainer>
            }
        </>
    );
}

function WaterGauges({ hasMoreAnimalTypes, groupInfo, waterLocations, inserted }) {

    const { t } = useTranslation();

    const waterUsage = useSelector(state => getWaterUsageForDevice(state, groupInfo.start, groupInfo.end, waterLocations, groupInfo));

    const expectedWaterUsage = useSelector(state => getExpectedWaterUsageForGroup(state, groupInfo));

    const wUsage = useMemo(() => {
        if (!waterUsage) return null;
        return [
            {
                label: t("dispensedWater"),
                value: waterUsage?.usage,
                min: expectedWaterUsage.min * inserted,
                max: expectedWaterUsage.max * inserted,
                id: "waterPiece"
            },
            {
                label: t("dispensedWaterForPiece"),
                value: waterUsage?.forPiece,
                ...expectedWaterUsage,
                id: "water"
            }
        ];
    }, [waterUsage, inserted, t, expectedWaterUsage]);

    const valueFormatter = useCallback(value => {
        const unit = value > 10000000 ? UnitTypes.BIG : UnitTypes.MEDIUM;
        return convertVolumeUnitTo(value, {
            showUnit: true,
            unit: unit,
            fixed: 1
        });
    }, []);

    if (!wUsage) return null;
    return (
        <GaugesContainer hasMoreAnimalTypes name={t("charts.waterHourlyChart.consumption")}>
            <Row className="justify-content-center">
                {
                    wUsage.map(row => (
                        <Col key={row.id} md={6}>
                            <GaugeItem key={row.label} label={row.label} min={row.min} max={row.max} value={row.value} sideToSide valueFormatter={valueFormatter} />
                        </Col>
                    ))
                }
            </Row>
        </GaugesContainer>
    );
}

function EconomyItem({ label, value, valueFormatter }) {
    return (
        <Col xs={12} lg={3} className="d-flex justify-content-center align-items-center flex-column mb-2">
            <h3 className="mb-1">{valueFormatter ? valueFormatter(value) : value}</h3>
            <div>{label}</div>
        </Col>
    );
}

function EconomyData({ groupInfo, forageLocations, waterLocations, groupResults, inserted }) {

    const { t } = useTranslation();

    const getCurrency = useMemo(makeGetCurrency, []);
    const currency = useSelector(getCurrency);

    const forageCost = useSelector(state => getForageCostForDevice(state, groupInfo.start, groupInfo.end, forageLocations));
    const waterCost = useSelector(state => getWaterCostForDevice(state, groupInfo.start, groupInfo.end, waterLocations));
    const medicineCost = useSelector(getAnimalMedicineCost);

    const currencyFormatter = useCallback(value => value.toFixed(2) + " " + currency, [currency]);

    const data = useMemo(() => {
        if (!groupResults) return [];

        return [
            {
                label: t("forageCost"),
                valueAll: forageCost,
                valueAnimal: forageCost / inserted,
                show: !!forageCost,
                valueFormatter: currencyFormatter
            },
            {
                label: t("waterCost"),
                valueAll: waterCost,
                valueAnimal: waterCost / inserted,
                show: !!waterCost,
                valueFormatter: currencyFormatter
            },
            {
                label: t("medicationCost"),
                valueAll: medicineCost,
                valueAnimal: medicineCost / inserted,
                show: !!medicineCost,
                valueFormatter: currencyFormatter
            },
            // {
            //     label: t("fixedCost"),
            //     valueAll: fixedCost,
            //     valueAnimal: fixedCost / inserted,
            //     show: !!fixedCost,
            //     text: true,
            //     valueFormatter: currencyFormatter
            // },
            // {
            //     label: t("buyCost"),
            //     valueAll: buyCost,
            //     valueAnimal: buyCost / inserted,
            //     show: !!buyCost,
            //     text: true,
            //     valueFormatter: currencyFormatter
            // }
        ].filter(item => item.show);
    }, [forageCost, groupResults, waterCost, medicineCost, currencyFormatter, t, inserted]);

    if (data.length === 0) return null;

    return (
        <CollapsableContainer.Card className="economy-data" header={t("economy")} defaultExpanded>
            <fieldset className="fieldset mb-3">
                <legend>{t("total")}</legend>
                <Row>
                    {
                        data.map(({ label, valueAll, valueFormatter }, index) => (
                            <EconomyItem label={label} value={valueAll} valueFormatter={valueFormatter} key={index} />
                        ))
                    }
                </Row>
            </fieldset>
            <fieldset className="fieldset">
                <legend>{t("forPiece")}</legend>
                <Row>
                    {
                        data.map(({ label, valueAnimal, valueFormatter }, index) => (
                            <EconomyItem label={label} value={valueAnimal} valueFormatter={valueFormatter} key={index} />
                        ))
                    }
                </Row>
            </fieldset>
        </CollapsableContainer.Card>
    );
}

function GroupGaugesRenderer({ groupInfo, forageLocations, waterLocations }) {

    const { t } = useTranslation();

    const sellGauges = useRef();
    const [groupTypes, setGroupTypes] = useState({ hasWeaners: false, hasGilts: false, hasFinisher: false });
    const [loadingGroupTypes, setLoadingGroupTypes] = useState(false);

    const [inserted, setInserted] = useState(0);
    const [loadingInserted, setLoadingInserted] = useState(false);

    const group = useSelector(getSelectedAnimalForDocuments);

    const queryCaller = useQueryCaller();

    const allTargets = useSelector(getResultsTarget);

    const targets = useMemo(() => allTargets?.porker, [allTargets]);

    const targetDictionary = useMemo(() => {
        return createKeyToValueDictionary(resultTargetList, "sqlKey");
    }, []);

    useEffect(() => {
        async function fetchAnimalTypes() {
            try {
                setLoadingGroupTypes(true);
                const result = await getGroupAnimalTypes(queryCaller, group.AnmGrp);
                setGroupTypes(result);
            } catch (e) {
                console.error(e);
            } finally {
                setLoadingGroupTypes(false);
            }
        }

        async function fetchInserted() {
            try {
                setLoadingInserted(true);
                const valueKeys = [{ key: group.AnmGrp }];
                const query = getRowQuery("porker", "generalInserted", valueKeys);
                const result = await queryCaller(query);
                setInserted(result[0].amount);
            } catch (e) {
                console.error(e);
            } finally {
                setLoadingInserted(false);
            }
        }

        fetchAnimalTypes();
        fetchInserted();
    }, [group.AnmGrp, queryCaller]);

    const hasMoreAnimalTypes = useMemo(() => {
        return groupTypes.hasWeaners + groupTypes.hasGilts + groupTypes.hasFinisher > 1;
    }, [groupTypes]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loadingGroupTypes || loadingInserted} />
            <CollapsableContainer.Card header={t("chamber.separationCageInfo.fatteningDay")} defaultExpanded>
                <FatteningProgress group={group} queryCaller={queryCaller} />
            </CollapsableContainer.Card>
            <CollapsableContainer.Card header={t("results.results")} defaultExpanded>
                <Row>
                    <MortalityGauges group={group} groupTypes={groupTypes} queryCaller={queryCaller} hasMoreAnimalTypes={hasMoreAnimalTypes} targets={targets} targetDictionary={targetDictionary} />
                    <GainGauges group={group} groupTypes={groupTypes} queryCaller={queryCaller} hasMoreAnimalTypes={hasMoreAnimalTypes} targets={targets} targetDictionary={targetDictionary} />
                    <SellGauges group={group} groupTypes={groupTypes} queryCaller={queryCaller} hasMoreAnimalTypes={hasMoreAnimalTypes} ref={sellGauges} targets={targets} targetDictionary={targetDictionary} />
                    <SellWeightGauges group={group} groupTypes={groupTypes} queryCaller={queryCaller} hasMoreAnimalTypes={hasMoreAnimalTypes} targets={targets} targetDictionary={targetDictionary} />
                    <ForageGauges group={group} hasMoreAnimalTypes={hasMoreAnimalTypes} groupInfo={groupInfo} forageLocations={forageLocations} sellGauges={sellGauges.current} inserted={inserted} />
                    <WaterGauges hasMoreAnimalTypes={hasMoreAnimalTypes} groupInfo={groupInfo} waterLocations={waterLocations} inserted={inserted} />
                </Row>
            </CollapsableContainer.Card>
            <EconomyData groupInfo={groupInfo} forageLocations={forageLocations} waterLocations={waterLocations} groupResults={group} inserted={inserted} />
        </div>
    );
}

export default function GroupGauges({ groupInfo }) {

    const { t } = useTranslation();

    const firstFetchingParams = useSelector(getAnimalParametersFirstFetching);

    const forageLocations = useDeviceLocations("forage", false);
    const waterLocations = useDeviceLocations("water", false);

    const loading = useSelector(state => getGroupGaugesAggDataLoading(state, groupInfo.start, groupInfo.end, waterLocations, forageLocations));

    if (firstFetchingParams || loading) {
        return (
            <>
                <CollapsableContainer.Card header={t("chamber.separationCageInfo.fatteningDay")} defaultExpanded>
                    <div className="h-0 mh-15rem position-relative">
                        <LoadingComponent isLoading />
                    </div>
                </CollapsableContainer.Card>
                <CollapsableContainer.Card header={t("results.results")} defaultExpanded>
                    <div className="h-0 mh-15rem position-relative">
                        <LoadingComponent isLoading />
                    </div>
                </CollapsableContainer.Card>
            </>
        );
    }
    return <GroupGaugesRenderer groupInfo={groupInfo} forageLocations={forageLocations} waterLocations={waterLocations} />;
}