import {createSelector} from 'reselect';
import {get, isArray, isObject, isString} from "lodash";
import animalsDB from "../database/animalsDB";
import settingsDB from "../database/settingsDB";
import {LogTableTypes} from "../constans/logCodeTypes";
import moment from "moment";
import distance from "jaro-winkler";
import { DICTIONARY_TYPE } from "../constans/general";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import { LogParser } from "../beans/LogParser";
import i18n from "../i18n";
import {controlLists} from "../constans/controlLists";
import {getControlListName} from "../utils/ControlListsUtils";
import {getTranslationPath} from "../utils/EventUtils";
import {SettingTypes} from "../constans/settingTypes";
import {AnimalTypes} from "../constans/animalTypes";
import {TIME_FORMAT} from "../utils/DateTimeUtils";
import utilSelectors from "./utilSelectors";
import {getLogNames} from "../utils/LogViewUtils";
import {getAllGroups} from "./groupSelector";
import {getPlacementArray} from '../utils/DeviceLocationUtils';
import salesDB from '../database/salesDB';

const extraInfoRegex = /\(.*?\)/g;

export const getSimilarityRatio = (text, matchingText) => {
    const isComplexName = extraInfoRegex.test(text ?? "");
    if (isComplexName) {
        const mainPart = text.split(extraInfoRegex)[0] ?? "";
        const mainPartDistance = distance(mainPart, matchingText);
        if (mainPartDistance >= 0.8) {
            return mainPartDistance;
        }
    }
    return distance(text, matchingText, {caseSensitive: false});
};

const getBuildings = (state) =>
    state.farms.buildings;

const getFarms = (state) =>
    state.farms.farms;

const getSearchString = (state) =>
    state.logs.search;

const getStartFrom = (state) =>
    state.logs.startFrom;

const getEmployees = (state) =>
    state.user.allUsers;
const getDeletedEmployees = (state) =>
    state.user.deletedEmps;

const getSettings = (state) =>
    state.settings.settings;

const getObjectId = (state) =>
    state.logs.objectId;

const getMedicines = (state) =>
    state.dictionary.medicine;

const getMedicineDictionary = createSelector([getMedicines], (_medicineList) => {
    const dict = {};
    _medicineList.forEach((medicine) => {
        dict[medicine.WordID] = medicine;
    });
    return dict;
});

const getManageSubgroups = (state) =>
    get(state, "settings.general.SetData.Settings.Cycle.ManageSubGroups", false);
//selectory dzialaja na zmemoizowanych argumentach jesli zmieni sie time to mozna zalozyc ze w animalsDB sa najnowsze zwierzeta


const getSettingDictionary = createSelector([getSettings, utilSelectors.getFarmID], (_settings, _farmID) => {
    const dict = {};
    let allSettings = [..._settings, ...settingsDB.getAllSettings(_farmID, {showDeleted: true})];
    allSettings.forEach(setting => {
        switch (setting.SetType) {
            case SettingTypes.FILE:
                dict[setting.SetID] = i18n.t("files");
                break;
            case SettingTypes.YEARLY_PLANS:
                dict[setting.SetID] = i18n.t("settings.yearlyFermPlans");
                break;
            default:
                dict[setting.SetID] = get(setting, "SetData.Name", setting.SetID);
                break;
        }
    });
    dict.General = i18n.t("newSettings.users.changeRolesView.general");
    return dict;
});

const getPlacementDictionary = createSelector([getBuildings, getFarms], (_buildings, _farms) => {
    const dict = {};
    //farmId -> farmName
    _farms.forEach(farm => {
        dict[farm.FarmID] = farm.FarmName;
    });
    //buildings
    const createName = (name, isDeleted) => {
        return isDeleted ? `${name} (${i18n.t("deleted")})` : name;
    };
    _buildings.forEach(building => {
        dict[building.BgID] = createName(building.BName, isFinite(building.DtaDelTime));
        get(building, "Sectors", []).forEach(sector => {
            dict[sector.SID] = createName(sector.SName, isFinite(sector.DtaDelTime) || isFinite(building.DtaDelTime));
            get(sector, "Chambers", []).forEach(chamber => {
                dict[chamber.CID] = createName(chamber.CName, isFinite(chamber.DtaDelTime) || isFinite(sector.DtaDelTime) || isFinite(building.DtaDelTime));
                get(chamber, "Boxes", []).forEach(box => {
                    dict[box.BID] = createName(`${chamber.CName} - ${box.BoxesName}`, isFinite(box.DtaDelTime) || isFinite(chamber.DtaDelTime) || isFinite(sector.DtaDelTime) || isFinite(building.DtaDelTime));
                });
            });
        });
    });
    return dict;
});

const getAnimalDictionary = createSelector([utilSelectors.getAnimalDtaModTime, utilSelectors.getFarmID, getPlacementDictionary, getAllGroups, getManageSubgroups], (_time, _farmID, _buildings, _animalGroups, _manageSubgroups) => {
    const dict = {};
    const _animals = animalsDB.getAllAnimals(_farmID, undefined, true, false, true);
    _animals.forEach(animal => {
        const locationName = getLocationNameByPlacements(animal.PlcmntID, _buildings);
        const additional = [];
        if (locationName) additional.push(`${locationName}`);
        if (animal.DtaDelTime) {
            additional.length = 0;
            additional.push(`${i18n.t("removedAnimal")}: ${moment(animal.DtaDelTime).format("L")}`);
        }
        if (animal.RFID) additional.push(`${animal.RFID}`);
        if (![AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(animal.AnimalKind)) {
            dict[animal.AnmID] = additional.length ? `${animal.AnmNo1} (${additional.join(" | ")})` : `${animal.AnmNo1}`;
        } else {
            for (const group of _animalGroups) {
                if ([...group.AnmIDs, ...group.Rmvd].includes(animal.AnmID)) {
                    let name = _manageSubgroups && group.GrNo1 ? `${group.GrNo1} | ${animal.AnmNo1}` : `${group.GrNo1}`;
                    dict[animal.AnmID] = additional.length ? `${name} (${additional.join(" | ")})` : `${name}`;
                    dict[`${group.AnmGrp}-isGroup`] = JSON.stringify([...group.AnmIDs, ...group.Rmvd]);
                    break;
                }
            }
        }
    });
    return dict;
});

const getBuildingDictionary = createSelector([getBuildings], (_buildings) => {
    const dict = {};
    _buildings.forEach(building => {
        dict[building.BgID] = building.BName;
    });
    return dict;
});
const getDevices = (state) =>
    state.farmDevices.devices;

function getLocationNameByPlacements(_placementId, buildingsDict, {maxItems = 3} = {}) {
    const ids = [];
    const result = [];
    if (isString(_placementId)) {
        ids.push(_placementId);
    } else if (isArray(_placementId)) {
        _placementId.forEach(id => {
            if (isString(id)) {
                ids.push(id);
            } else if (isObject(id)) {
                ids.push(id.PlcmntID);
            }
        });
    } else if (isObject(_placementId)) {
        ids.push(_placementId.PlcmntID);
    }
    ids.forEach(id => {
        const o = buildingsDict[id];
        if (o) {
            result.push(o);
        }
    });
    return result.slice(0, maxItems).join(", ") + (result.length > maxItems ? "..." : "");
}

export const getDeviceDictionary = createSelector([getDevices, getPlacementDictionary], (_devices, _buildings) => {
    const dict = {};
    _devices.forEach(device => {
        let locationName = getLocationNameByPlacements(getPlacementArray(device), _buildings);
        dict[device.DevID] = locationName ? `${device.Name} (${locationName})` : device.Name;
        if ([DevType.SCALE, DevType.DISPENSER, DevType.MODBUS_RELAY].includes(device.DevType)) {
            for (let i = 0; i < 20; i++) {
                locationName = getLocationNameByPlacements(getPlacementArray(device, i), _buildings);
                dict[`${device.DevID}_${i}`] = locationName ? `${device.Name} {${i}} (${locationName})` : `${device.Name} {${i}}`;
            }
        }
    });
    return dict;
});

export const getGroupsDictionary = createSelector([getAllGroups], (_groups) => {
    const dict = {
        AnmID: {},
        GrID: {}
    };
    _groups.sort((o1, o2) => o1.DtaModTime - o2.DtaModTime);
    _groups.forEach(group => {
        dict.GrID[group.AnmGrp] = group.GrNo1;
        group.AnmIDs.forEach(AnmID => {
            if (!dict.AnmID[AnmID]) {
                dict.AnmID[AnmID] = group.GrNo1;
            }
        });
    });
    _groups.forEach(group => {
        group.Rmvd.forEach(AnmID => {
            if (!dict.AnmID[AnmID]) {
                dict.AnmID[AnmID] = group.GrNo1;
            }
        });
    });
    return dict;
});

export const getEmployeeDictionary = createSelector([getDeletedEmployees, getEmployees], (_deletedEmployees, _employees) => {
    const dict = {};

    _employees.forEach(emp => {
        dict[emp.LocalUserID] = emp.Name;
    });
    _deletedEmployees.forEach(emp => {
        dict[emp.LocalUserID] = `${emp?.firstname}${emp?.surname} (${i18n.t("removed")})`;
    });
    // Dodanie systemu #9174
    dict['SYSTEM'] = 'FETURA Cloud';
    return dict;
});

const getLogs = (state) =>
    state.logs.logs;

const getUserID = (state) =>
    state.logs.userId;

const getCodes = (state) =>
    state.logs.searchCode || [];

const getCodeParams = createSelector([getSearchString, getCodes], (_search, _codes) => {
    const result = {
        codes: [],
        selectedCodes: []
    };
    const search = _search.trim().toLowerCase();
    const codes = getLogNames(i18n.t);
    _codes.forEach(code => {
        result.selectedCodes.push({
            type: "codes",
            value: +`${code}`.split("_")[0],
            name: `${codes[code]}(${code})`
        });
    });
    if (search) {
        //przepiać na inny klucz
        for (let [code, name] of Object.entries(codes)) {
            if (`${name}(${code})`.toLowerCase().includes(search)) {
                result.codes.push({
                    type: "codes",
                    value: +`${code}`.split("_")[0],
                    name: `${name}(${code})`
                });
            }
        }
        console.log(Object.entries(codes), "cdParams");
    }
    return result;
});

const getSalesModTime = state => state.sales.lastModificationTime;

const getSales = createSelector(utilSelectors.getFarmID, getSalesModTime, (FarmID) => {
    return salesDB.getAllSales(FarmID);
});

const getSaleDictionary = createSelector([getSales], (_sales) => {
    const dict = {};
    _sales.forEach(({SaleID, SaleDta}) => {
        dict[SaleID] = moment(SaleDta).format(TIME_FORMAT.DATE);
    });
    return dict;
});

const getEmployeeParams = createSelector([getSearchString, getUserID, getEmployeeDictionary], (_search, _userId, _employees) => {
    const result = {
        users: [],
        selectedUser: null
    };
    const search = _search.trim().toLowerCase();
    if (_userId && _employees[_userId]) {
        result.selectedUser = {
            type: "user",
            value: _userId,
            name: _employees[_userId]
        };
    }


    if (search) {
        for (let [id, name] of Object.entries(_employees)) {
            if (`${name}`.toLowerCase().includes(search)) {
                result.users.push({
                    type: "user",
                    value: id,
                    name: name,
                });
            }
        }
    }
    result.users.sort((o1, o2) => getSimilarityRatio(o2.name, search) - getSimilarityRatio(o1.name, search));
    return result;
});


const getDateParams = createSelector([getSearchString, getStartFrom], (_search, _startFrom) => {
    const search = _search.trim().toLowerCase();
    const result = {
        selectedDate: null,
        dates: []
    };
    if (search) {
        const dateFormats = ["H:mm", "D.M.YY H:mm", "H:mm D.M.YY", "D.M H:mm", "H:mm D.M", "D.M.YY", "D.M", "D"];
        for (let format of dateFormats) {
            const tmp = moment(search, format, true);
            if (tmp.isValid()) {
                result.dates.push({
                    type: "date",
                    name: +tmp.clone().startOf("day") === +tmp ? tmp.format("DD.MM.YY") : tmp.format("DD.MM.YY HH:mm"),
                    value: +tmp.clone().startOf("day") === +tmp ? +tmp.endOf("day") : +tmp.endOf("minute")
                });
                break;
            }
        }
    }
    if (_startFrom) {
        const tmp = moment(_startFrom);
        result.selectedDate = {
            type: "date",
            name: +tmp.clone().endOf("day") === +tmp ? tmp.format("DD.MM.YY") : tmp.format("DD.MM.YY HH:mm"),
            value: +tmp
        };
    }
    return result;
});


const getDictionaries = (state) => state.dictionary;

export const getDictionaryDictionary = createSelector([getDictionaries], (_dictionaries) => {
    const result = {};
    result.NAMES = {
        MEDICINE: {},
        CONTROLLIST: {},
        DICTIONARY: {},
        INGREDIENT: {},
        GRAFTINGPROGRAM: {}
    };
    Object.values(DICTIONARY_TYPE).forEach(dictKey => {
        result[dictKey] = {};
        const specialKeys = [DICTIONARY_TYPE.medicine, DICTIONARY_TYPE.controlLists, DICTIONARY_TYPE.forageIngredient, DICTIONARY_TYPE.graftingProgram];
        const specialPaths = ["medicine", "controlLists", "forageIngredients", "graftingPrograms"];
        const key = specialPaths[specialKeys.findIndex(o => o === dictKey)] || "someNonExistingKey";
        switch (key) {
            case "graftingPrograms": {
                const arr = get(_dictionaries, key, []);
                arr.forEach(item => {
                    result[dictKey][item.WordID] = item.WData.Name;
                    result.NAMES.GRAFTINGPROGRAM[item.WordID] = item.WData.Name;
                });
                break;
            }
            case "forageIngredients": {
                const arr = get(_dictionaries, key, []);
                arr.forEach(item => {
                    result[dictKey][item.WordID] = item.WData.Name;
                    result.NAMES.INGREDIENT[item.WordID] = item.WData.Name;
                });
                break;
            }
            case "medicine": {
                const arr = get(_dictionaries, key, []);
                arr.forEach(item => {
                    result[dictKey][item.WordID] = item.WData.Name;
                    result.NAMES.MEDICINE[item.WordID] = item.WData.Name;
                });
                break;
            }
            case "controlLists": {
                Object.values(controlLists).forEach(clKey => {
                    const name = getControlListName(clKey);
                    result[dictKey][clKey] = name;
                    result.NAMES.CONTROLLIST[clKey] = name;
                });
                break;
            }
            default: {
                for (let dict of Object.values(_dictionaries)) {
                    if (isObject(dict) && !isArray(dictKey)) {
                        if (dict.Type === dictKey) {
                            const arr = get(dict, `WData`, []);
                            arr.forEach(item => {
                                result[dictKey][item.ID] = item.Value;
                            });
                            const translationPath = getTranslationPath(dict.Type);
                            if (translationPath) {
                                result.NAMES.DICTIONARY[dict.WordID] = i18n.t(translationPath);
                            }
                        }
                    }
                }
                break;
            }

        }
    });
    return result;
});


const getObjectParams = createSelector([getSearchString, getObjectId, getDeviceDictionary, getAnimalDictionary, getDictionaryDictionary, getSettingDictionary, getBuildingDictionary], (_search, _objectId, _devices, _animals, _dictionaries, _settings, _buildings) => {
    const result = {
        selectedObject: null,
        objects: []
    };
    const types = [LogTableTypes.DEVICES, LogTableTypes.ANIMALS, LogTableTypes.DICTIONARY, "Medicine", "ControlList", "Ingredient", "GraftingProgram", LogTableTypes.SETTINGS, LogTableTypes.BUILDINGS];
    const search = _search.trim().toLowerCase();
    [_devices, _animals, _dictionaries.NAMES.DICTIONARY, _dictionaries.NAMES.MEDICINE, _dictionaries.NAMES.CONTROLLIST, _dictionaries.NAMES.INGREDIENT, _dictionaries.NAMES.GRAFTINGPROGRAM, _settings, _buildings].forEach((map, index) => {
        Object.entries(map).forEach(([id, name = ""]) => {
            if (id === _objectId) {
                result.selectedObject = {
                    type: types[index],
                    name: name,
                    value: id
                };
            }
            if (search && `${name}`.toLowerCase().includes(search)) {
                result.objects.push({
                    type: types[index],
                    name: name,
                    value: id
                });
            }
        });
    });
    result.objects.sort((o1, o2) => getSimilarityRatio(o2.name, search) - getSimilarityRatio(o1.name, search));
    return result;
});

const getSearchStaticParams = createSelector([getObjectParams, getEmployeeParams, getCodeParams], (objectParams, userParams, codeParams) => {
    const newObjectParams = {...objectParams};
    newObjectParams.objects = newObjectParams.objects.filter(({value, type}) => {
        console.log(value, type);
        if (type !== LogTableTypes.ANIMALS) return true;
        return !value.endsWith("-isGroup");
    })
    return {...newObjectParams, ...userParams, ...codeParams};
});

export const getSearchParams = createSelector([getSearchStaticParams, getDateParams], (staticParams, dateParams) => {
    return {...staticParams, ...dateParams};
});

export const getKeyValueLogDict = createSelector(
    [getDictionaryDictionary, getDeviceDictionary, getPlacementDictionary, getAnimalDictionary, getEmployeeDictionary, getGroupsDictionary, getSettingDictionary, getBuildingDictionary, getSaleDictionary, getMedicineDictionary], (_dictionaries, _devices, _placements, _animals, _employees, _groups, _settings, _buildings, _sales, _medicines) => ({
        devices: _devices,
        placements: _placements,
        buildings: _buildings,
        animals: _animals,
        employees: _employees,
        dictionaries: _dictionaries,
        groups: _groups,
        settings: _settings,
        sales: _sales,
        medicines: _medicines
    })
);
export const getParsedLogs = createSelector(
    [getLogs, getUserID, getObjectId, getStartFrom, getSearchString, getKeyValueLogDict],
    (_logs, _userId, _objectId, _startFrom, _search, _dictionaries) => {
        const objectIdCondition = (log) => _objectId ? log.ObjID === _objectId : true;
        const startFromCondition = (log) => _startFrom ? log.DtaCrtTime <= _startFrom : true;
        const userIdCondition = (log) => _userId ? log.UserID === _userId : true;
        return _logs.filter(log => objectIdCondition(log) && startFromCondition(log) && userIdCondition(log)).map(log => ({
            log: log, ...LogParser.createLogParser(log, _dictionaries, true).getData()
        }));
    }
);


