import React, { Component } from 'react';
import moment from "moment";
import "./_table-cell-animal-location.scss";
import Tooltip from "../../tooltip/Tooltip";
import { connect } from "react-redux";
import { makeGetBuildingsMap } from "../../../../selectors/buildingsSelector";
import { formatLocationName } from "../../../../utils/global-formatters/formatLocationName";

function makeMapStateToProps() {
    let getBuildingsMap = makeGetBuildingsMap();
    return (state) => ({
        buildingsMap: getBuildingsMap(state)
    });
}

class TableCellAnimalLocation extends Component {
    render() {
        const { row, column: { key }, buildingsMap, error, warning } = this.props;
        let name = row[key].AnmNo1;
        if (row[key].DtaDthTime) name += ` ✝ (${moment(row[key].DtaDthTime).format("L")})`;
        let plcmntID = row[key].PlcmntID;
        return (
            <div className={`table-cell-animal-location${(error || warning) ? " has-error" : ""}`}>
                <div><div>{name}</div></div>
                <Tooltip tooltipContent={formatLocationName(plcmntID, { buildingsMap, nameDeep: 3 })}>
                    <div><i className="fas fa-map-marker-alt" /></div>
                </Tooltip>
            </div>
        );
    }
}

export default connect(makeMapStateToProps)(TableCellAnimalLocation);