import pako from "pako";

export function decompressData(data) {
    return JSON.parse(
        pako.ungzip(
            pako.inflate(
                Array.isArray(data)
                    ? data.reduce((prev, next) => prev + next, "")
                    : data
            ),
            {
                to: "string",
            }
        )
    );
}

export function compressData(data, level = 9) {
    try {
        return pako.deflate(pako.gzip(JSON.stringify(data), {
            level,
        }), {
            level,
            to: "string",
        });
    } catch (e) {
        console.error(`Error while compressing data: ${e}`);
    }
    return null;
}