export const focus = (_id = null) => {
    return (dispatch) => {
        dispatch({
            type: "SET_FOCUS_FARM_MAP",
            payload: _id
        })
    }
}

export const changeLevel = (_selectedLevel = 0) => {
    return (dispatch) => {
        dispatch({
            type: "SET_SELECTED_LEVEL_FARM_MAP",
            payload: _selectedLevel
        })
    }
}

export const initializeFarmMap = (_levels = [], _size = {height: 1000, width: 1000}) => {
    return (dispatch) => {
        dispatch({
            type: "INITIALIZE_FARM_MAP",
            payload: {levels :_levels, size : _size}
        })
    }
}
export const destroyFarmMap = () => {
    return (dispatch) => {
        dispatch({
            type: "DESTROY_FARM_MAP"
        })
    }
}

export const add = (_item = {}) => {
    return (dispatch) => {
        dispatch({
            type: "ADD_FARM_MAP_ITEM",
            payload: _item
        })
    }
}

export const change = (_item = {}) => {
    return (dispatch) => {
        dispatch({
            type: "CHANGE_FARM_MAP_ITEM",
            payload: _item
        })
    }
}
export const changeBulk = (_items = []) => {
    return (dispatch) => {
        dispatch({
            type: "CHANGE_BULK_FARM_MAP_ITEM",
            payload: _items
        })
    }
}

export const remove = (_id = null) => {
    return (dispatch) => {
        dispatch({
            type: "REMOVE_FARM_MAP_ITEM",
            payload: _id
        })
    }
}

export const restore = () => {
    return (dispatch) => {
        dispatch({
            type: "HISTORY_GO_BACK"
        })
    }
}

export const addLevel = (_levelIndex = null) => {
    return (dispatch) => {
        dispatch({
            type: "ADD_LEVEL_FARM_MAP",
            payload: _levelIndex
        })
    }
}

export const removeLevel = (_levelIndex = null) => {
    return (dispatch) => {
        dispatch({
            type: "REMOVE_LEVEL_FARM_MAP",
            payload: _levelIndex
        })
    }
}


export const resizeCanvas = (_width, _height, _align) => {
    return (dispatch) => {
        dispatch({
            type: "RESIZE_CANVAS_FARM_MAP",
            payload: {width: _width, height: _height, align: _align}
        })
    }
}

export function changeMapSettings(
    data = {
        amountOfAnimals: true,
        groups: true,
        climate: true,
        electric: true,
        water: true,
        diode: true,
        light: true,
    }
) {
    return function (dispatch) {
        dispatch({
            type: "MAP_CHANGE_SETTINGS",
            payload: {mapSettings: data},
        });
    };
}