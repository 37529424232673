import moment from "moment";
import { editAnimalSubmit } from "../edit-animal-basics/EditAnimalBasicsModalSubmit";
import { prepareAnimalObjectToSave } from "../../../utils/AnimalsUtils";

export function submit(values, dispatch, props) {
    const { animal } = props;
    const { AnmCnt, DtaBrthTime } = values;
    let animalToSave = prepareAnimalObjectToSave({ ...animal, AnmCnt, DtaBrthTime });
    return editAnimalSubmit(animalToSave, props);
}

export function validate(values, props) {
    const errors = {};
    const { animal, isService, t } = props;
    if (!animal) return errors;
    if (animal.AnmCnt > 1 && !animal.RFID) {
        if (!values.AnmCnt) errors.AnmCnt = t("required");
    } else {
        if (!values.DtaBrthTime) errors.DtaBrthTime = t("required");
        else {
            if (!isService && moment(values.DtaBrthTime).isAfter(new Date(), "days")) {
                errors.DtaBrthTime = t("errors.doNotInsertEventInFuture");
            }
            if (animal.DtaInTime) {
                const date = moment(animal.DtaInTime).subtract(1, "day").format("L");
                if (moment(values.DtaBrthTime).isSameOrAfter(moment(animal.DtaInTime), "days")) {
                    errors.DtaBrthTime = t("errors.dateAfter", { date });
                }
            }
        }
    }
    return errors;
}