import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {get, pick} from "lodash";
import {getMapData, makeGetAlertsData, makeGetDiodeCounter} from "../../../selectors/farmMapSelector";
import {Browser} from "../../basics/browser-detection/Browser";
import {getClosestValueByMinMaxAndStep} from "../../../utils/MathUtils";
import Text from "./Text";


const TypesWhichShouldApplyAngle = ["standings", "groups", "animals", "devices", 'group'];

const TypesWhichShouldSnapAngle = ["chambers", "buildings", "sectors"];

const useData = (id, type, animals, devices) => {

    const getAlertsData = useMemo(() => makeGetAlertsData(), []);
    const getDiodeCounter = useMemo(() => makeGetDiodeCounter(), []);

    const mapData = useSelector(getMapData);

    const devicesWithShadow = useMemo(() => {
        for (let row of devices) {
            const {device, Index} = row;
            switch (Index?.length ?? 0) {
                case 0: {
                    const tmp = mapData[device.DevID];
                    row.shadow = tmp;
                    break;
                }
                case 1: {
                    const tmp = mapData[`${device.DevID}_${Index[0]}`];
                    row.shadow = tmp;
                    break;
                }
                default: {
                    row.shadow = [];
                    Index.forEach((index) => {
                        const tmp = mapData[`${device.DevID}_${index}`];
                        row.shadow.push(tmp);
                    });
                }
            }
        }
        return devices;
    }, [mapData, devices]);

    const animalsWithShadow = useMemo(() => {
        for (const animal of animals) {
            if (animal.AnmID) {
                animal.shadow = mapData[animal.AnmID];
            }
        }
        return animals;
    }, [animals, mapData]);

    const alerts = useSelector((state) => getAlertsData(state, id, type));
    const diodeCounter = useSelector((state) => getDiodeCounter(state, id, type));

    return useMemo(() => {
        return {
            devices: devicesWithShadow,
            animals: animalsWithShadow,
            alerts,
            diodeCounter
        }
    }, [alerts, diodeCounter, animalsWithShadow, devicesWithShadow])

}


const Element = ({
                     element,
                     onBeforeRender,
                     showSmalls,
                     hasAlarmsInChildren,
                     rotate
                 }) => {

    const {animals, devices, alerts, diodeCounter} = useData(element.id, element.type, element.animals, element.devices)

    const {
        fill,
        disabled,
        text,
        show,
        className,
        override = {},
        rotateObject = 0
    } = useMemo(() => onBeforeRender({
        object: element,
        devices,
        animals,
        alerts,
        showSmalls,
        hasAlarmsInChildren,
        diodeCounter
    }), [onBeforeRender, element, devices, animals, alerts, showSmalls, hasAlarmsInChildren, diodeCounter]);

    const angle = override.angle || TypesWhichShouldApplyAngle.includes(element.type) ? get(element, "_angle.angle", 0) : 0;

    const transform = useMemo(() => ({
        angle: override.angle || angle,
        originX: override.transformOriginX || (angle ? element._angle.x : 0),
        originY: override.transformOriginY || (angle ? element._angle.y : 0)
    }), [override, angle, element]);

    const elementProps = useMemo(() => ({
        ...pick(element, ["fill", "id", "cx", "cy", "r", "d"]),
        fill,
        disabled,
        show,
        clipPath: `url(#mask-${element.id})`,
        style: rotateObject ? {
            transform: `rotate(${rotateObject}deg) translateZ(0)`,
            transformOrigin: `${(element.rect.x2 + element.rect.x1) / 2}px ${(element.rect.y2 + element.rect.y1) / 2}px`
        } : null
    }), [rotateObject, element, fill, show, disabled]);

    const newRotate = useMemo(() => {
        let _rotate = TypesWhichShouldApplyAngle.includes(element.type) ? transform.angle + rotate : rotate;
        if (!Browser.is.Chrome()) {
            if (element._displayMode) {
                _rotate = 0;
            }
        }
        if (TypesWhichShouldSnapAngle.includes(element.type)) {
            _rotate = getClosestValueByMinMaxAndStep(_rotate, -900, 900, 90);
        }
        return _rotate;
    }, [rotate, transform, element]);

    if (!show) return null;

    return (
        <g transform={`rotate(${transform.angle} ${transform.originX} ${transform.originY})`}
           className={`map-wrapper ${className}`}>
            <ClipPath element={element}/>
            <ElementBody {...elementProps} nodeName={element.nodeName}/>
            {
                !!text && <Text text={text} rect={element.rect}
                                rotate={newRotate}/>
            }

        </g>
    )

}

const ClipPath = React.memo(({element}) => {
    return (
        <clipPath id={`mask-${element.id}`} className={"map-wall"}>
            {React.createElement(element.nodeName, {...pick(element, ["cx", "cy", "r", "d"]), fill: "white"})}
        </clipPath>
    )
});

const ElementBody = React.memo(({nodeName, ...props}) => {
    return React.createElement(nodeName, props);

})

export default React.memo(Element);