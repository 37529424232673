import BugsnagPerformance, { DefaultRoutingProvider } from '@bugsnag/browser-performance';
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getFarmID } from "../../selectors/farmSelector";
import store from "../../store/store";
import { cloneFast } from "../Utils";
import lokiDB from "../../database/lokiDB";
import "../../i18n";
import versionJson from "../../version.json";

const stage = process.env.REACT_APP_STAGE || "local";

const bugsnagReleaseStages = [
    "development",
    "staging",
    "production",
    // "local",
];

export const bugsnagClient = Bugsnag.start({
    apiKey: "1070bf20154ed0c3a5895d8f12ff58d2",
    plugins: [new BugsnagPluginReact()],
    onError: async event => {
        if (discardBugsnagError(event)) return false;
        let state = store.getState();
        let userData = {};
        // wstawianie danych usera
        try {
            userData = {
                ClientID: state.user.user?.ClientID,
                LocalUserID: state.user.user?.LocalUserID,
                FarmID: state.location.farm,
                email: state.user.attributes?.email,
                login: state.user.attributes?.login,
            };
        } catch (e) {
            console.error(e);
        }
        event.addMetadata("user", userData);
        // wstawianie danych lokiego
        let lokiData = {};
        try {
            let collections = lokiDB.db.listCollections();
            for (let collection of collections) {
                const { name } = collection;
                const col = lokiDB.db.getCollection(name);
                if (name === "cachedTablesParams") {
                    lokiData[name] = { amount: col.data.length, data: col.data };
                } else {
                    lokiData[name] = { amount: col.data.length };
                }
            }
        } catch (e) {
            console.error(e);
        }
        event.addMetadata("loki", lokiData);
        // obliczanie dysku
        try {
            let estimatedQuota = await navigator.storage.estimate();
            event.addMetadata("capacity", {
                ...estimatedQuota,
                percent: (estimatedQuota.usage / estimatedQuota.quota) * 100,
            });
        } catch (e) {
            console.error(e);
        }
        // status połączenia
        event.addMetadata("connection status", {
            online: state.online.status,
        });
        // mqtt
        event.addMetadata("mqtt", {
            connected: state.mqtt.connected,
            connecting: state.mqtt.connecting,
            reconnecting: state.mqtt.reconnecting,
            gatewayConnection: [...state.mqtt.status.values()].map((item) => ({
                ...item,
                compilation: isFinite(item.compilation)
                    ? new Date(item.compilation).toISOString()
                    : "-",
            })),
        });
    },
    appVersion: versionJson.version,
    releaseStage: stage,
    enabledReleaseStages: bugsnagReleaseStages,
    autoTrackSessions: true,
    maxBreadcrumbs: 40,
    maxEvents: 50,
    enabledBreadcrumbTypes: ["error", "navigation", "request", "user"],
});

BugsnagPerformance.start({
    apiKey: "1070bf20154ed0c3a5895d8f12ff58d2",
    appVersion: versionJson.version,
    releaseStage: stage,
    enabledReleaseStages: bugsnagReleaseStages,
    routingProvider: new DefaultRoutingProvider(routingResolver)
});

const errorsToDiscard = [
    "Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'",
    "The user aborted a request.",
    "Fetch is aborted",
    "Unmount",
    "NetworkError when attempting to fetch resource.",
    "NetworkError: A network error occurred.",
    "Unmount of component",
    "Failed to fetch",
    "task_timeout",
    "Start fetching again"
];

const importScriptsRegex = /Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'.*/;
const iosNoResponseRegex = /^FetchEvent\.respondWith received an error: no-response: no-response.*/;
const urlRegex = /^http:\/\/192\..*/;

/**
 * 
 * @param {*} ev 
 * @returns true if the event should be discarded
 */
export const discardBugsnagError = (event) => {
    const ev = cloneFast(event);
    console.log(`Discarding general ${JSON.stringify(ev, null, 2)}`);
    console.log(`Discarding general2 ${ev}`);
    // always discard errors when the environment is local
    if (window.location.hostname === 'localhost' || urlRegex.test(window.location.href)) {
        console.log("Discarded because of local environment");
        return true;
    }
    // check if the passed event is a bugsnag error object
    if (ev?.exceptions?.length > 0) {
        for (let err of ev.exceptions) {
            const bugsnagErrorMessage = err?.errorMessage;
            console.log(`Discarding in if bugsnagErrorMessage: ${bugsnagErrorMessage}`);
            if (
                errorsToDiscard.includes(bugsnagErrorMessage) ||
                importScriptsRegex.test(bugsnagErrorMessage) ||
                iosNoResponseRegex.test(bugsnagErrorMessage)
            ) {
                console.log(`Discarded in if bugsnagErrorMessage: ${bugsnagErrorMessage}`);
                return true;
            }
        }
    }
    // if the event is not a bugsnag error object check for common error fields
    else {
        const errorMessage = ev?.message;
        const error = ev?.error;
        console.log(`Discarding in else errorMessage: ${errorMessage} error: ${error}`);
        if (
            errorsToDiscard.includes(errorMessage) ||
            importScriptsRegex.test(errorMessage) ||
            iosNoResponseRegex.test(errorMessage) ||
            errorsToDiscard.includes(error) ||
            importScriptsRegex.test(error) ||
            iosNoResponseRegex.test(error)
        ) {
            console.log(`Discarded in else errorMessage: ${errorMessage} error: ${error}`);
            return true;
        }
    }
    return false;
};

export function routingResolver(url) {
    const state = store.getState();
    const farm = getFarmID(state);
    const user = state.user.user;
    const newUrl = url.pathname.replace(farm, ":FarmID").replace(user.ClientID, ":ClientID").replace(user.LocalUserID, ":LocalUserID");
    console.log("Bugsnag URL: ", url, newUrl);
    return newUrl;
}