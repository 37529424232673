import { invert, isArray, isNil, isString, memoize } from "lodash";
import memoizeOne from "memoize-one";
import { createSelector } from "reselect";
import { PIG_COLOR } from "../components/farm-map-components/patterns/utils";
import { AlertList } from "../components/farm-map/components/drawer-content/beans/AlertList";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import { Level } from "../constans/levelTypes";
import { mapSQL, mapSQLColors } from "../constans/sqlSelects";
import { isNutriPro, isRFID } from "../utils/DispenserNRFUtils";
import { getVolume } from "../utils/SiloRadarUtils";
import { createKeyToValueDictionary } from "../utils/Utils";
import { convertBuildingsToOldObject } from "../utils/dbutils/convertBuildings";
import { getMapSQLData, getMapSQLResult } from "./animalParametersSelector";
import { getActiveAnimals } from "./animalSelector";
import { makeGetBuildingsMap } from "./buildingsSelector";

const getDevices = (state) =>
    state.farmDevices.devices;

const getData = (state) => state.farmMapData.data;

const getExtraShadowParams = memoizeOne((devices) => {
    const extraParams = {};
    for (let device of devices) {
        if (device.DevType === DevType.SCALE) {
            device.Siloses.forEach((silo, index) => {
                if (silo.Capacity) {
                    extraParams[`${device.DevID}_${index}}`] = { Capacity: silo.Capacity };

                }
            });
        }
        if (device.DevType === DevType.DISPENSER_NRF) {
            extraParams[device.DevID] = {
                Configuration: isRFID(device) ? "RFID" : isNutriPro(device) ? "IPSUM" : "STD"
            };
        }
        if (device.DevType === DevType.SILO_RADAR) {
            extraParams[device.DevID] = {
                Capacity: getVolume(device)
            };
        }
    }
    return extraParams;

});

export const getMapData = createSelector([getDevices, getData], (devices, data) => {
    const extraParams = getExtraShadowParams(devices);
    const newData = { ...data };
    Object.keys(extraParams).forEach(key => {
        if (newData[key]) {
            newData[key] = { ...extraParams[key], ...newData[key] };
        }
    });
    return newData;
});


const getParams = memoize((state, id, type) => {
    return {
        id,
        type
    };
}, (...args) => `${args[1]}_${args[2]}`);

const getBuildings = (state) =>
    state.farms.buildings;

const filterRemovedLocations = memoizeOne(convertBuildingsToOldObject);

/**
 * return true to omit showing alerts on chamber
 * @param device
 * @return {boolean}
 */
const isDeviceTreatedAsAnotherEntity = (device) => {
    if ((isRFID(device))) return true;
    return false;
};

const createPlacementToDeviceDictionary = memoizeOne((_devices) => {
    const map = {};
    for (let device of _devices) {
        // panele wagowe nie sa na budynkach tylko jako osobna rzecz
        if ([DevType.SCALE, DevType.SILO_RADAR].includes(device.DevType) || isDeviceTreatedAsAnotherEntity(device)) continue;
        if (isArray(device.PlcmntID)) {
            for (let { PlcmntID, Adr } of device.PlcmntID) {
                const key = isNil(Adr) ? device.DevID : `${device.DevID}_${Adr}`;
                if (!map[PlcmntID]) map[PlcmntID] = {};
                map[PlcmntID][key] = true;
            }
        }
    }
    return map;
});

const createPlacementToDeviceChildrenDictionary = memoizeOne((_devices) => {
    const map = {};
    for (let device of _devices) {
        if (isDeviceTreatedAsAnotherEntity(device)) {
            if (isArray(device.PlcmntID)) {
                for (let { PlcmntID, Adr } of device.PlcmntID) {
                    const key = isNil(Adr) ? device.DevID : `${device.DevID}_${Adr}`;
                    if (!map[PlcmntID]) map[PlcmntID] = {};
                    map[PlcmntID][key] = true;
                }
            }
        }
    }
    return map;
});

const createPlacementChildrenDictionary = memoizeOne((_buildings) => {
    const map = {};
    const levelMap = {};
    const buildings = filterRemovedLocations(_buildings);
    const pushToMap = (id, chId) => {
        if (!map[id]) map[id] = {};
        map[id][chId] = true;
    };
    const setLevelForId = (id, level) => {
        if (!levelMap[id]) levelMap[id] = level;
    };
    buildings.forEach((b) => {
        setLevelForId(b.BgID, Level.BUILDING);
        (b.Sectors || []).forEach((s) => {
            setLevelForId(s.SID, Level.SECTOR);
            pushToMap(b.BgID, s.SID);
            (s.Chambers || []).forEach((c) => {
                setLevelForId(c.CID, Level.CHAMBER);
                pushToMap(s.SID, c.CID);
                pushToMap(b.BgID, c.CID);
                (c.Boxes || []).forEach((bx) => {
                    setLevelForId(bx.BID, Level.BOX);
                    pushToMap(c.CID, bx.BID);
                    pushToMap(s.SID, bx.BID);
                    pushToMap(b.BgID, bx.BID);
                });
            });
        });
    });
    return { map, levelMap };
});

const createPlacementAnimalDictionary = memoizeOne((_animals) => {
    const map = {};
    const pushToMap = (id, chId) => {
        if (!map[id]) map[id] = {};
        map[id][chId] = true;
    };
    _animals.forEach(({ AnmID, PlcmntID }) => {
        if (isString(AnmID) && isString(PlcmntID)) {
            pushToMap(PlcmntID, AnmID);
        }
    });
    return map;
});


const getChildrenIDs = (placementId, deviceDict, animalDict, locationDict, buildingsMap) => {
    const ids = [];
    const children = Object.keys(locationDict[placementId] || {});
    for (let id of children) {
        ids.push(...Object.keys(deviceDict[id] || {}));
        // nie chcemy mieć błędów ze zwierząt na stanowiskach
        if (!buildingsMap || (buildingsMap.get(id).level !== Level.BOX)) {
            ids.push(...Object.keys(animalDict[id] || {}));
        }
    }
    return ids;
};

const getParentIDs = (placementId, deviceDict, animalDict) => {
    const ids = [];
    ids.push(...Object.keys(deviceDict[placementId] || {}));
    ids.push(...Object.keys(animalDict[placementId] || {}));
    return ids;
};

const getDictionaries = createSelector([getDevices, getBuildings, getActiveAnimals], (devices, buildings, animals) => {
    const deviceDict = createPlacementToDeviceDictionary(devices);
    const deviceChildrenDict = createPlacementToDeviceChildrenDictionary(devices);
    const animalDict = createPlacementAnimalDictionary(animals);
    const { map: locationDict, levelMap } = createPlacementChildrenDictionary(buildings);
    return {
        deviceDict,
        deviceChildrenDict,
        animalDict,
        locationDict,
        levelDict: levelMap
    };
});

const getBuildingsMap = makeGetBuildingsMap();

export const makeGetAlertsData = () => {
    return createSelector([getMapData, getDictionaries, getParams, getBuildingsMap], (data, dict, {
        id,
        type
    }, buildingsMap) => {
        const {
            deviceDict,
            deviceChildrenDict,
            animalDict,
            locationDict
        } = dict;
        const result = {};
        result.warnings = new AlertList();
        result.errors = new AlertList();
        result.isWarningInChildren = false;
        result.isErrorInChildren = false;
        const setError = (_data) => {
            if (_data && _data.Alerts && _data.Alerts.length) {
                for (let code of _data.Alerts) {
                    result.errors.addAlert(code, _data._type, id);
                }
            }
        };

        const setWarning = (_data) => {
            if (_data && _data.Warnings && _data.Warnings.length) {
                for (let code of _data.Warnings) {
                    result.warnings.addAlert(code, _data._type, id);
                }
            }
        };
        switch (type) {
            case "groups":
            case "animals":
            case "devices":
                setError(data[id]);
                setWarning(data[id]);
                break;
            default:
                const placement = buildingsMap.get(id);
                const parentIds = getParentIDs(id, deviceDict, {});
                for (let id of parentIds) {
                    setError(data[id]);
                    setWarning(data[id]);
                }
                if (placement?.level === Level.BOX) {
                    // Żeby nie mieszać błędów z RFID oraz karmienia standardowego
                    // na stanowiskach pomijamy sprawdzanie błędów 'niżej' tzn. na zwierzętach,
                    result.isErrorInChildren = false;
                    result.isWarningInChildren = false;
                } else {
                    const childrenIds = [...getParentIDs(id, deviceChildrenDict, animalDict), ...getChildrenIDs(id, deviceDict, animalDict, locationDict, buildingsMap)];
                    result.isErrorInChildren = childrenIds.some(id => data[id]?.Alerts?.length);
                    result.isWarningInChildren = childrenIds.some(id => data[id]?.Warnings?.length);
                }
                break;
        }
        return result;
    });
};


export const makeGetDiodeCounter = () => {
    return createSelector([getMapData, getDevices, getBuildings, getParams], (data, devices, buildings, {
        id,
        type
    }) => {
        if (type === "chambers") {
            const deviceDict = createPlacementToDeviceDictionary(devices);
            const locationDict = createPlacementChildrenDictionary(buildings);
            const parentIds = getParentIDs(id, deviceDict, {});
            let counter = 0;
            for (let id of parentIds) {
                counter += +!!data[id]?.LED;
            }
            const childrenIds = [...getChildrenIDs(id, deviceDict, {}, locationDict)];
            for (let id of childrenIds) {
                counter += +!!data[id]?.LED;
            }
            return counter;
        }
        return 0;
    });
};

export const getAnimalCount = createSelector(getMapSQLResult, animals => animals.length);

/**
 * zostawiam na prośbe Daniela dla Marcina:
 * zwróć funkcję która dla danego AnmID zwróci kolor dostępny z PIG_COLOR
 * komponent od kontrolwania filtrów na mapie najlepiej wrzuć w FilterAnimalControl.js
 * jakieś podsumoanie filtrów dla komory/stanowiska/zwierzęcia wrzuć w FilterAnimalDrawerContent.js
 *
 * @type {OutputSelector<unknown, (function(): string|null) & MemoizedFunction, (res: *) => ((function(): string|null) & MemoizedFunction)>}
 */
export const getAnimalColorFunc = createSelector(getMapSQLResult, getMapSQLData, (animals, { sql }) => {
    const inverted = invert(mapSQL);
    const current = inverted[sql];
    let dict = createKeyToValueDictionary(animals, "AnmID");
    const color = mapSQLColors[current] || PIG_COLOR.INFO;
    return (AnmID) => {
        if (typeof color === "string") return dict.hasOwnProperty(AnmID) ? color : null;
        return dict.hasOwnProperty(AnmID) ? color(dict[AnmID]) : null;
    };
});