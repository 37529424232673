import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import i18n from "i18next";
import { findLast, first, get, isNil, last, memoize, minBy, pick, uniq, uniqBy } from "lodash";
import memoizeOne from "memoize-one";
import moment from "moment";
import { createSelector } from "reselect";
import { USG_STATE } from "../constans/eventTypes";
import { GraftingProgramApplicationTypes } from "../constans/graftingProgramApplicationTypes";
import { GRAFTING_RESULT } from "../constans/graftingResults";
import { SectorType } from "../constans/sectorTypes";
import animalsDB from "../database/animalsDB";
import buildingsDB from "../database/buildingsDB";
import dictionariesDB from "../database/dictionariesDB";
import { convertRowsToCycles, preEvents } from "../utils/AnimalDocumentsUtils";
import { getBirthDateFromAnimalGroup } from "../utils/AnimalsUtils";
import { colorsNonInvasive } from "../utils/ColorUtils";
import { localMomentToUTC } from "../utils/DateTimeUtils";
import {
    getDetailedLocationDaysForGroup,
    getEventsForAnimalsFattening,
    getLocationDaysForGroup,
    getPigBalanceByCycle,
    getPigBalanceForSowFromState,
} from "../utils/EventUtils";
import { fixFloatingPrecision, isBetween, isFiniteNumber } from "../utils/MathUtils";
import {
    getFirstCycleIndex,
    getFirstTechnologyGroupStart,
    getMedicineDelay,
    getTechnologyGroupColors,
    getTechnologyGroupWeeks,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy,
    getTimeOnBirthRoom,
    getTimeOnBirthRoomMommy
} from "../utils/SettingsUtils";
import { getTechnologyGroupName } from "../utils/TechnologyGroupUtils";
import {
    getCommentDictionary,
    getFallReasons,
    getGraftingProgram,
    getGraftingPrograms,
    getMedicines,
    getRaces,
} from "./dictionarySelectors";
import { getEconomy } from "./economySelectors";
import { getPigletTreatmentSettings } from "./settingsSelector";
import utilSelectors from "./utilSelectors";
import { getAnimalTimespanInGroupByAllEvents } from "../utils/GroupUtils";

export const getSelectedAnimalForDocuments = (state) =>
    state.animalDocuments.selectedItem;

const _getAnimalEvents = (state) => state.animalDocuments.events;

export const getSelectedGroup = (state) =>
    state.animalDocuments.selectedItem?.AnmGrp
        ? state.animalDocuments.selectedItem
        : null;

export const getAnimalEvents = createSelector([_getAnimalEvents, getSelectedGroup], (events, currentGroup) => {
    if (currentGroup) {
        const animalTimespan = getAnimalTimespanInGroupByAllEvents(events, currentGroup.AnmGrp);
        return events.filter((ev) => {
            if (!animalTimespan[ev.AnmID]) return true;
            return animalTimespan[ev.AnmID].some((range) => isBetween(ev.EvTime, range[0], range[1]));
        });
    }
    return events;
});

export const getAnimalEventsWithType = (state, type) =>
    getAnimalEvents(state).filter((item) => item.EvCode === type);

export const getCycleTable = (state) => state.animalDocuments.cycleTable;

const getOnlyRemovedAnimals = (state, { onlyRemoved = false } = {}) =>
    onlyRemoved;

export const fetchingAnimalDocumentEvents = (state) =>
    state.animalDocuments.fetchingEvent;

export const getCycles = createSelector([getCycleTable], (cycleTable) =>
    convertRowsToCycles(cycleTable)
);

const getCycleNumber = (state, { cycleNumber }) => cycleNumber;

const getGraftingsAndTreatments = createSelector(getAnimalEvents, (events) => {
    return events.filter(
        (item) =>
            item.EvCode === EventTypes.TREATMENT ||
            item.EvCode === EventTypes.GRAFTING
    );
});

const getPigletTreatments = createSelector(getAnimalEvents, (events) => {
    return events.filter(
        (item) => item.EvCode === EventTypes.PIGLETS_TREATMENT
    );
});


export const getDeletedAnimals = (state, props) => props?.getDeletedAnimals;

const _animalInGroupMemoized = memoizeOne((_group, _time) => {
    return memoize((onlyRemoved, getDelAnimals) => {
        if (!_group) return [];
        const { AnmIDs, Rmvd } = _group;
        const animals = [];
        const inGroup = [];
        if (!onlyRemoved) inGroup.push(...AnmIDs);
        inGroup.push(...Rmvd);
        inGroup.forEach((AnmID) => {
            const animal = animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: getDelAnimals,
            });
            if (animal) animals.push(animal);
        });
        return animals;
    }, (...args) => !!args[0] + "__" + !!args[1]);
});

// jest pasztet, bo ktoś rozwalil selector i na widoku karty świni woła na zmianę ten sam selector z innymi argumentami
const _getAnimalsInGroup = (_group, _time, onlyRemoved, getDelAnimals) => {
    return _animalInGroupMemoized(_group, _time)(onlyRemoved, getDelAnimals);
};

export const makeGetAnimalsInGroup = () =>
    createSelector(
        getSelectedGroup,
        utilSelectors.getAnimalDtaModTime,
        getOnlyRemovedAnimals,
        getDeletedAnimals,
        _getAnimalsInGroup
    );

const animalsInGroupSelector = createSelector(
    getSelectedGroup,
    utilSelectors.getAnimalDtaModTime,
    getOnlyRemovedAnimals,
    getDeletedAnimals,
    _getAnimalsInGroup
);

export const getGroupBirthTime = createSelector(
    [animalsInGroupSelector],
    (animalsInGroup) => {
        return getBirthDateFromAnimalGroup(animalsInGroup);
    }
);

export const getGroupTimespan = createSelector(
    [getSelectedGroup, animalsInGroupSelector],
    (group, animalsInGroup) => {
        const nowUTC = +localMomentToUTC(moment().startOf("day"));
        let start = nowUTC,
            end = nowUTC,
            groupEnded = false;
        if (group) {
            if (group.DtaDelTime || group.DtaDltTime) {
                end = +localMomentToUTC(
                    moment(group.DtaDelTime || group.DtaDltTime).startOf("day")
                ).startOf("day");
                groupEnded = true;
            }
            const insertionTime = minBy(
                animalsInGroup,
                (o) => o.DtaInTime || nowUTC
            )?.DtaInTime;
            if (insertionTime) {
                start = +localMomentToUTC(moment(insertionTime).startOf("day"));
            }
        }
        return {
            end: Math.max(end, start),
            start: Math.min(end, start),
            groupEnded
        };
    }
);

export const groupHasPorker = createSelector(animalsInGroupSelector, animals => {
    const animalKinds = uniq(animals.map(animal => animal.AnimalKind));
    return animalKinds.includes(AnimalTypes.PORKER);
});

function createMoment(date, useUTC = false) {
    return useUTC ? moment.utc(date) : moment(date);
}

function hadGrafting(
    graftings,
    medicine,
    programDate,
    { hasMoreDaysToGiveMedicine = false, finalDate = 0, useUTC = false } = {}
) {
    let medicineDelay = getMedicineDelay();
    return graftings.find((item) => {
        console.log(medicine.WordID, item.medicine, medicine.WordID !== item.medicine);
        if (medicine.WordID !== item.medicine) return false;
        if (hasMoreDaysToGiveMedicine) {
            let minDate = createMoment(programDate, useUTC).subtract(medicineDelay, "days");
            let maxDate = createMoment(finalDate, useUTC).add(medicineDelay, "days");
            console.log(createMoment(item.date, useUTC).format("L"));
            return createMoment(item.date, useUTC).isBetween(minDate, maxDate, "day", "[]");
        } else {
            let minDate = createMoment(item.date, useUTC).subtract(medicineDelay, "days");
            let maxDate = createMoment(item.date, useUTC).add(medicineDelay, "days");
            console.log(programDate.format("L HH:mm"), minDate.format("L HH:mm"), maxDate.format("L HH:mm"), programDate.isBetween(minDate, maxDate, "day", "[]"));
            return programDate.isBetween(minDate, maxDate, "day", "[]");
        }
    });
}

function getDose(_dose, _medicine) {
    let dose = fixFloatingPrecision(_dose);
    if (_medicine) {
        if (dose === undefined || isNaN(dose)) {
            dose = _medicine.WData.Dose + " " + _medicine.WData.Unit;
        } else {
            dose += " " + _medicine.WData.Unit.split("/")[0];
        }
    }
    return dose;
}

function getDelay(grafting, hasMoreDaysToGiveMedicine, date, endDate) {
    let d = moment(grafting.event.EvTime);
    if (hasMoreDaysToGiveMedicine) {
        if (!d.isBetween(date, endDate, "day", "[]")) {
            return {
                Date: d,
                Delay: d.isBefore(date, "day")
                    ? d.diff(date, "days")
                    : d.diff(endDate, "days"),
            };
        }
        return d;
    }
    if (!d.isSame(date, "day")) {
        return {
            Date: d,
            Delay: d.diff(date, "days"),
        };
    }
    return d;
}

function getSortDate(row) {
    if (row.date.Start) return +row.date.Start;
    if (row.date.Date) return +row.date.Date;
    return +row.date;
}

function getApplicationType(cycle, grafting) {
    if (!cycle) return null;
    let separation = cycle[EventTypes.SEPARATION][0];
    if (separation && separation.EvTime <= grafting.EvTime)
        return {
            event: separation,
            applicationType: GraftingProgramApplicationTypes.SEPARATION,
        };
    let parturition = cycle[EventTypes.PARTURITION][0];
    if (parturition && parturition.EvTime <= grafting.EvTime)
        return {
            event: parturition,
            applicationType: GraftingProgramApplicationTypes.PARTURITION,
        };
    let insemination = cycle[EventTypes.INSEMINATION][0];
    if (insemination && cycle.StartTime <= grafting.EvTime)
        return {
            // podmiana EvTime na date ostatniej inseminacji w cyklu ktora tak naprawde jest jego poczatkiem - rozwiazuje problem powtorek
            event: { ...insemination, EvTime: cycle.StartTime },
            applicationType: GraftingProgramApplicationTypes.INSEMINATION,
        };
    return null;
}

function getDiffForGrafting(grafting, applicationType, date) {
    if (
        applicationType?.applicationType ===
        GraftingProgramApplicationTypes.INSEMINATION
    ) {
        return grafting.date.diff(
            moment(
                moment
                    .utc(applicationType.event.EvTime)
                    .startOf("date")
                    .format(moment.HTML5_FMT.DATE)
            ),
            "days"
        );
    }
    return grafting.date.diff(applicationType?.event.EvTime || date, "days");
}

function getDiffForMedicine(date, animal) {
    const animalDate = animal.DtaBrthTime || animal.DtaInTime;
    if (!animalDate) return null;
    return date.clone().diff(animalDate, "days");
}

export function getMedicineArray(
    animal,
    cycles,
    events,
    medicines,
    graftingProgram,
    cycleNumber
) {
    // TODO - refactor i jako cycles wrzucic normalna strukture a nie zflatowane
    let data = [];
    let date = moment(0),
        maxDate;
    let isValidDateForGraftingProgram = false;
    let cycleIndex = cycleNumber - getFirstCycleIndex();
    let cycle = cycles[cycleIndex];
    let isSow = animal.AnimalKind === AnimalTypes.SOW;
    if (animal.DtaDthTime) {
        maxDate = animal.DtaDthTime;
    }
    if (isSow && !!cycle) {
        date = moment(cycle.StartTime);
        if (!animal.DtaDthTime) {
            maxDate = cycleIndex === 0 ? null : cycle.EndTime;
        }
        // wyswietlamy program szczepien w przypadku, gdy byla wykonana inseminacja, poród lub odsad z racji na dodane zastosowania leków w programie szczepień
        isValidDateForGraftingProgram =
            !!cycle[EventTypes.INSEMINATION][0] ||
            !!cycle[EventTypes.PARTURITION][0] ||
            !!cycle[EventTypes.SEPARATION][0];
    } else if (
        (typeof animal.DtaBrthTime === "number" && animal.DtaBrthTime) ||
        (typeof animal.DtaInTime === "number" && animal.DtaInTime)
    ) {
        date = moment(animal.DtaBrthTime || animal.DtaInTime).startOf("day");
        isValidDateForGraftingProgram = true;
    }
    if (animal.AnmGrp) {
        isValidDateForGraftingProgram = true;
    }
    let graftings = events.filter((item) => {
        if (item.EvCode !== EventTypes.GRAFTING) return false;
        if (
            item.EvTime < date.clone().startOf("day").toDate().getTime() &&
            cycleIndex > 0
        )
            return false;
        if (
            isSow &&
            item.EvTime > moment(cycle?.EndTime).endOf("day").toDate().getTime()
        )
            return false;
        return maxDate ? item.EvTime < maxDate : true;
    });
    let graftingArray = [];
    for (let grafting of graftings) {
        if (grafting.EvData.StartTime) {
            let endTime = grafting.EvData.EndTime || +new Date();
            let diff = moment(endTime)
                .startOf("day")
                .diff(moment(grafting.EvData.StartTime).startOf("day"), "days");
            for (let i = 0; i < diff + 1; i++) {
                graftingArray.push({
                    date: moment(grafting.EvData.StartTime).add(i, "days"),
                    medicine: grafting.EvData.Medicine,
                    event: grafting,
                });
            }
        } else {
            graftingArray.push({
                date: moment(grafting.EvTime),
                medicine: grafting.EvData.Medicine,
                event: grafting,
            });
        }
    }
    graftingArray.sort((a, b) => +a - +b);
    if (graftingProgram) {
        if (date && isValidDateForGraftingProgram) {
            data = graftingProgram.WData.MedicineList.map((row) => {
                let medicine = medicines.find(
                    (med) => med.WordID === row.Medicine
                );
                // jezeli to usuniety lek
                if (!medicine)
                    medicine = dictionariesDB.findRemovedDictionary(
                        row.Medicine
                    );
                if (medicine) {
                    let medApplication = get(
                        row,
                        "Application",
                        isSow
                            ? GraftingProgramApplicationTypes.INSEMINATION
                            : GraftingProgramApplicationTypes.AGE
                    );
                    let additionalDays = 0;
                    const getProgramDate = () => {
                        let _programDate = date.clone();
                        switch (medApplication) {
                            case GraftingProgramApplicationTypes.PARTURITION: {
                                if (isSow) {
                                    if (
                                        cycle &&
                                        cycle[EventTypes.PARTURITION].length > 0
                                    ) {
                                        additionalDays = moment(
                                            cycle[EventTypes.PARTURITION][0]
                                                .EvTime
                                        ).diff(moment(date), "days");
                                        return (_programDate = moment(
                                            cycle[EventTypes.PARTURITION][0]
                                                .EvTime
                                        )
                                            .clone()
                                            .add(row.Age, "days"));
                                    } else {
                                        additionalDays =
                                            getTimeFromInseminationToPartuition();
                                        return _programDate.add(
                                            getTimeFromInseminationToPartuition() +
                                            row.Age,
                                            "days"
                                        );
                                    }
                                }
                                break;
                            }
                            case GraftingProgramApplicationTypes.SEPARATION: {
                                if (isSow) {
                                    if (
                                        cycle &&
                                        cycle[EventTypes.SEPARATION].length > 0
                                    ) {
                                        additionalDays = moment(
                                            cycle[EventTypes.SEPARATION][0]
                                                .EvTime
                                        ).diff(moment(date), "days");
                                        return (_programDate = moment(
                                            cycle[EventTypes.SEPARATION][0]
                                                .EvTime
                                        )
                                            .clone()
                                            .add(row.Age, "days"));
                                    } else {
                                        if (
                                            cycle &&
                                            cycle[EventTypes.PARTURITION]
                                                .length > 0
                                        ) {
                                            additionalDays =
                                                moment(
                                                    cycle[
                                                        EventTypes.PARTURITION
                                                    ][0].EvTime
                                                ).diff(moment(date), "days") +
                                                getTimeOnBirthRoom();
                                            return (_programDate = moment(
                                                cycle[EventTypes.PARTURITION][0]
                                                    .EvTime
                                            )
                                                .clone()
                                                .add(
                                                    getTimeOnBirthRoom() +
                                                    row.Age,
                                                    "days"
                                                ));
                                        } else {
                                            additionalDays =
                                                getTimeFromInseminationToPartuition() +
                                                getTimeOnBirthRoom();
                                            return _programDate.add(
                                                getTimeFromInseminationToPartuition() +
                                                getTimeOnBirthRoom() +
                                                row.Age,
                                                "days"
                                            );
                                        }
                                    }
                                }
                                return _programDate.add(row.Age, "days"); // data wprowadzenia na warchlakarnie
                            }
                            case GraftingProgramApplicationTypes.INSERTION: {
                                if (animal.DtaInTime) {
                                    additionalDays = moment(
                                        animal.DtaInTime
                                    ).diff(moment(date), "days");
                                    return (_programDate = moment(
                                        animal.DtaInTime
                                    ).add(row.Age, "days"));
                                }
                                break;
                            }
                            case GraftingProgramApplicationTypes.INSEMINATION:
                            case GraftingProgramApplicationTypes.AGE:
                            default:
                                return _programDate.add(row.Age, "days");
                        }
                    };
                    let programDate = getProgramDate();
                    if (
                        programDate &&
                        ((maxDate &&
                            programDate.toDate().getTime() <
                            moment(maxDate).toDate().getTime()) ||
                            !maxDate)
                    ) {
                        let hasMoreDaysToGiveMedicine =
                            row?.DaysToGiveMedicine > 1;
                        let finalDate = hasMoreDaysToGiveMedicine
                            ? programDate
                                .clone()
                                .add(row.DaysToGiveMedicine - 1, "days")
                            : programDate;
                        let hadGraf = hadGrafting(
                            graftingArray,
                            medicine,
                            programDate,
                            {
                                hasMoreDaysToGiveMedicine,
                                finalDate,
                            }
                        );
                        if (hadGraf) {
                            graftingArray = graftingArray.filter(
                                (item) => item !== hadGraf
                            );
                        }
                        let dose = getDose(
                            hadGraf
                                ? get(hadGraf, "event.EvData.Dose") *
                                get(hadGraf, "event.EvData.PiCnt", 1)
                                : undefined,
                            medicine
                        );
                        return {
                            medicine,
                            date: hadGraf
                                ? getDelay(
                                    hadGraf,
                                    hasMoreDaysToGiveMedicine,
                                    programDate,
                                    finalDate
                                )
                                : hasMoreDaysToGiveMedicine
                                    ? {
                                        Start: programDate,
                                        End: finalDate,
                                    }
                                    : programDate,
                            // za kazdym razem od inseminacji bo nie wiemy czy program byl zrealizowany po porodzie badz inseminacji z racji na to, że mogą być te same leki
                            daysAfter: hadGraf
                                ? moment(hadGraf.event.EvTime)
                                    .subtract(additionalDays, "d")
                                    .diff(date.clone(), "days")
                                : programDate
                                    .clone()
                                    .subtract(additionalDays, "d")
                                    .diff(date.clone(), "days"),
                            // daysAfter: row.Age,
                            done: !!hadGraf
                                ? GRAFTING_RESULT.DONE
                                : !finalDate.clone().endOf("day").isBefore()
                                    ? GRAFTING_RESULT.PENDING
                                    : GRAFTING_RESULT.NOT_DONE,
                            comment: hadGraf
                                ? hadGraf.event.Comment
                                : i18n.t("graftingProgram"),
                            operator: hadGraf ? hadGraf.event.OperID : null,
                            reason: hadGraf ? hadGraf.event.EvData.Reasn : null,
                            daysToGiveMedicine: row?.DaysToGiveMedicine,
                            dose: hadGraf ? dose : null,
                            doneDay: hadGraf ? hadGraf.event.EvTime : null,
                            graftingProgramReason: medApplication
                                ? medApplication
                                : null,
                            event: hadGraf?.event || null,
                        };
                    }
                }
            }).filter((item) => item);
        }
    }
    let treatments = events.filter((item) => {
        if (item.EvCode !== EventTypes.TREATMENT) return false;
        if (item.EvTime < date.clone().startOf("day").toDate().getTime())
            return false;
        // dla maciory w ostatnim cyklu pokazujemy wszystkie rozpisane leczenia w przyszłości
        // jesli nie ma zadnych cykli to pokazujemy wszystkie - taka maciora nie powinna istniec w teorii
        if (
            isSow &&
            cycles.length > 0 &&
            item.EvTime > moment(cycle?.EndTime).endOf("day").toDate().getTime()
        )
            return cycleIndex === cycles.length - 1;
        return true;
    }).sort((a, b) => a.EvTime - b.EvTime);
    const tmp = [];
    for (let treatment of treatments) {
        for (let tr of treatment.EvData.Treatment) {
            for (let medicine of tr.Medicine) {
                for (let date of tr.Dates) {
                    tmp.push({ time: date, medicine, event: treatment });
                }
            }
        }
    }
    tmp.sort((a, b) => a.time - b.time);
    for (let row of tmp) {
        let medicine = medicines.find((med) => med.WordID === row.medicine);
        // jezeli to usuniety lek
        if (!medicine)
            medicine = dictionariesDB.findRemovedDictionary(row.medicine);
        if (medicine) {
            let trDate = moment(moment.utc(row.time).startOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL));
            console.log(trDate.format("L HH:mm"));
            let hadGraf = hadGrafting(
                graftingArray,
                medicine,
                trDate,
                { useUTC: false }
            );
            if (hadGraf) {
                graftingArray = graftingArray.filter(
                    (item) => item !== hadGraf
                );
            }
            let dose = getDose(
                hadGraf
                    ? (
                        get(hadGraf, "event.EvData.Dose") *
                        get(hadGraf, "event.EvData.PiCnt", 1)
                    ).toFixed(1)
                    : undefined,
                medicine
            );
            data.push({
                medicine,
                date: trDate,
                daysAfter: hadGraf
                    ? moment(hadGraf.event.EvTime).diff(
                        date.clone(),
                        "days"
                    )
                    : graftingProgram
                        ? trDate.diff(date, "day")
                        : trDate.diff(date, "days"),
                done: !!hadGraf
                    ? GRAFTING_RESULT.DONE
                    : !trDate.clone().endOf("day").isBefore()
                        ? GRAFTING_RESULT.PENDING
                        : GRAFTING_RESULT.NOT_DONE,
                comment: hadGraf
                    ? hadGraf.event.Comment
                    : row.event.Comment,
                operator: hadGraf
                    ? hadGraf.event.EvData.OperID
                    : row.event.EvData.OperID,
                reason: hadGraf
                    ? hadGraf.event.EvData.Reasn
                    : row.event.EvData.Reason,
                dose: hadGraf ? dose : null,
                doneDay: hadGraf ? hadGraf.event.EvTime : null,
                event: hadGraf?.event || row.event,
            });
        }
    }
    // for (let event of treatments) {
    //     for (let treatment of event.EvData.Treatment) {
    //         let dates = treatment.Dates;
    //         console.log(dates);
    //         for (let medID of treatment.Medicine) {
    //             let medicine = medicines.find((med) => med.WordID === medID);
    //             // jezeli to usuniety lek
    //             if (!medicine)
    //                 medicine = dictionariesDB.findRemovedDictionary(medID);
    //             if (medicine) {
    //                 for (let d of dates) {
    //                     let trDate = moment.utc(d).startOf("day");
    //                     console.log(trDate.format("L HH:mm"));
    //                     let hadGraf = hadGrafting(
    //                         graftingArray,
    //                         medicine,
    //                         trDate,
    //                         { useUTC: true }
    //                     );
    //                     if (hadGraf) {
    //                         graftingArray = graftingArray.filter(
    //                             (item) => item !== hadGraf
    //                         );
    //                     }
    //                     let dose = getDose(
    //                         hadGraf
    //                             ? (
    //                                 get(hadGraf, "event.EvData.Dose") *
    //                                 get(hadGraf, "event.EvData.PiCnt", 1)
    //                             ).toFixed(1)
    //                             : undefined,
    //                         medicine
    //                     );
    //                     data.push({
    //                         medicine,
    //                         date: trDate,
    //                         daysAfter: hadGraf
    //                             ? moment(hadGraf.event.EvTime).diff(
    //                                 date.clone(),
    //                                 "days"
    //                             )
    //                             : graftingProgram
    //                                 ? trDate.diff(date, "day")
    //                                 : trDate.diff(date, "days"),
    //                         done: !!hadGraf
    //                             ? GRAFTING_RESULT.DONE
    //                             : !trDate.clone().endOf("day").isBefore()
    //                                 ? GRAFTING_RESULT.PENDING
    //                                 : GRAFTING_RESULT.NOT_DONE,
    //                         comment: hadGraf
    //                             ? hadGraf.event.Comment
    //                             : event.Comment,
    //                         operator: hadGraf
    //                             ? hadGraf.event.EvData.OperID
    //                             : event.EvData.OperID,
    //                         reason: hadGraf
    //                             ? hadGraf.event.EvData.Reasn
    //                             : event.EvData.Reason,
    //                         dose: hadGraf ? dose : null,
    //                         doneDay: hadGraf ? hadGraf.event.EvTime : null,
    //                         event: hadGraf?.event || event,
    //                     });
    //                 }
    //             }
    //         }
    //     }
    // }
    for (let grafting of graftingArray) {
        let applicationType = getApplicationType(cycle, grafting.event);
        let medicine = medicines.find(
            (med) => med.WordID === grafting.medicine
        );
        // jezeli to usuniety lek
        if (!medicine)
            medicine = dictionariesDB.findRemovedDictionary(grafting.medicine);
        let dose = getDose(
            (
                get(grafting, "event.EvData.Dose") *
                get(grafting, "event.EvData.PiCnt", 1)
            ).toFixed(1),
            medicine
        );
        let daysReason = applicationType?.applicationType;
        if (!daysReason) {
            if (
                isSow &&
                ((!cycleIndex && grafting.date >= cycle?.StartTime) ||
                    cycleIndex)
            ) {
                daysReason = GraftingProgramApplicationTypes.INSEMINATION;
            } else {
                //jezeli zwierze nie bylo maciora
                animal.DtaBrthTime
                    ? (daysReason = GraftingProgramApplicationTypes.AGE)
                    : (daysReason = GraftingProgramApplicationTypes.INSERTION);
            }
        }
        data.push({
            medicine,
            date: grafting.date,
            done: GRAFTING_RESULT.DONE,
            daysAfter:
                !cycleIndex && grafting.date <= cycle?.StartTime
                    ? getDiffForMedicine(grafting.date, animal)
                    : getDiffForGrafting(grafting, applicationType, date),
            daysReason,
            comment: grafting.event.Comment,
            operator: grafting.event.EvData.OperID,
            reason: grafting.event.EvData.Reasn,
            dose,
            doneDay: grafting.event.EvTime,
            event: grafting.event,
            graftingProgramReason: applicationType?.applicationType,
        });
    }
    data.sort((a, b) => {
        let first = getSortDate(a);
        let second = getSortDate(b);
        return second - first;
    });
    return data;
}

function getDelayFoePiglets(treatment, date) {
    let d = moment(treatment.EvData.StartTime || treatment.EvTime);
    if (date.End) {
        if (!d.isBetween(date.Start, date.End, "day", "[]")) {
            return {
                Date: d,
                Delay: d.isBefore(date.Start, "day")
                    ? d.diff(date.Start, "days")
                    : d.diff(date.End, "days"),
            };
        }
        return d;
    }
    if (!d.isSame(date, "day")) {
        return {
            Date: d,
            Delay: d.diff(date, "days"),
        };
    }
    return date;
}

export function getPigletTreatmentArray(
    treatments,
    cycles,
    pigletGraftingProgram,
    medicines,
    cycle
) {
    if (!cycles.length) return [];
    const cycleIndex = cycle - getFirstCycleIndex();
    const currentCycle = cycles[cycleIndex];
    if (!currentCycle) return [];
    let array = [];
    const parturition = currentCycle[EventTypes.PARTURITION][0];
    const { balance } = getPigBalanceByCycle(currentCycle);
    let medicineDelay = getMedicineDelay();
    if (parturition) {
        // filtrowanie leczen stricte dla danego cyklu
        treatments = treatments.filter(
            (treatment) =>
                +moment(treatment.EvTime).startOf("day") >=
                +moment(parturition.EvTime).startOf("day")
        );
        if (pigletGraftingProgram) {
            // przeiterowanie po programie szczepien
            for (let row of pigletGraftingProgram.WData.MedicineList) {
                const { Medicine, Age, DaysToGiveMedicine = 1 } = row;
                let date = moment(parturition.EvTime).add(Age, "days");
                if (DaysToGiveMedicine > 1) {
                    date = {
                        Start: +date,
                        End: +date.clone().add(DaysToGiveMedicine - 1, "days"),
                    };
                }
                // sprawdzenie czy bylo zgloszone leczenie prosiat pomiedzy datami zgloszonymi w programie szczepien
                let hadTreatment = treatments.find((item) => {
                    if (item.EvData.Medicine !== Medicine) return false;
                    if (DaysToGiveMedicine > 1) {
                        let minDate = moment(date.Start).subtract(
                            medicineDelay,
                            "days"
                        );
                        let maxDate = moment(date.End).add(
                            medicineDelay,
                            "days"
                        );
                        return moment(
                            item.EvData.StartTime || item.EvTime
                        ).isBetween(minDate, maxDate, "day", "[]");
                    }
                    let minDate = moment(
                        item.EvData.StartTime || item.EvTime
                    ).subtract(medicineDelay, "days");
                    let maxDate = moment(
                        item.EvData.StartTime || item.EvTime
                    ).add(medicineDelay, "days");
                    return moment(
                        item.EvData.StartTime || item.EvTime
                    ).isBetween(minDate, maxDate, "day", "[]");
                });
                let index = treatments.indexOf(hadTreatment);
                if (index !== -1) {
                    // usuniecie z tablicy pozostalych jezeli bylo leczenie
                    treatments.splice(index, 1);
                }
                const getResult = (_time) => {
                    // w momencie kiedy w danym cyklu nie ma juz prosiat to oznacz jako niewykonane
                    if (balance === 0) return GRAFTING_RESULT.NOT_DONE;
                    return _time.clone().endOf("day").isBefore()
                        ? GRAFTING_RESULT.NOT_DONE
                        : GRAFTING_RESULT.PENDING;
                };
                // dawka na leczeniu prosiąt per prosię więc trzeba pomnożyć
                let dose = hadTreatment
                    ? +(
                        get(hadTreatment, "EvData.Dose") *
                        get(hadTreatment, "EvData.PiCnt", 1)
                    ).toFixed(1)
                    : undefined;
                let medicine = medicines.find(
                    (item) => item.WordID === Medicine
                );
                // jezeli to usuniety lek
                if (!medicine)
                    medicine = dictionariesDB.findRemovedDictionary(Medicine);
                if (medicine) {
                    if (dose === undefined || isNaN(dose)) {
                        dose = medicine.WData.Dose + medicine.WData.Unit;
                    } else {
                        dose += medicine.WData.Unit.split("/")[0];
                    }
                }
                array.push({
                    medicine: medicine,
                    date: hadTreatment
                        ? getDelayFoePiglets(hadTreatment, date)
                        : date.End
                            ? date
                            : +date,
                    day: date.End
                        ? `${Age} - ${Age + DaysToGiveMedicine - 1}`
                        : Age,
                    done: hadTreatment
                        ? GRAFTING_RESULT.DONE
                        : date.End
                            ? getResult(moment(date.End))
                            : getResult(date),
                    pigletsAmount: hadTreatment
                        ? hadTreatment.EvData.PiCnt
                        : null,
                    reason: hadTreatment ? hadTreatment.EvData.Reason : "2",
                    operator: hadTreatment ? hadTreatment.EvData.OperID : null,
                    comment: hadTreatment ? hadTreatment.Comment : null,
                    dose: hadTreatment ? dose : null,
                    piglets: true,
                    doneDay: hadTreatment ? hadTreatment.EvTime : null,
                    graftingProgramReason: GraftingProgramApplicationTypes.AGE,
                    event: hadTreatment || null,
                });
            }
        }
        // dopisanie wszystkich pozostalych leczen prosiat
        array.push(
            ...[...treatments].map((event) => {
                const obj = {
                    date: event.EvTime,
                    operator: event.EvData.OperID,
                    done: GRAFTING_RESULT.DONE,
                    piglets: true,
                    doneDay: event.EvTime,
                    event: event,
                    pigletsAmount: get(event, "EvData.PiCnt", ""),
                };
                if (event.EvCode === EventTypes.PIGLETS_TREATMENT) {
                    let diff =
                        moment(event.EvData.StartTime || event.EvTime).diff(
                            parturition.EvTime,
                            "days"
                        ) + 1;
                    let dose = (
                        get(event, "EvData.Dose") *
                        get(event, "EvData.PiCnt", 1)
                    ).toFixed(1);
                    let medicine = medicines.find(
                        (item) => item.WordID === event.EvData.Medicine
                    );
                    // jezeli to usuniety lek
                    if (!medicine)
                        medicine = dictionariesDB.findRemovedDictionary(
                            event.EvData.Medicine
                        );
                    if (medicine) {
                        if (dose === undefined || isNaN(dose)) {
                            dose = medicine.WData.Dose + medicine.WData.Unit;
                        } else {
                            dose += medicine.WData.Unit.split("/")[0];
                        }
                    }
                    obj.date = event.EvData.StartTime
                        ? {
                            Start: event.EvData.StartTime,
                            End: event.EvData.EndTime,
                        }
                        : event.EvTime;
                    obj.medicine = medicine;
                    obj.comment = event.Comment;
                    obj.reason = event.EvData.Reason;
                    obj.day = diff;
                    obj.dose = dose;
                }
                return obj;
            })
        );
    }
    array.sort((a, b) => {
        let first = getSortDate(a);
        let second = getSortDate(b);
        return second - first;
    });
    return array;
}

export const getMedicineArrayForGroup = createSelector(
    [
        getGraftingsAndTreatments,
        getMedicines,
        getGraftingPrograms,
        animalsInGroupSelector,
        getAnimalEvents
    ],
    (_events, _medicines, _graftingPrograms, _animals, animalEvents) => {
        const graftingEvents = _events.filter(
            ({ EvCode }) => EventTypes.GRAFTING === EvCode
        );
        const treatmentEvents = _events.filter(
            ({ EvCode }) => EventTypes.TREATMENT === EvCode
        );
        const result = [];
        let graftingList = [];
        for (let grafting of graftingEvents) {
            const hasStartTime = !!grafting.EvData.StartTime;
            if (hasStartTime) {
                const endTime = grafting.EvData.EndTime || new Date().getTime();
                const startTime = grafting.EvData.StartTime;
                const daysDifference = moment(endTime)
                    .startOf("day")
                    .diff(moment(startTime).startOf("day"), "days");
                for (let i = 0; i <= daysDifference; i++) {
                    graftingList.push({
                        date: moment(startTime).add(i, "days"),
                        medicine: grafting.EvData.Medicine,
                        event: grafting,
                        _AnmID: grafting.AnmID,
                    });
                }
            } else {
                graftingList.push({
                    date: moment(grafting.EvTime),
                    medicine: grafting.EvData.Medicine,
                    event: grafting,
                    _AnmID: grafting.AnmID,
                });
            }
        }
        for (let animal of _animals) {
            // w grupie powinny byc tylko tuczniki i warchlaki
            if (
                [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(
                    animal?.AnimalKind
                )
            ) {
                const usedGraftingProgram = _graftingPrograms.find(
                    ({ WData: { AnimalKind } }) => AnimalKind === animal.AnimalKind
                );

                if (usedGraftingProgram) {
                    const date = moment(
                        animal.DtaBrthTime || animal.DtaInTime || 0
                    );
                    const {
                        WData: { MedicineList },
                    } = usedGraftingProgram;
                    for (let row of MedicineList) {
                        const medicine = _medicines.find(
                            ({ WordID }) => WordID === row.Medicine
                        );
                        if (medicine) {
                            let medApplication = get(
                                row,
                                "Application",
                                GraftingProgramApplicationTypes.AGE
                            );
                            let additionalDays = 0;
                            // dziwna funkcja getProgramDate
                            let programDate = moment(date).clone();
                            switch (medApplication) {
                                case GraftingProgramApplicationTypes.INSERTION: {
                                    if (animal.DtaInTime) {
                                        additionalDays = moment(
                                            animal.DtaInTime
                                        ).diff(moment(date), "days");
                                        programDate
                                            .add(animal.DtaInTime)
                                            .add(row.Age, "days");
                                    }
                                    break;
                                }
                                case GraftingProgramApplicationTypes.SEPARATION:
                                case GraftingProgramApplicationTypes.INSEMINATION:
                                case GraftingProgramApplicationTypes.AGE:
                                default: {
                                    programDate.add(row.Age, "days");
                                }
                            }
                            // endof dziwna funkcja getProgramDate
                            const hasMoreDaysToGiveMedicine =
                                row?.DaysToGiveMedicine > 1;
                            const finalDate = hasMoreDaysToGiveMedicine
                                ? programDate
                                    .clone()
                                    .add(row.DaysToGiveMedicine - 1, "days")
                                : programDate;
                            const currentGrafting = graftingList.find((g) => {
                                if (g._AnmID !== animal.AnmID) return false;
                                if (medicine.WordID !== g.medicine)
                                    return false;
                                if (hasMoreDaysToGiveMedicine) {
                                    return moment(g.date).isBetween(
                                        programDate,
                                        finalDate,
                                        "day",
                                        "[]"
                                    );
                                } else {
                                    return programDate.isSame(g.date, "day");
                                }
                            });
                            if (currentGrafting) {
                                graftingList = graftingList.filter(
                                    (o) => o !== currentGrafting
                                );
                            }
                            const _dose = currentGrafting
                                ? get(currentGrafting, "event.EvData.Dose") *
                                get(currentGrafting, "event.EvData.PiCnt", 1)
                                : undefined;
                            const dose = getDose(_dose, medicine);
                            const getAmount = (date) => {
                                const fatteningEvents = getEventsForAnimalsFattening();
                                let amnt = 0;
                                const events = animalEvents.filter(({ EvCode, EvTime }) => {
                                    let EvTimeRound = moment(EvTime).startOf("day").toDate().getTime();
                                    return (fatteningEvents.includes(EvCode) && EvTimeRound <= date.valueOf());
                                });
                                if (events.length) {
                                    events.forEach(event => {
                                        const { EvCode, AnmCnt, EvData } = event;
                                        let amount = EvData.AnmCnt || AnmCnt;
                                        const subtract = [EventTypes.FALL, EventTypes.SELL, EventTypes.RECLASSIFY].includes(EvCode);
                                        amount = subtract ? amount * (-1) : amount;
                                        amnt += amount;
                                    });
                                } else {
                                    let firstIN = animalEvents.sort((e1, e2) => e1.EvTime - e2.EvTime).find(({ EvCode }) => EvCode === 'IN');
                                    if (firstIN) {
                                        amnt = firstIN?.EvData.AnmCnt || firstIN.AnmCnt;
                                        return { AnmCnt: amnt, In: 1 };
                                    } else
                                        return null;
                                }

                                return { AnmCnt: amnt };
                            };
                            const eventReturn = currentGrafting?.event || getAmount(programDate);
                            if (currentGrafting?.event === undefined)
                                result.push({
                                    medicine,
                                    date: currentGrafting
                                        ? moment(currentGrafting.event.EvTime)
                                        : hasMoreDaysToGiveMedicine
                                            ? {
                                                Start: programDate,
                                                End: finalDate,
                                            }
                                            : programDate,
                                    // za kazdym razem od inseminacji bo nie wiemy czy program byl zrealizowany po porodzie badz inseminacji z racji na to, że mogą być te same leki
                                    daysAfter: currentGrafting
                                        ? moment(currentGrafting.event.EvTime)
                                            .subtract(additionalDays, "d")
                                            .diff(date.clone(), "days")
                                        : programDate
                                            .clone()
                                            .subtract(additionalDays, "d")
                                            .diff(date.clone(), "days"),
                                    // daysAfter: row.Age,
                                    done: currentGrafting
                                        ? GRAFTING_RESULT.DONE
                                        : !finalDate.clone().endOf("day").isBefore()
                                            ? GRAFTING_RESULT.PENDING
                                            : GRAFTING_RESULT.NOT_DONE,
                                    comment: currentGrafting
                                        ? currentGrafting.event.Comment
                                        : i18n.t("graftingProgram"),
                                    operator: currentGrafting
                                        ? currentGrafting.event.OperID
                                        : null,
                                    reason: currentGrafting
                                        ? currentGrafting.event.EvData.Reasn
                                        : "2",
                                    daysToGiveMedicine: row?.DaysToGiveMedicine,
                                    dose: currentGrafting ? dose : null,
                                    doneDay: currentGrafting
                                        ? currentGrafting.event.EvTime
                                        : null,
                                    graftingProgramReason: medApplication
                                        ? medApplication
                                        : null,
                                    event: eventReturn,
                                    _AnmID: animal.AnmID,
                                });
                        }
                    }
                }
            }
        }
        for (let _treatment of treatmentEvents) {
            for (let treatment of _treatment.EvData.Treatment) {
                const dates = treatment.Dates;
                for (let medicineId of treatment.Medicine) {
                    const medicine = _medicines.find(
                        ({ WordID }) => WordID === medicineId
                    );
                    if (medicine) {
                        for (let treatmentTimestamp of dates) {
                            const date =
                                moment.utc(treatmentTimestamp).startOf("day");
                            const currentGrafting = graftingList.find((g) => {
                                if (g._AnmID !== _treatment.AnmID) return false;
                                if (medicine.WordID !== g.medicine)
                                    return false;
                                return date.isSame(g.date, "day");
                            });
                            if (currentGrafting) {
                                graftingList = graftingList.filter(
                                    (o) => o !== currentGrafting
                                );
                            }
                            const _dose = currentGrafting
                                ? get(currentGrafting, "event.EvData.Dose") *
                                get(currentGrafting, "event.EvData.PiCnt", 1)
                                : undefined;
                            const dose = getDose(_dose, medicine);
                            const animal = _animals.find(
                                (a) => a.AnmID === _treatment.AnmID
                            );
                            const usedGraftingProgram = _graftingPrograms.find(
                                ({ WData: { AnimalKind } }) =>
                                    AnimalKind === animal?.AnimalKind
                            );
                            result.push({
                                medicine,
                                date: date,
                                daysAfter: currentGrafting ? moment(currentGrafting.event.EvTime).diff(date.clone(), "days") : usedGraftingProgram ? date.diff(date, "day") : date.diff(moment(animal?.DtaBrthTime).startOf("day"), "days"),
                                done: currentGrafting
                                    ? GRAFTING_RESULT.DONE
                                    : !date.clone().endOf("day").isBefore()
                                        ? GRAFTING_RESULT.PENDING
                                        : GRAFTING_RESULT.NOT_DONE,
                                comment: currentGrafting
                                    ? currentGrafting.event.Comment
                                    : _treatment.Comment,
                                operator: currentGrafting
                                    ? currentGrafting.event.EvData.OperID
                                    : _treatment.EvData.OperID,
                                reason: currentGrafting
                                    ? currentGrafting.event.EvData.Reasn
                                    : _treatment.EvData.Reason,
                                dose: currentGrafting ? dose : null,
                                doneDay: currentGrafting
                                    ? currentGrafting.event.EvTime
                                    : null,
                                event: currentGrafting?.event || _treatment,
                                _AnmID: (currentGrafting?.event || _treatment)
                                    .AnmID,
                            });
                        }
                    }
                }
            }
        }
        for (let grafting of graftingList) {
            const medicine = _medicines.find(
                (med) => med.WordID === grafting.medicine
            );
            const _dose =
                get(grafting, "event.EvData.Dose") *
                get(grafting, "event.EvData.PiCnt", 1);
            const dose = getDose(_dose, medicine);
            let daysReason = "";
            const animal = _animals.find(
                ({ AnmID }) => AnmID === grafting._AnmID
            );
            animal?.DtaBrthTime
                ? (daysReason = GraftingProgramApplicationTypes.AGE)
                : (daysReason = GraftingProgramApplicationTypes.INSERTION);
            const date = moment(animal?.DtaBrthTime || animal?.DtaInTime || null);
            result.push({
                medicine,
                date: grafting.date,
                done: GRAFTING_RESULT.DONE,
                daysAfter: date ? grafting.date.clone().diff(date, "days") : null,
                daysReason,
                comment: grafting.event.Comment,
                operator: grafting.event.EvData.OperID,
                reason: grafting.event.EvData.Reasn,
                dose,
                doneDay: grafting.event.EvTime,
                event: grafting.event,
                _AnmID: grafting._AnmID,
            });
        }
        result.sort((a, b) => {
            if (a.date.Start && b.date.Start) {
                return b.date.Start - a.date.Start;
            } else if (a.date.Start) {
                return b.date.toDate().getTime() - a.date.Start;
            } else if (b.date.Start) {
                return b.date.Start - a.date.toDate().getTime();
            } else {
                return b.date.toDate().getTime() - a.date.toDate().getTime();
            }
        });
        //http://redmine.wesstron.local/issues/9392
        const mappedItems = {};
        result.forEach((item) => {
            const {
                date,
                medicine,
                event,
                reason,
                done,
                operator,
                daysAfter,
                _AnmID,
            } = item;
            const key = `${moment(date).format("LL")}-${medicine?.WordID
                }-${reason}-${done}`;
            let numberOfAnimals = event?.EvData?.PiCnt || event?.AnmCnt || 0;

            if (!mappedItems[key]) {
                let doseNumber = (numberOfAnimals || 1) * event?.EvData?.Dose;
                const dose = done === 1 ? getDose(doseNumber, medicine) : undefined;
                mappedItems[key] = {
                    date,
                    medicine,
                    animalsCnt: numberOfAnimals,
                    reason,
                    done,
                    data: [item],
                    dose,
                    operator: [operator],
                    daysAfter,
                    _AnmIDs: [_AnmID],
                };
            } else {
                mappedItems[key].data = [...mappedItems[key].data, item];
                mappedItems[key].animalsCnt = event?.In ? mappedItems[key].animalsCnt : mappedItems[key].animalsCnt + numberOfAnimals; //JEŻELI WPROWADZENIE NIE POWIELAJ
                let doseNumber =
                    mappedItems[key].animalsCnt * event?.EvData?.Dose;
                const dose = done === 1 ? getDose(doseNumber, medicine) : undefined;
                mappedItems[key].dose = dose;
                if (!mappedItems[key].operator.includes(operator))
                    mappedItems[key].operator = [
                        ...mappedItems[key].operator,
                        operator,
                    ];
                if (!mappedItems[key]._AnmIDs.includes(_AnmID))
                    mappedItems[key]._AnmIDs = [
                        ...mappedItems[key]._AnmIDs,
                        _AnmID,
                    ];
            }
        });
        return Object.values(mappedItems);
    }
);

export const getMedicineArrayForSelectedAnimal = createSelector(
    getSelectedAnimalForDocuments,
    getCycles,
    getGraftingsAndTreatments,
    getMedicines,
    getGraftingProgram,
    getCycleNumber,
    getMedicineArray
);

export const getPigletsGraftingProgram = (state) =>
    getGraftingProgram(state, { AnimalKind: AnimalTypes.PIG });

export const getPigletTreatmentArrayForSelectedAnimal = createSelector(
    getPigletTreatments,
    getCycles,
    getPigletsGraftingProgram,
    getMedicines,
    getCycleNumber,
    getPigletTreatmentArray
);

const getAnimal = (state, { animal }) => animal;

function getTreatmentStatusTODOList(days, parturition, castrationList, field) {
    let foundCastration = castrationList.find((item) => !!item.EvData[field]);
    if (foundCastration) {
        return {
            date: moment(foundCastration.EvTime),
            done: GRAFTING_RESULT.DONE,
        };
    }
    let date;
    if (days[0] === days[1]) {
        date = moment(parturition.EvTime).add(days[0], "days");
    } else {
        date = {
            Start: moment(parturition.EvTime).add(days[0], "days"),
            End: moment(parturition.EvTime).add(days[1], "days"),
        };
    }
    return { date, done: GRAFTING_RESULT.PENDING };
}

export const getAnimalTODOList = createSelector(
    getAnimal,
    getCycles,
    getGraftingsAndTreatments,
    getMedicines,
    getGraftingProgram,
    getPigletTreatments,
    getPigletsGraftingProgram,
    getPigletTreatmentSettings,
    (
        animal,
        cycles,
        graftingAndTreatments,
        medicines,
        graftingProgram,
        treatments,
        pigletsGraftingProgram,
        treatmentSettings
    ) => {
        let array = [];
        let pigletsTreatmentArray = [];
        let cycleNumber = null;
        if (animal.AnimalKind === AnimalTypes.SOW) {
            if (cycles.length === 0) return [];
            let lastCycle = cycles[cycles.length - 1];
            if (!lastCycle) return [];
            cycleNumber = lastCycle.cycle;
            let parturition = lastCycle[EventTypes.PARTURITION][0];
            if (parturition) {
                array.push({
                    name: "castration",
                    piglets: true,
                    ...getTreatmentStatusTODOList(
                        treatmentSettings.CastrationDays,
                        parturition,
                        lastCycle[EventTypes.CASTRATION],
                        "Castrate"
                    ),
                    field: "Castrate",
                });
                array.push({
                    name: "tails",
                    piglets: true,
                    ...getTreatmentStatusTODOList(
                        treatmentSettings.TailsDays,
                        parturition,
                        lastCycle[EventTypes.CASTRATION],
                        "Tails"
                    ),
                    field: "Tails",
                });
                array.push({
                    name: "teeth",
                    piglets: true,
                    ...getTreatmentStatusTODOList(
                        treatmentSettings.TeethDays,
                        parturition,
                        lastCycle[EventTypes.CASTRATION],
                        "Teeth"
                    ),
                    field: "Teeth",
                });
                array.push({
                    name: "weighting",
                    piglets: true,
                    ...getTreatmentStatusTODOList(
                        treatmentSettings.WeightingDays,
                        parturition,
                        lastCycle[EventTypes.CASTRATION],
                        "Weighting"
                    ),
                    field: "Weighting",
                });
            }
            // jesli maciora ma jakies prosiaki to pozwol dalej na nich wykonac leczenie
            if (getPigBalanceForSowFromState(animal, +new Date()) > 0) {
                pigletsTreatmentArray = getPigletTreatmentArray(
                    treatments,
                    cycles,
                    pigletsGraftingProgram,
                    medicines,
                    cycleNumber
                );
            }
        }
        let medicineArray = getMedicineArray(
            animal,
            cycles,
            graftingAndTreatments,
            medicines,
            graftingProgram,
            cycleNumber
        );
        array.push(...medicineArray);
        array.push(...pigletsTreatmentArray);
        array.sort((a, b) => {
            let first = getSortDate(a);
            let second = getSortDate(b);
            return first - second;
        });
        return array;
    }
);

export const getCycleDay = createSelector(
    getCycleTable,
    getSelectedAnimalForDocuments,
    (cycles, animal) => {
        try {
            if (animal.AnimalKind !== AnimalTypes.SOW) return null;
            let lastCycle = cycles[cycles.length - 1];
            console.log(lastCycle);
            if (!lastCycle) return null;
            /**
             * Liczenie ilosci dni
             * @param time
             * @return {number}
             */
            const calculateDays = (time) =>
                moment()
                    .startOf("day")
                    .diff(moment(time).startOf("day"), "days") + 1;
            /**
             * Liczenie ilości dni od poprzedniego cyklu
             * @return {null|{dayType: string, day: number}}
             */
            const calculateIdleDaysFromLastCycle = () => {
                let cycleBefore = cycles[cycles.length - 2];
                if (!cycleBefore) return null;
                let separationInBeforeCycle =
                    last(cycleBefore[EventTypes.SEPARATION]);
                let sowInfoInBeforeCycle =
                    last(cycleBefore[EventTypes.SOW_CYCLES]);
                if (!separationInBeforeCycle && !sowInfoInBeforeCycle) {
                    // nie ma zadnego eventu, wiec sprawdz czy byl porod i dolicz czas na porodowce z ustawien
                    let parturitionInCycleBefore =
                        last(cycleBefore[EventTypes.PARTURITION]);
                    if (!parturitionInCycleBefore) return null; // nie bylo porodu wiec nie wie co ma zwrocic
                    if (cycleBefore[EventTypes.MOMMY].length > 0) {
                        // byla mamka
                        let daysOnBirthRoomAsMommy = getTimeOnBirthRoomMommy();
                        let time = +moment(parturitionInCycleBefore.EvTime)
                            .startOf("day")
                            .add(daysOnBirthRoomAsMommy, "days");
                        return {
                            dayType: "IDLE",
                            day: calculateDays(time),
                        };
                    } else {
                        // nie byla mamka
                        let daysOnBirthRoom = getTimeOnBirthRoom();
                        let time = +moment(parturitionInCycleBefore.EvTime)
                            .startOf("day")
                            .add(daysOnBirthRoom, "days");
                        return {
                            dayType: "IDLE",
                            day: calculateDays(time),
                        };
                    }
                }
                // zwroc dni jalowe na podstawie daty odsadzenia z poprzedniego cyklu
                return {
                    dayType: "IDLE",
                    day: calculateDays(
                        separationInBeforeCycle
                            ? separationInBeforeCycle.EvTime
                            : sowInfoInBeforeCycle.EvTime
                    ),
                };
            };

            let separationInCycle = last(lastCycle[EventTypes.SEPARATION]);
            if (separationInCycle) {
                // byl odsad wiec zwroc dni jalowe
                return {
                    dayType: "IDLE",
                    day: calculateDays(separationInCycle.EvTime),
                };
            }
            let parturitionInCycle = lastCycle[EventTypes.PARTURITION][0];
            if (parturitionInCycle) {
                // byl porod wiec zwroc dzien laktacji
                return {
                    dayType: "LACTATION",
                    day: calculateDays(parturitionInCycle.EvTime),
                };
            }
            let inseminationInCycle = lastCycle[EventTypes.INSEMINATION][0];
            if (!inseminationInCycle) {
                // nie bylo inseminacji w cyklu, wiec sprawdz, czy w poprzednim cyklu bylo odsadzenie
                return calculateIdleDaysFromLastCycle();
            }
            let negativeUSGInCycle = lastCycle[EventTypes.USG].find(
                (item) => item.EvData.Pregnant === USG_STATE.NEGATIVE
            );
            let noPregnancyInCycle = lastCycle[EventTypes.NO_PREGNANCY][0];
            if (negativeUSGInCycle || noPregnancyInCycle) {
                // bylo negatywne badanie usg albo brak ciazy, wiec zwroc dni jalowe
                return calculateIdleDaysFromLastCycle();
            }
            // nie weszlo w zaden warunek, wiec pozostaje tylko dzien ciazy
            return {
                dayType: "PREGNANCY",
                day: calculateDays(inseminationInCycle.EvTime),
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    }
);

export const getTechnologyGroupForAnimal = createSelector(
    [getCycleTable],
    (cycles) => {
        let technologyGroupWeeks = getTechnologyGroupWeeks();
        let technologyGroupStart = getFirstTechnologyGroupStart();
        const lastCycle = last(cycles);
        const lastIsMommy = last(lastCycle?.[EventTypes.IS_MOMMY]);
        if (lastIsMommy) {
            return getTechnologyGroupName(moment.utc(lastIsMommy.EvData.GroupStart), {
                technologyGroupStart,
                technologyGroupWeeks
            });
        }
        const inseminations = get(lastCycle, `[${EventTypes.INSEMINATION}]`, []);
        let firstInsemination = first(inseminations);
        const usgs = get(lastCycle, `[${EventTypes.USG}]`, []);
        const lastUSG = get(last(usgs), 'EvData.Pregnant', 1);
        const abortion = get(lastCycle, `[${EventTypes.NO_PREGNANCY}]`, []);
        if (!firstInsemination || abortion.length || !lastUSG) return null; //JEŻELI NIE MA INSEMINACJI LUB USG 0 LUB PORONIENIE 
        return getTechnologyGroupName(moment.utc(firstInsemination.EvTime), {
            technologyGroupStart,
            technologyGroupWeeks
        });
    }
);

export const animalPigletsHadCastration = createSelector(
    getCycleTable,
    getSelectedAnimalForDocuments,
    getAnimalEvents,
    (cycles, animal, events) => {
        console.log(cycles);
        if (!animal) return null;
        if (animal.AnimalKind !== AnimalTypes.SOW) return null;
        let lastCycle = cycles[cycles.length - 1];
        let parturition = lastCycle?.[EventTypes.PARTURITION][0];
        // jezeli nie bylo porodu w obecnym cyklu, to znaczy ze nie mozna robic
        if (!parturition) return null;
        let separation = lastCycle?.[EventTypes.SEPARATION][0];
        // jezeli bylo odsadzenie to znaczy ze nie mozna robic
        if (separation) return null;
        let castrationStatus = {
            castrate: null,
            tails: null,
            teeth: null,
            weighting: null,
            weight: null,
        };
        let castrationEvents = events.filter(
            (item) =>
                item.EvCode === EventTypes.CASTRATION &&
                item.EvTime <= lastCycle.EndTime &&
                item.EvTime > lastCycle.StartTime
        );
        for (let event of castrationEvents) {
            console.log(event);
            if (event.EvData.Castrate) castrationStatus.castrate = event.EvTime;
            if (event.EvData.Tails) castrationStatus.tails = event.EvTime;
            if (event.EvData.Teeth) castrationStatus.teeth = event.EvTime;
            if (event.EvData.Weighting) {
                castrationStatus.weighting = event.EvTime;
                castrationStatus.weight = event.EvData.Weight;
            }
        }
        return castrationStatus;
    }
);

function getPlannedTreatmentDate(parturition, days) {
    if (days[0] === days[1])
        return { Start: moment(parturition.EvTime).add(days[0], "days") };
    return {
        Start: moment(parturition.EvTime).add(days[0], "days"),
        End: moment(parturition.EvTime).add(days[1], "days"),
    };
}

export const getCastrationData = createSelector(
    getCycleTable,
    getCycleNumber,
    getPigletTreatmentSettings,
    (cycleTable, cycleNumber, treatmentSettings) => {
        const result = {
            Castrate: { planned: null, done: null },
            Tails: { planned: null, done: null },
            Teeth: { planned: null, done: null },
            Weighting: { planned: null, done: null },
        };
        const selectedCycle = findLast(
            cycleTable,
            (cycle) => cycle.cycle === cycleNumber
        );
        if (!isNil(selectedCycle)) {
            const castrations = selectedCycle[EventTypes.CASTRATION];
            for (const castration of castrations) {
                const omittedEvData = pick(
                    castration.EvData,
                    Object.keys(result)
                );
                Object.entries(omittedEvData).forEach(([key, value]) => {
                    if (typeof value === "boolean" && value)
                        result[key].done = castration.EvTime;
                });
            }
            let parturition = selectedCycle[EventTypes.PARTURITION][0];
            if (parturition) {
                if (!result.Castrate.done)
                    result.Castrate.planned = getPlannedTreatmentDate(
                        parturition,
                        treatmentSettings.CastrationDays
                    );
                if (!result.Tails.done)
                    result.Tails.planned = getPlannedTreatmentDate(
                        parturition,
                        treatmentSettings.TailsDays
                    );
                if (!result.Teeth.done)
                    result.Teeth.planned = getPlannedTreatmentDate(
                        parturition,
                        treatmentSettings.TeethDays
                    );
                if (!result.Weighting.done)
                    result.Weighting.planned = getPlannedTreatmentDate(
                        parturition,
                        treatmentSettings.WeightingDays
                    );
            }
        }
        return result;
    }
);

export const makeGetWeightingEvents = () => {
    return createSelector([getAnimalEvents, getSelectedGroup, animalsInGroupSelector], (events, selectedGroup, animalsInGroup) => {
        const data = [];
        let insertedAnimals = 0;
        for (const event of events) {
            if (EventTypes.INSERTION === event.EvCode) insertedAnimals += get(event, "EvData.AnmCnt", 0);
            if (![EventTypes.WEIGHTING, EventTypes.SELL, EventTypes.FALL].includes(event.EvCode) || event.DtaDelTime) continue;
            data.push(event);
        }
        if (selectedGroup && insertedAnimals > 0) {
            const insertions = animalsInGroup.map((animal) => animal.DtaInTime).filter((timestamp) => timestamp)
                .sort((a, b) => a - b);
            data.push({
                EvCode: EventTypes.INSERTION,
                EvTime: insertions[0],
                EvData: { AnmCnt: insertedAnimals, Weight: selectedGroup.Weight }
            });
        }
        return data;
    });
};

export const makeGetFatMeasurementEvents = () => {
    return createSelector([getAnimalEvents], (events) => {
        return events.filter(({ EvCode }) => EvCode === EventTypes.FAT_MEASUREMENT);
    });
};

export const getCurrentCycle = createSelector(getCycleTable, (cycles) => {
    return cycles[cycles.length - 1] || null;
});

export const getSellList = createSelector([getAnimalEvents], (animalEvents) => {
    let sellList = [];
    for (let event of animalEvents) {
        if (event.EvCode !== EventTypes.SELL) continue;
        sellList.push({
            ...event,
            AnmWeight: event?.EvData?.Weight || 0,
        });
    }
    return sellList;
});


/**
 * zwraca objekt gdzie kluczem jest numer świni, a wartościa jest obiekt który ma id oraz czas wystąpienia najnowszego eventu z listy `allowedEventCodes` którą przekażemy
 * @type {OutputSelector<unknown, (function(*): {}) & MemoizedFunction, (res: *) => ((function(*): {}) & MemoizedFunction)>}
 */
export const getMostRecentEventIDs = createSelector([_getAnimalEvents], (animalEvents) => {
    return memoize((allowedEventCodes) => {
        const mostRecent = {};
        const comparator = (o1, o2) => {
            return (o1.EvTime - o2.EvTime) || (o1.DtaModTime - o2.DtaModTime);
        };
        for (let { AnmID, EvCode, EvTime, EvID, DtaDelTime, DtaModTime } of animalEvents) {
            if (DtaDelTime) continue;
            if (!allowedEventCodes.includes(EvCode)) continue;
            if (!mostRecent[AnmID]) {
                mostRecent[AnmID] = {
                    EvID,
                    EvTime,
                    DtaModTime
                };
                continue;
            }
            if (comparator(mostRecent[AnmID], { EvTime, DtaModTime }) < 0) {
                mostRecent[AnmID] = {
                    EvID,
                    EvTime,
                    DtaModTime
                };
            }
        }
        return mostRecent;
    }, (allowedEventCodes) => allowedEventCodes.join("_"));
});

export const getFallList = createSelector([getAnimalEvents], (animalEvents) => {
    let fallList = [];
    for (let event of animalEvents) {
        if (event.EvCode !== EventTypes.FALL) continue;
        fallList.push(event);
    }
    return fallList;
});

export const getReclassifyList = createSelector(
    [getAnimalEvents],
    (animalEvents) => {
        let reclassifyList = [];
        for (let event of animalEvents) {
            if (event.EvCode !== EventTypes.RECLASSIFY && event.EvCode !== EventTypes.RECLASSIFY_SOW) continue;
            let reclassifiedAnimals = get(
                event,
                "EvData.ReclassifiedAnimals",
                []
            );
            if (reclassifiedAnimals.length === 0) {
                const animal = animalsDB.getAnimalById(event?.AnmID);
                reclassifyList.push({ ...event, AnmNo1: animal?.AnmNo1 ? animal?.AnmNo1 : "-" });
            } else
                for (let { AnmNo1 } of reclassifiedAnimals)
                    reclassifyList.push({ ...event, AnmNo1 });
        }
        return reclassifyList;
    }
);

export const getCommentList = createSelector(
    [getCycleTable, getCommentDictionary, getSelectedGroup, getAnimalEvents],
    (cycles, comments, group, events) => {
        let data = [];
        if (group) {
            const commentEvents = events.filter((item) => item.EvCode === EventTypes.COMMENTS && !item.DtaDelTime);
            for (let comment of commentEvents) {
                for (let id of comment.EvData.Comments) {
                    data.push({
                        id,
                        comment: comments.find((item) => item.ID === id)?.Value || id,
                        date: comment.EvTime,
                        EvID: comment.EvID
                    });
                }
            }
            return uniqBy(data, (o) => o.date + o.comment);
        }
        for (let cycle of cycles) {
            for (let comment of cycle[EventTypes.COMMENTS]) {
                for (let id of comment.EvData.Comments) {
                    data.push({
                        cycle: cycle.cycle,
                        id,
                        comment: comments.find((item) => item.ID === id)?.Value || id,
                        date: comment.EvTime,
                        EvID: comment.EvID
                    });
                }
            }
        }
        return data;
    }
);

export const getExpectedDatesForAnimalCycle = createSelector(
    [getCycleTable],
    (cycles) => {
        const results = [];
        for (let index = 0; index < cycles.length; index++) {
            const cycle = cycles[index];
            const row = {};
            if (cycle[EventTypes.INSEMINATION].length > 0) {
                const inseminationTime = moment
                    .utc(cycle[EventTypes.INSEMINATION][0].EvTime)
                    .startOf("day");
                if (cycle[EventTypes.USG].length === 0) {
                    row[EventTypes.USG] = {
                        EvTime: +inseminationTime
                            .clone()
                            .add(getTimeFromInseminationToPregnancy(), "days"),
                    };
                }
                if (
                    cycle[EventTypes.PARTURITION].length === 0 &&
                    [true, USG_STATE.POSITIVE].includes(
                        get(
                            last(cycle[EventTypes.USG]),
                            "EvData.Pregnant",
                            USG_STATE.POSITIVE
                        )
                    )
                ) {
                    row[EventTypes.PARTURITION] = {
                        EvTime: +inseminationTime
                            .clone()
                            .add(getTimeFromInseminationToPartuition(), "days"),
                    };
                }
            }
            if (
                cycle[EventTypes.PARTURITION].length > 0 &&
                cycle[EventTypes.SEPARATION].length === 0
            ) {
                const { balance } = getPigBalanceByCycle(cycle);
                if (balance > 0) {
                    const parturitionTime = moment(
                        cycle[EventTypes.PARTURITION][0].EvTime
                    ).startOf("day");
                    row[EventTypes.SEPARATION] = {
                        EvTime: +parturitionTime.add(
                            getTimeOnBirthRoom(),
                            "days"
                        ),
                    };
                }
            }
            results.push(row);
        }
        return results;
    }
);

export const getTechnologyGroupTransfers = createSelector(
    [getAnimalEvents],
    (animalEvents) => {
        const technologyGroupTransfers = animalEvents.filter(
            (t) => t.EvCode === EventTypes.TECHNOLOGY_GROUP_TRANSFER
        );
        let technologyGroupWeeks = getTechnologyGroupWeeks();
        let technologyGroupStart = getFirstTechnologyGroupStart();
        return technologyGroupTransfers.map((t) => {
            const groupName = getTechnologyGroupName(t.EvData.InseminationTime, {
                technologyGroupStart,
                technologyGroupWeeks
            });
            return {
                ...t,
                TechnoGroupName: groupName,
            };
        });
    }
);

function getNumberOfTechnologyGroup(
    index,
    technologyGroupWeeks,
    startOfTechnologyGroup,
    week
) {
    if (technologyGroupWeeks === 1) return index;
    let diff = week.clone().diff(startOfTechnologyGroup, "weeks");
    return Math.floor(diff / technologyGroupWeeks);
}

export const getHeatsForSingleAnimal = (
    heats,
    currentDate,
    { technologyGroupWeeks, colorsForGroups, firstTechnologyGroupStart }
) => {
    const lastFifteenWeeks = [];
    for (let currentIndex = 0; currentIndex < 15; currentIndex++) {
        const date = currentDate.clone().subtract(currentIndex, "weeks");
        const weekNumber = date.clone().isoWeek();
        const technologyGroupNumber = getNumberOfTechnologyGroup(
            currentIndex,
            technologyGroupWeeks,
            firstTechnologyGroupStart,
            date
        );
        const color =
            colorsNonInvasive[
            colorsForGroups[technologyGroupNumber % colorsForGroups.length]
            ];
        lastFifteenWeeks.push({
            weekNumber,
            year: date.clone().year(),
            start: +date.clone().startOf("isoWeek"),
            end: +date.clone().endOf("isoWeek"),
            color,
            heats: [],
        });
    }
    heats.sort((a, b) => b.EvTime - a.EvTime);
    for (let heat of heats) {
        const matchedWeek = lastFifteenWeeks.find((x) => {
            const startWeek = moment()
                .year(x.year)
                .isoWeek(x.weekNumber)
                .startOf("isoWeek");
            const endWeek = startWeek.clone().endOf("isoWeek");
            return moment(heat.EvTime).isBetween(
                startWeek,
                endWeek,
                null,
                "[]"
            );
        });
        if (matchedWeek) matchedWeek.heats.push(heat);
    }
    return lastFifteenWeeks;
};

export const getHeatList = createSelector([getAnimalEvents], (animalEvents) => {
    const heats = animalEvents.filter(({ EvCode }) => EvCode === EventTypes.HEAT);
    if (heats.length === 0) return [];
    const technologyGroupStart = getFirstTechnologyGroupStart();
    const technologyGroupStartDay = moment
        .utc(technologyGroupStart)
        .isoWeekday();
    const currentDate = moment
        .utc()
        .startOf("isoWeek")
        .isoWeekday(technologyGroupStartDay);
    return getHeatsForSingleAnimal(heats, currentDate, {
        technologyGroupWeeks: getTechnologyGroupWeeks(),
        colorsForGroups: getTechnologyGroupColors(),
        firstTechnologyGroupStart: getFirstTechnologyGroupStart(),
    });
});

export const getTransferEvents = createSelector([getAnimalEvents], (animalEvents) => {
    const data = [];
    const transfers = animalEvents.filter((e) => e.EvCode === EventTypes.TRANSFER);
    const insertions = animalEvents.filter((e) => e.EvCode === EventTypes.INSERTION).map((el) => ({
        ...el,
        EvData: { DstID: el.EvData.PlcmntID, initial: true }
    }));
    data.push(...transfers, ...insertions);
    return data.sort((a, b) => b.EvTime - a.EvTime);
});

export const getRemovedEvents = createSelector([getAnimalEvents], (animalEvents) => {
    const data = [];

    for (let e of animalEvents) { //Dodawanie usuniętych zwierząt z grup
        if (e.EvCode === EventTypes.TRANSFER && e.EvData.RemovedReason) {
            data.push({
                AnmID: e.EvData.NewGroupID,
                EvTime: e.EvTime,
                OperID: e.EvData.OperID,
                Reason: e.EvData.RemovedReason,
            });
        }
    }

    return data.sort((a, b) => b.EvTime - a.EvTime);
});

export const getGeneticsInformationForSelectedAnimal = createSelector([getSelectedAnimalForDocuments, getRaces], (selectedItem, races) => {
    const geneticsInformation = {};
    const {
        Genetics: {
            BoarID,
            SowID,
            MotherTattoo,
            MotherNumber,
            FatherNumber,
            FatherTattoo,
            FatherRace,
            MotherRace,
            TattooNumber
        } = {}
    } = selectedItem;
    geneticsInformation.TattooNumber = selectedItem?.AnmNo2 || TattooNumber || "-";
    if (![AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW].includes(selectedItem.AnimalKind)) return geneticsInformation;
    const mother = animalsDB.getAnimalById(SowID, { joinEvents: false, findDeleted: true });
    const father = animalsDB.getAnimalById(BoarID, { joinEvents: false, findDeleted: true });
    const getRaceFromDictionary = (ID) => get(races, "WData", []).find((race) => race.ID === ID);
    const boarRaceInfo = getRaceFromDictionary(father?.Race || FatherRace);
    const sowRaceInfo = getRaceFromDictionary(mother?.Race || MotherRace);
    geneticsInformation.FatherAnmNo1 = father?.AnmNo1 || FatherNumber || selectedItem?.FatherNumber || "-";
    geneticsInformation.FatherTattoo = father?.AnmNo2 || FatherTattoo || selectedItem?.FatherTattoo || "-";
    geneticsInformation.FatherAnmID = father?.AnmID;
    geneticsInformation.MotherAnmNo1 = mother?.AnmNo1 || MotherNumber || selectedItem?.MotherNumber || "-";
    geneticsInformation.MotherTattoo = mother?.AnmNo2 || MotherTattoo || selectedItem?.MotherTattoo || "-";
    geneticsInformation.MotherAnmID = mother?.AnmID;
    if (boarRaceInfo) {
        geneticsInformation.FatherRaceName = boarRaceInfo.Value;
        geneticsInformation.FatherRaceCode = boarRaceInfo.Code;
        geneticsInformation.FatherRaceColor = boarRaceInfo.Color;
        geneticsInformation.FatherRaceValue = father?.Race || FatherRace;
    }
    if (sowRaceInfo) {
        geneticsInformation.MotherRaceName = sowRaceInfo.Value;
        geneticsInformation.MotherRaceCode = sowRaceInfo.Code;
        geneticsInformation.MotherRaceColor = sowRaceInfo.Color;
        geneticsInformation.MotherRaceValue = mother?.Race || MotherRace;
    }
    return geneticsInformation;
});

export const getMergedInsertionEventsForSubgroupAnimal = createSelector([getAnimalEvents], (_groupEvents) => {
    const insertionEvents = _groupEvents.filter((item) => item.EvCode === EventTypes.INSERTION && item.EvData?.AnmCnt > 1);
    const insertions = {};
    const insertionsIDs = {};
    for (const insertion of insertionEvents) {
        const time = +moment(insertion.EvTime).startOf("day");
        const key = `mergedInsertions_${time}_${insertion.AnmID}`;
        const anmCnt = insertion.EvData.AnmCnt;
        const mergedInsertions = insertions[key];
        if (mergedInsertions === undefined) {
            insertions[key] = 0;
            insertionsIDs[`${time}_${insertion.AnmID}`] = [];
        }
        insertions[key] = insertions[key] + anmCnt;
        insertionsIDs[`${time}_${insertion.AnmID}`].push(insertion.EvID);
    }
    return { insertions, insertionsIDs };
});

export const getLocationDays = createSelector([getSelectedAnimalForDocuments, getAnimalEvents, getGroupBirthTime], (selectedItem, animalEvents, groupBirth) => {
    return getLocationDaysForGroup(selectedItem, animalEvents, groupBirth);
});

export const getAnimalCountForLocationAndTimestampFunc = createSelector([getLocationDays], (animalNumberPerLocation) => {
    return memoize((locationId, timestamp, allowNegativeNumbers) => {
        const data = animalNumberPerLocation[locationId] || [];
        let number = 0;
        let currentTime = 0;
        data.forEach(({ date, value }) => {
            if (isFiniteNumber(value) && (timestamp >= date)) {
                if (date >= currentTime) {
                    number = value;
                    currentTime = date;
                }
            }
        });
        if (allowNegativeNumbers) {
            return isFiniteNumber(number) ? number : 0;
        }
        return Math.max(0, number);  // yep you've guessed it
    }, (locationId, timestamp, allowNegativeNumbers) => `${locationId}_${timestamp}_${+!!allowNegativeNumbers}`);
});

export const getDetailedLocationDays = createSelector([getLocationDays], (data) => {
    return getDetailedLocationDaysForGroup(data);
});

export const getTattooList = createSelector([getAnimalEvents], (animalEvents) => {
    const data = [];
    const cycles = preEvents(animalEvents, null)?.cycleTable || [];
    for (const row of cycles) {
        for (const tattoo of row[EventTypes.TATTOO]) {
            for (const tattooedAnimal of get(tattoo, "EvData.TattooedAnimals", [])) {
                data.push({ ...tattooedAnimal, cycle: row.cycle, AnmID: tattooedAnimal.MommyID });
            }
        }
    }
    return data;
});

export const getSeparationToMommyGiltsList = createSelector([getAnimalEvents], (animalEvents) => {
    const data = [];
    const cycles = preEvents(animalEvents, null)?.cycleTable || [];
    for (const row of cycles) {
        for (const mommy of row[EventTypes.SEPARATION_TO_MOMMY_GILTS]) {
            data.push({ ...(mommy.EvData || {}), cycle: row.cycle, AnmID: get(mommy, "EvData.MommyID") });
        }
    }
    return data;
});

export const getAnimalMedicineCost = createSelector(getAnimalEvents, getEconomy, (events, economy) => {
    const graftings = events.filter(item => item.EvCode === EventTypes.GRAFTING);
    let cost = 0;
    for (let grafting of graftings) {
        const { EvData: { Price } } = grafting;
        cost += Price || 0;
    }
    return cost;
});

export const getFallHistogram = createSelector(getAnimalEvents, getFallReasons, (events, fallReasons) => {
    const falls = events.filter(item => item.EvCode === EventTypes.FALL);
    const fallHistogram = {};
    for (let fall of falls) {
        const { EvData: { Reasn, AnmCnt, PlcmntID } } = fall;
        const sectorType = buildingsDB.getSectorTypeByChamberID(PlcmntID);
        const sectorName =
            sectorType === SectorType.PORK_HOUSE
                ? "porkhouse"
                : sectorType === SectorType.PIGLET_HOUSE
                    ? "piglethouse"
                    : "other";
        if (Reasn) {
            if (!fallHistogram[Reasn]) {
                fallHistogram[Reasn] = {
                    'porkhouse': 0,
                    'piglethouse': 0,
                    'other': 0,
                };
            }
            fallHistogram[Reasn][sectorName] = (fallHistogram[Reasn][sectorName] || 0) + (AnmCnt || 1);
        }
    }
    const object = Object.entries(fallHistogram).map(([reason, count]) => {
        const { other, piglethouse, porkhouse } = count;
        const dictionary = fallReasons.WData.find(item => item.ID === reason);
        return ({ reason: dictionary?.Value || reason, other, piglethouse, porkhouse });
    });
    return object;
});

export const getSelectedAnimalIDs = createSelector([getSelectedAnimalForDocuments], (animal) => {
    if (animal) return [animal.AnmID];
    return [];
});