import DevTypes from '@wesstron/utils/Api/constants/devTypes'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {connectModal} from 'redux-modal'
import {getAggregatedData} from '../../../actions/aggregatedActions'
import useDevicesInLocations from '../../../hooks/useDevicesInLocations'
import {makeGetAggregatedDataForDevices} from '../../../selectors/aggregatedSelector'
import {makeGetBuildingsMap} from '../../../selectors/buildingsSelector'
import {
    calculateDataForScaleChart,
    calculateDataForSiloRadarChart,
    calculateDataForTemperatureChart,
    calculateDataForWaterHourlyChart,
    createTickAndMinDomainByDate,
    temperatureConverter
} from '../../../utils/ChartsUtils'
import {colorsNonInvasive} from '../../../utils/ColorUtils'
import {isFiniteNumber} from '../../../utils/MathUtils'
import {getScaleUnit, getWaterFlowUnit} from '../../../utils/SettingsUtils'
import {convertVolumeUnitTo, convertWeightUnitTo, getUnit} from '../../../utils/UnitUtils'
import Chart from '../../basics/chart/Chart'
import LoadingComponent from '../../loading/LoadingComponent'
import ModalBody from '../ModalBody'
import ModalHeader from '../ModalHeader'
import {flatten, groupBy} from 'lodash'
import moment from 'moment'
import {UnitTypes} from '../../../constans/unitTypes'
import {getAlias} from "../../../utils/DevicesUtils";
import {textIdToObject} from "../../../utils/FarmMapUtils";

export const ModalName = 'DeviceChartDetailsModal';

function DeviceChartDetailsModalWater({locations, time}) {

    const {t} = useTranslation();

    const params = useRef([DevTypes.WATER_FLOW_METER])

    const dispatch = useDispatch();

    const waterUnit = useRef(getWaterFlowUnit());

    const getAggregatedDataSelector = useMemo(makeGetAggregatedDataForDevices, []);

    const devices = useDevicesInLocations(locations, params.current);

    const {loading, ...aggData} = useSelector(state => getAggregatedDataSelector(state, devices, time, time));

    useEffect(() => {
        for (let device of devices) {
            dispatch(getAggregatedData(device, {AggDataTime: time}));
        }
    }, [dispatch, time]); // eslint-disable-line

    const chartData = useMemo(() => {
        const data = {};
        for (let DevID in aggData) {
            const chartData = calculateDataForWaterHourlyChart(aggData[DevID], time);
            for (let row of chartData) {
                if (!data[row.name]) data[row.name] = {name: row.name};
                data[row.name][DevID] = row.consumption;
            }
        }
        return Object.values(data).sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}));
        ;
    }, [aggData, time]);

    const chartValueConverter = useCallback((value) => isFiniteNumber(value) ? convertVolumeUnitTo(value, {
        showUnit: false,
        unit: waterUnit.current,
        rawValue: true,
        fixed: 5,
    }) : null, []);

    const chartDef = useMemo(() => {
        const colors = Object.values(colorsNonInvasive);
        const unit = getUnit("volume", waterUnit.current);
        return devices.map((device, index) => {
            const color = colors[index % colors.length];
            return {
                color,
                dataKey: device.DevID,
                name: device.Name,
                valueConverter: chartValueConverter,
                unit
            }
        })
    }, [devices, chartValueConverter]);

    return (
        <>
            <LoadingComponent isLoading={loading}/>
            <Chart
                type={"Bar"}
                dataDef={chartDef}
                data={chartData}
                Yaxis={{
                    name: t("charts.waterHourlyChart.consumption"),
                }}
                Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                }}
                enableFullscreen={false}
            />
        </>
    )
}

function DeviceChartDetailsModalForage({locations, time}) {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const scaleUnit = useRef(getScaleUnit());

    const params = useRef([DevTypes.SCALE, DevTypes.SILO_RADAR])

    const getBuildingsMap = useMemo(makeGetBuildingsMap, []);
    const getAggregatedDataSelector = useMemo(makeGetAggregatedDataForDevices, []);

    const devices = useDevicesInLocations(locations, params.current);

    const buildingsMap = useSelector(getBuildingsMap);

    const groupedBuildings = useMemo(() => {
        const values = buildingsMap.values();
        return groupBy([...values], "parentId");
    }, [buildingsMap]);

    const availableLocations = useMemo(() => {
        const tmp = [];

        function getChildrenIDs(locationID) {
            const array = groupedBuildings[locationID];
            if (!array) return [];
            const children = [];
            for (let row of array) {
                children.push(row.id, ...getChildrenIDs(row.id));
            }
            return children;
        }

        for (let location of locations) {
            const children = getChildrenIDs(location);
            tmp.push(location, ...children);
        }
        return tmp;
    }, [groupedBuildings, locations]);

    const mappedDevices = useMemo(() => {
        return flatten(devices.map(device => {
            if (device.DevType === DevTypes.SCALE) {
                const possibleIndexes = [];
                for (let location of availableLocations) {
                    const outputs = device.PlcmntID.filter(item => item.PlcmntID === location && isFiniteNumber(item.Adr)).map(({Adr}) => Adr);
                    if (outputs.length) {
                        possibleIndexes.push(...outputs);
                    }
                }
                return possibleIndexes.map(index => ({
                    DevID: `${device.DevID}_${index}`,
                    FarmID: device.FarmID,
                    Name: getAlias(device, index) || device.Name
                }));
            } else {
                return [{DevID: device.DevID, FarmID: device.FarmID, Name: getAlias(device) || device.Name}];
            }
        }));
    }, [devices, availableLocations]);

    useEffect(() => {
        for (let device of mappedDevices) {
            const {id: DevID, index} = textIdToObject(device.DevID);
            dispatch(getAggregatedData({DevID, FarmID: device.FarmID}, {
                AggDataTime: time,
                index: index !== undefined ? +index : undefined
            }));
        }
    }, [dispatch, time]); // eslint-disable-line

    const {loading, ...aggData} = useSelector(state => getAggregatedDataSelector(state, mappedDevices, time, time));

    const chartData = useMemo(() => {
        const data = {};
        for (let id in aggData) {
            const [DevID] = id.split("_");
            const device = devices.find(item => item.DevID === DevID);
            const chartData = device.DevType === DevTypes.SILO_RADAR ? calculateDataForSiloRadarChart(aggData[id], time) : calculateDataForScaleChart(aggData[id], time);
            for (let row of chartData) {
                if (!data[row.name]) data[row.name] = {name: row.name};
                data[row.name][id] = row.weight;
            }
        }
        return Object.values(data).sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}));
    }, [aggData, time, devices]);

    const weightConverter = useCallback((value) => {
        return convertWeightUnitTo(value, {
            rawValue: true,
            unit: scaleUnit.current,
            acceptNil: true,
            fixed: scaleUnit.current ? 2 : 0
        });
    }, [])

    const chartDef = useMemo(() => {
        const colors = Object.values(colorsNonInvasive);
        const unit = getUnit("weight", scaleUnit.current);
        return mappedDevices.map((device, index) => {
            const color = colors[index % colors.length];
            return {
                color,
                dataKey: device.DevID,
                name: device.Name,
                valueConverter: weightConverter,
                unit,
                opacity: 0.2
            }
        })
    }, [mappedDevices, weightConverter]);

    return (
        <>
            <LoadingComponent isLoading={loading}/>
            <Chart dataDef={chartDef} data={chartData} Yaxis={{name: t("settings.forageWeight")}}
                   Xaxis={{name: t("time"), dataKey: "name"}}
                   showDomainInput enableFullscreen={false}/>
        </>
    )
}

function DeviceChartDetailsModalCLimate({locations, time}) {

    const {t} = useTranslation();

    const params = useRef([DevTypes.CLIMATE, DevTypes.CLIMATE_SK3, DevTypes.CLIMATE_SK4]);

    const dispatch = useDispatch();

    const getAggregatedDataSelector = useMemo(makeGetAggregatedDataForDevices, []);

    const devices = useDevicesInLocations(locations, params.current);

    const {loading, ...aggData} = useSelector(state => getAggregatedDataSelector(state, devices, time, time));

    useEffect(() => {
        for (let device of devices) {
            dispatch(getAggregatedData(device, {AggDataTime: time}));
        }
    }, [dispatch, time]); // eslint-disable-line

    const chartData = useMemo(() => {
        const data = {};
        for (let DevID in aggData) {
            const chartData = calculateDataForTemperatureChart(aggData[DevID], time);
            console.log(chartData);
            for (let row of [...chartData.temps.values()]) {
                if (!data[row.name]) data[row.name] = {name: row.name};
                data[row.name][DevID] = row.temperature;
            }
        }
        return Object.values(data).sort((a, b) => a - b);
    }, [aggData, time]);

    const tickFormatter = useCallback(value => {
        return moment(value).format("HH:mm");
    }, []);

    const chartDef = useMemo(() => {
        const colors = Object.values(colorsNonInvasive);
        const unit = getUnit("temperature", UnitTypes.SMALL);
        return devices.map((device, index) => {
            const color = colors[index % colors.length];
            return {
                color,
                dataKey: device.DevID,
                name: device.Name,
                unit,
                opacity: 0,
                valueConverter: temperatureConverter
            }
        })
    }, [devices]);

    const {ticks, domainMin} = useMemo(() => {
        return createTickAndMinDomainByDate(time, 23);
    }, [time]);

    return (
        <>
            <LoadingComponent isLoading={loading}/>
            <Chart
                dataDef={chartDef}
                data={chartData}
                Yaxis={{
                    name: t("chamber.chart.temperature")
                }}
                Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [domainMin, "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }}
                tooltipLabelFormatter={tickFormatter} showDomainInput enableFullscreen={false}/>
        </>
    )
}

function DeviceChartDetailsModal({handleHide, show, type, locations, time}) {

    const {t} = useTranslation();

    const Component = useMemo(() => {
        switch (type) {
            case "water":
                return DeviceChartDetailsModalWater;
            case "forage":
                return DeviceChartDetailsModalForage;
            case "climate":
                return DeviceChartDetailsModalCLimate;
            default:
                return null;
        }
    }, [type])

    return (
        <Modal onHide={handleHide} show={show} fullscreen>
            <ModalHeader title={t("chartDetailsData", {day: moment.utc(time).format("L")})} onCloseClick={handleHide}/>
            <ModalBody className={ModalName}>
                {
                    Component && <Component locations={locations} time={time}/>
                }
            </ModalBody>
        </Modal>
    )
}

export default connectModal({name: ModalName})(DeviceChartDetailsModal);