import devicesDB from "../database/devicesDB";
import animalsDB from "../database/animalsDB";
import _ from "lodash";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import { FeedingSortType } from "../selectors/feedingSelector";
import { createAnimalDict } from "../utils/FeedingUtils";
import { NRFWorkType } from "../constans/feedingTypes";
import { logActionForCypress } from "../utils/CypressUtils";

export const ViewType = {
    ADVANCED: 0,
    SIMPLE: 1
};

export const initialValue = {
    feeding: {},
    chamber: {},
    chamberDeviceDict: {},
    selectedChamber: null,
    feedingForPig: {
        feeding: {},
        loading: {},
        lastSync: null,
        syncing: false,
        amount: 0,
        inserted: 0
    },
    viewType: ViewType.SIMPLE,
    data: [],
    event: null
};

const initialFeedingObj = {
    data: {},
    devices: {},
    feedingSort: FeedingSortType.BY_DEFAULT_ASC,
    feedingFilter: "",
    animalDict: new Map()
};

export default function feedingReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_FEEDING_EVENT": {
            return {
                ...state,
                event: action.payload
            }
        }
        case "SET_VIEW_TYPE": {
            return {
                ...state,
                viewType: action.payload
            }
        }
        case "SET_CHAMBER_FEEDING_DATA": {
            return {
                ...state,
                data: action.payload
            }
        }
        case "FEEDING_DESTROY": {
            const chamberId = action.payload.id;
            const feeding = Object.assign({}, state.feeding);
            const chamber = Object.assign({}, state.chamber);
            const chamberDeviceDict = Object.assign({}, state.chamberDeviceDict);
            delete chamberDeviceDict[chamberId];
            delete chamber[chamberId];
            delete feeding[chamberId];
            return {
                ...state,
                feeding,
                chamber,
                chamberDeviceDict,
                selectedChamber: chamberId === state.selectedChamber ? null : state.selectedChamber
            }
        }
        case "FEEDING_INITIALIZE": {
            const { id, chamber, chamber: { Boxes = [], IndividualFeeding = false }, options } = action.payload;
            const dispensers = options.dispensers ? options.dispensers : _.uniqBy(devicesDB.getDevicesInPlcmnt(chamber, { showDevicesInChildren: IndividualFeeding }).filter((device) => [DevType.DISPENSER, DevType.DISPENSER_NRF].includes(device.DevType)), e => e.DevID);
            const chamberDeviceDict = Object.assign({}, state.chamberDeviceDict);
            const deviceToStanding = {};
            const feeding = {}; //new Map([...state.feeding]);
            //mapa jest zbyt wolna
            const data = {};
            const devices = {};
            const modTime = +new Date();
            const animalDict = createAnimalDict(chamber, options);
            const feedingObj = Object.assign({}, initialFeedingObj);
            const chamberMap = {}; //new Map([...state.chamber]);
            if (IndividualFeeding) {
                Boxes.forEach(box => {
                    if (data[box.BID]) {
                        delete data[box.BID].animal;
                        delete data[box.BID].receiver;
                    }
                    data[box.BID] = {
                        ...(data[box.BID] || []),
                        id: box.BID,
                        box: box,
                        name: box.BoxesName,
                        animal: animalDict.get(box.BID),
                        modTime
                    };
                });

                dispensers.forEach(o => {
                    let boxId;
                    if (o.DevType === DevType.DISPENSER) {
                        _.get(o, "PlcmntID", []).forEach(a => {
                            boxId = a.PlcmntID;
                            if (boxId) {
                                deviceToStanding[`${o.DevID}_${a.Adr}`] = boxId;
                                chamberDeviceDict[`${o.DevID}_${a.Adr}`] = id;
                                if (data[boxId]) {
                                    data[boxId].receiver = {
                                        deviceId: o.DevID,
                                        index: a.Adr,
                                        gatewayId: o.GatewayID
                                    };
                                    data[boxId].device = o;
                                }

                            }
                        })
                    } else {
                        boxId = _.get(o, "PlcmntID[0].PlcmntID");
                        if (boxId) {
                            deviceToStanding[`${o.DevID}`] = boxId;
                            chamberDeviceDict[`${o.DevID}`] = id;
                            if (data[boxId]) {
                                data[boxId].receiver = {
                                    deviceId: o.DevID,
                                    gatewayId: o.GatewayID
                                };
                                data[boxId].device = o;
                            }

                        }
                    }
                })
            } else {
                for (let rfid of animalDict.keys()) {
                    const animal = animalDict.get(rfid) || animalsDB.getAnimalByRfid(rfid, _.get(Object.values(feedingObj.devices), "[0].device.FarmID"));
                    data[rfid] = {
                        id: rfid,
                        animal: animal,
                        feed: {},
                        modTime
                    }
                }
                dispensers.forEach(o => {
                    if (o.DevType === DevType.DISPENSER_NRF) {
                        devices[o.DevID] = {
                            receiver: {
                                deviceId: o.DevID,
                                gatewayId: o.GatewayID
                            },
                            id: o.DevID,
                            connected: false,
                            name: o.Name,
                            device: o,
                            modTime
                        };
                        chamberDeviceDict[o.DevID] = chamber.CID
                    }
                })
            }
            feedingObj.data = data;
            feedingObj.devices = devices;
            chamberMap[id] = {
                deviceToStanding,
                animalDict,
                individualFeeding: !!chamber.IndividualFeeding
            };
            feeding[id] = feedingObj;
            return { ...state, chamberDeviceDict, feeding, chamber: chamberMap, selectedChamber: id }
        }
        case "GET_FEED_STATE_DELTA":
        case "GET_FEED_STATE": {
            logActionForCypress(action)
            const payload = action.payload;
            const isPartial = action.type.endsWith("_DELTA");
            const modTime = +new Date();
            const feeding = Object.assign({}, state.feeding);
            for (let [deviceId, shadow] of payload.entries()) {
                const chamberId = state.chamberDeviceDict[deviceId];
                const isInitialized = !!chamberId && state.chamber[chamberId];
                //bez sensu liczyc cos jak ktos nie jest na widoku
                if (isInitialized) {
                    const feedingObj = Object.assign({}, feeding[chamberId] || initialFeedingObj);
                    const { individualFeeding, deviceToStanding, animalDict } = isInitialized;
                    if (individualFeeding) {
                        let boxId = deviceToStanding[deviceId];
                        //zamiast mapy obiekt bo szybszy
                        const obj = boxId && feedingObj.data[boxId];
                        if (obj) {
                            obj.animal = animalDict.get(boxId);
                            if (isPartial) {
                                if (obj.feed) {
                                    feedingObj.data[boxId] = {
                                        ...obj,
                                        ...shadow,
                                        connected: true,
                                        modTime
                                    };
                                }
                            } else {
                                feedingObj.data[boxId] = {
                                    ...obj,
                                    ...shadow,
                                    connected: true,
                                    dateTime: shadow.oldest,
                                    modTime
                                };
                            }
                        }

                    } else {
                        if (feedingObj.devices[deviceId]) {
                            if (isPartial) {
                                if (feedingObj.devices[deviceId].connected) {
                                    feedingObj.devices[deviceId] = {
                                        ...feedingObj.devices[deviceId],
                                        connected: true,
                                        ..._.omit(shadow, ["RFID"]),
                                        id: deviceId,
                                        modTime
                                    }
                                }
                            } else {
                                feedingObj.devices[deviceId] = {
                                    ...feedingObj.devices[deviceId],
                                    connected: true,
                                    ..._.omit(shadow, ["RFID"]),
                                    id: deviceId,
                                    dateTime: shadow.oldest,
                                    modTime
                                }
                            }

                        }
                    }
                    feeding[chamberId] = feedingObj;
                }
            }
            return { ...state, feeding: feeding }
        }
        case "GET_FEED_RFID_STATE_DELTA":
        case "GET_FEED_RFID_STATE": {
            const payload = action.payload;
            // const dateTime = +new Date();
            const modTime = +new Date();
            const feeding = Object.assign({}, state.feeding);
            for (let [chamberId, shadow] of payload.entries()) {
                const isInitialized = !!chamberId && state.chamber[chamberId];
                if (isInitialized) {
                    const feedingObj = Object.assign({}, (feeding[chamberId] || initialFeedingObj));
                    const { individualFeeding, animalDict } = isInitialized;
                    if (!individualFeeding) {
                        Object.keys(shadow).forEach(rfid => {
                            if (shadow[rfid]) {
                                const animal = animalDict.get(rfid) || animalsDB.getAnimalByRfid(rfid, _.get(Object.values(feedingObj.devices), "[0].device.FarmID"));
                                if (!feedingObj.data[rfid]) {
                                    feedingObj.data[rfid] = {};
                                }
                                feedingObj.data[rfid] = {
                                    ...feedingObj.data[rfid],
                                    ...shadow[rfid],
                                    workType: NRFWorkType.RFID,
                                    animal: animal,
                                    id: rfid,
                                    name: rfid,
                                    connected: true,
                                    dateTime: shadow[rfid].oldest,
                                    modTime

                                }

                            } else {
                                //usuniecie nieuzywanej swini
                                delete feedingObj.data[rfid];
                            }

                        });
                        feeding[chamberId] = feedingObj;
                    }
                }
            }
            return { ...state, feeding: feeding }
        }
        case "FEEDING_SELECT": {
            const feeding = Object.assign({}, state.feeding);
            const chamberData = Object.assign({}, feeding[action.payload.chamberId]);
            if (chamberData) {
                if (chamberData.data[action.payload.id]) {
                    chamberData.data[action.payload.id].selected = !chamberData.data[action.payload.id].selected;
                }
                feeding[action.payload.chamberId] = { ...chamberData };
            }
            return {
                ...state,
                feeding
            }
        }
        case "FEEDING_FILTER": {
            const { chamberId, feedingFilter } = action.payload;
            const feeding = Object.assign({}, state.feeding);
            if (chamberId && feeding[chamberId]) {
                const tmp = Object.assign({}, feeding[chamberId]);
                tmp.feedingFilter = feedingFilter || "";
                feeding[chamberId] = tmp;
            }
            return { ...state, feeding };
        }
        case "FEEDING_SORT": {
            const { chamberId, feedingSort } = action.payload;
            const feeding = state.feeding;
            if (chamberId && feeding[chamberId]) {
                const tmp = Object.assign({}, feeding[chamberId]);
                tmp.feedingSort = Object.values(FeedingSortType).includes(feedingSort) ? feedingSort : tmp.feedingSort;
                feeding[chamberId] = tmp;
            }
            return { ...state, feeding };
        }
        case "FEEDING_UPDATE_ANIMALS": {
            const { chamberId, chamber, options } = action.payload;
            const feeding = Object.assign({}, state.feeding);
            const chamberMap = Object.assign({}, state.chamber);
            if (chamberId && feeding[chamberId] && chamberMap[chamberId]) {
                const modTime = +new Date();
                const animalDict = createAnimalDict(chamber, options);
                const tmpFeeding = Object.assign({}, feeding[chamberId]);
                const tmpChamber = Object.assign({}, chamberMap[chamberId]);
                tmpChamber.animalDict = animalDict;
                Object.values(tmpFeeding.data).forEach(o => {
                    tmpFeeding.data[o.id] = {
                        ...o,
                        animal: animalDict.get(o.id),
                        modTime
                    }
                }
                );
                chamberMap[chamberId] = tmpChamber;
                feeding[chamberId] = tmpFeeding;
            }
            return { ...state, feeding, chamber: chamberMap };
        }
        case "FEEDING_SELECT_ALL":
        case "FEEDING_SELECT_NONE": {
            const { chamberId } = action.payload;
            const feeding = Object.assign({}, state.feeding);
            if (chamberId && feeding[chamberId]) {
                const tmp = Object.assign({}, feeding[chamberId]);
                Object.keys(tmp.data).forEach(id => {
                    tmp.data[id].selected = action.type.endsWith("_ALL");
                });
                feeding[chamberId] = tmp;
            }
            return { ...state, feeding }
        }
        case "SET_FEEDING_FOR_PIG": {
            let inserted = state.feedingForPig.inserted + Object.keys(action.payload).length;
            return {
                ...state,
                feedingForPig: {
                    ...state.feedingForPig,
                    feeding: { ...state.feedingForPig.feeding, ...action.payload },
                    inserted,
                    syncing: state.feedingForPig.syncing ? inserted !== state.feedingForPig.amount : false,
                    lastSync: state.feedingForPig.syncing && inserted === state.feedingForPig.amount ? new Date() : state.feedingForPig.lastSync
                }
            };
        }
        case "SET_FEEDING_FOR_PIG_LOADING": {
            return {
                ...state,
                feedingForPig: {
                    ...state.feedingForPig,
                    loading: {
                        ...state.feedingForPig.loading,
                        ...action.payload
                    }
                }
            }
        }
        case "SET_SYNCING_STATUS":
            return {
                ...state,
                feedingForPig: {
                    ...state.feedingForPig,
                    syncing: action.payload,
                    inserted: 0,
                    lastSync: !action.payload ? new Date() : state.feedingForPig.lastSync
                }
            };
        case "SET_SYNCING_AMOUNT":
            return { ...state, feedingForPig: { ...state.feedingForPig, amount: action.payload } };
        case "FEEDING_CLEAR":
            return { ...initialValue, viewType: state.viewType } // optymalizacja renderow na karmieniu, po wejsciu potrafilo zmienic widok i renderowac podwojnie
        case "USER_LOGOUT":
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
