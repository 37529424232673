import store from "../store/store";
import { get, isArray, isEqual } from "lodash";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import * as UserTypes from "@wesstron/utils/Api/constants/userTypes";
import i18next from "../i18n";
import { LicPackageKeys, LicPackageLevel } from "../constans/roles";
import buildingsDB from "../database/buildingsDB";
import memoizeOne from "memoize-one";
import FlatRoleTypes from "@wesstron/utils/Api/constants/flatRoleTypes";
import { RoleStage } from "../constans/roleStages";
import { AnimalTypes } from "../constans/animalTypes";
import { userIsService } from "./NewRolesUtils2";
import { levenshteinDistance } from "./TextUtils";
import { getRoleName } from "./DevicesUtils";

export function hasRole(roles, roleName, farmID) {
    let role = roles.find(r => r.Role === roleName);
    if (role) {
        role = role.LocalRights.find(r => r.FarmID === farmID)
    }
    return role;
}

export function getRolesForCurrentUser(userRoles, farmID = store.getState().location.farm) {
    const userType = get(store.getState(), "user.user.UserType");
    const isOwner = userType === UserTypes.OWNER;
    const isService = userType === UserTypes.SERVICE;
    let result = [];
    for (let property in RoleTypes) {
        const roleObject = { hasRole: !!hasRole(userRoles, RoleTypes[property], farmID) };
        switch (RoleTypes[property]) {
            case RoleTypes.BREEDING:
                roleObject.text = i18next.t("breeding");
                break;
            case RoleTypes.REPORT_READ:
                roleObject.text = i18next.t("userRolePicker.reportsRead");
                break;
            case RoleTypes.REPORT_WRITE:
                roleObject.text = i18next.t("userRolePicker.reportsWrite");
                break;
            case RoleTypes.BUILDING:
                roleObject.text = i18next.t("userRolePicker.buildings");
                break;
            case RoleTypes.BILLING: {
                if (isOwner || isService) {
                    roleObject.text = i18next.t("billing"); // tylko owner/serwis moze widziec czy ktos ma dostep do platnosci}
                }
                break;
            }
            case RoleTypes.USER_MANAGE:
                roleObject.text = i18next.t("userRolePicker.userManage");
                break;
            case RoleTypes.ITS_READ:
                roleObject.text = i18next.t("userRolePicker.readingTasks");
                break;
            case RoleTypes.ITS_WRITE:
                roleObject.text = i18next.t("userRolePicker.writingTasks");
                break;
            case RoleTypes.FILES:
                roleObject.text = i18next.t("files");
                break;
            case RoleTypes.SALE_READ:
                roleObject.text = i18next.t("userRolePicker.saleRead");
                break;
            case RoleTypes.SALE_WRITE:
                roleObject.text = i18next.t("userRolePicker.saleWrite");
                break;
            case RoleTypes.ECONOMY_READ:
                roleObject.text = i18next.t("userRolePicker.economyRead");
                break;
            case RoleTypes.ECONOMY_WRITE:
                roleObject.text = i18next.t("userRolePicker.economyWrite");
                break;
            case RoleTypes.AI_READ:
                roleObject.text = "";
                break;
            case RoleTypes.AI_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CAGE_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CAGE_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_SILO_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_SILO_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_FEEDING_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_FEEDING_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_IPSUM_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_IPSUM_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_NUTRI_PRO_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_NUTRI_PRO_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CLIMATE_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CLIMATE_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CHAIN_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CHAIN_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_PIGLET_CAGE_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_PIGLET_CAGE_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_COUNTER_READ:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_COUNTER_WRITE:
                roleObject.text = "";
                break;
            case RoleTypes.DEVICE_CONFIG:
                roleObject.text = "";
                break;
            default:
                break;
        }
        result.push(roleObject);
    }
    return result.filter(r => r.text);
}

/**
 * funkcja sprawdza czy user może konfigurować urządzenia na danej fermie
 * @returns {boolean}
 */
export function checkIfUserCanManage() {
    const { user: { user: { Roles: userRoles, UserType } }, location: { farm: FarmID } } = store.getState();
    const deviceRoles = (userRoles || []).filter((r) => get(r, "Role", "").startsWith("_DEVICE"));
    if ([UserType.OWNER, UserTypes.SERVICE].includes(UserType)) return true;
    else {
        const deviceConfigRole = deviceRoles.find((dr) => dr.Role === RoleTypes.DEVICE_CONFIG);
        if (deviceConfigRole) {
            const hasRightsToFarm = deviceConfigRole.LocalRights.find((item) => item.FarmID === FarmID);
            if (hasRightsToFarm) return true;
        }
    }
    return false;
}

export function checkIfUserHasRole(role, farmID, userRoles = null) {
    if (!userRoles) {
        userRoles = store.getState().user.user.Roles;
    }
    if (!farmID) {
        farmID = store.getState().location.farm;
    }
    if (role === RoleTypes.SERVICE) {
        let serviceRole = userRoles.find(item => item.Role === RoleTypes.SERVICE);
        if (serviceRole) {
            return !!serviceRole.LocalRights.find(item => item.FarmID === farmID);
        }
        return false;
    }
    for (let uRole of userRoles) {
        if (uRole.Role === RoleTypes.SERVICE || uRole.Role === role) {
            let hasRightsToFarm = uRole.LocalRights.find((item) => item.FarmID === farmID);
            if (hasRightsToFarm) return true;
        }
    }
    return false;
}

export function checkIfUserHasAccessToProject(ProjID) {
    const projets = store.getState().projects.projects;
    return !!projets.find(item => item.ProjID === ProjID);
}

export function checkIfUserIsService(userRoles) {
    if (!userRoles) {
        const usr = store.getState().user.user;
        userRoles = usr && usr.Roles ? usr.Roles : [];
    }
    return userRoles.some(item => item.Role === RoleTypes.SERVICE);
}

export function checkIfUserIsMasterService(user) {
    if (!user) {
        user = store.getState().user.user;
    }
    return get(user, "MasterService", false);
}

export function checkIfUserIsTV(user) {
    if (!user) {
        user = store.getState().user.user;
    }
    return user.UserType === UserTypes.TV;
}

export function checkIfUserIsOwner() {
    let userType = store.getState().user.user.UserType;
    return userType === UserTypes.OWNER;
}

export function checkIfUserIsOperator() {
    let userType = store.getState().user.user.UserType;
    return userType === UserTypes.USER;
}

export function checkIfUserIsTranslator() {
    let userType = store.getState().user.user.UserType;
    return userType === UserTypes.TRANSLATOR;
}

export function checkIfUserIsSupport() {
    let userType = store.getState().user.user.UserType;
    return userType === UserTypes.SUPPORT;
}

export function checkIfUserHasAiRole(user = store.getState().user.user) {
    // serwis albo ai
    return get(user, "Roles", []).filter((item) => [RoleTypes.AI_READ, RoleTypes.SERVICE].includes(item.Role)).length > 0;
}

export function checkIfUserHasUsersManagement(user = store.getState().user.user) {
    return get(user, "Roles", []).filter(item => item.Role === RoleTypes.USER_MANAGE).length > 0;
}

export function checkIfUserHasBilling(user = store.getState().user.user) {
    return get(user, "Roles", []).filter(item => item.Role === RoleTypes.BILLING).length > 0;
}

export function checkIfUserHasToSubscribe(user = store.getState().user.user) {
    if (get(user, "Roles", []).filter((item) => [RoleTypes.SERVICE, RoleTypes.SUPPORT].includes(item.Role)).length > 0) return false;
    return !get(user, "AdminSubscription", false);
}

export function checkIfUserIsFarmAdmin(user = store.getState().user.user) {
    return user.UserType === UserTypes.MANAGER
}

export function hasMinPackageLevel(licPackages = [], packageKeys = [], packageLevels = [], considerServiceRole = true) {
    if (considerServiceRole && checkIfUserIsService()) return true;
    const toNumber = (level) => {
        switch (level) {
            case LicPackageLevel.EXTENDED:
                return 2;
            case LicPackageLevel.BASIC:
                return 1;
            default:
                return 0;
        }
    };
    for (let i = 0; i < packageKeys.length; i++) {
        const packageKey = packageKeys[i];
        const packageLevel = packageLevels[i];
        const level = licPackages[packageKey] || LicPackageLevel.NO_ACCESS;
        if (toNumber(level) >= toNumber(packageLevel)) return true;
    }
    return false;
}

export const getPackageForAnimalType = (animalType) => {
    switch (animalType) {
        case AnimalTypes.PORKER:
            return [LicPackageKeys.PORKER];
        case AnimalTypes.RENOVATION_SOW:
        case AnimalTypes.SOW:
        case AnimalTypes.BOAR:
            return [LicPackageKeys.SOW];
        case AnimalTypes.PIGLET:
            return [LicPackageKeys.SOW, LicPackageKeys.PORKER];
        default:
            return [];
    }
}

export function checkIfHasAccessToAnimalKind(animalType, licPackages, serviceRole = false, keys = []) {
    if (animalType === undefined && keys.length === 0) return false;
    const _keys = keys.length === 0 ? getPackageForAnimalType(animalType) : keys;
    const _levels = new Array(_keys.length).fill(LicPackageLevel.BASIC);
    return hasMinPackageLevel(licPackages, _keys, _levels, serviceRole);
}

/**
 * funkcja sprawdzajaca czy user jest kontem serwisowym, ownerem lub menadżerem
 * @param user
 * @returns {boolean}
 */
export function checkIfUserHasPrivilegedAccess(user = store.getState().user.user) {
    return [UserTypes.SERVICE, UserTypes.OWNER, UserTypes.MANAGER].includes(user.UserType);
}

/**
 * Funkcja sprawdza czy user jest ownerem
 * @param user
 * @param farm {string|null}
 * @return {boolean}
 */
export function userIsOwner(user, farm = null) {
    // kiedys bylo to sprawdzenie czy jest ownerem dla fermy ale teraz chyba zawsze ma dostep do wszystkich ferm
    return user?.UserType === UserTypes.OWNER;
}

export function canAccessDevice(device, { user, farm, locationMap } = {}) {
    if (!device) return false;
    if (!user || !farm || !locationMap) {
        let state = store.getState();
        user = state.user.user;
        farm = state.location.farm;
        locationMap = buildingsDB.getLocationFallthrough(farm);
    }
    if (userIsService(user, farm) || userIsOwner(user, farm)) return true;
    let roleName = getRoleName(device);
    if (roleName === null) return true; // jezeli rola dla urzadzenia to null to zatwierdz (TODO przeanalizowac co mozna wyciac)
    let role = user.Roles.find(item => item.Role === roleName);
    if (!role) return false; // jezeli nie znaleziono roli to odrzuc
    let localRight = role.LocalRights.find(item => item.FarmID === farm);
    if (!localRight || !isArray(localRight.Plcmnts)) return false; // jezeli nie znaleziono roli dla fermy to odrzuc
    if (role.Role.endsWith("_READ") && localRight.Plcmnts.length === 0) return true; // role do odczytu nie maja plmcnt wiec mozemy zwrocic true
    if (localRight.Plcmnts.includes("*")) return true;
    for (let plcmntID of localRight.Plcmnts) {
        try {
            const locationIds = locationMap[plcmntID] || [];
            if (isArray(device.PlcmntID)) {
                if (device.PlcmntID.some(({ PlcmntID }) => locationIds.includes(PlcmntID))) {
                    return true;
                }
            }
        } catch (err) {
            console.error(err);
        }

    }
    return false;
}

export function filterDevicesListByRoles(devices) {
    const { user: { user }, location: { farm } } = store.getState();
    if (userIsService(user, farm) || userIsOwner(user, farm)) return devices;
    let locationMap = buildingsDB.getLocationFallthrough(farm);
    return devices.filter(device => canAccessDevice(device, { user, farm, locationMap }));
}

export function checkIfUserIsEmployee(employee) {
    const currentUser = store.getState().user?.user;
    return currentUser?.LocalUserID === employee?.LocalUserID;
}

function getBestFlatRoleKey(flatRoleKeys, roleName) {
    let bestKey = "";
    let bestDistance = null;
    for (let key of flatRoleKeys) {
        const distance = levenshteinDistance(key, roleName);
        if (distance < bestDistance || bestDistance === null) {
            bestKey = key;
            bestDistance = distance;
        }
    }
    return roleName.includes(bestKey) ? bestKey : null;
}

export function getRoleStagesForSpecificUser(user, FarmID) {
    if (!user) return [];
    const flatRoleTypes = {
        // zarządzanie
        [FlatRoleTypes.BREEDING]: "",
        [FlatRoleTypes.REPORT]: "",
        [FlatRoleTypes.ITS]: "",
        [FlatRoleTypes.ITS_HISTORY]: "",
        [FlatRoleTypes.FILES]: "",
        [FlatRoleTypes.SALE]: "",
        [FlatRoleTypes.ECONOMY]: "",
        [FlatRoleTypes.AI]: "",
        [FlatRoleTypes.FARM_MAP]: "",
        // urządzenia
        [FlatRoleTypes.DEVICE_CAGE]: "",
        [FlatRoleTypes.DEVICE_SILO]: "",
        [FlatRoleTypes.DEVICE_FEEDING]: "",
        [FlatRoleTypes.DEVICE_IPSUM]: "",
        [FlatRoleTypes.DEVICE_NUTRI_PRO]: "",
        [FlatRoleTypes.DEVICE_CLIMATE]: "",
        [FlatRoleTypes.DEVICE_CHAIN]: "",
        [FlatRoleTypes.DEVICE_PIGLET_CAGE]: "",
        [FlatRoleTypes.DEVICE_COUNTER]: "",
        [FlatRoleTypes.DEVICE_FARM_WATCH_PRO]: "",
        // ustawienia
        [FlatRoleTypes.BUILDING]: "",
        [FlatRoleTypes.BILLING]: "",
        [FlatRoleTypes.USER_MANAGE]: "",
        [FlatRoleTypes.DEVICE_CONFIG]: ""
    };
    Object.keys(flatRoleTypes).forEach((key) => flatRoleTypes[key] = RoleStage.NONE);
    user.roles.filter((r) => r.LocalRights.some((lr) => lr.FarmID === FarmID)).sort((a, b) => a.Role - b.Role).map((role) => {
        const similarRoleName = getBestFlatRoleKey(Object.keys(flatRoleTypes), role.Role);
        if (similarRoleName) {
            if (!role.Role.includes("WRITE") && !role.Role.includes("READ")) flatRoleTypes[similarRoleName] = RoleStage.DEFAULT;
            if (role.Role.includes("READ")) {
                const hasWriteAssigment = flatRoleTypes[similarRoleName] === RoleStage.WRITE;
                if (!hasWriteAssigment) flatRoleTypes[similarRoleName] = RoleStage.READ;
            }
            if (role.Role.includes("WRITE")) {
                const localRights = role.LocalRights;
                flatRoleTypes[similarRoleName] = (Array.isArray(localRights.Plcmnts) && localRights.Plcmnts.length === 1 && localRights.Plcmnts[0] === "*") || !localRights.hasOwnProperty("Plcmnts") ? RoleStage.WRITE : RoleStage.CUSTOM;
            }
        }

    });
    return flatRoleTypes;
}

export const filterFarmData = memoizeOne((farmData = [], searchString = "") => {
    return farmData.slice(0).filter((_fd) => {
        return _fd.FarmName.trim().toLowerCase().includes(searchString.trim().toLowerCase());
    });
}, isEqual);

export const getClientPackageForAnimalType = memoizeOne((animalKind) => {
    let clientPackage = {};
    if ([AnimalTypes.BOAR, AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, "pigletsBalance"].includes(animalKind)) {
        clientPackage = { [LicPackageKeys.SOW]: LicPackageLevel.BASIC };
    } else if (animalKind === AnimalTypes.PORKER) {
        clientPackage = { [LicPackageKeys.PORKER]: LicPackageLevel.BASIC };
    } else if (animalKind === AnimalTypes.PIGLET) {
        clientPackage = {
            [LicPackageKeys.SOW]: LicPackageLevel.BASIC,
            [LicPackageKeys.PORKER]: LicPackageLevel.BASIC
        }
    }
    return clientPackage;
});

