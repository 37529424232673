import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useQueryCaller from '../../../hooks/useQueryCaller';
import { getAnimalParametersFirstFetching } from '../../../selectors/animalParametersSelector';
import { getFirstTechnologyGroupStart, getTechnologyGroupWeeks } from '../../../utils/SettingsUtils';
import { formatTechnoGroupName, getLastTechnologyGroups, getTechnologyGroupsAnimals } from '../../../utils/TechnologyGroupUtils';
import Select from "../../basics/select/Select";
import "./_technology-group-select.scss";

function TechnologyGroupSelectRenderer({ value, onFocus, onChange }) {
    const { t } = useTranslation();

    const [groups, setGroups] = useState([]);

    const queryCaller = useQueryCaller();

    useEffect(() => {
        async function fetchData() {
            const technologyGroupStart = getFirstTechnologyGroupStart();
            const technologyGroupDuration = getTechnologyGroupWeeks();
            const lastGroups = getLastTechnologyGroups(technologyGroupStart, technologyGroupDuration, Math.ceil(21 / technologyGroupDuration));
            const tmp = lastGroups.map(item => formatTechnoGroupName(item.start, item.end, true));
            const res = await getTechnologyGroupsAnimals(tmp, queryCaller);
            setGroups(tmp.map(group => ({ value: { AnmIDs: res[group]?.animals || [], group }, name: group })).reverse());
        }

        fetchData();
    }, [queryCaller]);

    return (
        <div className="technology-group-select">
            <Select value={value} onFocus={onFocus} options={groups} onChange={onChange} placeholder={t("technologyGroup")} callOnChangeOnSingleOption={false} />
        </div>
    );
}

export default function TechnologyGroupSelect({ value, onFocus, onChange }) {

    const firstFetchingParams = useSelector(getAnimalParametersFirstFetching);

    if (firstFetchingParams) {
        return (
            <div className="position-relative w-100 h-100">
                <div className="technology-group-select-loading">
                    <i className="fas fa-circle-notch fa-spin" />
                </div>
            </div>
        );
    }
    return (
        <TechnologyGroupSelectRenderer value={value} onFocus={onFocus} onChange={onChange} />
    );
}
